// eslint-disable-next-line @atlaskit/design-system/no-unsupported-drag-and-drop-libraries
import type {
	DraggableProvided,
	DraggableStateSnapshot,
	DroppableProvided,
	DroppableStateSnapshot,
	DraggableRubric,
	DragDropContext,
	DragDropContextProps,
	Draggable,
	DraggableProps,
	Droppable,
	DroppableProps,
} from 'react-beautiful-dnd';

const providedDraggable: DraggableProvided = {
	innerRef: () => undefined,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	draggableProps: {} as DraggableProvided['draggableProps'],
	dragHandleProps: undefined,
};

const snapshotDraggable: DraggableStateSnapshot = {
	isDragging: false,
	isDropAnimating: false,
	dropAnimation: undefined,
	draggingOver: undefined,
	combineWith: undefined,
	combineTargetFor: undefined,
	mode: undefined,
	isClone: false,
};

const providedDroppable: DroppableProvided = {
	innerRef: () => undefined,
	placeholder: undefined,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	droppableProps: {} as DroppableProvided['droppableProps'],
};

const snapshotDroppable: DroppableStateSnapshot = {
	isDraggingOver: false,
	draggingOverWith: undefined,
	draggingFromThisWith: undefined,
	isUsingPlaceholder: false,
};

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const rubricDraggable = {} as DraggableRubric;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const DegenerateDragDropContext = (({ children }: DragDropContextProps) =>
	children) as unknown as typeof DragDropContext;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const DegenerateDraggable = (({ children }: DraggableProps) =>
	children(providedDraggable, snapshotDraggable, rubricDraggable)) as unknown as typeof Draggable;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const DegenerateDroppable = (({ children }: DroppableProps) =>
	children(providedDroppable, snapshotDroppable)) as unknown as typeof Droppable;
