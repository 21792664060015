import React from 'react';
import { styled } from '@compiled/react';
import {
	STAGING_ENVIRONMENT,
	DEVELOPMENT_ENVIRONMENT,
} from '@atlassian/jira-forge-ui-constants/src/constants.tsx';
import type { ExtensionEnvironment } from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import { nameWithCustomEnvironment, EnvironmentLozenge } from './environment-lozenge/index.tsx';

export type Props = {
	extensionName: string;
	extensionEnvType: ExtensionEnvironment;
	extensionEnvKey?: string;
};

const shouldShowEnv = (extensionEnvType: ExtensionEnvironment) =>
	[STAGING_ENVIRONMENT, DEVELOPMENT_ENVIRONMENT].includes(extensionEnvType);

const ExtensionTitle = ({ extensionEnvType, extensionEnvKey, extensionName }: Props) => {
	if (!shouldShowEnv(extensionEnvType)) {
		return <>{extensionName}</>;
	}
	return (
		<ExtensionTitleWrapper>
			{nameWithCustomEnvironment(extensionName, extensionEnvType, extensionEnvKey)}{' '}
			<EnvironmentLozenge environmentType={extensionEnvType} />
		</ExtensionTitleWrapper>
	);
};

export { ExtensionTitle };

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ExtensionTitleWrapper = styled.div({
	alignItems: 'baseline',
	/* The below selector targets span tag of <EnvironmentLozenge /> */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&>span': {
		flexShrink: 0,
	},
});
