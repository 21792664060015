import React, { type ReactNode } from 'react';
import Button, { type ButtonProps } from '@atlaskit/button';
import { LinkButton, type LinkButtonProps } from '@atlaskit/button/new';
import { Link, generatePath, type Route, type LinkProps } from '@atlassian/react-resource-router';

type RouteLinkButtonPropsOld = ButtonProps &
	LinkProps & {
		children: ReactNode;
	};

export const RouteLinkButtonOld = ({ children, to, params, ...rest }: RouteLinkButtonPropsOld) => (
	<Button
		component={Link}
		{...rest}
		{...(to && {
			// Link would not update when 'to' prop changes, so we need to force an update with key
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			key: (to as Route).name,
			// href prop is required to be passed to the Button component for Button to render as an anchor tag
			// instead of a button tag. Remove this line and related code after this issue is fixed for AK Button.
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			href: generatePath((to as Route).path, params),
			to,
			params,
		})}
	>
		{children}
	</Button>
);

type RouteLinkButtonProps = Partial<LinkButtonProps> & {
	to: Route;
	params: {
		[paramName: string]: string | number | boolean | null | undefined;
	};
};

export const RouteLinkButton = ({ to, params, children, ...rest }: RouteLinkButtonProps) => (
	<LinkButton {...rest} key={to.name} href={generatePath(to.path, params)}>
		{children}
	</LinkButton>
);
