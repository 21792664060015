import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	minimize: {
		id: 'dashboard-internal-common.gadget.toolbar.minimize',
		defaultMessage: 'Minimize',
		description: 'Minimize gadget',
	},
	minimizeAriaLabel: {
		id: 'dashboard-internal-common.gadget.toolbar.minimize-aria-label',
		defaultMessage: 'Minimize {gadgetName}',
		description:
			'aria-label text as a verb for button to minimize gadget on a dashboard. {gadgetName} will be replaced with the name of a gadget',
	},
	expand: {
		id: 'dashboard-internal-common.gadget.toolbar.expand',
		defaultMessage: 'Expand',
		description: 'expand gadget',
	},
	expandAriaLabel: {
		id: 'dashboard-internal-common.gadget.toolbar.expand-aria-label',
		defaultMessage: 'Expand {gadgetName}',
		description:
			'aria-label text as a verb for button to expand gadget on a dashboard. {gadgetName} will be replaced with the name of a gadget',
	},
	maximize: {
		id: 'dashboard-internal-common.gadget.toolbar.maximize',
		defaultMessage: 'Maximize',
		description: 'maximize gadget',
	},
	maximizeAriaLabel: {
		id: 'dashboard-internal-common.gadget.toolbar.maximize-aria-label',
		defaultMessage: 'Maximize {gadgetName}',
		description:
			'aria-label text as a verb for button to maximize gadget on a dashboard. {gadgetName} will be replaced with the name of a gadget',
	},
	refresh: {
		id: 'dashboard-internal-common.gadget.toolbar.refresh',
		defaultMessage: 'Refresh',
		description: 'refresh gadget',
	},
	refreshAriaLabel: {
		id: 'dashboard-internal-common.gadget.toolbar.refresh-aria-label',
		defaultMessage: 'Refresh {gadgetName}',
		description:
			'aria-label text as a verb for button to refresh gadget on a dashboard. {gadgetName} will be replaced with the name of a gadget',
	},
	singleColumn: {
		id: 'dashboard-internal-common.gadget.toolbar.single-column',
		defaultMessage: 'Single column',
		description: 'Single column gadget',
	},
});
