import { useCallback, useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useCurrentPage } from '@atlassian/jira-spa-state-controller/src/components/current-page/index.tsx';
import { useSpaStateTransition } from '@atlassian/jira-spa-state-controller/src/components/transition-state/index.tsx';

export const useDashboardScrollAnalytics = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const currentPage = useCurrentPage();
	const [{ isInitialRender, currentPageId }] = useSpaStateTransition();
	const { startTime } = currentPage;
	const scrollStartTimestamp = useRef<number | null>(null);

	const debounceSendAnalytics = debounce((_scrollStartTimestamp) => {
		if (scrollStartTimestamp.current != null) return;

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const percentageOfPageScrolled = window.scrollY / window.innerHeight;

		if (percentageOfPageScrolled < 0.2) return;

		fireUIAnalytics(
			createAnalyticsEvent({ action: 'scrolled', actionSubject: 'page' }),
			'dashboardScroll',
			{
				isInitialRender,
				scrollStartTimeFromPageLoad: _scrollStartTimestamp - startTime,
				percentageOfPageScrolled,
				currentPageId,
			},
		);
		scrollStartTimestamp.current = _scrollStartTimestamp;
	}, 500);

	useEffect(() => {
		const fireScrollAnalytics = () => {
			debounceSendAnalytics(Date.now());
		};
		const resetScrollPosition = () => {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.scrollTo(0, 0);
		};

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.addEventListener('scroll', fireScrollAnalytics);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.addEventListener('unload', resetScrollPosition);

		return () => {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.removeEventListener('scroll', fireScrollAnalytics);

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.removeEventListener('unload', resetScrollPosition);
		};
	}, [debounceSendAnalytics]);

	const resetScrollStartTime = useCallback(() => {
		scrollStartTimestamp.current = null;
	}, []);

	return { scrollStartTimestamp, resetScrollStartTime };
};
