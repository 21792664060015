import React from 'react';
import { FavoriteButtonWrapper } from '@atlassian/jira-favourite-button-stateless/src/view/view.tsx';
import FavoriteButtonInner from '@atlassian/jira-favourite-button/src/index.tsx';
import { DASHBOARDS_ITEM_TYPE } from '@atlassian/jira-favourite-change-provider/src/model/constants.tsx';
import type { FavoriteButtonProps } from './types.tsx';

export const FavoriteButton = ({ dashboardId, dashboardName }: FavoriteButtonProps) => (
	<FavoriteButtonWrapper>
		<FavoriteButtonInner
			baseUrl=""
			itemId={dashboardId}
			itemType={DASHBOARDS_ITEM_TYPE}
			favoriteItemName={dashboardName}
		/>
	</FavoriteButtonWrapper>
);
