import { useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';
import { GADGET_HEIGHT_UPDATE_WAIT_TIME } from '@atlassian/jira-dashboard-common/src/constants.tsx';
import type { SaveCustomGadgetTitle } from '@atlassian/jira-dashboard-common/src/types.tsx';
import { useDashboardResource } from '@atlassian/jira-router-resources-dashboard/src/index.tsx';
import { updateLocalStorageHeight } from '../utils/local-storage/index.tsx';
import { useGadgetAdditionalStateActions } from './gadget-additional-state.tsx';

type ConnectApiArgs = {
	saveCustomGadgetTitle: SaveCustomGadgetTitle;
};

type SetGadgetHeight = (height: number) => void;

export const useConnectApi = ({ saveCustomGadgetTitle }: ConnectApiArgs) => {
	const { data, update } = useDashboardResource();
	const debouncedHeightRef = useRef<Record<string, null | SetGadgetHeight>>({});
	const [, { addNonRenamable, addForcedHeight }] = useGadgetAdditionalStateActions();

	useEffect(() => {
		if (!data) return;

		const setGadgetTitle = async (id: string, title: string) => {
			if (data.gadgets.some((g) => g.id === id)) {
				saveCustomGadgetTitle(id, title);
				addNonRenamable(id);
			}
		};

		const setGadgetHeight = (id: string, height: number) => {
			if (debouncedHeightRef.current[id] == null) {
				debouncedHeightRef.current[id] = debounce((debouncedHeight) => {
					updateLocalStorageHeight(data.id, id, debouncedHeight);
					addForcedHeight(id, debouncedHeight);
				}, GADGET_HEIGHT_UPDATE_WAIT_TIME);
			}
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			(debouncedHeightRef.current[id] as SetGadgetHeight)(height);
		};

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const isGadgetConfigurable = (id: any) => {
			const gadget = data.gadgets.find((g) => g.id === id);
			const { configurable, hasNonHiddenUserPrefs, renderedGadgetUrl } = gadget || {};

			return data.writable && (configurable || hasNonHiddenUserPrefs || Boolean(renderedGadgetUrl));
		};

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.dashboardSpaBridge = {
			setGadgetTitle,
			setGadgetHeight,
			isGadgetConfigurable,
		};
	}, [data, addNonRenamable, addForcedHeight, saveCustomGadgetTitle, update]);
};
