import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	moreActions: {
		id: 'dashboard-internal-common.dashboard-content.header.action-dropdown.more-actions',
		defaultMessage: 'More dashboard actions',
		description: 'Dashboard hamburger menu description',
	},
	copy: {
		id: 'dashboard-internal-common.dashboard-content.header.action-dropdown.copy',
		defaultMessage: 'Copy',
		description: 'Copy',
	},
	automaticRefresh: {
		id: 'dashboard-internal-common.dashboard-content.header.action-dropdown.automatic-refresh',
		defaultMessage: 'Configure automatic refresh',
		description: 'Configure automatic refresh',
	},
	renameOrShare: {
		id: 'dashboard-internal-common.dashboard-content.header.action-dropdown.rename-or-share',
		defaultMessage: 'Rename or share',
		description: 'Rename or share',
	},
	share: {
		id: 'dashboard-internal-common.dashboard-content.header.action-dropdown.share',
		defaultMessage: 'Share dashboard',
		description: 'Share dashboard',
	},
	moveToTrash: {
		id: 'dashboard-internal-common.dashboard-content.header.action-dropdown.move-to-trash',
		defaultMessage: 'Move to trash',
		description: 'Move the current active dashboard to trash',
	},
	find: {
		id: 'dashboard-internal-common.dashboard-content.header.action-dropdown.find',
		defaultMessage: 'View all dashboards',
		description: 'View all dashboards',
	},
	create: {
		id: 'dashboard-internal-common.dashboard-content.header.action-dropdown.create',
		defaultMessage: 'Create a dashboard',
		description: 'Create a dashboard',
	},
	wallboard: {
		id: 'dashboard-internal-common.dashboard-content.header.action-dropdown.wallboard',
		defaultMessage: 'View as wallboard',
		description: 'View as wallboard',
	},
});
