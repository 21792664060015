import type { GadgetData } from '@atlassian/jira-dashboard-common/src/types.tsx';

export const toColumns = (gadgets: GadgetData[], layout: string) => {
	const getGadgetsForCol = (col: number): GadgetData[] =>
		gadgets.filter((gadget) => gadget.column === col);

	switch (layout) {
		case 'A':
			return [getGadgetsForCol(0)];
		case 'AA':
		case 'AB':
		case 'BA':
			return [getGadgetsForCol(0), getGadgetsForCol(1)];
		case 'AAA':
			return [getGadgetsForCol(0), getGadgetsForCol(1), getGadgetsForCol(2)];
		default:
			throw new Error('Unexpected layout was provided');
	}
};
