import React from 'react';
import { isReactKey } from '@atlassian/jira-react-gadgets-definition/src/main.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import {
	useLocalStorageGadgetHeight,
	useOnGadgetRender,
} from '../../../controllers/above-the-fold/main.tsx';
import { useGadgetDataContextEnhancer } from '../../../controllers/gadget-data.tsx';
import { isConnectGadget } from '../../../utils/gadget-type.tsx';
import { Amd } from './amd/main.tsx';
import { Connect } from './connect/index.tsx';
import { Forge } from './forge/index.tsx';
import { InlineHtml } from './inline-html/index.tsx';
import { ReactGadget } from './react/index.tsx';
import type { GadgetInnerProps } from './types.tsx';

export const GadgetInner = ({
	dashboardId,
	data,
	isUnconfigured,
	isEditModeEnabled,
	isLoading,
	isInEditMode,
	setIsLoading,
	setDefaultTitle,
	setTitle,
	shouldUseCache,
	isMaximized,
	onEditModeCancel,
	unconfiguredFallback,
	onRegisterConnectGadget,
	onUnregisterConnectGadget,
}: GadgetInnerProps) => {
	const enhancedContext = useGadgetDataContextEnhancer(data.context);
	const onGadgetRender = useOnGadgetRender(data.id);
	const unconfiguredModernGadgetInViewMode =
		!isInEditMode && isUnconfigured && !isConnectGadget(data);
	const localStorageHeight = useLocalStorageGadgetHeight(data.id);

	if (unconfiguredFallback != null && unconfiguredModernGadgetInViewMode) {
		return unconfiguredFallback;
	}

	if (isConnectGadget(data)) {
		return (
			<UFOSegment name="gadget-inner-connect">
				<Connect
					dashboardId={dashboardId}
					gadgetId={data.id}
					inlineHtml={data.inlineHtml}
					isLoading={isLoading}
					isInEditMode={isInEditMode}
					setIsLoading={setIsLoading}
					onEditModeCancel={onEditModeCancel}
					onRegister={onRegisterConnectGadget}
					onUnregister={onUnregisterConnectGadget}
					setTitle={setTitle}
				/>
			</UFOSegment>
		);
	}

	if (data.amdModule != null) {
		return (
			<UFOSegment name="gadget-inner-amd">
				<Amd
					dashboardId={dashboardId}
					gadgetId={data.id}
					amdModule={data.amdModule}
					context={enhancedContext}
					defaultTitle={data.title}
					userPrefs={data.userPrefs}
					inlineHtml={data.inlineHtml}
					isEditable={isEditModeEnabled}
					isLoading={isLoading}
					isInEditMode={isInEditMode}
					setIsLoading={setIsLoading}
					setDefaultTitle={setDefaultTitle}
					shouldUseCache={shouldUseCache}
					isMaximized={isMaximized}
					onEditModeCancel={onEditModeCancel}
				/>
			</UFOSegment>
		);
	}

	if (data.inlineHtml != null && data.renderedGadgetUrl == null) {
		return (
			<UFOSegment name="gadget-inner-inline-html">
				<InlineHtml
					id={data.id}
					inlineHtml={data.inlineHtml}
					isLoading={isLoading}
					setIsLoading={setIsLoading}
				/>
			</UFOSegment>
		);
	}

	if (data.forge != null) {
		return (
			<UFOSegment name="gadget-inner-forge">
				<Forge
					forgeKey={data.forge.key}
					gadgetData={data}
					isInEditMode={isInEditMode}
					onEditModeCancel={onEditModeCancel}
					setIsLoading={setIsLoading}
				/>
			</UFOSegment>
		);
	}

	if (isReactKey(data.reactKey)) {
		return (
			<UFOSegment name="gadget-inner-react">
				<ReactGadget
					id={data.id}
					reactKey={data.reactKey}
					onEditModeCancel={onEditModeCancel}
					isInEditMode={isInEditMode}
					userPrefs={data.userPrefs}
					onRenderComplete={onGadgetRender}
					setDefaultTitle={setDefaultTitle}
					customSkeletonHeightInPx={localStorageHeight}
				/>
			</UFOSegment>
		);
	}

	throw new Error(`Unrecognised gadget type [${data.title}, ${data?.gadgetSpecUrl}]`);
};
