import { useCallback } from 'react';
import {
	getGadgetDataFromLocalStorage,
	putGadgetDataFromLocalStorage,
	deleteGadgetDataFromLocalStorage,
} from '../utils/local-storage/index.tsx';

export const useGetGadgetDataFromLocalStorage = (id: string) =>
	useCallback<(arg1: string) => string | undefined>(
		(...args) => getGadgetDataFromLocalStorage(id, ...args),
		[id],
	);

export const usePutGadgetDataFromLocalStorage = (id: string) =>
	useCallback<(arg1: string, arg2: string) => void>(
		(...args) => putGadgetDataFromLocalStorage(id, ...args),
		[id],
	);

export const useDelGadgetDataFromLocalStorage = (id: string) =>
	useCallback<(arg1: string) => void>(
		(...args) => deleteGadgetDataFromLocalStorage(id, ...args),
		[id],
	);

export type LocalStorageService = {
	get: ReturnType<typeof useGetGadgetDataFromLocalStorage>;
	put: ReturnType<typeof usePutGadgetDataFromLocalStorage>;
	del: ReturnType<typeof useDelGadgetDataFromLocalStorage>;
};

export const useLocalStorageService = (id: string): LocalStorageService => {
	const get = useGetGadgetDataFromLocalStorage(id);
	const put = usePutGadgetDataFromLocalStorage(id);
	const del = useDelGadgetDataFromLocalStorage(id);

	return { get, put, del };
};
