import type { MouseEvent, KeyboardEvent } from 'react';
import { UI_EVENT_TYPE } from '@atlassian/jira-product-analytics-bridge';
import { useRouterActions } from '@atlassian/react-resource-router';
import { useDashboardAnalytics } from '../controllers/dashboard-analytics/main.tsx';
import { clickedWithShortcutKeys } from './events/index.tsx';

// Dropdown items in Atlaskit don't trigger an SPA rerender when they open a new URL.
// This hook allows for an SPA transition when the dropdown item has been clicked.
export const useDropdownItemOnClickHandler = () => {
	const { push } = useRouterActions();
	const fireAnalytics = useDashboardAnalytics();

	const dropdownItemClickHandler = (
		e: MouseEvent<Element> | KeyboardEvent<Element>,
		url: string,
		actionSubject?: string,
	) => {
		if (actionSubject) {
			fireAnalytics({
				eventType: UI_EVENT_TYPE,
				actionSubject,
				action: 'clicked',
				actionSubjectId: 'dashboard',
			});
		}
		if (!clickedWithShortcutKeys(e)) {
			e.preventDefault();
			push(url);
		}
	};

	return { dropdownItemClickHandler };
};
