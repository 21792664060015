import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	linkInterceptorUrlTooLongTitle: {
		id: 'dashboard-internal-common.controllers.link-interceptor-url-too-long-title',
		defaultMessage: 'Requested URL too large',
		description: 'Popup warning title when over limit URL is detected',
	},
	linkInterceptorUrlTooLongDescription: {
		id: 'dashboard-internal-common.controllers.link-interceptor-url-too-long-description',
		defaultMessage: 'Try shortening the JQL of your saved filter.',
		description: 'Popup warning description when over limit URL is detected',
	},
});
