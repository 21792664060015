import { useMemo } from 'react';
import type {
	GadgetContext,
	EnhancedGadgetContext,
	EnhancedGadgetContextUser,
} from '@atlassian/jira-dashboard-common/src/types.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { useLanguageTag } from '@atlassian/jira-tenant-context-controller/src/components/language-tag/index.tsx';

export const enhanceGadgetContextUser = (
	{ user, ...context }: GadgetContext,
	enhancedUser: Partial<EnhancedGadgetContextUser>,
): EnhancedGadgetContext => ({
	...context,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	user: {
		...user,
		...enhancedUser,
	} as EnhancedGadgetContextUser,
});

export const useGadgetDataContextEnhancer = (data: GadgetContext): EnhancedGadgetContext => {
	const accountId = useAccountId();
	const languageTag = useLanguageTag();

	return useMemo<EnhancedGadgetContext>(
		() => enhanceGadgetContextUser(data, { accountId, languageTag }),
		[accountId, data, languageTag],
	);
};
