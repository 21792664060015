import React from 'react';
import Heading from '@atlaskit/heading';
import { Box, xcss } from '@atlaskit/primitives';
import {
	EmptyDashboardPlaceholderContainer,
	EmptyDashboardPlaceholderContentContainer,
	EmptyDashboardPlaceholderText,
} from '@atlassian/jira-dashboard-internal-common/src/ui/dashboard-content/empty-dashboard-placeholder/index.tsx';
import ToolboxIllustration from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/objects/components/toolbox/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

type EmptyDashboardPlaceholderProps = {
	hasEditPermissions: boolean;
};

const TOOLBOX_ILLUSTRATION_SIZE = '256px';

export const EmptyDashboardPlaceholder = ({
	hasEditPermissions,
}: EmptyDashboardPlaceholderProps) => {
	const { formatMessage } = useIntl();

	return (
		<EmptyDashboardPlaceholderContainer>
			<EmptyDashboardPlaceholderContentContainer>
				<Box xcss={boxStyle}>
					<ToolboxIllustration alt="" data-testId="empty-dashboard-img-placeholder" />
				</Box>
				<Heading size="large">{formatMessage(messages.header)}</Heading>
				<EmptyDashboardPlaceholderText>
					{hasEditPermissions
						? formatMessage(messages.clickEditButton)
						: formatMessage(messages.noPermissions)}
				</EmptyDashboardPlaceholderText>
			</EmptyDashboardPlaceholderContentContainer>
		</EmptyDashboardPlaceholderContainer>
	);
};

const boxStyle = xcss({
	width: TOOLBOX_ILLUSTRATION_SIZE,
	height: TOOLBOX_ILLUSTRATION_SIZE,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});
