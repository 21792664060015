// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ComponentProps, forwardRef } from 'react';
import { styled } from '@compiled/react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Container = styled.div<{ minHeight?: number }>({
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	minHeight: ({ minHeight }) => `${minHeight}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SkeletonWrapper = styled.div({
	position: 'absolute',
	left: 0,
	top: 0,
	width: '100%',
	height: '100%',
	background: '#ffffff',
	zIndex: 50,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const GadgetContainerComponent = styled.div<{ isDisabled: boolean; isHidden: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	display: ({ isHidden }) => (isHidden ? 'none' : 'inherit'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	pointerEvents: ({ isDisabled }) => (isDisabled ? 'none' : 'inherit'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const GadgetContainer = forwardRef<
	HTMLDivElement,
	ComponentProps<typeof GadgetContainerComponent>
>((props, ref) => (
	// for monolith to identify gadget
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
	<GadgetContainerComponent className="connect-gadget" {...props} ref={ref} />
));
