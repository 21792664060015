import React from 'react';
import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled, { css } from 'styled-components';
import { colors } from '@atlaskit/theme';
import { h200 } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';
import { fontFamily } from '@atlassian/jira-common-styles/src/main.tsx';

type Props = {
	label: string;
	htmlFor?: string;
	isRequired?: boolean;
};

export const FieldLabel = ({ label, htmlFor, isRequired = false }: Props) => (
	<Label htmlFor={htmlFor}>
		<LabelText>
			<span>{label}</span>
			{isRequired ? <RequiredIndicator role="presentation">*</RequiredIndicator> : null}
		</LabelText>
	</Label>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Label = styled2.label({
	display: 'block',
});

// FIXME: h200() mixin as first arg causes type error in object syntax
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LabelText = styled.div`
	${h200()}
	display: block;
	font-family: ${fontFamily};
	margin: 0; /* override h200 margins. Padding used for backwards compatibility */
	${css({
		padding: `${token('space.250', '20px')} 0px ${token('space.050', '4px')} 0px`,
	})}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RequiredIndicator = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.danger', colors.R400),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	fontFamily,
	paddingLeft: token('space.025', '2px'),
});
RequiredIndicator.displayName = 'RequiredIndicator';
