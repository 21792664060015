import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	header: {
		defaultMessage: 'This dashboard is empty',
		id: 'dashboard-view.empty-dashboard-placeholder.header',
		description: 'Message displayed to the user saying that the current dashboard is empty.',
	},
	clickEditButton: {
		defaultMessage:
			'You can add to this dashboard by clicking the ‘Edit’ button on the top right corner.',
		id: 'dashboard-view.empty-dashboard-placeholder.click-edit-button',
		description: 'Description to instruct user to click the edit button to add to the dashboard.',
	},
	noPermissions: {
		defaultMessage:
			"You don't have permissions to add to this dashboard. You may want to create your own dashboard instead, by selecting Dashboards > Create Dashboard in the top navigation bar.",
		id: 'dashboard-view.empty-dashboard-placeholder.no-permissions',
		description:
			"Description to tell users that they don't have permissions to add to the current dashboard and suggests them to create their own dashboard.",
	},
});
