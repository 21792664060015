import { useCallback } from 'react';
import { useDashboardResource } from '@atlassian/jira-router-resources-dashboard/src/index.tsx';

type DasboardOperationData = {
	name: string;
};

export const useEditDashboard = () => {
	const { update } = useDashboardResource();

	return useCallback(
		(dashboardData: DasboardOperationData) => {
			if (!dashboardData) {
				return;
			}

			update(
				(currentData) =>
					currentData && {
						...currentData,
						title: dashboardData.name,
					},
			);
		},
		[update],
	);
};
