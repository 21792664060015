import React, { type ReactNode } from 'react';
import InlineMessage from '@atlaskit/inline-message';
import { Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { Link } from '@atlassian/react-resource-router';
import messages from './messages.tsx';

export const DeprecationTooltip = () => {
	const { formatMessage } = useIntl();
	return (
		<Box xcss={tooltipStyles}>
			<InlineMessage appearance="warning">
				{formatMessage(messages.gadgetDeprecationTooltip, {
					Link: (chunks: ReactNode[]) => (
						<Link href="https://community.atlassian.com/t5/Jira-articles/Update-We-re-ending-support-for-five-dashboard-gadgets-in-May/ba-p/2844556">
							{chunks}
						</Link>
					),
					br: () => (
						<>
							<br />
							<br />
						</>
					),
				})}
			</InlineMessage>
		</Box>
	);
};

const tooltipStyles = xcss({
	marginLeft: 'space.050',
});
