import { createHook } from '@atlassian/react-sweet-state';
import Store from '../../store.tsx';

const useSweetStateCurrentPage = createHook(Store, {
	selector: (state) => state.currentPage,
});

export const useCurrentPage = () => {
	const [currentPage] = useSweetStateCurrentPage();
	return currentPage;
};
