type ShortcutEvent = {
	button?: number;
	metaKey: boolean;
	ctrlKey: boolean;
	altKey: boolean;
	shiftKey: boolean;
};
// Check if shortcut keys are used to open link in a new tab/window etc
export const clickedWithShortcutKeys = (e: ShortcutEvent) => {
	if (e.metaKey || e.ctrlKey || e.altKey || e.shiftKey || e.button === 1) {
		return true;
	}
	return false;
};
