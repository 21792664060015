import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	wallboardSlideshow: {
		id: 'dashboard-internal-common.dashboard-content.header.action-dropdown.wallboard-slideshow.wallboard-slideshow',
		defaultMessage: 'View wallboard slide show',
		description: 'Link to wallboard slideshow',
	},
	wallboardSetup: {
		id: 'dashboard-internal-common.dashboard-content.header.action-dropdown.wallboard-slideshow.wallboard-setup',
		defaultMessage: 'Configure wallboard slide show',
		description: 'Open wallboard slideshow modal to set up wallboard slideshow',
	},
});
