// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ComponentProps } from 'react';
import Blanket from '@atlaskit/blanket';
import sendExperienceAnalytics from '@atlassian/jira-common-experience-tracking-analytics/src/index.tsx';
import type { CopyDashboardModal } from '@atlassian/jira-copy-dashboard-modal/src/index.tsx';
import {
	MODAL_ID,
	COPY_DASHBOARD_EXPERIENCE,
	COPY_DASHBOARD,
} from '@atlassian/jira-dashboard-common/src/constants.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { withFlagService } from '@atlassian/jira-flags';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { useSwitchModals } from '@atlassian/jira-switch-modals-controller/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import { useRouterActions } from '@atlassian/react-resource-router';
import { handleFetchErrorAsExperience } from '../../../../../utils/handle-fetch-error-as-experience/index.tsx';
import { logErrorEventFunc } from './utils.tsx';

type OnSuccessType = ComponentProps<typeof CopyDashboardModal>['onSuccess'];

const LazyDashboardModal = withFlagService(
	// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
	lazy<typeof CopyDashboardModal>(() =>
		import(
			/* webpackChunkName: "async-dashboard-copy-dashboard-modal" */ '@atlassian/jira-copy-dashboard-modal/src'
		).then(({ CopyDashboardModal }) => CopyDashboardModal),
	),
);

const expAttributes = {
	analyticsSource: 'dashboard',
	experience: COPY_DASHBOARD_EXPERIENCE,
	application: null,
	edition: null,
	additionalAttributes: {
		isCancel: false,
	},
} as const;

const sendSuccessExperience = () =>
	sendExperienceAnalytics({
		...expAttributes,
		wasExperienceSuccesful: true,
	});

const sendFailedExperience = () =>
	sendExperienceAnalytics({
		...expAttributes,
		wasExperienceSuccesful: false,
	});

export const CopyModalInner = ({
	id,
	onModalCloseCallback,
}: {
	id: string;
	onModalCloseCallback?: () => void;
}) => {
	const [isOpen, { off: close }] = useSwitchModals(MODAL_ID.DASHBOARD_COPY);
	const { push } = useRouterActions();
	const onSuccess: OnSuccessType = (_, response) => {
		sendSuccessExperience();
		close();
		if (response.view != null) {
			push(response.view);
		} else {
			onModalCloseCallback?.();
		}
	};

	const onInitiateFailed = (error: undefined | Error) => {
		handleFetchErrorAsExperience({
			error: error || new Error('copy dashboard modal initialisation failed'),
			sendSuccessExperience,
			sendFailedExperience,
			logErrorEvent: logErrorEventFunc(error, COPY_DASHBOARD),
		});
		close();
		onModalCloseCallback?.();
	};

	const onFailed = (error: Error) => {
		handleFetchErrorAsExperience({
			error,
			sendSuccessExperience,
			sendFailedExperience,
			logErrorEvent: logErrorEventFunc(error, COPY_DASHBOARD),
		});
		close();
		onModalCloseCallback?.();
	};

	const onCancel = () => {
		sendExperienceAnalytics({
			...expAttributes,
			wasExperienceSuccesful: true,
			additionalAttributes: {
				isCancel: true,
			},
		});
		close();
		onModalCloseCallback?.();
	};

	return isOpen ? (
		<Placeholder name="dashboard-modal" fallback={<Blanket />}>
			<LazyDashboardModal
				onInitiateFailed={onInitiateFailed}
				onSuccess={onSuccess}
				onFailed={onFailed}
				onCancel={onCancel}
				id={id}
			/>
		</Placeholder>
	) : null;
};

export const CopyModal = (props: { id: string; onModalCloseCallback?: () => void }) => (
	<JSErrorBoundary
		id={COPY_DASHBOARD_EXPERIENCE}
		packageName="dashboard"
		fallback="flag"
		attributes={{ task: COPY_DASHBOARD_EXPERIENCE }}
		withExperienceTracker
	>
		<CopyModalInner {...props} />
	</JSErrorBoundary>
);
