import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	gadgetDeprecationTitleV1: {
		defaultMessage:
			"Extended timeline: We're no longer supporting five of your gadgets starting May 2025",
		id: 'dashboard-internal-common.common.gadget.deprecation-banner-v1.gadget-deprecation-title.non-final',
		description:
			'Title of the deprecation message to be displayed to the user about gadget deprecation.',
	},
	gadgetDeprecationMessageV1: {
		defaultMessage:
			"Starting May 2025, we're removing the functionality of the Jira Road Map, Bubble Chart, Heat Map, Projects, and Labels gadgets. If you don't delete them before then, they'll no longer work, and you won't be able to reference them for the available alternatives. <Link>More about the gadgets and the extended timeline</Link>",
		id: 'dashboard-internal-common.common.gadget.deprecation-banner-v1.gadget-deprecation-message.non-final',
		description:
			'An information section message to be displayed to the user about gadget deprecation.',
	},
	gadgetDeprecationMessageDismissV1: {
		defaultMessage: 'Dismiss',
		id: 'dashboard-internal-common.common.gadget.deprecation-banner-v1.gadget-deprecation-message-dismiss.non-final',
		description: 'Label for button to dimiss the gadget deprecation banner.',
	},
});
