import React, { useLayoutEffect, useMemo } from 'react';
import { PRELOADED_GADGET_DATA_KEY } from '@atlassian/jira-dashboard-common/src/constants.tsx';
import { getPrefetches } from '@atlassian/jira-dashboard-common/src/utils/prefetch/main.tsx';
import { useDashboardResource } from '@atlassian/jira-router-resources-dashboard/src/index.tsx';
import { useSpaStateTransition } from '@atlassian/jira-spa-state-controller/src/components/transition-state/index.tsx';

type PrefetchLinkParam = {
	dashboardId: string;
};

type PartialWindow = {
	[PRELOADED_GADGET_DATA_KEY]?: string;
};

export const PrefetchLinks = ({ dashboardId }: PrefetchLinkParam) => {
	const [{ isInitialRender }] = useSpaStateTransition();
	const { data } = useDashboardResource();

	const prefetches = useMemo(() => (data ? getPrefetches(data) : []), [data]);

	useLayoutEffect(() => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		if (!isInitialRender && window && (window as PartialWindow)[PRELOADED_GADGET_DATA_KEY]) {
			// cleanup preloaded data on window, which is only used on initial load
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			delete (window as PartialWindow)[PRELOADED_GADGET_DATA_KEY];
		}
	}, [isInitialRender]);

	return (
		<>
			{prefetches.map((prefetch) => (
				<link
					key={`${dashboardId}-${prefetch.url}`}
					rel={isInitialRender ? prefetch.priority : 'prefetch'}
					as="fetch"
					href={prefetch.url}
				/>
			))}
		</>
	);
};
