import type { ReactNode } from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ContentWrapper = styled.div<{
	isDimmed: boolean;
	isHidden: boolean;
	children: ReactNode;
}>(
	{
		width: '100%',
		borderRadius: '8px',
		transition: 'background-color 0.2s ease',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		background: ({ isDimmed }) =>
			isDimmed
				? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					token('elevation.surface.sunken', colors.N20)
				: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					token('elevation.surface', colors.N0),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isHidden }) => (isHidden ? 'display: none' : undefined),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ContainerWrapper = styled.main<{
	children: ReactNode;
	['data-test-id']?: unknown;
}>({
	marginTop: 0,
	marginBottom: 0,
	marginLeft: token('space.negative.200', '-16px'),
	marginRight: token('space.negative.200', '-16px'),
});
