import { useState, useEffect } from 'react';
import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import type { DEPRECATION_BANNER_V1 } from '../constants.tsx';

export const useDeprecationBannerState = (
	dashboardBannerStorageKey: typeof DEPRECATION_BANNER_V1,
	dashboardId: string,
) => {
	const [isDeprecationBannerDismissed, setIsDeprecationBannerDismissed] = useState(false);
	const store = createLocalStorageProvider('jira-dashboard-deprecation-banner-state');

	useEffect(() => {
		const localDeprecatedBannersState = store.get(dashboardBannerStorageKey);
		if (localDeprecatedBannersState) {
			const bannerIsDismissed = localDeprecatedBannersState.includes(dashboardId);
			setIsDeprecationBannerDismissed(bannerIsDismissed);
		}
	}, [dashboardId, dashboardBannerStorageKey, store]);

	const onDeprecationBannerDismiss = () => {
		setIsDeprecationBannerDismissed(true);
		const localDeprecatedBannersState = store.get(dashboardBannerStorageKey);
		if (localDeprecatedBannersState) {
			localDeprecatedBannersState.push(dashboardId);
			store.set(dashboardBannerStorageKey, localDeprecatedBannersState);
		} else {
			store.set(dashboardBannerStorageKey, [dashboardId]);
		}
	};

	return {
		isDeprecationBannerDismissed,
		onDeprecationBannerDismiss,
	};
};
