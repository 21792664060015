import { useCallback, useState } from 'react';

export type TitleSetter = ReturnType<typeof useGadgetTitle>['setTitle'];

export type DefaultTitleSetter = ReturnType<typeof useGadgetTitle>['setDefaultTitle'];

export function useGadgetTitle({
	defaultTitle: initialDefaultTitle,
	title,
	onSave,
}: {
	defaultTitle: string;
	title?: string;
	onSave?: (title: string) => Promise<void>;
}) {
	const [defaultTitle, setDefaultTitle] = useState(initialDefaultTitle);
	const [userTitle, setUserTitle] = useState(title);

	const setTitle = useCallback(
		async (newTitle: string) => {
			const previousTitle = userTitle;
			setUserTitle(newTitle);

			onSave?.(newTitle).catch(() => {
				setUserTitle(previousTitle);
			});
		},
		[userTitle, onSave],
	);

	const setDefaultTitleWithInitialTitle = useCallback(
		(defaultTitleUpdaterOrValue: string | ((initialDefaultTitle: string) => string)) => {
			setDefaultTitle(
				typeof defaultTitleUpdaterOrValue === 'function'
					? defaultTitleUpdaterOrValue(initialDefaultTitle)
					: defaultTitleUpdaterOrValue,
			);
		},
		[initialDefaultTitle],
	);

	return {
		title: userTitle || defaultTitle,
		setDefaultTitle: setDefaultTitleWithInitialTitle,
		setTitle,
	};
}

export const useConnectGadgetTitles = () => {
	const [connectGadgets, setConnectGadgets] = useState<Record<string, TitleSetter>>({});

	const registerConnectGadget = useCallback((id: string, setTitle: TitleSetter) => {
		setConnectGadgets((gadgets) => ({
			...gadgets,
			[id]: setTitle,
		}));
	}, []);

	const unregisterConnectGadget = useCallback((id: string) => {
		setConnectGadgets((gadgets) => {
			const { [id]: _, ...rest } = gadgets;
			return rest;
		});
	}, []);

	const setCustomTitle = useCallback(
		(id: string, title: string) => {
			const setTitle = connectGadgets[id];
			if (!setTitle) {
				throw new Error(
					`Unable to set custom title on Connect gadget. No gadget with id ${id} found. Available ids: ${Object.keys(
						connectGadgets,
					).join(', ')}`,
				);
			}

			return setTitle(title);
		},
		[connectGadgets],
	);

	return { registerConnectGadget, setCustomTitle, unregisterConnectGadget };
};
