import type {
	DELETE_DASHBOARD as DeleteDashboardType,
	COPY_DASHBOARD as CopyDashboardType,
	RENAME_OR_SHARE_DASHBOARD as RenameOrShareDashboardType,
	DASHBOARD_AUTOMATIC_REFRESH as DashboardAutoRefreshType,
} from '@atlassian/jira-dashboard-common/src/constants.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';

export const logErrorEventFunc =
	(
		error: Error | undefined,
		eventId:
			| typeof RenameOrShareDashboardType
			| typeof CopyDashboardType
			| typeof DeleteDashboardType
			| typeof DashboardAutoRefreshType,
	) =>
	(reason?: string) => {
		if (error != null) {
			fireErrorAnalytics({
				error,
				meta: {
					id: eventId,
					packageName: 'jiraDashboardInternalCommon',
				},
				attributes: {
					reason,
				},
			});
		}
	};
