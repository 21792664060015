import { useCallback, useEffect } from 'react';

export const useDashboardRef = () => {
	useEffect(() => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const originalGetDashboard = window.AG?.DashboardManager?.getDashboard;

		return () => {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.AG = {
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				...window.AG,
				DashboardManager: {
					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					...window.AG?.DashboardManager,
					getDashboard: originalGetDashboard,
				},
			};
		};
	}, []);

	return useCallback((node: HTMLElement | null) => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.AG = {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			...window.AG,
			DashboardManager: {
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				...window.AG?.DashboardManager,
				getDashboard: () => node,
			},
		};
	}, []);
};
