import React, { useCallback, useLayoutEffect, useRef } from 'react';
import sendExperienceAnalytics from '@atlassian/jira-common-experience-tracking-analytics/src/index.tsx';
import {
	GADGET_METRICS_TYPE,
	GADGET_ERROR_TYPE,
	VIEW_REACT_GADGET_EXPERIENCE,
} from '@atlassian/jira-dashboard-common/src/constants.tsx';
import type { GadgetMetricsEventType } from '@atlassian/jira-dashboard-common/src/types.tsx';
import type { GadgetUserPreferences } from '@atlassian/jira-dashboard-user-preference/src/types.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import type { ReactKey } from '@atlassian/jira-react-gadgets-definition/src/main.tsx';
import { useSpaStateTransition } from '@atlassian/jira-spa-state-controller/src/components/transition-state/index.tsx';
import { GadgetErrorFallback } from '../../../../common/gadget/gadget-error-fallback/index.tsx';
import { useGadgetSetPreferences } from '../../../../controllers/gadget/context.tsx';
import { useMessageBus } from '../../../../controllers/message-bus/message-bus.tsx';
import {
	GADGET_START_EVENT,
	GADGET_RENDER_CONTAINER_EVENT,
	GADGET_RENDERING_FINISHED_EVENT,
	REACT_GADGET_METRICS,
} from '../../../../controllers/metrics/constants.tsx';
import type { DefaultTitleSetter } from '../../../../utils/use-gadget-title/index.tsx';
import { useReactGadgetState } from './utils.tsx';

const expAttributes = {
	analyticsSource: 'dashboard',
	application: null,
	edition: null,
	additionalAttributes: {},
} as const;

export type ReactGadgetProps = {
	isInEditMode: boolean;
	id: string;
	reactKey: ReactKey;
	userPrefs?: GadgetUserPreferences;
	onEditModeCancel: () => void;
	onRenderComplete: (height?: number) => void;
	setDefaultTitle: DefaultTitleSetter;
	customSkeletonHeightInPx?: number;
};

export const ReactGadget = ({
	id,
	userPrefs,
	isInEditMode,
	reactKey,
	onEditModeCancel,
	onRenderComplete,
	setDefaultTitle,
	customSkeletonHeightInPx,
}: ReactGadgetProps) => {
	const [{ currentPageId }] = useSpaStateTransition();
	const [, { broadcastMessage: broadcastMessageBusListener }] = useMessageBus();
	const setPreferences = useGadgetSetPreferences();
	const ref = useRef<HTMLDivElement | null>(null);
	const isExperienceCompletedRef = useRef(false);

	const {
		state,
		componentView: ComponentView,
		componentEdit: ComponentEdit,
		setState,
	} = useReactGadgetState(id, reactKey);

	const experienceKey = `${VIEW_REACT_GADGET_EXPERIENCE}-${reactKey.split('/')[1]}`; // e.g. view-react-gadget-filter-results
	const reportGadgetMetrics = useCallback(
		(eventType: GadgetMetricsEventType) => {
			broadcastMessageBusListener(
				GADGET_METRICS_TYPE.REPORT,
				{
					eventType,
					contentType: isInEditMode ? 'Config' : 'View',
					source: REACT_GADGET_METRICS,
				},
				{ gadgetId: id, pageId: currentPageId },
			);
		},
		[broadcastMessageBusListener, isInEditMode, id, currentPageId],
	);

	const reportGadgetStart = useCallback(() => {
		reportGadgetMetrics(GADGET_START_EVENT);
	}, [reportGadgetMetrics]);

	const onGadgetFinish = useCallback(
		// this API is not ideal, but a temporary solution to allow gadget to not save height to localStorage
		(shouldPersistHeight = true) => {
			if (shouldPersistHeight) {
				const height = ref.current?.getBoundingClientRect().height;
				onRenderComplete(height);
			}

			reportGadgetMetrics(GADGET_RENDERING_FINISHED_EVENT);

			if (!isExperienceCompletedRef.current) {
				sendExperienceAnalytics({
					...expAttributes,
					experience: experienceKey,
					wasExperienceSuccesful: true,
				});
				isExperienceCompletedRef.current = true;
			}
		},
		[reportGadgetMetrics, onRenderComplete, experienceKey],
	);

	const errorFallback = useCallback(
		() => <GadgetErrorFallback id={id} errorType={GADGET_ERROR_TYPE.VIEW_ERROR} />,
		[id],
	);
	const sendFailExperience = useCallback(() => {
		if (!isExperienceCompletedRef.current) {
			sendExperienceAnalytics({
				...expAttributes,
				experience: experienceKey,
				wasExperienceSuccesful: false,
			});
			isExperienceCompletedRef.current = true;
		}
	}, [experienceKey]);

	useLayoutEffect(() => {
		// gadget container mounted event
		reportGadgetMetrics(GADGET_RENDER_CONTAINER_EVENT);
	}, [reportGadgetMetrics]);

	const setGadgetContentTitle = useCallback(
		(contentTitle: string | undefined) => {
			setDefaultTitle((initialDefaultTitle) =>
				contentTitle ? `${initialDefaultTitle}: ${contentTitle}` : initialDefaultTitle,
			);
		},
		[setDefaultTitle],
	);

	const Component = isInEditMode ? ComponentEdit : ComponentView;

	if (state instanceof Error) {
		sendFailExperience();
		throw state;
	}

	return (
		<JSErrorBoundary
			fallback={errorFallback}
			packageName="jiraDashboardInternalCommon"
			id={reactKey}
			teamName="endeavour"
			onError={sendFailExperience}
			sendToPrivacyUnsafeSplunk
		>
			<div ref={ref}>
				{Component && (
					<Component
						setPreferences={setPreferences}
						state={state}
						onGadgetStart={reportGadgetStart}
						onGadgetFinish={onGadgetFinish}
						userPrefs={userPrefs}
						onEditModeCancel={onEditModeCancel}
						setState={setState}
						setGadgetContentTitle={setGadgetContentTitle}
						customSkeletonHeightInPx={customSkeletonHeightInPx}
					/>
				)}
			</div>
		</JSErrorBoundary>
	);
};
