// A list of gadgets (gadgetType) should contribute to the overall dashboard TTI.
// Some gadgets not in this list may not send metrics event to the dashboard and potentially hold up the dashboard
// metrics until 60 second timeout.
export const GADGETS_TO_REPORT = new Set<string>([
	// WRM gadgets
	'jira-dashboard-items/assigned-to-me',
	'jira-dashboard-items/bubble-chart-dashboard-item',
	'jira-dashboard-items/createdvsresolved',
	'jira-dashboard-items/favourite-filters',
	'jira-dashboard-items/filter-results',
	'jira-dashboard-items/in-progress',
	'jira-dashboard-items/labels',
	'jira-dashboard-items/piechart',
	'jira-dashboard-items/stats',
	'jira-dashboard-items/two-dimensional-stats',
	'jira-dashboard-items/voted',
	'jira-dashboard-items/watched',
	'jira-dashboard-items/issuescalendar',
	'jira-dashboard-items/projects',
	'jira-dashboard-items/activitystream',
	'jira-dashboard-items/recently-created',
	'jira-dashboard-items/workload-pie-chart',
	'jira-dashboard-items/roadmap',
	'jira-dashboard-items/average-age-chart',
	'jira-dashboard-items/average-time-in-status-chart',
	'jira-dashboard-items/average-number-of-times-in-status-chart',
	'jira-dashboard-items/heat-map',
	'jira-dashboard-items/time-to-first-response',
	'jira-dashboard-items/sprint-burndown',
	'jira-dashboard-items/resolution-time',
	'jira-dashboard-items/timesince',
	'jira-dashboard-items/spacer',
	'jira-dashboard-items/rapid-view',
	'jira-dashboard-items/version-report',
	// simple iframe gadgets
	'rest/gadgets/1.0/g/com.atlassian.bonfire.plugin:bonfire-test-sessions-gadget/gadget/bonfire-sessions-gadget.xml',
	'rest/gadgets/1.0/g/com.atlassian.jira.ext.calendar:issuescalendar-gadget/templates/plugins/jira/portlets/calendar/gadget/calendar-gadget.xml',
	'rest/gadgets/1.0/g/com.atlassian.jira.ext.charting:firstresponse-gadget/com/atlassian/jira/ext/charting/gadget/firstresponse-gadget.xml',
	'rest/gadgets/1.0/g/com.atlassian.jira.ext.charting:numberoftimesinstatus-gadget/com/atlassian/jira/ext/charting/gadget/numberoftimesinstatus-gadget.xml',
	'rest/gadgets/1.0/g/com.atlassian.jira.ext.charting:timeinstatus-gadget/com/atlassian/jira/ext/charting/gadget/timeinstatus-gadget.xml',
	'rest/gadgets/1.0/g/com.atlassian.jira.ext.charting:workloadpie-gadget/com/atlassian/jira/ext/charting/gadget/workloadpie-gadget.xml',
	'rest/gadgets/1.0/g/com.atlassian.jira.gadgets:average-age-chart-gadget/gadgets/average-age-gadget.xml',
	'rest/gadgets/1.0/g/com.atlassian.jira.gadgets:heat-map-gadget/gadgets/heatmap-gadget.xml',
	'rest/gadgets/1.0/g/com.atlassian.jira.gadgets:project-gadget/gadgets/project-gadget.xml',
	'rest/gadgets/1.0/g/com.atlassian.jira.gadgets:recently-created-chart-gadget/gadgets/recently-created-gadget.xml',
	'rest/gadgets/1.0/g/com.atlassian.jira.gadgets:resolution-time-gadget/gadgets/resolution-time-gadget.xml',
	'rest/gadgets/1.0/g/com.atlassian.jira.gadgets:road-map-gadget/gadgets/roadmap-gadget.xml',
	'rest/gadgets/1.0/g/com.atlassian.jira.gadgets:time-since-chart-gadget/gadgets/timesince-gadget.xml',
	'rest/gadgets/1.0/g/com.atlassian.jirafisheyeplugin:crucible-charting-gadget/gadgets/crucible-charting-gadget.xml',
	'rest/gadgets/1.0/g/com.atlassian.jirafisheyeplugin:fisheye-charting-gadget/gadgets/fisheye-charting-gadget.xml',
	'rest/gadgets/1.0/g/com.atlassian.jirafisheyeplugin:fisheye-recent-commits-gadget/gadgets/fisheye-recent-commits-gadget.xml',
	'rest/gadgets/1.0/g/com.atlassian.jirawallboard.atlassian-wallboard-plugin:spacer-gadget/gadgets/spacerGadget.xml',
	'rest/gadgets/1.0/g/com.atlassian.streams.streams-jira-plugin:activitystream-gadget/gadgets/activitystream-gadget.xml',
	'rest/gadgets/1.0/g/com.pyxis.greenhopper.jira:greenhopper-gadget-rapid-view/gadgets/greenhopper-rapid-view.xml',
	'rest/gadgets/1.0/g/com.pyxis.greenhopper.jira:greenhopper-gadget-sprint-burndown/gadgets/greenhopper-sprint-burndown.xml',
	'rest/gadgets/1.0/g/com.pyxis.greenhopper.jira:greenhopper-gadget-version-report/gadgets/greenhopper-version-report.xml',
]);

// A list of gadgets (gadgetType) should NOT contribute to the overall dashboard TTI
// These are the gadgets known to be ignored in the overall dashboard TTI, mainly because they haven't.
// implement logic to send metrics event.
//
// If a new WRM or simple iframe gadget is not in both the inclusion and exclusion list, an warning log will be raised,
// we should then determine whether add it to this exclusion list or fix it.
//
// Note: The GADGETS_TO_REPORT has higher priority, if you need a gadget to be excluded, make sure it is NOT
// in the GADGETS_TO_REPORT list.
export const GADGETS_IGNORED = new Set<string>([
	'jira-dashboard-items/sprint-days-remaining',
	'jira-dashboard-items/sprint-health',
	'servicedesk/internal/reports/feature/custom-report-dashboard-item/custom-report-dashboard-item',
	'servicedesk/internal/dashboard-items/filter/filter-dashboard-item',
]);
