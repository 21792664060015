import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	justNow: {
		id: 'dashboard-internal-common.last-refreshed.just-now',
		defaultMessage: 'Just now',
		description: 'Label telling user that gadget was last loaded very recently (just now)',
	},
	timeAgo: {
		id: 'dashboard-internal-common.last-refreshed.time-ago',
		defaultMessage: '{timeAgo} ago',
		description:
			"Label telling user that gadget was last loaded 'timeAgo' ago (such as 5 hours ago)",
	},
	lastRefreshedIcon: {
		id: 'dashboard-internal-common.last-refreshed.last-refreshed-icon',
		defaultMessage: 'Last refreshed',
		description: 'Description of icon on refresh button for accessibility',
	},
});
