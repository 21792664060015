import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorMessage: {
		id: 'dashboard-internal-common.common.gadget.gadget-error-fallback.error-message',
		defaultMessage:
			"We're having some trouble displaying this gadget. It's probably a temporary problem. Try refreshing the gadget.",
		description: 'Describe the error to the user',
	},
	errorTitle: {
		id: 'dashboard-internal-common.common.gadget.gadget-error-fallback.error-title',
		defaultMessage: 'Warning',
		description: 'Warning',
	},
	configErrorMessage: {
		id: 'dashboard-internal-common.common.gadget.gadget-error-fallback.config-error-message',
		defaultMessage:
			'Something went wrong. We could not save your gadget configuration. Refresh this gadget to update its configuration and try again.',
		description: 'Describe the error to the user',
	},
	refreshButtonText: {
		id: 'dashboard-internal-common.common.gadget.gadget-error-fallback.refresh-button-text',
		defaultMessage: 'Refresh',
		description: 'refresh this gadget',
	},
	notSupportedErrorMessage: {
		id: 'dashboard-internal-common.common.gadget.gadget-error-fallback.not-supported-error-message',
		defaultMessage: 'This gadget is no longer supported by Jira.',
		description: 'Describe the error to the user',
	},
});
