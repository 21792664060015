import memoizeOne from 'memoize-one';
import { fg } from '@atlassian/jira-feature-gating';

// This is a hardcoded map of feature gates that we want to use the value of for analytics
// These can be used to track performance with Splunk when the gate is on/off
// Unlike feature flags, there isn't util to get all feature gates, so we need to hardcode the list
// Each gate is called directly (instead of a list that's looped) otherwise ESLINT fails
export const getDashboardFeatureGates = memoizeOne(() => ({
	endeavour_nin_assigned_to_me_gadget: fg('endeavour_nin_assigned_to_me_gadget'),
	endeavour_nin_issues_in_progress_gadget: fg('endeavour_nin_issues_in_progress_gadget'),
	endeavour_nin_filter_results_gadget: fg('endeavour_nin_filter_results_gadget'),
	endeavour_nin_watched_issues_gadget: fg('endeavour_nin_watched_issues_gadget'),
	endeavour_nin_voted_issues_gadget: fg('endeavour_nin_voted_issues_gadget'),
	endeavour_gadget_deprecation_banner_v1: fg('endeavour_gadget_deprecation_banner_v1'),
	endeavour_improve_dashboard_connect_scripts: fg('endeavour_improve_dashboard_connect_scripts'),
}));
