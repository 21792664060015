function $parcel$interopDefault(a) {
    return a && a.__esModule ? a.default : a;
}
function $parcel$defineInteropFlag(a) {
    Object.defineProperty(a, '__esModule', {
        value: true,
        configurable: true
    });
}
function $parcel$export(e, n, v, s) {
    Object.defineProperty(e, n, {
        get: v,
        set: s,
        enumerable: true,
        configurable: true
    });
}
var $parcel$global = globalThis;
var parcelRequire = $parcel$global["parcelRequire94c2"];
var parcelRegister = parcelRequire.register;
parcelRegister("iqENN", function(module, exports) {
    $parcel$defineInteropFlag(module.exports);
    $parcel$export(module.exports, "DashboardReadOnly", ()=>$f7f0a5b36aaaab3e$export$aa188e8cc04077b4);
    $parcel$export(module.exports, "default", ()=>$f7f0a5b36aaaab3e$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $lL5b5;
    var $JIPqS;
    var $duhqo;
    var $iyrll;
    var $32Vhm;
    var $1rce7;
    var $1CEw6;
    var $5HxxK;
    var $4kIa4;
    var $h7J1E;
    var $beRD7;
    var $koVbs;
    var $gh1hi;
    var $l8ruj;
    var $52i38;
    var $fPA7s;
    var $cOK0t;
    var $32zIt;
    var $kiq64;
    const $f7f0a5b36aaaab3e$export$aa188e8cc04077b4 = ()=>{
        const { data: data, error: error, loading: loading } = (0, (0, parcelRequire("gh1hi")).useDashboardResource)();
        const { registerConnectGadget: registerConnectGadget, unregisterConnectGadget: unregisterConnectGadget, setCustomTitle: setCustomTitle } = (0, (0, parcelRequire("h7J1E")).useConnectGadgetTitles)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4kIa4")).DashboardContentContainer), {
            analyticsExperience: (0, (0, parcelRequire("JIPqS")).VIEW_DASHBOARD_READONLY_EXPERIENCE),
            resourceData: {
                data: data,
                error: error,
                loading: loading
            },
            permissionErrorFallback: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("beRD7")).DashboardPermissionErrorPageAsync), null),
            saveCustomGadgetTitle: setCustomTitle
        }, data ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("32Vhm")).DashboardController), {
            id: data.id,
            automaticRefreshMs: data.automaticRefreshMs
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("32zIt")).HeaderReadOnly), {
            data: data,
            isEditLinkEnabled: data.writable,
            banners: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("duhqo")).DeprecationBanner), {
                gadgets: data.gadgets,
                gadgetId: data.id
            }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1CEw6")).EngagementBanners), null))
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5HxxK")).ColumnLayout), {
            data: data,
            dnd: null,
            columnPlaceholder: null,
            layoutPlaceholder: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fPA7s")).EmptyDashboardPlaceholder), {
                hasEditPermissions: data.writable
            }),
            onLayoutReposition: null
        }, ({ dragHandleProps: _dragHandleProps, isDraggable: _isDraggable, isDragging: _isDragging, isAnyDragging: _isAnyDragging, ...gadgetProps })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iyrll")).DashboardControllerContextConsumer), null, ({ refreshId: refreshId })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cOK0t")).GadgetReadOnly), {
                    dashboardId: data.id,
                    refreshId: refreshId,
                    unconfiguredFallback: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("kiq64")).UnconfiguredGadgetFallback), {
                        id: gadgetProps.data.id,
                        hasEditPermissions: data.writable
                    }),
                    ...gadgetProps,
                    onRegisterConnectGadget: registerConnectGadget,
                    onUnregisterConnectGadget: unregisterConnectGadget
                })))) : null);
    };
    var $f7f0a5b36aaaab3e$export$2e2bcd8739ae039 = ()=>{
        const { data: data, error: error, loading: loading } = (0, (0, parcelRequire("gh1hi")).useDashboardResource)();
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("l8ruj")).default), {
            name: "dashboard-view"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1rce7")).DashboardPageContainer), {
            analyticsSourceName: (0, (0, parcelRequire("JIPqS")).DASHBOARD_VIEW_SOURCENAME),
            analyticsExperience: (0, (0, parcelRequire("JIPqS")).VIEW_DASHBOARD_READONLY_EXPERIENCE),
            documentTitle: data?.title ?? formatMessage((0, (0, parcelRequire("52i38")).default).pageTitle),
            unauthenticatedFallback: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lL5b5")).default), null),
            resourceData: {
                data: data,
                error: error,
                loading: loading
            }
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($f7f0a5b36aaaab3e$export$aa188e8cc04077b4, null)));
    };
});
parcelRegister("lL5b5", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$07c2532655d93687$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $j0xEB;
    var $j2Jlg;
    var $07c2532655d93687$export$2e2bcd8739ae039 = ()=>{
        const { href: href = null } = (0, (0, parcelRequire("j0xEB")).default)() || {};
        if (href && !href.includes('login.jsp')) {
            const encodedRedirectUrl = encodeURIComponent(href);
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("j2Jlg")).default), {
                url: `/login.jsp?os_destination=${encodedRedirectUrl}`
            });
        }
        return null;
    };
});
parcelRegister("j0xEB", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$9b1198aeec732ce1$export$2e2bcd8739ae039);
    var $9b1198aeec732ce1$export$2e2bcd8739ae039 = ()=>{
        if (typeof window !== 'undefined') return window.location;
        return undefined;
    };
});
parcelRegister("j2Jlg", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$773e6f988af77e5c$export$2e2bcd8739ae039);
    var $773e6f988af77e5c$export$2e2bcd8739ae039 = ({ url: url })=>{
        window.location.assign(url);
        return null;
    };
});
parcelRegister("duhqo", function(module, exports) {
    $parcel$export(module.exports, "DeprecationBanner", ()=>$76f52a94495cb7a6$export$35ae8bd48b5aa255);
    var $5uXOq = parcelRequire("5uXOq");
    var $4R6GH;
    var $JIPqS;
    var $eOQrC;
    var $l6TQP;
    var $7YQcX;
    const $76f52a94495cb7a6$var$DEPRECATION_BANNERS = {
        endeavour_gadget_deprecation_banner_v1: {
            version: (0, (0, parcelRequire("JIPqS")).DEPRECATION_BANNER_V1),
            component: (0, (0, parcelRequire("7YQcX")).DeprecationBannerV1)
        }
    };
    const $76f52a94495cb7a6$export$35ae8bd48b5aa255 = ({ gadgets: gadgets, gadgetId: gadgetId })=>{
        const { dashboardHasDeprecatedGadget: dashboardHasDeprecatedGadget } = (0, (0, parcelRequire("eOQrC")).useDashboardHasDeprecatedGadget)(gadgets);
        const featureGatedBannerVersion = 'endeavour_gadget_deprecation_banner_v1';
        let hasDeprecationBannerFGon = false;
        if ((0, (0, parcelRequire("4R6GH")).fg)('endeavour_gadget_deprecation_banner_v1')) hasDeprecationBannerFGon = true;
        const BannerComponent = $76f52a94495cb7a6$var$DEPRECATION_BANNERS[featureGatedBannerVersion].component;
        const { isDeprecationBannerDismissed: isDeprecationBannerDismissed, onDeprecationBannerDismiss: onDeprecationBannerDismiss } = (0, (0, parcelRequire("l6TQP")).useDeprecationBannerState)($76f52a94495cb7a6$var$DEPRECATION_BANNERS[featureGatedBannerVersion].version, gadgetId);
        return hasDeprecationBannerFGon && !isDeprecationBannerDismissed && dashboardHasDeprecatedGadget ? (0, ($parcel$interopDefault($5uXOq))).createElement(BannerComponent, {
            onDismiss: onDeprecationBannerDismiss
        }) : null;
    };
});
parcelRegister("eOQrC", function(module, exports) {
    $parcel$export(module.exports, "useDashboardHasDeprecatedGadget", ()=>$aef4a1d59628b665$export$3b0936c4a7671adc);
    var $5uXOq = parcelRequire("5uXOq");
    var $hGdg6;
    const $aef4a1d59628b665$export$3b0936c4a7671adc = (gadgets)=>{
        const [dashboardHasDeprecatedGadget, setDashboardHasDeprecatedGadget] = (0, $5uXOq.useState)(false);
        (0, $5uXOq.useEffect)(()=>{
            setDashboardHasDeprecatedGadget($aef4a1d59628b665$var$checkDashboardHasDeprecatedGadget(gadgets));
        }, [
            gadgets
        ]);
        return {
            dashboardHasDeprecatedGadget: dashboardHasDeprecatedGadget
        };
    };
    const $aef4a1d59628b665$var$checkDashboardHasDeprecatedGadget = (gadgets)=>{
        return gadgets.some((gadget)=>(0, (0, parcelRequire("hGdg6")).isDeprecatedGadget)(gadget.amdModule));
    };
});
parcelRegister("hGdg6", function(module, exports) {
    $parcel$export(module.exports, "isDeprecatedGadget", ()=>$ea8c7aa113f400f7$export$4b6bff4e9074989);
    var $JIPqS;
    const $ea8c7aa113f400f7$export$4b6bff4e9074989 = (gadgetResource)=>{
        return gadgetResource !== undefined && (gadgetResource in (0, (0, parcelRequire("JIPqS")).DEPRECATED_GADGETS_MAP) || Object.values((0, (0, parcelRequire("JIPqS")).DEPRECATED_GADGETS_MAP)).includes(gadgetResource));
    };
});
parcelRegister("l6TQP", function(module, exports) {
    $parcel$export(module.exports, "useDeprecationBannerState", ()=>$61287e76e54d4874$export$ce2c61270a55779b);
    var $5uXOq = parcelRequire("5uXOq");
    var $bSSG7;
    const $61287e76e54d4874$export$ce2c61270a55779b = (dashboardBannerStorageKey, dashboardId)=>{
        const [isDeprecationBannerDismissed, setIsDeprecationBannerDismissed] = (0, $5uXOq.useState)(false);
        const store = (0, (0, parcelRequire("bSSG7")).createLocalStorageProvider)('jira-dashboard-deprecation-banner-state');
        (0, $5uXOq.useEffect)(()=>{
            const localDeprecatedBannersState = store.get(dashboardBannerStorageKey);
            if (localDeprecatedBannersState) {
                const bannerIsDismissed = localDeprecatedBannersState.includes(dashboardId);
                setIsDeprecationBannerDismissed(bannerIsDismissed);
            }
        }, [
            dashboardId,
            dashboardBannerStorageKey,
            store
        ]);
        const onDeprecationBannerDismiss = ()=>{
            setIsDeprecationBannerDismissed(true);
            const localDeprecatedBannersState = store.get(dashboardBannerStorageKey);
            if (localDeprecatedBannersState) {
                localDeprecatedBannersState.push(dashboardId);
                store.set(dashboardBannerStorageKey, localDeprecatedBannersState);
            } else store.set(dashboardBannerStorageKey, [
                dashboardId
            ]);
        };
        return {
            isDeprecationBannerDismissed: isDeprecationBannerDismissed,
            onDeprecationBannerDismiss: onDeprecationBannerDismiss
        };
    };
});
parcelRegister("7YQcX", function(module, exports) {
    $parcel$export(module.exports, "DeprecationBannerV1", ()=>$fb1cba861a89581f$export$b10eedc6ae25381c);
    var $5uXOq = parcelRequire("5uXOq");
    var $92Z2l;
    var $ctz0s;
    var $koVbs;
    var $hDzhb;
    var $2O7o7;
    const $fb1cba861a89581f$export$b10eedc6ae25381c = ({ onDismiss: onDismiss })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("92Z2l")).default), {
            appearance: "warning",
            actions: [
                (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ctz0s")).default), {
                    key: "gadgetDeprecationBannerDimissV1",
                    onClick: onDismiss
                }, formatMessage((0, (0, parcelRequire("2O7o7")).default).gadgetDeprecationMessageDismissV1))
            ],
            title: formatMessage((0, (0, parcelRequire("2O7o7")).default).gadgetDeprecationTitleV1)
        }, formatMessage((0, (0, parcelRequire("2O7o7")).default).gadgetDeprecationMessageV1, {
            Link: (chunks)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hDzhb")).default), {
                    href: "https://community.atlassian.com/t5/Jira-articles/Update-We-re-ending-support-for-five-dashboard-gadgets-in-May/ba-p/2844556"
                }, chunks),
            br: ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement("br", null), (0, ($parcel$interopDefault($5uXOq))).createElement("br", null))
        }));
    };
});
parcelRegister("ctz0s", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$2e844da07d79ea04$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $fVnbM;
    const $2e844da07d79ea04$var$SectionMessageAction = (0, $5uXOq.memo)(function SectionMessageAction({ children: children, onClick: onClick, href: href, testId: testId, linkComponent: linkComponent }) {
        return onClick || href ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
            testId: testId,
            appearance: "link",
            spacing: "none",
            onClick: onClick,
            href: href,
            component: href ? linkComponent : undefined
        }, children) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, children);
    });
    $2e844da07d79ea04$var$SectionMessageAction.displayName = 'SectionMessageAction';
    var $2e844da07d79ea04$export$2e2bcd8739ae039 = $2e844da07d79ea04$var$SectionMessageAction;
});
parcelRegister("2O7o7", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$681e6c098aed09e5$export$2e2bcd8739ae039);
    var $7VHMR;
    var $681e6c098aed09e5$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        gadgetDeprecationTitleV1: {
            "id": "dashboard-internal-common.common.gadget.deprecation-banner-v1.gadget-deprecation-title.non-final",
            "defaultMessage": "Extended timeline: We're no longer supporting five of your gadgets starting May 2025"
        },
        gadgetDeprecationMessageV1: {
            "id": "dashboard-internal-common.common.gadget.deprecation-banner-v1.gadget-deprecation-message.non-final",
            "defaultMessage": "Starting May 2025, we're removing the functionality of the Jira Road Map, Bubble Chart, Heat Map, Projects, and Labels gadgets. If you don't delete them before then, they'll no longer work, and you won't be able to reference them for the available alternatives. <Link>More about the gadgets and the extended timeline</Link>"
        },
        gadgetDeprecationMessageDismissV1: {
            "id": "dashboard-internal-common.common.gadget.deprecation-banner-v1.gadget-deprecation-message-dismiss.non-final",
            "defaultMessage": "Dismiss"
        }
    });
});
parcelRegister("iyrll", function(module, exports) {
    $parcel$export(module.exports, "DashboardControllerContextProvider", ()=>$669a1fafb8f4566e$export$36eb228a17cae64d);
    $parcel$export(module.exports, "DashboardControllerContextConsumer", ()=>$669a1fafb8f4566e$export$4ce01c0dbab5bb18);
    $parcel$export(module.exports, "useDashboardRefresh", ()=>$669a1fafb8f4566e$export$71d14892f68b2430);
    $parcel$export(module.exports, "useOnAddedGadget", ()=>$669a1fafb8f4566e$export$2471f3ee3e3601fe);
    var $jnkfq;
    const { createUseContextField: $669a1fafb8f4566e$export$3fb47a2904db1f37, Provider: $669a1fafb8f4566e$export$36eb228a17cae64d, Consumer: $669a1fafb8f4566e$export$4ce01c0dbab5bb18 } = (0, (0, parcelRequire("jnkfq")).createRendererContext)();
    const $669a1fafb8f4566e$export$71d14892f68b2430 = $669a1fafb8f4566e$export$3fb47a2904db1f37('onRefresh');
    const $669a1fafb8f4566e$export$2471f3ee3e3601fe = $669a1fafb8f4566e$export$3fb47a2904db1f37('onAddedGadget');
});
parcelRegister("jnkfq", function(module, exports) {
    $parcel$export(module.exports, "createRendererContext", ()=>$2cbc9584a68b4b0d$export$ac09db0e74937f7f);
    var $5uXOq = parcelRequire("5uXOq");
    var $9pe79;
    const $2cbc9584a68b4b0d$export$ac09db0e74937f7f = ()=>{
        const _UnmemoisedContext = (0, $5uXOq.createContext)(undefined);
        const { Provider: UnmemoisedProvider, Consumer: Consumer } = _UnmemoisedContext;
        const Provider = (0, $5uXOq.memo)(UnmemoisedProvider, ({ value: valueA, ...propsA }, { value: valueB, ...propsB })=>(0, (0, parcelRequire("9pe79")).default)(valueA, valueB) && (0, (0, parcelRequire("9pe79")).default)(propsA, propsB));
        const createRenderFunction = (children)=>(value)=>children != null ? (0, ($parcel$interopDefault($5uXOq))).createElement(Provider, {
                    value: value
                }, typeof children === 'function' ? (0, ($parcel$interopDefault($5uXOq))).createElement(Consumer, null, children) : children) : null;
        const createUseContextField = (key)=>()=>{
                const value = (0, $5uXOq.useContext)(_UnmemoisedContext);
                if (value == null) throw new Error('context not found');
                return value[key];
            };
        return {
            _UnmemoisedContext: _UnmemoisedContext,
            Provider: Provider,
            Consumer: Consumer,
            createRenderFunction: createRenderFunction,
            createUseContextField: createUseContextField
        };
    };
});
parcelRegister("32Vhm", function(module, exports) {
    $parcel$export(module.exports, "DashboardController", ()=>$5e7fb5d7d6929ddb$export$b9771780c6ecf3f);
    var $5uXOq = parcelRequire("5uXOq");
    var $3v45P;
    var $flEgL;
    var $JIPqS;
    var $iyrll;
    const $5e7fb5d7d6929ddb$export$d7eb50fb85f631d = ({ automaticRefreshMs: automaticRefreshMs })=>{
        const [refreshId, setRefreshId] = (0, $5uXOq.useState)(Date.now());
        const [, setMostRecentlyAddedGadgetId] = (0, $5uXOq.useState)(null);
        const onRefresh = (0, $5uXOq.useCallback)(()=>{
            (0, (0, parcelRequire("3v45P")).emit)((0, (0, parcelRequire("JIPqS")).FORGE_JIRA_DASHBOARD_GADGET_REFRESH_EVENT), {
                origin: 'dashboard'
            });
            (0, (0, parcelRequire("flEgL")).default)((0, (0, parcelRequire("JIPqS")).CONNECT_GADGET_REFRESH_REQUEST_EVENT), {}, {
                origin: 'dashboard'
            });
            setRefreshId(Date.now());
        }, []);
        (0, $5uXOq.useEffect)(()=>{
            if (automaticRefreshMs == null || automaticRefreshMs < 1) return ()=>undefined;
            const refreshIntervalMs = Math.min(automaticRefreshMs, Math.pow(2, 31) - 1);
            const interval = setInterval(onRefresh, refreshIntervalMs);
            return ()=>{
                clearInterval(interval);
            };
        }, [
            automaticRefreshMs,
            onRefresh
        ]);
        const onMount = (0, $5uXOq.useCallback)((gadgetId, focusCallback)=>{
            setMostRecentlyAddedGadgetId((mostRecentlyAddedGadgetId)=>{
                if (gadgetId === mostRecentlyAddedGadgetId) {
                    focusCallback();
                    return null;
                }
                return mostRecentlyAddedGadgetId;
            });
        }, []);
        return {
            onRefresh: onRefresh,
            refreshId: refreshId,
            onAddedGadget: setMostRecentlyAddedGadgetId,
            onMount: onMount
        };
    };
    const $5e7fb5d7d6929ddb$export$b9771780c6ecf3f = ({ children: children, ...props })=>{
        const value = $5e7fb5d7d6929ddb$export$d7eb50fb85f631d(props);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iyrll")).DashboardControllerContextProvider), {
            value: value
        }, children);
    };
});
parcelRegister("3v45P", function(module, exports) {
    $parcel$export(module.exports, "on", ()=>$b978cb322a482db9$export$af631764ddc44097);
    $parcel$export(module.exports, "emit", ()=>$b978cb322a482db9$export$8f35fbd5db0f3634);
    const $b978cb322a482db9$var$EVENT_PREFIX = 'forge.bridge.';
    function $b978cb322a482db9$export$af631764ddc44097(event, callback, extension) {
        const wrappedCallback = (e)=>{
            if (!extension || !e.detail?.extension || extension.id === e.detail?.extension?.id && extension.installationId === e.detail?.extension?.installationId) callback(e.detail?.payload, e.detail?.extension);
        };
        window.document.addEventListener(`${$b978cb322a482db9$var$EVENT_PREFIX}${event}`, wrappedCallback);
        return {
            unsubscribe: ()=>window.document.removeEventListener(`${$b978cb322a482db9$var$EVENT_PREFIX}${event}`, wrappedCallback)
        };
    }
    function $b978cb322a482db9$export$8f35fbd5db0f3634(event, payload, extension) {
        const newEvent = new CustomEvent(`${$b978cb322a482db9$var$EVENT_PREFIX}${event}`, {
            detail: {
                extension: extension,
                payload: payload
            }
        });
        window.document.dispatchEvent(newEvent);
    }
});
parcelRegister("flEgL", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$d28f43dbb1e0cb29$export$2e2bcd8739ae039);
    var $hrlU6;
    var $d28f43dbb1e0cb29$export$2e2bcd8739ae039 = (eventName, addonFilter, eventData)=>{
        (0, (0, parcelRequire("hrlU6")).withConnectHost)((host)=>{
            host && host.broadcastEvent(eventName, addonFilter, eventData);
        });
    };
});
parcelRegister("1rce7", function(module, exports) {
    $parcel$export(module.exports, "DashboardPageContainer", ()=>$e6660bef9b59d455$export$8aeaeff803e753cd);
    var $5uXOq = parcelRequire("5uXOq");
    var $2i4sA;
    var $ew1Lk;
    var $iXVV6;
    var $9dbF6;
    var $bVDE5;
    var $9OXo1;
    var $cRaE8;
    var $he6UQ;
    var $cwRtG;
    const $e6660bef9b59d455$export$8aeaeff803e753cd = ({ children: children, analyticsSourceName: analyticsSourceName, analyticsExperience: analyticsExperience, unauthenticatedFallback: unauthenticatedFallback, unauthorisedFallback: unauthorisedFallback, documentTitle: documentTitle, resourceData: resourceData })=>{
        const tenantContext = (0, (0, parcelRequire("9OXo1")).useTenantContext)();
        const { data: data, loading: loading } = resourceData;
        const [{ isInitialRender: isInitialRender }] = (0, (0, parcelRequire("bVDE5")).useSpaStateTransition)();
        const ref = (0, (0, parcelRequire("he6UQ")).useDashboardRef)();
        if (!tenantContext) return null;
        if (!tenantContext.atlassianAccountId && !loading && !data) return unauthenticatedFallback;
        if (unauthorisedFallback != null && data && !data.writable) return unauthorisedFallback;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iXVV6")).ContextualAnalyticsData), {
            sourceName: analyticsSourceName,
            sourceType: (0, $4d5e0871c06cee03$export$f636b5b56f1d624a),
            containerId: data?.id
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ew1Lk")).DocumentTitle), {
            title: documentTitle
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9dbF6")).SubProductUpdater), {
            subProduct: "platform"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cwRtG")).PageLayout), {
            ref: ref
        }, !isInitialRender && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cRaE8")).PrefetchLinks), {
            dashboardId: data?.id ?? 'pending'
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("div", {
            id: "dashboard",
            className: "dashboard",
            "data-testid": "dashboard-internal-common.ui.container.div"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("2i4sA")).ViewExperienceTrackingProvider), {
            experienceId: data?.id,
            experience: analyticsExperience,
            analyticsSource: "dashboard",
            edition: null,
            application: null
        }, children))));
    };
});
parcelRegister("ew1Lk", function(module, exports) {
    $parcel$export(module.exports, "DocumentTitle", ()=>$41b549fa18234081$export$dd5ad0b65cf665da);
    var $5uXOq = parcelRequire("5uXOq");
    var $6nkVm;
    class $41b549fa18234081$export$dd5ad0b65cf665da extends (0, $5uXOq.Component) {
        static{
            this.defaultProps = {
                TitleSubscriber: (0, (0, parcelRequire("6nkVm")).DocumentTitleSubscriber)
            };
        }
        shouldComponentUpdate(nextProps) {
            return nextProps.title !== this.props.title;
        }
        render() {
            const { title: title, TitleSubscriber: TitleSubscriber } = this.props;
            return (0, ($parcel$interopDefault($5uXOq))).createElement(TitleSubscriber, null, ({ contextArray: contextArray }, { setTitle: setTitle, addDocumentTitle: addDocumentTitle, removeDocumentTitle: removeDocumentTitle })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6nkVm")).DocumentTitleHelper), {
                    title: title,
                    setTitle: setTitle,
                    addDocumentTitle: addDocumentTitle,
                    contextArray: contextArray,
                    removeDocumentTitle: removeDocumentTitle,
                    TitleSubscriber: TitleSubscriber
                }));
        }
    }
});
const $4d5e0871c06cee03$export$f636b5b56f1d624a = 'Screen';
const $4d5e0871c06cee03$export$9d2de6eebf7462b5 = 'Drawer';
const $4d5e0871c06cee03$export$1171103ea9083ffa = 'Modal';
const $4d5e0871c06cee03$export$cae2783c9279926 = 'InlineDialog';
const $4d5e0871c06cee03$export$d1123261ca8831d2 = 'Dropdown';
const $4d5e0871c06cee03$export$238d30785c4bca84 = 'Banner';
const $4d5e0871c06cee03$export$a1562d08625f5d7e = 'UI';
const $4d5e0871c06cee03$export$947dac6c72b26f81 = 'TRACK';
const $4d5e0871c06cee03$export$f1d537d508d2945a = 'SCREEN';
const $4d5e0871c06cee03$export$12346a674644c156 = 'OPERATIONAL';
parcelRegister("9dbF6", function(module, exports) {
    $parcel$export(module.exports, "SubProductUpdater", ()=>$5979c081afa32bfa$export$eb40d3df56aa5c53);
    var $5uXOq = parcelRequire("5uXOq");
    var $bNvU3;
    var $3iTiR;
    var $ero6m;
    var $54I24;
    var $8OHH4;
    const $5979c081afa32bfa$export$eb40d3df56aa5c53 = ({ allowMonolithDeferral: allowMonolithDeferral = false, ...props })=>{
        const subProduct = (0, (0, parcelRequire("8OHH4")).isSubProductProps)(props) ? props.subProduct : (0, (0, parcelRequire("ero6m")).getSubProductFromProjectType)(props.projectType);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("bNvU3")).default), {
            op: (0, (0, parcelRequire("54I24")).default),
            args: [
                subProduct,
                allowMonolithDeferral
            ]
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3iTiR")).default), {
            subProduct: subProduct
        }));
    };
});
parcelRegister("3iTiR", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$1214b0731559b377$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $1u3Jv;
    var $hFChZ;
    var $1214b0731559b377$export$2e2bcd8739ae039 = ({ subProduct: subProduct })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1u3Jv")).default), {
            op: (0, (0, parcelRequire("hFChZ")).default),
            args: [
                subProduct
            ]
        });
});
parcelRegister("hFChZ", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$0e606430ead76cd8$export$2e2bcd8739ae039);
    var $dJl1T;
    var $eusud;
    var $0e606430ead76cd8$export$2e2bcd8739ae039 = (subProduct)=>{
        (0, (0, parcelRequire("eusud")).performGetRequest)(`/rest/internal/2/mauTag/${subProduct}`).catch((err)=>{
            (0, (0, parcelRequire("dJl1T")).default).safeErrorWithoutCustomerData('common.tag-mau.tag-mau-event', 'Error connecting to monolith mau tag endpoint', err);
        });
    };
});
parcelRegister("ero6m", function(module, exports) {
    $parcel$export(module.exports, "getSubProductFromProjectType", ()=>$0997694844c8a9e7$export$b1fe420ed15dff7b);
    var $16v4H;
    const $0997694844c8a9e7$export$b1fe420ed15dff7b = (projectType)=>{
        if (projectType === 'product_discovery') return 'polaris';
        return (0, (0, parcelRequire("16v4H")).subProductFromProjectType)(projectType);
    };
});
parcelRegister("54I24", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$bcbbcd48ca398ed4$export$2e2bcd8739ae039);
    var $7T84i;
    var $9B86Q;
    var $cQZXC;
    var $dJl1T;
    var $fjcgS;
    const $bcbbcd48ca398ed4$var$maybeDelayAndRace = (maybeWaitPromise, getDefaultPromise, getRacePromise)=>maybeWaitPromise ? Promise.race([
            maybeWaitPromise.then(getDefaultPromise),
            getRacePromise()
        ]) : getDefaultPromise();
    var $bcbbcd48ca398ed4$export$2e2bcd8739ae039 = async (subProduct, allowMonolithDeferral)=>{
        (0, (0, parcelRequire("fjcgS")).getAnalyticsWebClientPromise)().then((client)=>{
            client.setSubProduct(subProduct);
            if (subProduct === 'serviceDesk') client.setUIViewedAttributes({
                solutions: [
                    (0, $86344d58e71e925a$export$c8b406ad8ee0dab9)
                ]
            });
            else client.removeUIViewedAttribute('solutions');
        });
        $bcbbcd48ca398ed4$var$maybeDelayAndRace(allowMonolithDeferral ? (0, (0, parcelRequire("cQZXC")).waitForHeritage)() : null, ()=>(0, (0, parcelRequire("7T84i")).loadBridgeStrict)({
                name: 'jira/analytics/sub-product-resolver',
                wrmKeys: [
                    'wrc!com.atlassian.jira.jira-client-analytics-plugin:analytics-web-client'
                ]
            }), ()=>(0, (0, parcelRequire("9B86Q")).loadBridgeWeak)({
                name: 'jira/analytics/sub-product-resolver'
            })).then((subProductResolver)=>{
            subProductResolver.setSubProduct(subProduct);
        }).catch((e)=>(0, (0, parcelRequire("dJl1T")).default).safeErrorWithoutCustomerData('common.analytics-sub-product.sub-product-updater', 'Error connecting to monolith sub product resolver', e));
    };
});
const $86344d58e71e925a$export$c8b406ad8ee0dab9 = 'jiraServiceManagement';
parcelRegister("8OHH4", function(module, exports) {
    $parcel$export(module.exports, "isSubProductProps", ()=>$701d07b56c3e3574$export$c3063d6d7a8c075f);
    const $701d07b56c3e3574$export$c3063d6d7a8c075f = (props)=>'subProduct' in props && typeof props.subProduct === 'string';
    const $701d07b56c3e3574$export$81da4a7e1ba1dcee = (props)=>'projectType' in props && typeof props.projectType === 'string';
});
parcelRegister("cRaE8", function(module, exports) {
    $parcel$export(module.exports, "PrefetchLinks", ()=>$956d4da8aa4ae614$export$b64797ad00b7bd3c);
    var $5uXOq = parcelRequire("5uXOq");
    var $JIPqS;
    var $83oax;
    var $gh1hi;
    var $bVDE5;
    const $956d4da8aa4ae614$export$b64797ad00b7bd3c = ({ dashboardId: dashboardId })=>{
        const [{ isInitialRender: isInitialRender }] = (0, (0, parcelRequire("bVDE5")).useSpaStateTransition)();
        const { data: data } = (0, (0, parcelRequire("gh1hi")).useDashboardResource)();
        const prefetches = (0, $5uXOq.useMemo)(()=>data ? (0, (0, parcelRequire("83oax")).getPrefetches)(data) : [], [
            data
        ]);
        (0, $5uXOq.useLayoutEffect)(()=>{
            if (!isInitialRender && window && window[0, (0, parcelRequire("JIPqS")).PRELOADED_GADGET_DATA_KEY]) delete window[0, (0, parcelRequire("JIPqS")).PRELOADED_GADGET_DATA_KEY];
        }, [
            isInitialRender
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, prefetches.map((prefetch)=>(0, ($parcel$interopDefault($5uXOq))).createElement("link", {
                key: `${dashboardId}-${prefetch.url}`,
                rel: isInitialRender ? prefetch.priority : 'prefetch',
                as: "fetch",
                href: prefetch.url
            })));
    };
});
parcelRegister("he6UQ", function(module, exports) {
    $parcel$export(module.exports, "useDashboardRef", ()=>$7859c7156387c1ba$export$a52b4118d451e93c);
    var $5uXOq = parcelRequire("5uXOq");
    const $7859c7156387c1ba$export$a52b4118d451e93c = ()=>{
        (0, $5uXOq.useEffect)(()=>{
            const originalGetDashboard = window.AG?.DashboardManager?.getDashboard;
            return ()=>{
                window.AG = {
                    ...window.AG,
                    DashboardManager: {
                        ...window.AG?.DashboardManager,
                        getDashboard: originalGetDashboard
                    }
                };
            };
        }, []);
        return (0, $5uXOq.useCallback)((node)=>{
            window.AG = {
                ...window.AG,
                DashboardManager: {
                    ...window.AG?.DashboardManager,
                    getDashboard: ()=>node
                }
            };
        }, []);
    };
});
parcelRegister("cwRtG", function(module, exports) {
    $parcel$export(module.exports, "PageLayout", ()=>$a3f1ff5d07230114$export$6d1402fb58384e4f);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    const $a3f1ff5d07230114$export$6d1402fb58384e4f = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_18s8zdiv _kqswh2mm _1pbyb4wl",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("1CEw6", function(module, exports) {
    $parcel$export(module.exports, "EngagementBanners", ()=>$ea6f1e008ef29904$export$eaa8218767cf0103);
    var $5uXOq = parcelRequire("5uXOq");
    const $ea6f1e008ef29904$export$eaa8218767cf0103 = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null);
});
parcelRegister("5HxxK", function(module, exports) {
    $parcel$export(module.exports, "ColumnPlaceholderContextConsumer", ()=>$42fb06f36f97089d$export$efa1c423df3eb8d9);
    $parcel$export(module.exports, "ColumnLayout", ()=>$42fb06f36f97089d$export$c7e5bac7049eee2a);
    var $5uXOq = parcelRequire("5uXOq");
    var $bJcWY;
    var $aZdfU;
    var $bbkUF;
    var $6hp8I;
    var $1oaeD;
    var $jnkfq;
    var $6fab6;
    var $bfdYn;
    var $9bw66;
    var $h6EHU;
    const { createRenderFunction: $42fb06f36f97089d$var$createPlaceholderRender, Provider: $42fb06f36f97089d$export$e3a62d26c35df0c2, Consumer: $42fb06f36f97089d$export$efa1c423df3eb8d9 } = (0, (0, parcelRequire("jnkfq")).createRendererContext)();
    const { createRenderFunction: $42fb06f36f97089d$var$createGadgetRender, Provider: $42fb06f36f97089d$export$de8f6456a7a635a7, Consumer: $42fb06f36f97089d$export$599894a5533c31ef } = (0, (0, parcelRequire("jnkfq")).createRendererContext)();
    const $42fb06f36f97089d$export$c7e5bac7049eee2a = ({ data: data, dnd: dnd, columnPlaceholder: columnPlaceholder, layoutPlaceholder: layoutPlaceholder, onLayoutReposition: onLayoutReposition, children: children })=>{
        const { layout: layout, writable: writable, gadgets: gadgets } = data;
        const { DragDropContext: DragDropContext = (0, (0, parcelRequire("bfdYn")).DegenerateDragDropContext), Draggable: Draggable = (0, (0, parcelRequire("bfdYn")).DegenerateDraggable), Droppable: Droppable = (0, (0, parcelRequire("bfdYn")).DegenerateDroppable) } = dnd ?? {};
        const { getPropsByGadgetId: getPropsByGadgetId, getPropsByColumnIndex: getPropsByColumnIndex } = (0, (0, parcelRequire("1oaeD")).useMaximizedGadget)(gadgets);
        const [isAnyDragging, setIsAnyDragging] = (0, $5uXOq.useState)(false);
        const columns = (0, $5uXOq.useMemo)(()=>(0, (0, parcelRequire("6fab6")).toColumns)(gadgets, layout), [
            gadgets,
            layout
        ]);
        const width = (0, (0, parcelRequire("bbkUF")).useDebouncedWindowWidth)();
        const onDragEnd = (0, $5uXOq.useCallback)((result)=>{
            if (!writable || !onLayoutReposition) return;
            const { destination: destination, source: source } = result;
            if (destination && destination.droppableId != null) {
                const moved = columns[+source.droppableId].splice(source.index, 1)[0];
                columns[+destination.droppableId].splice(destination.index, 0, moved);
                moved.column = +destination.droppableId;
            }
            onLayoutReposition(layout, columns);
            setIsAnyDragging(false);
        }, [
            columns,
            layout,
            writable,
            onLayoutReposition
        ]);
        const onDragStart = (0, $5uXOq.useCallback)(()=>{
            setIsAnyDragging(true);
        }, []);
        const renderColumnPlaceholder = $42fb06f36f97089d$var$createPlaceholderRender(columnPlaceholder);
        const renderGadget = $42fb06f36f97089d$var$createGadgetRender(children);
        if (!gadgets.length && layoutPlaceholder != null) return layoutPlaceholder;
        return (0, ($parcel$interopDefault($5uXOq))).createElement(DragDropContext, {
            onDragEnd: onDragEnd,
            onDragStart: onDragStart
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9bw66")).ContainerWrapper), {
            "data-testid": "dashboard-internal-common.ui.dashboard-content.layout.container-wrapper"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6hp8I")).DimensionsProvider), {
            value: `${layout}-${width}`
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("bJcWY")).default), {
            layout: "fluid"
        }, columns.map((items, columnIndex)=>{
            const columnWidth = (0, (0, parcelRequire("h6EHU")).getColumnWidth)(columnIndex, layout, getPropsByColumnIndex(columnIndex).display);
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("aZdfU")).default), {
                key: columnIndex,
                medium: columnWidth,
                testId: "dashboard-internal-common.ui.dashboard-content.layout.column"
            }, (0, ($parcel$interopDefault($5uXOq))).createElement(Droppable, {
                droppableId: columnIndex.toString()
            }, ({ innerRef: columnRef, droppableProps: droppableProps, placeholder: dndPlaceholder }, { isDraggingOver: isDraggingOver })=>{
                const isNonEmptyCol = items.length > 0;
                return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9bw66")).ContentWrapper), {
                    ...droppableProps,
                    ref: columnRef,
                    isDimmed: isNonEmptyCol && isDraggingOver,
                    isHidden: columnWidth === 0
                }, isNonEmptyCol ? items.map((gadgetData, rowIndex)=>{
                    const { isDraggable: isMaybeDraggable, ...gadgetProps } = getPropsByGadgetId(gadgetData.id);
                    const isDraggable = dnd != null && isMaybeDraggable;
                    return (0, ($parcel$interopDefault($5uXOq))).createElement(Draggable, {
                        key: gadgetData.id,
                        draggableId: gadgetData.id,
                        index: rowIndex,
                        isDragDisabled: !isDraggable
                    }, ({ innerRef: rowRef, dragHandleProps: dragHandleProps, draggableProps: draggableProps }, { isDragging: isDragging })=>(0, ($parcel$interopDefault($5uXOq))).createElement("div", {
                            ...draggableProps,
                            ref: rowRef
                        }, renderGadget({
                            data: gadgetData,
                            dragHandleProps: dragHandleProps,
                            isDraggable: isDraggable,
                            isDragging: isDragging,
                            isAnyDragging: isAnyDragging,
                            ...gadgetProps
                        })));
                }) : renderColumnPlaceholder({
                    columnIndex: columnIndex,
                    isDraggingOver: isDraggingOver
                }), dndPlaceholder);
            }));
        })))));
    };
});
parcelRegister("bJcWY", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$08fe944bdcd177c2$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $f1WIN;
    var $itnZ9;
    var $aZdfU;
    var $ephoW;
    const $08fe944bdcd177c2$var$GridWrapper = ({ spacing: spacingProp, columns: columnsProp, layout: layout, testId: testId, children: children, theme: theme })=>{
        const { isRoot: isRoot } = (0, $5uXOq.useContext)((0, (0, parcelRequire("ephoW")).GridContext));
        const { medium: medium } = (0, $5uXOq.useContext)((0, (0, parcelRequire("aZdfU")).GridColumnContext));
        const defaultColumns = medium > 0 ? medium : (0, (0, parcelRequire("f1WIN")).defaultGridColumns);
        const spacing = spacingProp ?? theme?.spacing ?? (0, (0, parcelRequire("f1WIN")).defaultSpacing);
        const columns = columnsProp ?? theme?.columns ?? defaultColumns;
        const isNested = theme?.isNestedGrid ?? !isRoot;
        const contextValue = (0, $5uXOq.useMemo)(()=>({
                isRoot: false,
                isNested: isNested,
                spacing: spacing,
                columns: columns
            }), [
            spacing,
            columns,
            isNested
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ephoW")).GridContext).Provider, {
            value: contextValue
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("itnZ9")).Grid), {
            layout: layout,
            testId: testId
        }, children));
    };
    var $08fe944bdcd177c2$export$2e2bcd8739ae039 = $08fe944bdcd177c2$var$GridWrapper;
});
parcelRegister("f1WIN", function(module, exports) {
    $parcel$export(module.exports, "defaultGridColumns", ()=>$196a11a7b8c2fbef$export$74797698e12f8baa);
    $parcel$export(module.exports, "defaultGridColumnWidth", ()=>$196a11a7b8c2fbef$export$b8241a1616ab1b96);
    $parcel$export(module.exports, "spacingMapping", ()=>$196a11a7b8c2fbef$export$2a89a65a43bab52d);
    $parcel$export(module.exports, "defaultSpacing", ()=>$196a11a7b8c2fbef$export$8ec7372efd74b753);
    $parcel$export(module.exports, "defaultBannerHeight", ()=>$196a11a7b8c2fbef$export$d77f83c3d17a7b8a);
    $parcel$export(module.exports, "defaultMedium", ()=>$196a11a7b8c2fbef$export$f8725d21f81f3e01);
    $parcel$export(module.exports, "defaultLayout", ()=>$196a11a7b8c2fbef$export$2303d2bd9eeff99a);
    $parcel$export(module.exports, "varColumnsNum", ()=>$196a11a7b8c2fbef$export$9ddc3632bbfb8966);
    $parcel$export(module.exports, "varColumnSpan", ()=>$196a11a7b8c2fbef$export$8b7e31f391e6e681);
    $parcel$export(module.exports, "varGridSpacing", ()=>$196a11a7b8c2fbef$export$936829b4511db3e4);
    const $196a11a7b8c2fbef$export$3aaf3a78c909ea56 = 8;
    const $196a11a7b8c2fbef$export$74797698e12f8baa = 12;
    const $196a11a7b8c2fbef$export$b8241a1616ab1b96 = $196a11a7b8c2fbef$export$3aaf3a78c909ea56 * 10;
    const $196a11a7b8c2fbef$export$2a89a65a43bab52d = {
        comfortable: $196a11a7b8c2fbef$export$3aaf3a78c909ea56 * 5,
        cosy: $196a11a7b8c2fbef$export$3aaf3a78c909ea56 * 2,
        compact: $196a11a7b8c2fbef$export$3aaf3a78c909ea56 * 0.5
    };
    const $196a11a7b8c2fbef$export$8ec7372efd74b753 = 'cosy';
    const $196a11a7b8c2fbef$export$d77f83c3d17a7b8a = 52;
    const $196a11a7b8c2fbef$export$f8725d21f81f3e01 = 0;
    const $196a11a7b8c2fbef$export$2303d2bd9eeff99a = 'fixed';
    const $196a11a7b8c2fbef$export$9ddc3632bbfb8966 = '--ds-columns-num';
    const $196a11a7b8c2fbef$export$8b7e31f391e6e681 = '--ds-column-span';
    const $196a11a7b8c2fbef$export$936829b4511db3e4 = '--ds-grid-spacing';
});
parcelRegister("itnZ9", function(module, exports) {
    $parcel$export(module.exports, "Grid", ()=>$92e4fcbfc398e3a4$export$ef2184bd89960b14);
    var $5uXOq = parcelRequire("5uXOq");
    var $f8Sb3;
    var $f1WIN;
    var $ephoW;
    function $92e4fcbfc398e3a4$var$_EMOTION_STRINGIFIED_CSS_ERROR__() {
        return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop).";
    }
    const $92e4fcbfc398e3a4$var$gridStyles = (0, (0, parcelRequire("f8Sb3")).css)({
        display: 'flex',
        margin: '0 auto',
        padding: `0 calc(var(${(0, (0, parcelRequire("f1WIN")).varGridSpacing)}) / 2)`,
        position: 'relative',
        alignItems: 'flex-start',
        flexWrap: 'wrap'
    });
    const $92e4fcbfc398e3a4$var$gridLayoutStyles = {
        fixed: (0, (0, parcelRequire("f8Sb3")).css)({
            maxWidth: `calc(var(${(0, (0, parcelRequire("f1WIN")).varColumnsNum)}) * ${(0, (0, parcelRequire("f1WIN")).defaultGridColumnWidth)}px)`
        }),
        fluid: {
            name: "qhxz92",
            styles: "max-width:100%"
        }
    };
    const $92e4fcbfc398e3a4$var$nestedGridStyles = (0, (0, parcelRequire("f8Sb3")).css)({
        margin: `0 calc(-1 * var(${(0, (0, parcelRequire("f1WIN")).varGridSpacing)}))`
    });
    const $92e4fcbfc398e3a4$export$ef2184bd89960b14 = ({ layout: layout = (0, (0, parcelRequire("f1WIN")).defaultLayout), testId: testId, children: children })=>{
        const { isNested: isNested, columns: columns, spacing: spacing } = (0, $5uXOq.useContext)((0, (0, parcelRequire("ephoW")).GridContext));
        return (0, (0, parcelRequire("f8Sb3")).jsx)("div", {
            css: [
                $92e4fcbfc398e3a4$var$gridStyles,
                $92e4fcbfc398e3a4$var$gridLayoutStyles[layout],
                isNested && $92e4fcbfc398e3a4$var$nestedGridStyles
            ],
            style: {
                [(0, (0, parcelRequire("f1WIN")).varColumnsNum)]: columns,
                [(0, (0, parcelRequire("f1WIN")).varGridSpacing)]: `${(0, (0, parcelRequire("f1WIN")).spacingMapping)[spacing]}px`
            },
            "data-testid": testId
        }, children);
    };
});
parcelRegister("ephoW", function(module, exports) {
    $parcel$export(module.exports, "GridContext", ()=>$529bf77650fbff0f$export$11cae3e04ab99fae);
    var $5uXOq = parcelRequire("5uXOq");
    var $f1WIN;
    const $529bf77650fbff0f$export$11cae3e04ab99fae = (0, $5uXOq.createContext)({
        isRoot: true,
        isNested: false,
        spacing: (0, (0, parcelRequire("f1WIN")).defaultSpacing),
        columns: (0, (0, parcelRequire("f1WIN")).defaultGridColumns)
    });
});
parcelRegister("aZdfU", function(module, exports) {
    $parcel$export(module.exports, "GridColumnContext", ()=>$11b791725219e059$export$1229ad7112b989f5);
    $parcel$export(module.exports, "default", ()=>$11b791725219e059$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $f8Sb3;
    var $f1WIN;
    var $ephoW;
    var $11b791725219e059$var$ColumnVariant;
    (function(ColumnVariant) {
        ColumnVariant["Auto"] = "auto";
        ColumnVariant["Bounded"] = "bounded";
        ColumnVariant["FullWidth"] = "fullWidth";
    })($11b791725219e059$var$ColumnVariant || ($11b791725219e059$var$ColumnVariant = {}));
    const $11b791725219e059$var$getVariant = ({ medium: medium, columns: columns })=>{
        if (medium === (0, (0, parcelRequire("f1WIN")).defaultMedium)) return "auto";
        else if (medium < columns) return "bounded";
        return "fullWidth";
    };
    const $11b791725219e059$var$availableWidth = '99.9999%';
    const $11b791725219e059$var$singleColumnWidth = `(${$11b791725219e059$var$availableWidth} / var(${(0, (0, parcelRequire("f1WIN")).varColumnsNum)}))`;
    const $11b791725219e059$var$gridColumnStyles = (0, (0, parcelRequire("f8Sb3")).css)({
        minWidth: `calc(${$11b791725219e059$var$singleColumnWidth} - var(${(0, (0, parcelRequire("f1WIN")).varGridSpacing)}))`,
        margin: `0 calc(var(${(0, (0, parcelRequire("f1WIN")).varGridSpacing)}) / 2)`,
        flexGrow: 1,
        flexShrink: 0,
        wordWrap: 'break-word'
    });
    const $11b791725219e059$var$gridColumnWidthStyles = {
        ["auto"]: (0, (0, parcelRequire("f8Sb3")).css)({
            maxWidth: `calc(100% - var(${(0, (0, parcelRequire("f1WIN")).varGridSpacing)}))`,
            flexBasis: `auto`
        }),
        ["bounded"]: (0, (0, parcelRequire("f8Sb3")).css)({
            maxWidth: `calc(${$11b791725219e059$var$singleColumnWidth} *  var(${(0, (0, parcelRequire("f1WIN")).varColumnSpan)}) - var(${(0, (0, parcelRequire("f1WIN")).varGridSpacing)}))`,
            flexBasis: `100%`
        }),
        ["fullWidth"]: (0, (0, parcelRequire("f8Sb3")).css)({
            maxWidth: `calc(100% - var(${(0, (0, parcelRequire("f1WIN")).varGridSpacing)}))`,
            flexBasis: `100%`
        })
    };
    const $11b791725219e059$export$1229ad7112b989f5 = (0, $5uXOq.createContext)({
        medium: (0, (0, parcelRequire("f1WIN")).defaultMedium)
    });
    const $11b791725219e059$var$GridColumn = ({ medium: medium = (0, (0, parcelRequire("f1WIN")).defaultMedium), children: children, testId: testId })=>{
        const { columns: columns } = (0, $5uXOq.useContext)((0, (0, parcelRequire("ephoW")).GridContext));
        const contextValue = (0, $5uXOq.useMemo)(()=>({
                medium: medium
            }), [
            medium
        ]);
        const colSpan = Math.max(1, Math.min(medium, columns));
        const variant = $11b791725219e059$var$getVariant({
            medium: medium,
            columns: columns
        });
        return (0, (0, parcelRequire("f8Sb3")).jsx)($11b791725219e059$export$1229ad7112b989f5.Provider, {
            value: contextValue
        }, (0, (0, parcelRequire("f8Sb3")).jsx)("div", {
            css: [
                $11b791725219e059$var$gridColumnStyles,
                $11b791725219e059$var$gridColumnWidthStyles[variant]
            ],
            style: {
                [(0, (0, parcelRequire("f1WIN")).varColumnSpan)]: variant === "auto" ? 'auto' : colSpan
            },
            "data-testid": testId
        }, children));
    };
    var $11b791725219e059$export$2e2bcd8739ae039 = $11b791725219e059$var$GridColumn;
});
parcelRegister("bbkUF", function(module, exports) {
    $parcel$export(module.exports, "useDebouncedWindowWidth", ()=>$c482c1c1fc6dfb26$export$4b69c5ad33ff59ee);
    var $5uXOq = parcelRequire("5uXOq");
    var $lFKmC = parcelRequire("lFKmC");
    const $c482c1c1fc6dfb26$export$4b69c5ad33ff59ee = (ms = 100)=>{
        const [width, setWidth] = (0, $5uXOq.useState)(window.innerWidth);
        const debounced = (0, $5uXOq.useRef)((0, ($parcel$interopDefault($lFKmC)))((w)=>setWidth(w), ms));
        (0, $5uXOq.useEffect)(()=>{
            const handleResize = ()=>{
                const { innerWidth: innerWidth } = window;
                if (innerWidth !== width) debounced.current(innerWidth);
            };
            window.addEventListener('resize', handleResize);
            return ()=>{
                window.removeEventListener('resize', handleResize);
            };
        }, [
            width
        ]);
        return width;
    };
});
parcelRegister("6hp8I", function(module, exports) {
    $parcel$export(module.exports, "DimensionsProvider", ()=>$096975c907c9ee30$export$a31e22a91fd34dd9);
    $parcel$export(module.exports, "useDimensions", ()=>$096975c907c9ee30$export$d04af0e501fb729f);
    var $5uXOq = parcelRequire("5uXOq");
    const $096975c907c9ee30$var$DimensionsContext = (0, $5uXOq.createContext)('');
    const $096975c907c9ee30$export$a31e22a91fd34dd9 = $096975c907c9ee30$var$DimensionsContext.Provider;
    const $096975c907c9ee30$export$d04af0e501fb729f = ()=>(0, $5uXOq.useContext)($096975c907c9ee30$var$DimensionsContext);
});
parcelRegister("1oaeD", function(module, exports) {
    $parcel$export(module.exports, "useMaximizedGadget", ()=>$3f7e0a0376bf6236$export$23a0742f39986cba);
    var $5uXOq = parcelRequire("5uXOq");
    var $JIPqS;
    var $4oDnX;
    var $ieDtd;
    var $doZTA;
    const $3f7e0a0376bf6236$export$23a0742f39986cba = (gadgetsData)=>{
        const [{ location: { hash: hash } }] = (0, (0, parcelRequire("doZTA")).useRouter)();
        const [maximizedQueryParam, setMaximizedQueryParam] = (0, (0, parcelRequire("ieDtd")).useQueryParam)((0, (0, parcelRequire("JIPqS")).MAXIMIZED_PARAM));
        const [, { setAppReady: setAppReady }] = (0, (0, parcelRequire("4oDnX")).useSpaStateActions)();
        const decodedId = hash.match(/.*\/(?<id>\d+)/)?.groups?.id ?? null;
        const idFromHashOrQuery = maximizedQueryParam != null ? maximizedQueryParam : decodedId;
        const gadget = idFromHashOrQuery != null && gadgetsData?.find(({ id: id })=>id === idFromHashOrQuery) || null;
        const maximizedId = gadget?.isMaximizable ? idFromHashOrQuery : null;
        const getMaximisedGadgetId = (0, $5uXOq.useMemo)(()=>maximizedId, [
            maximizedId
        ]);
        const getPropsByColumnIndex = (0, $5uXOq.useCallback)((column)=>{
            const maximizedGadget = maximizedId !== null ? gadgetsData?.find(({ id: id })=>id === maximizedId) : null;
            const { column: maximizedColumn = null } = maximizedGadget ?? {};
            const display = maximizedColumn === null && (0, (0, parcelRequire("JIPqS")).GADGET_DISPLAY).NORMAL || maximizedColumn === column && (0, (0, parcelRequire("JIPqS")).GADGET_DISPLAY).MAXIMIZED || (0, (0, parcelRequire("JIPqS")).GADGET_DISPLAY).HIDDEN;
            return {
                display: display
            };
        }, [
            maximizedId,
            gadgetsData
        ]);
        const maximizedLookup = (0, $5uXOq.useMemo)(()=>Object.fromEntries(gadgetsData?.filter(({ isMaximizable: isMaximizable })=>isMaximizable).map((data)=>[
                    data.id,
                    (doMaximizeNotRestore)=>{
                        if (maximizedId === (doMaximizeNotRestore ? data.id : null)) return;
                        setMaximizedQueryParam(doMaximizeNotRestore ? data.id : undefined);
                        setTimeout(setAppReady);
                    }
                ]) ?? []), [
            gadgetsData,
            maximizedId,
            setAppReady,
            setMaximizedQueryParam
        ]);
        const getPropsByGadgetId = (0, $5uXOq.useCallback)((id)=>{
            const display = maximizedId === null && (0, (0, parcelRequire("JIPqS")).GADGET_DISPLAY).NORMAL || maximizedId === id && (0, (0, parcelRequire("JIPqS")).GADGET_DISPLAY).MAXIMIZED || (0, (0, parcelRequire("JIPqS")).GADGET_DISPLAY).HIDDEN;
            const isDraggable = id !== maximizedId;
            const onMaximizeOrRestore = maximizedLookup[id] ?? (()=>undefined);
            return {
                display: display,
                isDraggable: isDraggable,
                onMaximizeOrRestore: onMaximizeOrRestore
            };
        }, [
            maximizedId,
            maximizedLookup
        ]);
        return {
            getMaximisedGadgetId: getMaximisedGadgetId,
            getPropsByGadgetId: getPropsByGadgetId,
            getPropsByColumnIndex: getPropsByColumnIndex
        };
    };
});
parcelRegister("6fab6", function(module, exports) {
    $parcel$export(module.exports, "toColumns", ()=>$78db23f50316c14c$export$755ca66aa40344da);
    const $78db23f50316c14c$export$755ca66aa40344da = (gadgets, layout)=>{
        const getGadgetsForCol = (col)=>gadgets.filter((gadget)=>gadget.column === col);
        switch(layout){
            case 'A':
                return [
                    getGadgetsForCol(0)
                ];
            case 'AA':
            case 'AB':
            case 'BA':
                return [
                    getGadgetsForCol(0),
                    getGadgetsForCol(1)
                ];
            case 'AAA':
                return [
                    getGadgetsForCol(0),
                    getGadgetsForCol(1),
                    getGadgetsForCol(2)
                ];
            default:
                throw new Error('Unexpected layout was provided');
        }
    };
});
parcelRegister("bfdYn", function(module, exports) {
    $parcel$export(module.exports, "DegenerateDragDropContext", ()=>$b11313e6244f86bc$export$cc74aca994b0ed41);
    $parcel$export(module.exports, "DegenerateDraggable", ()=>$b11313e6244f86bc$export$c114e58211833c77);
    $parcel$export(module.exports, "DegenerateDroppable", ()=>$b11313e6244f86bc$export$50925b5bb72c3049);
    const $b11313e6244f86bc$var$providedDraggable = {
        innerRef: ()=>undefined,
        draggableProps: {},
        dragHandleProps: undefined
    };
    const $b11313e6244f86bc$var$snapshotDraggable = {
        isDragging: false,
        isDropAnimating: false,
        dropAnimation: undefined,
        draggingOver: undefined,
        combineWith: undefined,
        combineTargetFor: undefined,
        mode: undefined,
        isClone: false
    };
    const $b11313e6244f86bc$var$providedDroppable = {
        innerRef: ()=>undefined,
        placeholder: undefined,
        droppableProps: {}
    };
    const $b11313e6244f86bc$var$snapshotDroppable = {
        isDraggingOver: false,
        draggingOverWith: undefined,
        draggingFromThisWith: undefined,
        isUsingPlaceholder: false
    };
    const $b11313e6244f86bc$var$rubricDraggable = {};
    const $b11313e6244f86bc$export$cc74aca994b0ed41 = ({ children: children })=>children;
    const $b11313e6244f86bc$export$c114e58211833c77 = ({ children: children })=>children($b11313e6244f86bc$var$providedDraggable, $b11313e6244f86bc$var$snapshotDraggable, $b11313e6244f86bc$var$rubricDraggable);
    const $b11313e6244f86bc$export$50925b5bb72c3049 = ({ children: children })=>children($b11313e6244f86bc$var$providedDroppable, $b11313e6244f86bc$var$snapshotDroppable);
});
parcelRegister("9bw66", function(module, exports) {
    $parcel$export(module.exports, "ContentWrapper", ()=>$53e240ce7384f76f$export$6ce170286acaa8d3);
    $parcel$export(module.exports, "ContainerWrapper", ()=>$53e240ce7384f76f$export$1312e4658e616d9d);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    const $53e240ce7384f76f$export$6ce170286acaa8d3 = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { isDimmed: isDimmed, isHidden: isHidden, ...__cmpldp } = __cmplp;
        return $5uXOq.createElement(C, {
            ...__cmpldp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1bsb1osq _2rkoftgi _v564mfn2",
                __cmplp.isDimmed ? "_11q7hri9" : "_11q7vuon",
                __cmplp.isHidden && "_1e0cglyw",
                __cmplp.className
            ])
        });
    });
    const $53e240ce7384f76f$export$1312e4658e616d9d = (0, $5uXOq.forwardRef)(({ as: C = "main", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_19pkidpf _otyridpf _18u0mgjw _2hwxmgjw",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("h6EHU", function(module, exports) {
    $parcel$export(module.exports, "getColumnWidth", ()=>$3e20f2a7b6f0ea06$export$dfa11a34ceba822);
    var $JIPqS;
    const $3e20f2a7b6f0ea06$export$dfa11a34ceba822 = (index, layout, display)=>{
        if (display === (0, (0, parcelRequire("JIPqS")).GADGET_DISPLAY).HIDDEN) return 0;
        if (layout === 'A' || display === (0, (0, parcelRequire("JIPqS")).GADGET_DISPLAY).MAXIMIZED) return 12;
        if (layout === 'AA') return 6;
        if (layout === 'AAA') return 4;
        if (layout === 'AB') return index === 0 ? 8 : 4;
        return index === 0 ? 4 : 8;
    };
});
parcelRegister("4kIa4", function(module, exports) {
    $parcel$export(module.exports, "DashboardContentContainer", ()=>$e519b350b77b4296$export$b49692a976fb3d05);
    var $5uXOq = parcelRequire("5uXOq");
    var $47bDC = parcelRequire("47bDC");
    var $g0Gtc;
    var $dJl1T;
    var $beRD7;
    var $gh1hi;
    var $1yBXu;
    var $i7Ldp;
    var $bOnpg;
    var $8h45R;
    var $6IxJi;
    var $h5Jym;
    var $5jJ3W;
    var $1Bx5m;
    var $7v0Ei;
    var $fxC6K;
    var $kUwqK;
    var $hwuTz;
    const $e519b350b77b4296$var$HALF_HOUR = 1800000;
    const $e519b350b77b4296$export$d3a4cb300c572c66 = ({ data: { id: id, gadgets: gadgets, layout: layout }, saveCustomGadgetTitle: saveCustomGadgetTitle })=>{
        (0, (0, parcelRequire("h5Jym")).useSpaStatePageReady)();
        (0, (0, parcelRequire("kUwqK")).usePageStatusLogger)($e519b350b77b4296$var$HALF_HOUR);
        (0, (0, parcelRequire("6IxJi")).useGadgetMetricsBridge)(id, gadgets, layout);
        (0, (0, parcelRequire("i7Ldp")).useConnectApi)({
            saveCustomGadgetTitle: saveCustomGadgetTitle
        });
        return null;
    };
    const $e519b350b77b4296$var$AMD_RESOURCE_RETRY_LIMIT = 2;
    const $e519b350b77b4296$export$b49692a976fb3d05 = ({ children: children, customHeight: customHeight, analyticsExperience: analyticsExperience, saveCustomGadgetTitle: saveCustomGadgetTitle = ()=>Promise.resolve(), resourceData: resourceData, permissionErrorFallback: permissionErrorFallback })=>{
        const { data: configData, error: configError, loading: configLoading } = resourceData;
        const { loading: amdLoading, data: amdData, error: amdError, refresh: amdRefresh } = (0, (0, parcelRequire("gh1hi")).useDashboardAMDModulesResource)();
        const [amdResourceRetries, setAmdResourceRetries] = (0, $5uXOq.useState)(0);
        const fireAnalytics = (0, (0, parcelRequire("bOnpg")).useDashboardAnalytics)();
        const eventSent = (0, $5uXOq.useRef)(null);
        const isAmdResourceUnhealthy = configData != null && amdData == null && amdError != null || amdData?.amdModules != null && Object.values(amdData?.amdModules).some((v)=>v == null);
        const isAmdResourceRetriesExceeded = !amdLoading && amdResourceRetries >= $e519b350b77b4296$var$AMD_RESOURCE_RETRY_LIMIT;
        (0, $5uXOq.useEffect)(()=>{
            if (false || configLoading || configData == null || eventSent.current === Number(configData.id)) return;
            fireAnalytics({
                eventType: (0, $4d5e0871c06cee03$export$f1d537d508d2945a),
                actionSubjectId: 'dashboard',
                actionSubject: 'dashboardScreen',
                action: 'viewed'
            });
            (0, (0, parcelRequire("5jJ3W")).addDashboardToRecents)(configData.id);
            eventSent.current = Number(configData.id);
        }, [
            configData,
            fireAnalytics,
            configLoading
        ]);
        (0, $5uXOq.useEffect)(()=>{
            if (false) return;
            if (amdLoading) {
                !isAmdResourceUnhealthy && setAmdResourceRetries(0);
                return;
            }
            if (!isAmdResourceUnhealthy) (0, (0, parcelRequire("dJl1T")).default).safeInfoWithoutCustomerData('spa-apps.dashboard.content-container', `AMD resource loaded, after retries [${amdResourceRetries}]`);
            else if (isAmdResourceRetriesExceeded) {
                const [failedCount, totalCount] = Object.values(amdData?.amdModules ?? []).reduce(([f], v, i, arr)=>[
                        f + (v == null ? 1 : 0),
                        arr.length
                    ], [
                    0,
                    0
                ]);
                (0, (0, parcelRequire("dJl1T")).default).safeErrorWithoutCustomerData('spa-apps.dashboard.content-container', amdError ? `AMD resource error [${String(amdError.message)}]` : `AMD resource degraded, failed [${failedCount}] of [${totalCount}] gadgets`);
            } else {
                (0, (0, parcelRequire("dJl1T")).default).safeWarnWithoutCustomerData('spa-apps.dashboard.content-container', `Retrying AMD resource, retry [${amdResourceRetries + 1}]`);
                setAmdResourceRetries((n)=>n + 1);
                amdRefresh();
            }
        }, [
            amdResourceRetries,
            amdLoading,
            amdError,
            amdData,
            isAmdResourceUnhealthy,
            isAmdResourceRetriesExceeded,
            amdRefresh
        ]);
        if (false || configLoading) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1yBXu")).GenericLoadingView), {
            customHeight: customHeight
        });
        if (configError != null || configData == null) {
            const errorCode = Number(configError?.message?.substring(configError.message.length - 3));
            if ((0, (0, parcelRequire("fxC6K")).DASHBOARD_PERMISSION_ERROR_CODE_LIST).includes(errorCode)) return permissionErrorFallback;
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("g0Gtc")).ExperienceFailureTracker), {
                location: analyticsExperience,
                failureEventAttributes: {
                    error: configError ? configError.message : 'unknown error'
                }
            }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("beRD7")).JSErrorPageAsync), null));
        }
        if (isAmdResourceRetriesExceeded && amdError != null && amdData == null) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("g0Gtc")).ExperienceFailureTracker), {
            location: analyticsExperience,
            failureEventAttributes: {
                error: `AMD resource error [${String(amdError.message)}]`
            }
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("beRD7")).JSErrorPageAsync), null));
        return ((0, ($parcel$interopDefault($5uXOq))).createElement((0, $47bDC.ThemeProvider), {
            theme: {}
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("g0Gtc")).ExperienceSuccessTracker), {
            location: analyticsExperience
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7v0Ei")).AmdGadgetMonolithOverrideStyles), null), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1Bx5m")).AboveTheFoldContainer), {
            dashboardId: configData.id,
            layout: configData.layout,
            gadgets: configData.gadgets
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("8h45R")).GadgetAdditionalStateContainer), null, (0, ($parcel$interopDefault($5uXOq))).createElement($e519b350b77b4296$export$d3a4cb300c572c66, {
            data: configData,
            saveCustomGadgetTitle: saveCustomGadgetTitle
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hwuTz")).RouteResourceValidator), null), children)))));
    };
});
parcelRegister("1yBXu", function(module, exports) {
    $parcel$export(module.exports, "GenericLoadingView", ()=>$1052d375f7549967$export$901c7e24ef786b0f);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $lD23V;
    var $fNobW;
    var $44v1T;
    const $1052d375f7549967$export$901c7e24ef786b0f = ({ customHeight: customHeight })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fNobW")).default), {
            name: "generic-loading-view"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($1052d375f7549967$var$Wrapper, null, (0, ($parcel$interopDefault($5uXOq))).createElement($1052d375f7549967$var$Header, null, (0, ($parcel$interopDefault($5uXOq))).createElement($1052d375f7549967$var$Title, null), (0, ($parcel$interopDefault($5uXOq))).createElement($1052d375f7549967$var$Button, null), (0, ($parcel$interopDefault($5uXOq))).createElement($1052d375f7549967$var$Button, null)), (0, ($parcel$interopDefault($5uXOq))).createElement($1052d375f7549967$var$ContainerWrapper, {
            customHeight: customHeight
        })));
    const $1052d375f7549967$var$Wrapper = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_4cvr1y6m _1e0c1txw _1n261g80 _p12f1osq _kqswh2mm _19pkidpf _2hwx1wug _otyridpf _18u01wug",
                __cmplp.className
            ])
        });
    });
    const $1052d375f7549967$var$ContainerWrapper = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { customHeight: customHeight, ...__cmpldp } = __cmplp;
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmpldp,
            style: {
                ...__cmpls,
                "--_6y8cer": (0, (0, parcelRequire("lD23V")).default)(__cmplp.customHeight),
                "--_1s93bfy": (0, (0, parcelRequire("lD23V")).default)((0, (0, parcelRequire("44v1T")).PAGE_LAYOUT_OFFSET_TOP))
            },
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_11q71aqf _1bsb1osq _1pbyb4wl",
                __cmplp.customHeight != null ? "_4t3i1hh3" : "_4t3inb68",
                __cmplp.className
            ])
        });
    });
    const $1052d375f7549967$var$Header = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _1bsb1osq _18s8gx6n _1okqidpf _142fidpf _kdjlidpf _1octidpf",
                __cmplp.className
            ])
        });
    });
    const $1052d375f7549967$var$Title = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_2hwx1wug _11q71aqf _4t3izwfg _1bsbclpc",
                __cmplp.className
            ])
        });
    });
    const $1052d375f7549967$var$Button = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_2hwxu2gc _11q71aqf _4t3izwfg _1bsbzwfg",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("i7Ldp", function(module, exports) {
    $parcel$export(module.exports, "useConnectApi", ()=>$5ce347994b87c55a$export$521765443e759ceb);
    var $5uXOq = parcelRequire("5uXOq");
    var $lFKmC = parcelRequire("lFKmC");
    var $JIPqS;
    var $gh1hi;
    var $19nfo;
    var $8h45R;
    const $5ce347994b87c55a$export$521765443e759ceb = ({ saveCustomGadgetTitle: saveCustomGadgetTitle })=>{
        const { data: data, update: update } = (0, (0, parcelRequire("gh1hi")).useDashboardResource)();
        const debouncedHeightRef = (0, $5uXOq.useRef)({});
        const [, { addNonRenamable: addNonRenamable, addForcedHeight: addForcedHeight }] = (0, (0, parcelRequire("8h45R")).useGadgetAdditionalStateActions)();
        (0, $5uXOq.useEffect)(()=>{
            if (!data) return;
            const setGadgetTitle = async (id, title)=>{
                if (data.gadgets.some((g)=>g.id === id)) {
                    saveCustomGadgetTitle(id, title);
                    addNonRenamable(id);
                }
            };
            const setGadgetHeight = (id, height)=>{
                if (debouncedHeightRef.current[id] == null) debouncedHeightRef.current[id] = (0, ($parcel$interopDefault($lFKmC)))((debouncedHeight)=>{
                    (0, (0, parcelRequire("19nfo")).updateLocalStorageHeight)(data.id, id, debouncedHeight);
                    addForcedHeight(id, debouncedHeight);
                }, (0, (0, parcelRequire("JIPqS")).GADGET_HEIGHT_UPDATE_WAIT_TIME));
                debouncedHeightRef.current[id](height);
            };
            const isGadgetConfigurable = (id)=>{
                const gadget = data.gadgets.find((g)=>g.id === id);
                const { configurable: configurable, hasNonHiddenUserPrefs: hasNonHiddenUserPrefs, renderedGadgetUrl: renderedGadgetUrl } = gadget || {};
                return data.writable && (configurable || hasNonHiddenUserPrefs || Boolean(renderedGadgetUrl));
            };
            window.dashboardSpaBridge = {
                setGadgetTitle: setGadgetTitle,
                setGadgetHeight: setGadgetHeight,
                isGadgetConfigurable: isGadgetConfigurable
            };
        }, [
            data,
            addNonRenamable,
            addForcedHeight,
            saveCustomGadgetTitle,
            update
        ]);
    };
});
parcelRegister("19nfo", function(module, exports) {
    $parcel$export(module.exports, "getGadgetDataFromLocalStorage", ()=>$bf0cad76c08ceb09$export$9d44840cd06e000d);
    $parcel$export(module.exports, "putGadgetDataFromLocalStorage", ()=>$bf0cad76c08ceb09$export$bfbe102b0a9ddb0b);
    $parcel$export(module.exports, "deleteGadgetDataFromLocalStorage", ()=>$bf0cad76c08ceb09$export$d0ed0cab2f2757a9);
    $parcel$export(module.exports, "updateLocalStorageHeight", ()=>$bf0cad76c08ceb09$export$2e93c23f060e835b);
    $parcel$export(module.exports, "getLocalStorageHeight", ()=>$bf0cad76c08ceb09$export$e31e30011965d5e7);
    $parcel$export(module.exports, "getAllLocalStorageHeights", ()=>$bf0cad76c08ceb09$export$2590d76ae5192a56);
    var $bSSG7;
    var $JIPqS;
    const $bf0cad76c08ceb09$export$6bac6bdc21b646b4 = (cookieData)=>{
        if (!cookieData) return {};
        const cookieInfos = cookieData.split('|').filter((cookieInfo)=>cookieInfo.trim().length);
        return cookieInfos.reduce((acc, cookieInfo)=>{
            const COOKIE_INFO_PATTERN = /(?<cookieKey>.*)=(?<cookieValue>.*)/;
            const matchRes = cookieInfo.match(COOKIE_INFO_PATTERN);
            if (!matchRes) return acc;
            const { cookieKey: cookieKey, cookieValue: cookieValue } = matchRes.groups;
            if (cookieKey.includes(':')) {
                const [gadgetId, gadgetAttributeName] = cookieKey.split(':');
                acc[gadgetId] = acc[gadgetId] || {};
                acc[gadgetId][gadgetAttributeName] = cookieValue;
            } else if (cookieKey.includes('-')) {
                const [, gadgetId, gadgetAttributeName] = cookieKey.split('-');
                acc[gadgetId] = acc[gadgetId] || {};
                acc[gadgetId][gadgetAttributeName] = cookieValue;
            }
            return acc;
        }, {});
    };
    const $bf0cad76c08ceb09$export$20a3a47cb251067e = (mappedCookieInfo)=>{
        let res = '';
        if (typeof mappedCookieInfo !== 'object') return res;
        Object.keys(mappedCookieInfo).forEach((id)=>{
            const attributeNames = mappedCookieInfo[id];
            if (typeof attributeNames !== 'object') return;
            Object.keys(attributeNames).forEach((attributeName)=>{
                const attributeValue = attributeNames[attributeName];
                if (!attributeValue) return;
                if (res.length) res += '|';
                if (attributeName.includes('fh')) res += `gadget-${id}-${attributeName}=${attributeValue}`;
                else res += `${id}:${attributeName}=${attributeValue}`;
            });
        });
        return res;
    };
    const $bf0cad76c08ceb09$export$ad3e452a397f8ecc = ()=>{
        const localStorage = (0, (0, parcelRequire("bSSG7")).createLocalStorageProvider)('');
        const cookieData = localStorage.get((0, (0, parcelRequire("JIPqS")).GADGET_LOCAL_STORAGE_NAME));
        if (typeof cookieData !== 'string') return '';
        return cookieData.replace(/"/g, '');
    };
    const $bf0cad76c08ceb09$export$9d44840cd06e000d = (id, gadgetDataKey)=>{
        const cleanedCookieData = $bf0cad76c08ceb09$export$ad3e452a397f8ecc();
        const mappedCookieInfo = $bf0cad76c08ceb09$export$6bac6bdc21b646b4(cleanedCookieData);
        const gadgetInfo = mappedCookieInfo[id];
        return gadgetInfo?.[gadgetDataKey];
    };
    const $bf0cad76c08ceb09$export$bfbe102b0a9ddb0b = (id, gadgetDataKey, value)=>{
        const cleanedCookieData = $bf0cad76c08ceb09$export$ad3e452a397f8ecc();
        const mappedCookieInfo = $bf0cad76c08ceb09$export$6bac6bdc21b646b4(cleanedCookieData);
        mappedCookieInfo[id] = mappedCookieInfo[id] ?? {};
        if (mappedCookieInfo[id]) mappedCookieInfo[id][gadgetDataKey] = value;
        const updatedCookieData = $bf0cad76c08ceb09$export$20a3a47cb251067e(mappedCookieInfo);
        const localStorage = (0, (0, parcelRequire("bSSG7")).createLocalStorageProvider)('');
        localStorage.set((0, (0, parcelRequire("JIPqS")).GADGET_LOCAL_STORAGE_NAME), updatedCookieData);
    };
    const $bf0cad76c08ceb09$export$d0ed0cab2f2757a9 = (id, gadgetDataKey)=>{
        const cleanedCookieData = $bf0cad76c08ceb09$export$ad3e452a397f8ecc();
        const mappedCookieInfo = $bf0cad76c08ceb09$export$6bac6bdc21b646b4(cleanedCookieData);
        if (mappedCookieInfo[id]) delete mappedCookieInfo[id][gadgetDataKey];
        const updatedCookieData = $bf0cad76c08ceb09$export$20a3a47cb251067e(mappedCookieInfo);
        const localStorage = (0, (0, parcelRequire("bSSG7")).createLocalStorageProvider)('');
        localStorage.set((0, (0, parcelRequire("JIPqS")).GADGET_LOCAL_STORAGE_NAME), updatedCookieData);
    };
    const $bf0cad76c08ceb09$export$2e93c23f060e835b = (dashboardId, gadgetId, height)=>{
        if (dashboardId == null || height == null) return;
        const heightAsInt = Math.round(height);
        const localStorage = (0, (0, parcelRequire("bSSG7")).createLocalStorageProvider)('');
        const dashboardLocalData = localStorage.get((0, (0, parcelRequire("JIPqS")).DASHBOARD_LOCAL_STORAGE_KEY_NAME)) ?? {};
        dashboardLocalData[dashboardId] = dashboardLocalData[dashboardId] ?? {};
        const dashboardData = dashboardLocalData[dashboardId];
        dashboardData[gadgetId] = dashboardData[gadgetId] ?? {};
        const gadgetData = dashboardData[gadgetId];
        const storedHeight = gadgetData.height;
        if (storedHeight === heightAsInt) return;
        gadgetData.height = heightAsInt;
        localStorage.set((0, (0, parcelRequire("JIPqS")).DASHBOARD_LOCAL_STORAGE_KEY_NAME), dashboardLocalData);
    };
    const $bf0cad76c08ceb09$export$e31e30011965d5e7 = (dashboardId, gadgetId)=>{
        const localStorage = (0, (0, parcelRequire("bSSG7")).createLocalStorageProvider)('');
        const dashboardLocalData = localStorage.get((0, (0, parcelRequire("JIPqS")).DASHBOARD_LOCAL_STORAGE_KEY_NAME));
        const gadgetLocalHeight = dashboardLocalData?.[dashboardId]?.[gadgetId]?.height;
        return gadgetLocalHeight !== 0 && gadgetLocalHeight != null ? gadgetLocalHeight : (0, (0, parcelRequire("JIPqS")).GADGET_DEFAULT_HEIGHT);
    };
    const $bf0cad76c08ceb09$export$2590d76ae5192a56 = (dashboardId)=>{
        const localStorage = (0, (0, parcelRequire("bSSG7")).createLocalStorageProvider)('');
        const dashboardLocalData = localStorage.get((0, (0, parcelRequire("JIPqS")).DASHBOARD_LOCAL_STORAGE_KEY_NAME));
        return dashboardLocalData?.[dashboardId] ?? {};
    };
});
parcelRegister("8h45R", function(module, exports) {
    $parcel$export(module.exports, "useIsRenamable", ()=>$424d7b25bd304486$export$99680f47796b1368);
    $parcel$export(module.exports, "useForcedHeight", ()=>$424d7b25bd304486$export$6286e5c3de90c7ce);
    $parcel$export(module.exports, "useGadgetAdditionalStateActions", ()=>$424d7b25bd304486$export$7050d853cb5c3b12);
    $parcel$export(module.exports, "GadgetAdditionalStateContainer", ()=>$424d7b25bd304486$export$c59b94948beb035f);
    var $gg7kZ;
    var $3sQ5x;
    var $iXqE5;
    const $424d7b25bd304486$var$actions = {
        addNonRenamable: (id)=>({ setState: setState, getState: getState })=>{
                const gadgetIdToStateMap = getState().idToStateMap;
                const newIds = new Map(gadgetIdToStateMap.set(id, {
                    ...gadgetIdToStateMap.get(id),
                    isNonRenamable: true
                }));
                setState({
                    idToStateMap: newIds
                });
            },
        addForcedHeight: (id, forcedHeight)=>({ setState: setState, getState: getState })=>{
                const gadgetIdToStateMap = getState().idToStateMap;
                const newGadgetIdToStateMap = new Map(gadgetIdToStateMap.set(id, {
                    ...gadgetIdToStateMap.get(id),
                    forcedHeight: forcedHeight
                }));
                setState({
                    idToStateMap: newGadgetIdToStateMap
                });
            },
        remove: (id)=>({ setState: setState, getState: getState })=>{
                if (!getState().idToStateMap.has(id)) return;
                const newGadgetIdToStateMap = new Map(getState().idToStateMap);
                newGadgetIdToStateMap.delete(id);
                setState({
                    idToStateMap: newGadgetIdToStateMap
                });
            }
    };
    const $424d7b25bd304486$var$Store = (0, (0, parcelRequire("iXqE5")).createStore)({
        name: 'gadget-additional-state',
        initialState: {
            idToStateMap: new Map()
        },
        actions: $424d7b25bd304486$var$actions
    });
    const $424d7b25bd304486$export$b8a31aa234db5dc9 = (hook)=>(args)=>{
            const [value] = hook(args);
            return value;
        };
    const $424d7b25bd304486$export$99680f47796b1368 = $424d7b25bd304486$export$b8a31aa234db5dc9((0, (0, parcelRequire("3sQ5x")).createHook)($424d7b25bd304486$var$Store, {
        selector: (state, id)=>!state.idToStateMap.get(id)?.isNonRenamable
    }));
    const $424d7b25bd304486$export$6286e5c3de90c7ce = $424d7b25bd304486$export$b8a31aa234db5dc9((0, (0, parcelRequire("3sQ5x")).createHook)($424d7b25bd304486$var$Store, {
        selector: (state, id)=>state.idToStateMap.get(id)?.forcedHeight
    }));
    const $424d7b25bd304486$export$7050d853cb5c3b12 = (0, (0, parcelRequire("3sQ5x")).createHook)($424d7b25bd304486$var$Store, {
        selector: null
    });
    const $424d7b25bd304486$export$c59b94948beb035f = (0, (0, parcelRequire("gg7kZ")).createContainer)($424d7b25bd304486$var$Store);
});
parcelRegister("bOnpg", function(module, exports) {
    $parcel$export(module.exports, "useDashboardAnalytics", ()=>$d2709a7a4ffbbf0c$export$6da6d2b3a61f8861);
    var $5uXOq = parcelRequire("5uXOq");
    var $8Rkzz = parcelRequire("8Rkzz");
    var $8zOmE;
    var $2NMNM;
    const $d2709a7a4ffbbf0c$export$6da6d2b3a61f8861 = ()=>{
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        return (0, $5uXOq.useCallback)(({ eventType: eventType, actionSubject: actionSubject, action: action, actionSubjectId: actionSubjectId, attributes: attributes = {} })=>{
            let fireAnalytics = (0, ($parcel$interopDefault($8Rkzz)));
            switch(eventType){
                case 0, $4d5e0871c06cee03$export$947dac6c72b26f81:
                    fireAnalytics = (0, (0, parcelRequire("8zOmE")).fireTrackAnalytics);
                    break;
                case 0, $4d5e0871c06cee03$export$12346a674644c156:
                    fireAnalytics = (0, (0, parcelRequire("8zOmE")).fireOperationalAnalytics);
                    break;
                case 0, $4d5e0871c06cee03$export$a1562d08625f5d7e:
                    fireAnalytics = (0, (0, parcelRequire("8zOmE")).fireUIAnalytics);
                    break;
                case 0, $4d5e0871c06cee03$export$f1d537d508d2945a:
                    fireAnalytics = (0, (0, parcelRequire("8zOmE")).fireScreenAnalytics);
                    break;
                default:
                    fireAnalytics = (0, ($parcel$interopDefault($8Rkzz)));
            }
            fireAnalytics(createAnalyticsEvent({}), `${actionSubject} ${action}`, actionSubjectId, attributes);
        }, [
            createAnalyticsEvent
        ]);
    };
});
parcelRegister("6IxJi", function(module, exports) {
    $parcel$export(module.exports, "useGadgetMetricsBridge", ()=>$d46d9a3e27973a8e$export$a81a90ecebb273c6);
    var $5uXOq = parcelRequire("5uXOq");
    var $dJl1T;
    var $JIPqS;
    var $6jY6G;
    var $8zOmE;
    var $2NMNM;
    var $bVDE5;
    var $6fab6;
    var $8UPoO;
    var $1oaeD;
    var $i0rkS;
    var $5TsWX;
    var $7FHsN;
    var $gW6vr;
    const $d46d9a3e27973a8e$var$OVERALL_TIMEOUT = 60000;
    const $d46d9a3e27973a8e$var$buildEventKey = (event)=>{
        if (event.eventType !== (0, (0, parcelRequire("5TsWX")).GADGET_MARK_EVENT_PREFIX) || event.markName == null || event.markStage == null) return event.eventType;
        return `${0, (0, parcelRequire("5TsWX")).GADGET_MARK_EVENT_PREFIX}-${event.markName}-${event.markStage}`;
    };
    const $d46d9a3e27973a8e$export$b70b6efb2347a731 = (gadgetMetricsEvents, gadgetId, event)=>{
        let recorded = gadgetMetricsEvents.get(gadgetId);
        if (recorded == null) {
            recorded = {
                contentType: 'Unspecified',
                source: event.source,
                events: new Map()
            };
            gadgetMetricsEvents.set(gadgetId, recorded);
        }
        if (recorded.contentType !== 'ErrorMessage' && event.contentType !== 'Unspecified') recorded.contentType = event.contentType;
        const record = {
            spaTime: Date.now()
        };
        if (!recorded.events.has($d46d9a3e27973a8e$var$buildEventKey(event))) recorded.events.set($d46d9a3e27973a8e$var$buildEventKey(event), record);
    };
    const $d46d9a3e27973a8e$export$8aed66d0a3a7041d = (gadgets)=>gadgets.filter((item)=>item.amdModule != null || item.forge != null || item.reactKey != null).filter((item)=>item.forge != null || item.reactKey != null || (0, (0, parcelRequire("gW6vr")).GADGETS_TO_REPORT).has(item.amdModule ?? '')).map((gadget)=>gadget.id);
    const $d46d9a3e27973a8e$export$b2ee8eefeb9c97ce = (gadgets)=>gadgets.filter((item)=>item.amdModule != null).filter((item)=>!(0, (0, parcelRequire("5TsWX")).CONNECT_MODULE_PATTERN).test(item.amdModule || '')).filter((item)=>!(0, (0, parcelRequire("gW6vr")).GADGETS_TO_REPORT).has(item.amdModule ?? '') && !(0, (0, parcelRequire("gW6vr")).GADGETS_IGNORED).has(item.amdModule ?? ''));
    const $d46d9a3e27973a8e$var$shouldSendGadgetMetrics = (event)=>!(event.source === (0, (0, parcelRequire("5TsWX")).WRM_GADGET_METRICS) && event.contentType === 'Config');
    const $d46d9a3e27973a8e$export$a81a90ecebb273c6 = (dashboardId, gadgets, layout)=>{
        const currentDashboardId = (0, $5uXOq.useRef)(null);
        const dashboardMetricsContext = (0, $5uXOq.useRef)(null);
        const [, { registerListener: registerMessageListener, unregisterListener: unregisterMessageListener, broadcastMessage: broadcastMessageListener }] = (0, (0, parcelRequire("i0rkS")).useMessageBus)();
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const { sendGadgetMetrics: sendGadgetMetrics, sendOverallGadgetMetrics: sendOverallGadgetMetrics, onAboveTheFoldGadgetsRender: onAboveTheFoldGadgetsRender, resetScrollStartMetrics: resetScrollStartMetrics } = (0, (0, parcelRequire("7FHsN")).useGadgetMetricsAnalytics)(dashboardId);
        const [, { onGadgetRender: onGadgetRender }] = (0, (0, parcelRequire("8UPoO")).useRenderAboveTheFold)();
        const [{ isInitialRender: isInitialRender, lastTransitionStartTime: lastTransitionStartTime, navigationStart: navigationStart, currentPageId: currentPageId }] = (0, (0, parcelRequire("bVDE5")).useSpaStateTransition)();
        const { name: routeName } = (0, (0, parcelRequire("6jY6G")).useCurrentRoute)();
        const { getMaximisedGadgetId: getMaximisedGadgetId } = (0, (0, parcelRequire("1oaeD")).useMaximizedGadget)(gadgets);
        const maximizedId = getMaximisedGadgetId;
        if (currentDashboardId.current == null || currentDashboardId.current !== dashboardId) {
            (0, (0, parcelRequire("8zOmE")).fireOperationalAnalytics)(createAnalyticsEvent({}), 'dashboardMetricsContext renewed', {
                dashboardId: dashboardId,
                previousDashboardId: currentDashboardId.current,
                currentPageId: currentPageId
            });
            resetScrollStartMetrics();
            currentDashboardId.current = dashboardId;
            if (dashboardMetricsContext.current?.metricsTimeout != null) clearTimeout(dashboardMetricsContext.current.metricsTimeout);
            const extractedGadgets = $d46d9a3e27973a8e$export$8aed66d0a3a7041d(maximizedId ? gadgets.filter((gadget)=>gadget.id === maximizedId) : gadgets);
            const allAboveTheFoldGadgets = (0, (0, parcelRequire("8UPoO")).getAboveTheFoldGadgets)((0, (0, parcelRequire("6fab6")).toColumns)(gadgets, layout), dashboardId);
            dashboardMetricsContext.current = {
                gadgetMetricsEvents: new Map(),
                gadgetIdsInOverallAnalytics: extractedGadgets,
                gadgetIdsInTti: extractedGadgets.filter((gadgetId)=>allAboveTheFoldGadgets.has(gadgetId)),
                allGadgetIds: gadgets.map((item)=>item.id),
                overallMetricsSent: false,
                atfMetricsSent: false,
                implicitlyIgnoredGadgetLogged: false,
                gadgetRenderStartTime: Date.now(),
                metricsTimeout: setTimeout(()=>{
                    broadcastMessageListener((0, (0, parcelRequire("JIPqS")).GADGET_METRICS_TYPE).TIMEOUT, {}, {});
                }, $d46d9a3e27973a8e$var$OVERALL_TIMEOUT),
                timestamps: {
                    created: Date.now(),
                    pnow: performance?.now(),
                    currentPageId: currentPageId,
                    lastTransitionStartTime: lastTransitionStartTime,
                    navigationStart: navigationStart,
                    navStart: (0, (0, parcelRequire("7FHsN")).getNavStart)(isInitialRender, lastTransitionStartTime, navigationStart)
                }
            };
        }
        const gadgetMetricsTimeout = (0, $5uXOq.useCallback)(()=>{
            const context = dashboardMetricsContext.current;
            if (context == null) return;
            if (context.overallMetricsSent) return;
            context.metricsTimeout = undefined;
            context.overallMetricsSent = true;
            context.gadgetMetricsEvents.forEach((item, id)=>{
                if (item.allEventHandled !== true) {
                    item.timedOut = true;
                    sendGadgetMetrics(id, item);
                }
            });
            context.gadgetIdsInOverallAnalytics.filter((id)=>!context.gadgetMetricsEvents.has(id)).forEach((id)=>{
                sendGadgetMetrics(id, {
                    source: (0, (0, parcelRequire("5TsWX")).IDLE_GADGET_SOURCE),
                    timedOut: true,
                    events: new Map()
                });
            });
            onAboveTheFoldGadgetsRender(context.gadgetIdsInOverallAnalytics, context.gadgetMetricsEvents, context.gadgetRenderStartTime, context.timestamps, true);
            sendOverallGadgetMetrics(context.gadgetIdsInOverallAnalytics, context.gadgetMetricsEvents, true);
        }, [
            sendGadgetMetrics,
            sendOverallGadgetMetrics,
            onAboveTheFoldGadgetsRender
        ]);
        const onAllGadgetHandled = (0, $5uXOq.useCallback)(()=>{
            const context = dashboardMetricsContext.current;
            if (context == null) return;
            context.overallMetricsSent = true;
            if (context.metricsTimeout != null) {
                clearTimeout(context.metricsTimeout);
                context.metricsTimeout = undefined;
            }
            sendOverallGadgetMetrics(context.gadgetIdsInOverallAnalytics, context.gadgetMetricsEvents, false);
        }, [
            sendOverallGadgetMetrics
        ]);
        const onAboveTheFoldGadgetsHandled = (0, $5uXOq.useCallback)(()=>{
            const context = dashboardMetricsContext.current;
            if (context == null) return;
            onAboveTheFoldGadgetsRender(context.gadgetIdsInOverallAnalytics, context.gadgetMetricsEvents, context.gadgetRenderStartTime, context.timestamps, false);
            context.atfMetricsSent = true;
        }, [
            onAboveTheFoldGadgetsRender
        ]);
        const gadgetMetricsListener = (0, $5uXOq.useCallback)((gid, command, ...args)=>{
            if (command !== 'metrics_event' || !args[0]) return;
            const context = dashboardMetricsContext.current;
            if (context == null) return;
            if (context.overallMetricsSent) return;
            const eventData = args[0];
            const eventsPerGadget = (0, (0, parcelRequire("7FHsN")).getAllEventHandlers)(eventData.source, eventData.contentType);
            const { pageId: pageIdInEvent } = eventData;
            if (currentPageId !== pageIdInEvent || context.allGadgetIds.every((id)=>id !== gid)) {
                (0, (0, parcelRequire("dJl1T")).default).safeWarnWithoutCustomerData('spa-apps.dashboard.gadget.metrics', `Page [${currentPageId ?? ''}] recieved staled gadget metrics event [${eventData.eventType}] from gadget [${gid}] in page [${pageIdInEvent ?? ''}]`);
                return;
            }
            const isAllGadgetEventsHandled = (gadgetEvents)=>gadgetEvents?.allEventHandled === true || gadgetEvents?.timedOut === true || eventsPerGadget.every((e)=>gadgetEvents?.events.has(e));
            const isAllGadgetHandled = (gadgetIds)=>gadgetIds.every((id)=>context.gadgetMetricsEvents.get(id)?.allEventHandled);
            if (context.gadgetMetricsEvents.has(gid)) {
                if (isAllGadgetEventsHandled(context.gadgetMetricsEvents.get(gid))) return;
                if (context.gadgetMetricsEvents.get(gid)?.events.has($d46d9a3e27973a8e$var$buildEventKey(eventData))) return;
            }
            $d46d9a3e27973a8e$export$b70b6efb2347a731(context.gadgetMetricsEvents, gid, eventData);
            const event = context.gadgetMetricsEvents.get(gid);
            if (event != null && isAllGadgetEventsHandled(event)) {
                event.allEventHandled = true;
                if ($d46d9a3e27973a8e$var$shouldSendGadgetMetrics(event)) {
                    onGadgetRender(gid);
                    sendGadgetMetrics(gid, event);
                }
                if (context.atfMetricsSent === false) {
                    if (isAllGadgetHandled(context.gadgetIdsInTti)) onAboveTheFoldGadgetsHandled();
                }
                if (isAllGadgetHandled(context.gadgetIdsInOverallAnalytics)) {
                    onAllGadgetHandled();
                    if (context.atfMetricsSent === false) onAboveTheFoldGadgetsHandled();
                }
            }
        }, [
            currentPageId,
            onAboveTheFoldGadgetsHandled,
            onAllGadgetHandled,
            sendGadgetMetrics,
            onGadgetRender
        ]);
        (0, $5uXOq.useEffect)(()=>()=>{
                if (routeName === 'dashboard-wallboard') return;
                if (dashboardMetricsContext.current?.metricsTimeout != null) {
                    clearTimeout(dashboardMetricsContext.current.metricsTimeout);
                    dashboardMetricsContext.current.metricsTimeout = undefined;
                }
            }, [
            routeName
        ]);
        const gadgetMetricsMessageBusListenerAdaptor = (0, $5uXOq.useCallback)((type, message, context)=>{
            if (type !== (0, (0, parcelRequire("JIPqS")).GADGET_METRICS_TYPE).REPORT) return;
            const { gadgetId: gid, pageId: pageId } = context;
            if (gid == null) return;
            gadgetMetricsListener(gid, 'metrics_event', {
                ...message,
                pageId: pageId
            });
        }, [
            gadgetMetricsListener
        ]);
        const timeoutMessageBusListenerAdaptor = (0, $5uXOq.useCallback)((type)=>{
            if (type !== (0, (0, parcelRequire("JIPqS")).GADGET_METRICS_TYPE).TIMEOUT) return;
            gadgetMetricsTimeout();
        }, [
            gadgetMetricsTimeout
        ]);
        (0, $5uXOq.useLayoutEffect)(()=>{
            if (routeName === 'dashboard-wallboard') return ()=>{};
            registerMessageListener(gadgetMetricsMessageBusListenerAdaptor);
            registerMessageListener(timeoutMessageBusListenerAdaptor);
            return ()=>{
                unregisterMessageListener(gadgetMetricsMessageBusListenerAdaptor);
                unregisterMessageListener(timeoutMessageBusListenerAdaptor);
            };
        }, [
            gadgetMetricsMessageBusListenerAdaptor,
            gadgetMetricsListener,
            registerMessageListener,
            timeoutMessageBusListenerAdaptor,
            unregisterMessageListener,
            routeName
        ]);
        if (routeName === 'dashboard-wallboard') return;
        if (dashboardMetricsContext.current == null || dashboardMetricsContext.current.overallMetricsSent === true) return;
        if (dashboardMetricsContext.current?.gadgetIdsInOverallAnalytics.length === 0) {
            onAboveTheFoldGadgetsHandled();
            onAllGadgetHandled();
        } else if (dashboardMetricsContext.current?.gadgetIdsInTti.length === 0 && dashboardMetricsContext.current?.atfMetricsSent === false) onAboveTheFoldGadgetsHandled();
        if (dashboardMetricsContext.current != null && !dashboardMetricsContext.current.implicitlyIgnoredGadgetLogged) {
            dashboardMetricsContext.current.implicitlyIgnoredGadgetLogged = true;
            $d46d9a3e27973a8e$export$b2ee8eefeb9c97ce(gadgets).forEach((item)=>{
                (0, (0, parcelRequire("dJl1T")).default).safeWarnWithoutCustomerData('spa-apps.dashboard.gadget.metrics', `metrics for gadget [${item.amdModule ?? 'unknown'}] is ignored implicitly.`);
            });
        }
    };
});
parcelRegister("8UPoO", function(module, exports) {
    $parcel$export(module.exports, "getAboveTheFoldGadgets", ()=>$149138b232138cbc$export$e12eddecf5e86fe5);
    $parcel$export(module.exports, "useRenderAboveTheFold", ()=>$149138b232138cbc$export$16f58cfd4c8ccf70);
    $parcel$export(module.exports, "RenderAboveTheFoldContainer", ()=>$149138b232138cbc$export$9710049f7f21917b);
    $parcel$export(module.exports, "useOnGadgetRender", ()=>$149138b232138cbc$export$c127791d148e941c);
    $parcel$export(module.exports, "useOnGadgetRenderEffect", ()=>$149138b232138cbc$export$1f28971b410f9e3);
    $parcel$export(module.exports, "useShouldRenderGadget", ()=>$149138b232138cbc$export$428a5d740650db3e);
    $parcel$export(module.exports, "useLocalStorageGadgetHeight", ()=>$149138b232138cbc$export$94d0a966fb813ab7);
    var $5uXOq = parcelRequire("5uXOq");
    var $JIPqS;
    var $gg7kZ;
    var $3sQ5x;
    var $iXqE5;
    var $19nfo;
    const $149138b232138cbc$var$TOP_NAVIGATION_HEIGHT = 56;
    const $149138b232138cbc$var$DASHBOARD_HEADER_HEIGHT = 80;
    const $149138b232138cbc$var$GADGET_HEADER_AND_FOOTER_HEIGHT = 84;
    const $149138b232138cbc$var$PRIORITISED_RENDERING_TIMEOUT = 7000;
    const $149138b232138cbc$export$e12eddecf5e86fe5 = (columns, dashboardId)=>{
        const aboveTheFoldGadgets = new Set();
        const availableViewportHeight = window.innerHeight - $149138b232138cbc$var$TOP_NAVIGATION_HEIGHT - $149138b232138cbc$var$DASHBOARD_HEADER_HEIGHT;
        columns.forEach((column)=>{
            let occupiedViewportHeight = 0;
            column.some((gadget)=>{
                if (occupiedViewportHeight < availableViewportHeight) {
                    const gadgetHeight = (0, (0, parcelRequire("19nfo")).getLocalStorageHeight)(dashboardId, gadget.id) + $149138b232138cbc$var$GADGET_HEADER_AND_FOOTER_HEIGHT;
                    aboveTheFoldGadgets.add(gadget.id);
                    occupiedViewportHeight += gadgetHeight;
                    return false;
                }
                return true;
            });
        });
        return aboveTheFoldGadgets;
    };
    const $149138b232138cbc$export$e324594224ef24da = {
        onGadgetRender: (gadgetId, height)=>({ setState: setState, getState: getState })=>{
                const { dashboardId: dashboardId, loadingAboveTheFold: hasAboveTheFold, timeoutId: timeoutLast } = getState();
                if (dashboardId === null) return;
                if (height != null) {
                    (0, (0, parcelRequire("19nfo")).updateLocalStorageHeight)(dashboardId, gadgetId, height);
                    setState({
                        localStorageGadgetHeights: {
                            ...(0, (0, parcelRequire("19nfo")).getAllLocalStorageHeights)(dashboardId),
                            [gadgetId]: {
                                height: height
                            }
                        }
                    });
                }
                if (!hasAboveTheFold) return;
                const remainingAboveTheFoldGadgets = new Set(getState().remainingAboveTheFoldGadgets);
                remainingAboveTheFoldGadgets.delete(gadgetId);
                const loadingAboveTheFold = Boolean(remainingAboveTheFoldGadgets.size);
                const timeoutId = loadingAboveTheFold ? timeoutLast : null;
                if (timeoutLast && !timeoutId) clearTimeout(timeoutLast);
                setState({
                    remainingAboveTheFoldGadgets: remainingAboveTheFoldGadgets,
                    loadingAboveTheFold: loadingAboveTheFold,
                    timeoutId: timeoutId
                });
            }
    };
    const $149138b232138cbc$var$Store = (0, (0, parcelRequire("iXqE5")).createStore)({
        name: 'RenderAboveTheFold',
        initialState: {
            dashboardId: null,
            loadingAboveTheFold: true,
            aboveTheFoldGadgets: new Set(),
            remainingAboveTheFoldGadgets: new Set(),
            timeoutId: null,
            timeout: false,
            localStorageGadgetHeights: {}
        },
        actions: $149138b232138cbc$export$e324594224ef24da
    });
    const $149138b232138cbc$export$16f58cfd4c8ccf70 = (0, (0, parcelRequire("3sQ5x")).createHook)($149138b232138cbc$var$Store);
    const $149138b232138cbc$export$f0ff1fd44503e803 = (0, (0, parcelRequire("3sQ5x")).createHook)($149138b232138cbc$var$Store, {
        selector: null
    });
    const $149138b232138cbc$export$9710049f7f21917b = (0, (0, parcelRequire("gg7kZ")).createContainer)($149138b232138cbc$var$Store, {
        onInit: ()=>({ setState: setState, getState: getState }, { columns: columns, dashboardId: dashboardId, maximizedGadgetId: maximizedGadgetId })=>{
                if (getState().dashboardId !== dashboardId && dashboardId != null) {
                    const loadingAboveTheFoldTimeout = ()=>{
                        setState({
                            remainingAboveTheFoldGadgets: new Set(),
                            loadingAboveTheFold: false,
                            timeout: true
                        });
                    };
                    const aboveTheFoldGadgets = maximizedGadgetId ? new Set([
                        maximizedGadgetId
                    ]) : $149138b232138cbc$export$e12eddecf5e86fe5(columns, dashboardId);
                    setState({
                        dashboardId: dashboardId,
                        aboveTheFoldGadgets: aboveTheFoldGadgets,
                        remainingAboveTheFoldGadgets: new Set(aboveTheFoldGadgets),
                        loadingAboveTheFold: Boolean(aboveTheFoldGadgets.size),
                        timeoutId: setTimeout(loadingAboveTheFoldTimeout, $149138b232138cbc$var$PRIORITISED_RENDERING_TIMEOUT),
                        timeout: false,
                        localStorageGadgetHeights: (0, (0, parcelRequire("19nfo")).getAllLocalStorageHeights)(dashboardId)
                    });
                }
            }
    });
    const $149138b232138cbc$export$c127791d148e941c = (id)=>{
        const [, { onGadgetRender: onGadgetRender }] = $149138b232138cbc$export$f0ff1fd44503e803();
        return (0, $5uXOq.useCallback)((height)=>onGadgetRender(id, height), [
            id,
            onGadgetRender
        ]);
    };
    const $149138b232138cbc$export$1f28971b410f9e3 = (id, gadgetRef)=>{
        const onGadgetRender = $149138b232138cbc$export$c127791d148e941c(id);
        (0, $5uXOq.useEffect)(()=>{
            onGadgetRender(gadgetRef?.getBoundingClientRect().height);
        }, [
            onGadgetRender,
            gadgetRef
        ]);
    };
    const $149138b232138cbc$export$b8a31aa234db5dc9 = (hook)=>(args)=>{
            const [value] = hook(args);
            return value;
        };
    const $149138b232138cbc$export$428a5d740650db3e = $149138b232138cbc$export$b8a31aa234db5dc9((0, (0, parcelRequire("3sQ5x")).createHook)($149138b232138cbc$var$Store, {
        selector: ({ loadingAboveTheFold: loadingAboveTheFold, aboveTheFoldGadgets: aboveTheFoldGadgets, dashboardId: dashboardId }, gadgetId)=>{
            if (dashboardId === null) return true;
            return !loadingAboveTheFold || aboveTheFoldGadgets.has(gadgetId);
        }
    }));
    const $149138b232138cbc$export$94d0a966fb813ab7 = $149138b232138cbc$export$b8a31aa234db5dc9((0, (0, parcelRequire("3sQ5x")).createHook)($149138b232138cbc$var$Store, {
        selector: ({ localStorageGadgetHeights: localStorageGadgetHeights }, gadgetId)=>localStorageGadgetHeights[gadgetId]?.height ?? (0, (0, parcelRequire("JIPqS")).GADGET_DEFAULT_HEIGHT)
    }));
});
parcelRegister("i0rkS", function(module, exports) {
    $parcel$export(module.exports, "useMessageBus", ()=>$f812b795237bfc34$export$69241ffa7d4e8d8d);
    var $3sQ5x;
    var $iXqE5;
    const $f812b795237bfc34$var$actions = {
        registerListener: (listener)=>({ setState: setState, getState: getState })=>{
                const { listeners: listeners } = getState();
                setState({
                    listeners: [
                        ...listeners,
                        listener
                    ]
                });
            },
        unregisterListener: (listener)=>({ setState: setState, getState: getState })=>{
                const { listeners: listeners } = getState();
                setState({
                    listeners: listeners.filter((cb)=>cb !== listener)
                });
            },
        broadcastMessage: (type, message, context)=>({ getState: getState })=>{
                getState().listeners.forEach((listener)=>listener(type, message, context));
            }
    };
    const $f812b795237bfc34$var$Store = (0, (0, parcelRequire("iXqE5")).createStore)({
        name: 'dashboard-message-bus',
        initialState: {
            listeners: []
        },
        actions: $f812b795237bfc34$var$actions
    });
    const $f812b795237bfc34$export$69241ffa7d4e8d8d = (0, (0, parcelRequire("3sQ5x")).createHook)($f812b795237bfc34$var$Store);
});
parcelRegister("5TsWX", function(module, exports) {
    $parcel$export(module.exports, "UNKNOWN_GADGET_TYPE", ()=>$3975f005d3e53b97$export$4db4c2521224b627);
    $parcel$export(module.exports, "CONNECT_MODULE_PATTERN", ()=>$3975f005d3e53b97$export$c1ee01d79441a123);
    $parcel$export(module.exports, "FORGE_MODULE_PATTERN", ()=>$3975f005d3e53b97$export$216b1f7517647987);
    $parcel$export(module.exports, "WRM_GADGET_METRICS", ()=>$3975f005d3e53b97$export$747c046b02db553e);
    $parcel$export(module.exports, "FORGE_GADGET_METRICS", ()=>$3975f005d3e53b97$export$fd11359c208e3b3d);
    $parcel$export(module.exports, "REACT_GADGET_METRICS", ()=>$3975f005d3e53b97$export$e6e5910bcc3a541f);
    $parcel$export(module.exports, "FALLBACK_GADGET_SOURCE", ()=>$3975f005d3e53b97$export$b17b847ae2e73914);
    $parcel$export(module.exports, "IDLE_GADGET_SOURCE", ()=>$3975f005d3e53b97$export$8e7a356fc2e45e1);
    $parcel$export(module.exports, "GADGET_MARK_EVENT_PREFIX", ()=>$3975f005d3e53b97$export$deb23548f6bb4aa6);
    $parcel$export(module.exports, "GADGET_MARK_EVENT_PATTERN", ()=>$3975f005d3e53b97$export$dc82d3f06c18522e);
    $parcel$export(module.exports, "GADGET_RENDER_CONTAINER_EVENT", ()=>$3975f005d3e53b97$export$479474c5d2f30a0a);
    $parcel$export(module.exports, "GADGET_START_EVENT", ()=>$3975f005d3e53b97$export$76b0ea781056f8d3);
    $parcel$export(module.exports, "GADGET_RENDERING_FINISHED_EVENT", ()=>$3975f005d3e53b97$export$b9c9f3ffa9290059);
    $parcel$export(module.exports, "DEFAULT_WRM_GADGET_EVENTS", ()=>$3975f005d3e53b97$export$f34d5b0533833e95);
    const $3975f005d3e53b97$export$4db4c2521224b627 = 'unknown';
    const $3975f005d3e53b97$export$c1ee01d79441a123 = /^atlassian-connect\/.*/;
    const $3975f005d3e53b97$export$216b1f7517647987 = /^ari:cloud:ecosystem::extension\/.*/;
    const $3975f005d3e53b97$export$747c046b02db553e = 'wrm_gadget_metrics';
    const $3975f005d3e53b97$export$fd11359c208e3b3d = 'forge_gadget_metrics';
    const $3975f005d3e53b97$export$e6e5910bcc3a541f = 'react_gadget_metrics';
    const $3975f005d3e53b97$export$b17b847ae2e73914 = 'fallback_gadget';
    const $3975f005d3e53b97$export$8e7a356fc2e45e1 = 'idle_gadget';
    const $3975f005d3e53b97$export$deb23548f6bb4aa6 = 'gadget-mark';
    const $3975f005d3e53b97$export$dc82d3f06c18522e = new RegExp(`^${$3975f005d3e53b97$export$deb23548f6bb4aa6}-(.+)-(start|end)$`);
    const $3975f005d3e53b97$export$479474c5d2f30a0a = 'gadget-render-container';
    const $3975f005d3e53b97$export$593632c36804eee0 = 'gadget-dom-ready';
    const $3975f005d3e53b97$export$c10c3db63be202d5 = 'gadget-loaded';
    const $3975f005d3e53b97$export$76b0ea781056f8d3 = 'gadget-start';
    const $3975f005d3e53b97$export$b9c9f3ffa9290059 = 'gadget-rendering-finished';
    const $3975f005d3e53b97$export$eebee3f00d7d097d = 'gadget-sync-rendering-finished';
    const $3975f005d3e53b97$export$f34d5b0533833e95 = [
        $3975f005d3e53b97$export$479474c5d2f30a0a,
        $3975f005d3e53b97$export$76b0ea781056f8d3,
        $3975f005d3e53b97$export$b9c9f3ffa9290059
    ];
});
parcelRegister("7FHsN", function(module, exports) {
    $parcel$export(module.exports, "getAllEventHandlers", ()=>$f42b084586d299a2$export$835bb833cb903f59);
    $parcel$export(module.exports, "getNavStart", ()=>$f42b084586d299a2$export$f409d0aa399347ac);
    $parcel$export(module.exports, "useGadgetMetricsAnalytics", ()=>$f42b084586d299a2$export$ea517a7cfce02895);
    var $5uXOq = parcelRequire("5uXOq");
    var $8QpSo;
    var $99gkn;
    var $bFU4G;
    var $hh0bW;
    var $dJl1T;
    var $JIPqS;
    var $hGdg6;
    var $co1wz;
    var $j8DrX;
    var $8zOmE;
    var $2NMNM;
    var $iAWa3;
    var $3B7oW;
    var $gh1hi;
    var $bVDE5;
    var $doZTA;
    var $7cLxq;
    var $8UDz8;
    var $trHKK;
    var $hmyfM;
    var $8UPoO;
    var $5TsWX;
    const $f42b084586d299a2$var$getSSRFeatureFlags = (0, (0, parcelRequire("8QpSo")).default)(()=>{
        try {
            const metaTagValue = (0, (0, parcelRequire("j8DrX")).default)('spa-service-flags');
            return metaTagValue != null && JSON.parse(metaTagValue) || {};
        } catch (e) {
            return null;
        }
    });
    const $f42b084586d299a2$var$expAttributes = {
        analyticsSource: 'dashboard',
        application: null,
        edition: null,
        additionalAttributes: {},
        wasExperienceSuccesful: true
    };
    const $f42b084586d299a2$var$DEFAULT_WRM_GADGET_CONFIG_EVENTS = [
        (0, (0, parcelRequire("5TsWX")).GADGET_RENDER_CONTAINER_EVENT)
    ];
    const $f42b084586d299a2$var$IMMEDIATE_FINISH_GADGET_EVENTS = [
        (0, (0, parcelRequire("5TsWX")).GADGET_RENDER_CONTAINER_EVENT)
    ];
    const $f42b084586d299a2$var$GADGET_CONTAINER_EVENTS = new Set([
        (0, (0, parcelRequire("5TsWX")).GADGET_RENDER_CONTAINER_EVENT)
    ]);
    const $f42b084586d299a2$export$835bb833cb903f59 = (eventSource, contentType = 'View')=>{
        if (eventSource === (0, (0, parcelRequire("5TsWX")).WRM_GADGET_METRICS) || eventSource === (0, (0, parcelRequire("5TsWX")).FORGE_GADGET_METRICS) || eventSource === (0, (0, parcelRequire("5TsWX")).REACT_GADGET_METRICS)) return contentType === 'Config' ? $f42b084586d299a2$var$DEFAULT_WRM_GADGET_CONFIG_EVENTS : (0, (0, parcelRequire("5TsWX")).DEFAULT_WRM_GADGET_EVENTS);
        return $f42b084586d299a2$var$IMMEDIATE_FINISH_GADGET_EVENTS;
    };
    const $f42b084586d299a2$var$isIdle = (gadgetEventRecord)=>{
        if (gadgetEventRecord == null) return false;
        if (gadgetEventRecord.source === (0, (0, parcelRequire("5TsWX")).IDLE_GADGET_SOURCE)) return true;
        const eventEntries = Array.from(gadgetEventRecord.events.entries());
        return eventEntries.length === $f42b084586d299a2$var$GADGET_CONTAINER_EVENTS.size && eventEntries.every((entry)=>$f42b084586d299a2$var$GADGET_CONTAINER_EVENTS.has(entry[0]));
    };
    const $f42b084586d299a2$export$b1f29dd0b70f467b = (events)=>{
        const customMarksMap = {};
        const customMarksList = [];
        events.forEach((event, eventKey)=>{
            const parts = (0, (0, parcelRequire("5TsWX")).GADGET_MARK_EVENT_PATTERN).exec(eventKey);
            if (parts == null) return;
            const key = parts[1];
            const stage = parts[2];
            const customMark = customMarksMap[key];
            if (customMark == null) {
                const newCustomMark = {
                    name: `gadgetMark_${key}`,
                    [stage]: event.spaTime
                };
                customMarksMap[key] = newCustomMark;
                customMarksList.push(newCustomMark);
            } else customMark[stage] = event.spaTime;
        });
        return customMarksList;
    };
    const $f42b084586d299a2$var$getInitialMarks = (context)=>({
            TTI: Date.now() - Number(context.navStart),
            isInitialRender: context.isInitialRender,
            isWritable: context.isWritable,
            isRouteReplaced: context.isRouteReplaced,
            currentPageId: context.currentPageId,
            pageVisible: !window?.document?.hidden,
            ssrFeatureFlags: $f42b084586d299a2$var$getSSRFeatureFlags(),
            featureFlags: (0, (0, parcelRequire("7cLxq")).getDashboardFeatureFlags)(),
            featureGates: (0, (0, parcelRequire("8UDz8")).getDashboardFeatureGates)(),
            centralised: true
        });
    const $f42b084586d299a2$export$f409d0aa399347ac = (isInitialRender, lastTransitionStartTime, navigationStart)=>{
        const sessionStart = navigationStart != null ? new Date(navigationStart).getTime() : 0;
        return !isInitialRender && lastTransitionStartTime != null ? lastTransitionStartTime : sessionStart;
    };
    const $f42b084586d299a2$var$isInvalidContextState = (metricsContextTimeStamp, currentNavStart, currentPageId)=>{
        if (metricsContextTimeStamp.currentPageId !== currentPageId) return true;
        if (metricsContextTimeStamp.created < metricsContextTimeStamp.navStart) return true;
        const now = Date.now();
        if (now < metricsContextTimeStamp.navStart) return true;
        if (now < Number(currentNavStart)) return true;
        return false;
    };
    const $f42b084586d299a2$export$ea517a7cfce02895 = (dashboardId)=>{
        const [{ isInitialRender: isInitialRender, lastTransitionStartTime: lastTransitionStartTime, navigationStart: navigationStart, currentPageId: currentPageId = 'unknown' }] = (0, (0, parcelRequire("bVDE5")).useSpaStateTransition)();
        const [{ aboveTheFoldGadgets: aboveTheFoldGadgets }] = (0, (0, parcelRequire("8UPoO")).useRenderAboveTheFold)();
        const [routerState] = (0, (0, parcelRequire("doZTA")).useRouter)();
        const navStart = $f42b084586d299a2$export$f409d0aa399347ac(isInitialRender, lastTransitionStartTime, navigationStart);
        const dashboardData = (0, (0, parcelRequire("gh1hi")).useDashboardResource)().data;
        const { scrollStartTimestamp: scrollStartTimestamp, resetScrollStartTime: resetScrollStartTime } = (0, (0, parcelRequire("hmyfM")).useDashboardScrollAnalytics)();
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const newContext = {
            dashboardId: dashboardId,
            isInitialRender: isInitialRender,
            isRouteReplaced: routerState.action === 'REPLACE',
            lastTransitionStartTime: lastTransitionStartTime,
            navigationStart: navigationStart,
            currentPageId: currentPageId,
            navStart: navStart,
            gadgets: dashboardData?.gadgets,
            isWritable: dashboardData?.writable ?? false,
            scrollStartTimestamp: scrollStartTimestamp,
            aboveTheFoldGadgets: aboveTheFoldGadgets,
            resetScrollStartTime: resetScrollStartTime
        };
        const context = (0, $5uXOq.useRef)(newContext);
        context.current = newContext;
        const sendGadgetMetrics = (0, $5uXOq.useCallback)((gadgetId, gadgetEventRecord)=>{
            if (context.current.navigationStart == null) return;
            const gadgetModel = context.current.gadgets?.find((item)=>item.id === gadgetId) || null;
            const customMarks = {
                ...$f42b084586d299a2$var$getInitialMarks(context.current),
                timeout: gadgetEventRecord.timedOut === true,
                gadgetContentType: gadgetEventRecord.contentType,
                isAboveTheFold: context.current.aboveTheFoldGadgets.has(gadgetId)
            };
            if (gadgetEventRecord.timedOut === true) {
                if ($f42b084586d299a2$var$isIdle(gadgetEventRecord)) customMarks.timeout_idle = true;
                else $f42b084586d299a2$export$835bb833cb903f59(gadgetEventRecord.source, gadgetEventRecord.contentType).filter((event)=>!$f42b084586d299a2$var$GADGET_CONTAINER_EVENTS.has(event)).filter((event)=>!gadgetEventRecord.events.has(event)).forEach((event)=>{
                    customMarks[`timeout_${event}`] = true;
                });
            }
            if (gadgetEventRecord.source === (0, (0, parcelRequire("5TsWX")).WRM_GADGET_METRICS) || gadgetEventRecord.source === (0, (0, parcelRequire("5TsWX")).FORGE_GADGET_METRICS) || gadgetEventRecord.source === (0, (0, parcelRequire("5TsWX")).REACT_GADGET_METRICS)) {
                customMarks.gadgetType = gadgetModel?.amdModule || gadgetModel?.forge?.key || gadgetModel?.reactKey || (0, (0, parcelRequire("5TsWX")).UNKNOWN_GADGET_TYPE);
                const renderContainer = gadgetEventRecord.events.get((0, (0, parcelRequire("5TsWX")).GADGET_RENDER_CONTAINER_EVENT));
                const start = gadgetEventRecord.events.get((0, (0, parcelRequire("5TsWX")).GADGET_START_EVENT));
                if (renderContainer != null) customMarks.gadgetRenderContainer = renderContainer.spaTime - Number(context.current.navStart);
                if (start != null) {
                    customMarks.gadgetStartTime = start.spaTime - Number(context.current.navStart);
                    customMarks.gadgetDuration = Date.now() - start.spaTime;
                }
            } else if (gadgetEventRecord.source === (0, (0, parcelRequire("5TsWX")).IDLE_GADGET_SOURCE) || gadgetEventRecord.source === (0, (0, parcelRequire("5TsWX")).FALLBACK_GADGET_SOURCE)) customMarks.gadgetType = gadgetModel?.amdModule || gadgetModel?.forge?.key || (0, (0, parcelRequire("5TsWX")).UNKNOWN_GADGET_TYPE);
            $f42b084586d299a2$export$b1f29dd0b70f467b(gadgetEventRecord.events).forEach((gadgetMark)=>{
                if (gadgetMark.start != null) customMarks[`${gadgetMark.name}-start`] = gadgetMark.start - Number(context.current.navStart);
                if (gadgetMark.start != null && gadgetMark.end != null) customMarks[`${gadgetMark.name}-duration`] = gadgetMark.end - gadgetMark.start;
            });
            const gadgetLoadIdentifier = `gadget-${context.current.dashboardId ?? 'no-dashboard-id'}-${gadgetId}`;
            (0, (0, parcelRequire("trHKK")).gadgetLoad)(gadgetLoadIdentifier).start({
                startTime: context.current.navStart
            });
            (0, (0, parcelRequire("trHKK")).gadgetLoad)(gadgetLoadIdentifier).stop({
                customData: customMarks,
                stopTime: Date.now()
            });
            if (gadgetEventRecord.source === (0, (0, parcelRequire("5TsWX")).WRM_GADGET_METRICS) && gadgetEventRecord.contentType !== 'View' && gadgetEventRecord.contentType !== 'Config') (0, (0, parcelRequire("hh0bW")).default)({
                ...$f42b084586d299a2$var$expAttributes,
                experience: (0, (0, parcelRequire("JIPqS")).VIEW_WRM_GADGET_EXPERIENCE),
                wasExperienceSuccesful: false
            });
        }, []);
        const sendOverallGadgetMetrics = (0, $5uXOq.useCallback)((gadgetIds, gadgetMetricsEvents, timeout)=>{
            const metricToReport = {
                timeout: timeout,
                fullDashboardTTI: Date.now() - Number(context.current.navStart),
                dashboardScrollStartTime: context.current.scrollStartTimestamp.current != null ? context.current.scrollStartTimestamp.current - Number(context.current.navStart) : 'unknown',
                belowTheFoldTTI: Date.now() - (context.current.scrollStartTimestamp.current != null && (context.current.gadgets?.length || 0) - context.current.aboveTheFoldGadgets.size > 0 ? context.current.scrollStartTimestamp.current : Date.now())
            };
            (0, (0, parcelRequire("8zOmE")).fireOperationalAnalytics)(createAnalyticsEvent({}), 'fullDashboard loaded', metricToReport);
        }, [
            createAnalyticsEvent
        ]);
        const onAboveTheFoldGadgetsRender = (0, $5uXOq.useCallback)((gadgetIds, gadgetMetricsEvents, gadgetRenderStartTime, contextTimeStamps, timeout)=>{
            const totalGadgetsCount = context.current.gadgets?.length || 0;
            const connectGadgetCount = context.current.gadgets?.filter((item)=>(0, (0, parcelRequire("5TsWX")).CONNECT_MODULE_PATTERN).test(item.amdModule || '')).length || 0;
            const forgeGadgetCount = context.current.gadgets?.filter((item)=>(0, (0, parcelRequire("5TsWX")).FORGE_MODULE_PATTERN).test(item.forge?.key || '')).length || 0;
            const internalGadgetCount = totalGadgetsCount - connectGadgetCount - forgeGadgetCount;
            const untrackedGadgetCount = context.current.gadgets?.filter((item)=>gadgetIds.every((id)=>id !== item.id)).length || 0;
            const deprecatedGadgetsCount = context.current.gadgets ? context.current.gadgets.filter((gadget)=>(0, (0, parcelRequire("hGdg6")).isDeprecatedGadget)(gadget.amdModule)).length : 0;
            const initialMarks = $f42b084586d299a2$var$getInitialMarks(context.current);
            const gadgetRenderStart = gadgetRenderStartTime - Number(context.current.navStart);
            const invalidContextState = $f42b084586d299a2$var$isInvalidContextState(contextTimeStamps, context.current.navStart, context.current.currentPageId) || initialMarks.TTI < 0 || gadgetRenderStart < 0;
            if (invalidContextState) (0, (0, parcelRequire("dJl1T")).default).safeWarnWithoutCustomerData('spa-apps.dashboard.gadget.metrics', `metrics context state is invalid for dashboard [${context.current.currentPageId ?? 'null'}]: { contextTimestamps: ${JSON?.stringify(contextTimeStamps)}, now:${Date.now()}, pnow:${performance?.now()}, navStart:${context.current.navStart} }`);
            const customData = {
                ...initialMarks,
                gadgetRenderStart: gadgetRenderStart,
                gadgetCount_Total: totalGadgetsCount,
                gadgetCount_Internal: internalGadgetCount,
                gadgetCount_Connect: connectGadgetCount,
                gadgetCount_Forge: forgeGadgetCount,
                gadgetCount_Unknown: untrackedGadgetCount - connectGadgetCount,
                timeout: timeout,
                gadgetCount_AboveTheFold: context.current.aboveTheFoldGadgets.size,
                gadgetCount_Deprecated: deprecatedGadgetsCount,
                isStaled: invalidContextState
            };
            (0, (0, parcelRequire("99gkn")).addUFOCustomData)(customData);
            (0, (0, parcelRequire("3B7oW")).trackBM3FeatureFlagsAccessed)((0, (0, parcelRequire("trHKK")).dashboardLoad));
            (0, (0, parcelRequire("bFU4G")).addApdexToAll)({
                key: (0, (0, parcelRequire("trHKK")).dashboardLoad).key,
                stopTime: performance.now()
            });
            if ((0, (0, parcelRequire("co1wz")).ff)('bm3.emit-on-raf.top-experiences')) (0, (0, parcelRequire("iAWa3")).afterPaintEmit)((stopTime)=>{
                (0, (0, parcelRequire("trHKK")).dashboardLoad).stop({
                    stopTime: stopTime,
                    customData: customData
                });
            }, (0, (0, parcelRequire("trHKK")).dashboardLoad));
            else (0, (0, parcelRequire("trHKK")).dashboardLoad).stop({
                customData: customData
            });
        }, []);
        const resetScrollStartMetrics = (0, $5uXOq.useCallback)(()=>{
            context.current.resetScrollStartTime();
        }, []);
        const sendDebuggingAnalytics = (0, $5uXOq.useCallback)((contextTimeStamps)=>{
            const attributes = {
                isStale: $f42b084586d299a2$var$isInvalidContextState(contextTimeStamps, context.current.navStart, context.current.currentPageId),
                tti: Date.now() - Number(context.current.navStart),
                currentPageId: context.current.currentPageId
            };
            (0, (0, parcelRequire("8zOmE")).fireOperationalAnalytics)(createAnalyticsEvent({}), 'tti sent', attributes);
        }, [
            createAnalyticsEvent
        ]);
        return {
            sendGadgetMetrics: sendGadgetMetrics,
            sendOverallGadgetMetrics: sendOverallGadgetMetrics,
            onAboveTheFoldGadgetsRender: onAboveTheFoldGadgetsRender,
            resetScrollStartMetrics: resetScrollStartMetrics,
            sendDebuggingAnalytics: sendDebuggingAnalytics
        };
    };
});
parcelRegister("iAWa3", function(module, exports) {
    $parcel$export(module.exports, "afterPaintEmit", ()=>$8ad29d98765af503$export$dc4c3408669b8f4e);
    var $x9xyg;
    const $8ad29d98765af503$export$dc4c3408669b8f4e = (emitMetrics, metric)=>{
        const stopTime = performance.now();
        setTimeout(()=>{
            metric.mark((0, $ce556f125dee8174$export$99c08ec92759c71c));
            (0, (0, parcelRequire("x9xyg")).setMark)((0, $ce556f125dee8174$export$99c08ec92759c71c));
            setTimeout(()=>{
                emitMetrics(stopTime);
            }, 500);
        });
    };
});
const $ce556f125dee8174$export$56940a9488e85199 = 'jira.fe.spa-';
const $ce556f125dee8174$export$45c4b6bf7935c69a = `${$ce556f125dee8174$export$56940a9488e85199}start`;
const $ce556f125dee8174$export$135c96ab5afee429 = 'ssr.spa.rendered';
const $ce556f125dee8174$export$ff834eccc35b9089 = 'appBundleReady';
const $ce556f125dee8174$export$86b05e34d462ead8 = 'prefetchApiReady';
const $ce556f125dee8174$export$d6a604a7d9a390fb = 'prefetchApiStart';
const $ce556f125dee8174$export$1211adfff27a31fe = 'issueViewInitialDataReady';
const $ce556f125dee8174$export$3ae43cc1782d058f = 'jswRenderStart';
const $ce556f125dee8174$export$363f6703ffa600be = 'jswCreateReduxStoreStart';
const $ce556f125dee8174$export$e87b74567b79c8 = 'jswCreateReduxStoreEnd';
const $ce556f125dee8174$export$99fe642459a8b02 = 'jswTransformCriticalDataStart';
const $ce556f125dee8174$export$ab1f5705fc8e0571 = 'jswTransformCriticalDataEnd';
const $ce556f125dee8174$export$8c3a9046da692c35 = 'jswReduxSoftwareAppLoadedStart';
const $ce556f125dee8174$export$5aa497d1efdcc5bf = 'jswReduxSoftwareAppLoadedEnd';
const $ce556f125dee8174$export$99c08ec92759c71c = 'tti-raf-mark';
const $ce556f125dee8174$export$48552baa9db8ce1d = 'tti-raf';
parcelRegister("3B7oW", function(module, exports) {
    $parcel$export(module.exports, "trackBM3FeatureFlagsAccessed", ()=>$afa764d83f81f55e$export$f00f1e874ce869e9);
    var $1u4q7;
    var $y7eb2;
    const $afa764d83f81f55e$var$startsAsObject = (value)=>{
        try {
            if (value.charAt(0) === '{') return true;
        } catch (e) {
            return false;
        }
        return false;
    };
    const $afa764d83f81f55e$var$isObject = (value)=>value && typeof value === 'object' && value.constructor === Object;
    const $afa764d83f81f55e$var$isString = (input)=>{
        let result;
        try {
            result = typeof input === 'string' || input instanceof String;
        } catch (err) {
            result = false;
        }
        return result;
    };
    const $afa764d83f81f55e$export$7b4abba432e57989 = (value)=>$afa764d83f81f55e$var$isString(value) && $afa764d83f81f55e$var$startsAsObject(value) || $afa764d83f81f55e$var$isObject(value);
    const $afa764d83f81f55e$export$fcc03ee4c944962f = (featureFlagValue)=>$afa764d83f81f55e$export$7b4abba432e57989(featureFlagValue) ? 'non_boolean' : featureFlagValue;
    const $afa764d83f81f55e$export$d2818866b4acbea4 = new Map();
    const $afa764d83f81f55e$export$f00f1e874ce869e9 = (metric)=>{
        const interactionId = (0, (0, parcelRequire("1u4q7")).getInteractionId)();
        const currentInteractionId = interactionId.current;
        if (!currentInteractionId) return;
        const data = metric.getData();
        const config = data?.config;
        if (config && config?.featureFlags?.length) {
            const allFeatureFlags = (0, (0, parcelRequire("y7eb2")).default)();
            config.featureFlags.forEach((featureFlagName)=>{
                const value = allFeatureFlags[featureFlagName];
                if (value === undefined) return;
                const featureFlagValue = $afa764d83f81f55e$export$fcc03ee4c944962f(value);
                $afa764d83f81f55e$export$d2818866b4acbea4.set(featureFlagName, featureFlagValue);
            });
        }
    };
});
parcelRegister("7cLxq", function(module, exports) {
    $parcel$export(module.exports, "getDashboardFeatureFlags", ()=>$9b049cc4dacaaa3a$export$5f260d38da0c0462);
    var $8QpSo;
    var $y7eb2;
    const $9b049cc4dacaaa3a$var$DASHBOARD_ADDITIONAL_FLAGS = [];
    const $9b049cc4dacaaa3a$var$DASHBOARD_FEATURE_FLAG_SCHEMA = /^endeavour\.(spa|dashboard|gadget)\.[\w-]+$/;
    const $9b049cc4dacaaa3a$export$5f260d38da0c0462 = (0, (0, parcelRequire("8QpSo")).default)(()=>{
        try {
            const allFFs = (0, (0, parcelRequire("y7eb2")).default)() || {};
            return Object.fromEntries(Object.keys(allFFs).filter((key)=>$9b049cc4dacaaa3a$var$DASHBOARD_FEATURE_FLAG_SCHEMA.test(key) || $9b049cc4dacaaa3a$var$DASHBOARD_ADDITIONAL_FLAGS.includes(key)).sort((a, b)=>a.localeCompare(b, undefined, {
                    ignorePunctuation: true
                })).map((key)=>[
                    key,
                    allFFs[key].value
                ]));
        } catch (e) {
            return null;
        }
    });
});
parcelRegister("8UDz8", function(module, exports) {
    $parcel$export(module.exports, "getDashboardFeatureGates", ()=>$0c1208fe9722c23a$export$7cf9f66e85966b7);
    var $8QpSo;
    var $4R6GH;
    const $0c1208fe9722c23a$export$7cf9f66e85966b7 = (0, (0, parcelRequire("8QpSo")).default)(()=>({
            endeavour_nin_assigned_to_me_gadget: (0, (0, parcelRequire("4R6GH")).fg)('endeavour_nin_assigned_to_me_gadget'),
            endeavour_nin_issues_in_progress_gadget: (0, (0, parcelRequire("4R6GH")).fg)('endeavour_nin_issues_in_progress_gadget'),
            endeavour_nin_filter_results_gadget: (0, (0, parcelRequire("4R6GH")).fg)('endeavour_nin_filter_results_gadget'),
            endeavour_nin_watched_issues_gadget: (0, (0, parcelRequire("4R6GH")).fg)('endeavour_nin_watched_issues_gadget'),
            endeavour_nin_voted_issues_gadget: (0, (0, parcelRequire("4R6GH")).fg)('endeavour_nin_voted_issues_gadget'),
            endeavour_gadget_deprecation_banner_v1: (0, (0, parcelRequire("4R6GH")).fg)('endeavour_gadget_deprecation_banner_v1'),
            endeavour_improve_dashboard_connect_scripts: (0, (0, parcelRequire("4R6GH")).fg)('endeavour_improve_dashboard_connect_scripts')
        }));
});
parcelRegister("trHKK", function(module, exports) {
    $parcel$export(module.exports, "gadgetLoad", ()=>$b46e477904ae8756$export$4de73f6aec94634c);
    $parcel$export(module.exports, "dashboardLoad", ()=>$b46e477904ae8756$export$2b2c4cbb9844d8cb);
    var $dGrdR;
    const $b46e477904ae8756$export$4de73f6aec94634c = (0, (0, parcelRequire("dGrdR")).metrics).concurrent.custom({
        key: 'dashboard.gadget',
        featureFlags: []
    });
    const $b46e477904ae8756$export$2b2c4cbb9844d8cb = (0, (0, parcelRequire("dGrdR")).metrics).pageLoad({
        key: 'dashboard',
        featureFlags: []
    });
});
parcelRegister("hmyfM", function(module, exports) {
    $parcel$export(module.exports, "useDashboardScrollAnalytics", ()=>$03a594e6b9b8c3c6$export$83af525d5e7538fa);
    var $5uXOq = parcelRequire("5uXOq");
    var $lFKmC = parcelRequire("lFKmC");
    var $8zOmE;
    var $2NMNM;
    var $eNjJm;
    var $bVDE5;
    const $03a594e6b9b8c3c6$export$83af525d5e7538fa = ()=>{
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const currentPage = (0, (0, parcelRequire("eNjJm")).useCurrentPage)();
        const [{ isInitialRender: isInitialRender, currentPageId: currentPageId }] = (0, (0, parcelRequire("bVDE5")).useSpaStateTransition)();
        const { startTime: startTime } = currentPage;
        const scrollStartTimestamp = (0, $5uXOq.useRef)(null);
        const debounceSendAnalytics = (0, ($parcel$interopDefault($lFKmC)))((_scrollStartTimestamp)=>{
            if (scrollStartTimestamp.current != null) return;
            const percentageOfPageScrolled = window.scrollY / window.innerHeight;
            if (percentageOfPageScrolled < 0.2) return;
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(createAnalyticsEvent({
                action: 'scrolled',
                actionSubject: 'page'
            }), 'dashboardScroll', {
                isInitialRender: isInitialRender,
                scrollStartTimeFromPageLoad: _scrollStartTimestamp - startTime,
                percentageOfPageScrolled: percentageOfPageScrolled,
                currentPageId: currentPageId
            });
            scrollStartTimestamp.current = _scrollStartTimestamp;
        }, 500);
        (0, $5uXOq.useEffect)(()=>{
            const fireScrollAnalytics = ()=>{
                debounceSendAnalytics(Date.now());
            };
            const resetScrollPosition = ()=>{
                window.scrollTo(0, 0);
            };
            window.addEventListener('scroll', fireScrollAnalytics);
            window.addEventListener('unload', resetScrollPosition);
            return ()=>{
                window.removeEventListener('scroll', fireScrollAnalytics);
                window.removeEventListener('unload', resetScrollPosition);
            };
        }, [
            debounceSendAnalytics
        ]);
        const resetScrollStartTime = (0, $5uXOq.useCallback)(()=>{
            scrollStartTimestamp.current = null;
        }, []);
        return {
            scrollStartTimestamp: scrollStartTimestamp,
            resetScrollStartTime: resetScrollStartTime
        };
    };
});
parcelRegister("eNjJm", function(module, exports) {
    $parcel$export(module.exports, "useCurrentPage", ()=>$a69ad6f74a66de9c$export$6faff8ed5e305ed3);
    var $3sQ5x;
    var $7w9eZ;
    const $a69ad6f74a66de9c$var$useSweetStateCurrentPage = (0, (0, parcelRequire("3sQ5x")).createHook)((0, (0, parcelRequire("7w9eZ")).default), {
        selector: (state)=>state.currentPage
    });
    const $a69ad6f74a66de9c$export$6faff8ed5e305ed3 = ()=>{
        const [currentPage] = $a69ad6f74a66de9c$var$useSweetStateCurrentPage();
        return currentPage;
    };
});
parcelRegister("gW6vr", function(module, exports) {
    $parcel$export(module.exports, "GADGETS_TO_REPORT", ()=>$a70b755fbe2a81b5$export$2c28b0760818db72);
    $parcel$export(module.exports, "GADGETS_IGNORED", ()=>$a70b755fbe2a81b5$export$c1d730ac3dcf5785);
    const $a70b755fbe2a81b5$export$2c28b0760818db72 = new Set([
        'jira-dashboard-items/assigned-to-me',
        'jira-dashboard-items/bubble-chart-dashboard-item',
        'jira-dashboard-items/createdvsresolved',
        'jira-dashboard-items/favourite-filters',
        'jira-dashboard-items/filter-results',
        'jira-dashboard-items/in-progress',
        'jira-dashboard-items/labels',
        'jira-dashboard-items/piechart',
        'jira-dashboard-items/stats',
        'jira-dashboard-items/two-dimensional-stats',
        'jira-dashboard-items/voted',
        'jira-dashboard-items/watched',
        'jira-dashboard-items/issuescalendar',
        'jira-dashboard-items/projects',
        'jira-dashboard-items/activitystream',
        'jira-dashboard-items/recently-created',
        'jira-dashboard-items/workload-pie-chart',
        'jira-dashboard-items/roadmap',
        'jira-dashboard-items/average-age-chart',
        'jira-dashboard-items/average-time-in-status-chart',
        'jira-dashboard-items/average-number-of-times-in-status-chart',
        'jira-dashboard-items/heat-map',
        'jira-dashboard-items/time-to-first-response',
        'jira-dashboard-items/sprint-burndown',
        'jira-dashboard-items/resolution-time',
        'jira-dashboard-items/timesince',
        'jira-dashboard-items/spacer',
        'jira-dashboard-items/rapid-view',
        'jira-dashboard-items/version-report',
        'rest/gadgets/1.0/g/com.atlassian.bonfire.plugin:bonfire-test-sessions-gadget/gadget/bonfire-sessions-gadget.xml',
        'rest/gadgets/1.0/g/com.atlassian.jira.ext.calendar:issuescalendar-gadget/templates/plugins/jira/portlets/calendar/gadget/calendar-gadget.xml',
        'rest/gadgets/1.0/g/com.atlassian.jira.ext.charting:firstresponse-gadget/com/atlassian/jira/ext/charting/gadget/firstresponse-gadget.xml',
        'rest/gadgets/1.0/g/com.atlassian.jira.ext.charting:numberoftimesinstatus-gadget/com/atlassian/jira/ext/charting/gadget/numberoftimesinstatus-gadget.xml',
        'rest/gadgets/1.0/g/com.atlassian.jira.ext.charting:timeinstatus-gadget/com/atlassian/jira/ext/charting/gadget/timeinstatus-gadget.xml',
        'rest/gadgets/1.0/g/com.atlassian.jira.ext.charting:workloadpie-gadget/com/atlassian/jira/ext/charting/gadget/workloadpie-gadget.xml',
        'rest/gadgets/1.0/g/com.atlassian.jira.gadgets:average-age-chart-gadget/gadgets/average-age-gadget.xml',
        'rest/gadgets/1.0/g/com.atlassian.jira.gadgets:heat-map-gadget/gadgets/heatmap-gadget.xml',
        'rest/gadgets/1.0/g/com.atlassian.jira.gadgets:project-gadget/gadgets/project-gadget.xml',
        'rest/gadgets/1.0/g/com.atlassian.jira.gadgets:recently-created-chart-gadget/gadgets/recently-created-gadget.xml',
        'rest/gadgets/1.0/g/com.atlassian.jira.gadgets:resolution-time-gadget/gadgets/resolution-time-gadget.xml',
        'rest/gadgets/1.0/g/com.atlassian.jira.gadgets:road-map-gadget/gadgets/roadmap-gadget.xml',
        'rest/gadgets/1.0/g/com.atlassian.jira.gadgets:time-since-chart-gadget/gadgets/timesince-gadget.xml',
        'rest/gadgets/1.0/g/com.atlassian.jirafisheyeplugin:crucible-charting-gadget/gadgets/crucible-charting-gadget.xml',
        'rest/gadgets/1.0/g/com.atlassian.jirafisheyeplugin:fisheye-charting-gadget/gadgets/fisheye-charting-gadget.xml',
        'rest/gadgets/1.0/g/com.atlassian.jirafisheyeplugin:fisheye-recent-commits-gadget/gadgets/fisheye-recent-commits-gadget.xml',
        'rest/gadgets/1.0/g/com.atlassian.jirawallboard.atlassian-wallboard-plugin:spacer-gadget/gadgets/spacerGadget.xml',
        'rest/gadgets/1.0/g/com.atlassian.streams.streams-jira-plugin:activitystream-gadget/gadgets/activitystream-gadget.xml',
        'rest/gadgets/1.0/g/com.pyxis.greenhopper.jira:greenhopper-gadget-rapid-view/gadgets/greenhopper-rapid-view.xml',
        'rest/gadgets/1.0/g/com.pyxis.greenhopper.jira:greenhopper-gadget-sprint-burndown/gadgets/greenhopper-sprint-burndown.xml',
        'rest/gadgets/1.0/g/com.pyxis.greenhopper.jira:greenhopper-gadget-version-report/gadgets/greenhopper-version-report.xml'
    ]);
    const $a70b755fbe2a81b5$export$c1d730ac3dcf5785 = new Set([
        'jira-dashboard-items/sprint-days-remaining',
        'jira-dashboard-items/sprint-health',
        'servicedesk/internal/reports/feature/custom-report-dashboard-item/custom-report-dashboard-item',
        'servicedesk/internal/dashboard-items/filter/filter-dashboard-item'
    ]);
});
parcelRegister("h5Jym", function(module, exports) {
    $parcel$export(module.exports, "useSpaStatePageReady", ()=>$80ca19e1fa573e80$export$ea2491906b753456);
    var $5uXOq = parcelRequire("5uXOq");
    var $x9xyg;
    var $JIPqS;
    var $gh1hi;
    var $4oDnX;
    var $i0rkS;
    const $80ca19e1fa573e80$var$isAmdGadget = (gadget)=>gadget.amdModule != null && gadget.amdModule !== (0, (0, parcelRequire("JIPqS")).CONNECT_ITEM_AMD_MODULE);
    const $80ca19e1fa573e80$var$isWaitableGadget = (gadget)=>$80ca19e1fa573e80$var$isAmdGadget(gadget);
    const $80ca19e1fa573e80$var$getWaitableGadgetSet = (gadgets)=>new Set(gadgets.filter($80ca19e1fa573e80$var$isWaitableGadget).map(({ id: id })=>id));
    const $80ca19e1fa573e80$export$ea2491906b753456 = ()=>{
        const { data: data, loading: loading } = (0, (0, parcelRequire("gh1hi")).useDashboardResource)();
        const [, { registerListener: registerListener, unregisterListener: unregisterListener }] = (0, (0, parcelRequire("i0rkS")).useMessageBus)();
        const lastDashboardId = (0, $5uXOq.useRef)();
        const pendingGadgetSet = (0, $5uXOq.useRef)(new Set());
        const [, { setAppReady: setAppReady }] = (0, (0, parcelRequire("4oDnX")).useSpaStateActions)();
        const callback = (0, $5uXOq.useCallback)((type, _, { gadgetId: gadgetId })=>{
            if (type !== (0, (0, parcelRequire("JIPqS")).GADGET_METRICS_TYPE).REPORT) return;
            if (!pendingGadgetSet.current.has(gadgetId)) return;
            pendingGadgetSet.current.delete(gadgetId);
            if (pendingGadgetSet.current.size > 0) return;
            unregisterListener(callback);
            (0, (0, parcelRequire("x9xyg")).setMark)((0, (0, parcelRequire("JIPqS")).PAGE_READY_METRIC_KEY));
            setAppReady();
        }, [
            setAppReady,
            unregisterListener
        ]);
        (0, $5uXOq.useLayoutEffect)(()=>{
            if (loading || data == null || data.id === lastDashboardId.current) return ()=>{};
            lastDashboardId.current = data.id;
            pendingGadgetSet.current = $80ca19e1fa573e80$var$getWaitableGadgetSet(data.gadgets);
            if (pendingGadgetSet.current.size === 0) {
                (0, (0, parcelRequire("x9xyg")).setMark)((0, (0, parcelRequire("JIPqS")).PAGE_READY_METRIC_KEY));
                setAppReady();
                return ()=>{};
            }
            registerListener(callback);
            return ()=>{
                unregisterListener(callback);
            };
        }, [
            callback,
            data,
            loading,
            registerListener,
            setAppReady,
            unregisterListener
        ]);
    };
});
parcelRegister("5jJ3W", function(module, exports) {
    $parcel$export(module.exports, "addDashboardToRecents", ()=>$f6e4dfdb0385e67e$export$9f034829a82f8a11);
    const $f6e4dfdb0385e67e$export$9f034829a82f8a11 = (dashboardId)=>fetch(`/rest/internal/latest/dashboards/history/${dashboardId}`, {
            method: 'POST'
        });
});
parcelRegister("1Bx5m", function(module, exports) {
    $parcel$export(module.exports, "AboveTheFoldContainer", ()=>$c6a764536e1ff0c0$export$60c1d52f3e30a1cd);
    var $5uXOq = parcelRequire("5uXOq");
    var $8UPoO;
    var $1oaeD;
    var $6fab6;
    const $c6a764536e1ff0c0$export$60c1d52f3e30a1cd = ({ gadgets: gadgets, dashboardId: dashboardId, layout: layout, children: children })=>{
        const { getMaximisedGadgetId: getMaximisedGadgetId } = (0, (0, parcelRequire("1oaeD")).useMaximizedGadget)(gadgets);
        const columns = (0, $5uXOq.useMemo)(()=>(0, (0, parcelRequire("6fab6")).toColumns)(gadgets, layout), [
            gadgets,
            layout
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("8UPoO")).RenderAboveTheFoldContainer), {
            columns: columns,
            dashboardId: dashboardId,
            scope: dashboardId,
            maximizedGadgetId: getMaximisedGadgetId
        }, children);
    };
});
parcelRegister("7v0Ei", function(module, exports) {
    $parcel$export(module.exports, "AmdGadgetMonolithOverrideStyles", ()=>$c5037e48649c048f$export$17921465e6f60e58);
    var $5uXOq = parcelRequire("5uXOq");
    const $c5037e48649c048f$var$styles = `.piechart-gadget .piechart-fill {
    opacity: 1
}

.piechart-gadget .legend-icon {
    float: left;
    margin-top: 2px;
    margin-right: 10px
}


.piechart-gadget a {
    color: #0052cc;
    text-decoration: none;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,'Fira Sans','Droid Sans','Helvetica Neue',sans-serif;
    color: #172b4d;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 20px
}

.piechart-gadget a:focus,.piechart-gadget a:hover {
    color: #0065ff;
    text-decoration: underline
}

.piechart-gadget a:active {
    color: #0049b0;
    text-decoration: underline
}

.piechart-gadget .legend-wrapper {
    padding: 0 7px 7px 7px;
    max-width: 450px
}

.piechart-gadget .legend-wrapper a {
    text-decoration: none;
    color: ${"var(--ds-text, #5e6c84)"};
}

.piechart-gadget .legend-wrapper a:focus,.piechart-gadget .legend-wrapper a:hover {
    color: ${"var(--ds-link, #0065ff)"};
    text-decoration: underline
}

.piechart-gadget .legend-wrapper a:active {
    color: ${"var(--ds-text, #0049b0)"};
    text-decoration: underline
}

.piechart-gadget .legend-wrapper .legend-item {
    border-top-width: 0;
    border-bottom-width: 0
}

.piechart-gadget .legend-wrapper .legend-item:first-child {
    border-top: 2px solid ${"var(--ds-border-accent-gray, #dfe1e6)"};
}

.piechart-gadget .legend-wrapper .legend-item:last-child {
    border-bottom: 2px solid ${"var(--ds-border-accent-gray, #dfe1e6)"};
}

.piechart-gadget .legend-wrapper .legend-item .legend-content {
    padding: 8px 7px 8px 7px
}

.piechart-gadget .legend-wrapper .legend-item .legend-content .legend-item-value {
    padding-right: 7px
}

.two-d-container .extra-container {
    padding: 0
}

.two-d-container .extra-container tbody tr:last-child>td,.two-d-container .extra-container tbody tr:last-child>th,.two-d-container .extra-container tbody tr:last-child>tr,.two-d-container .extra-container tbody tr>td:last-child {
    padding: 7px;
    background: ${"var(--ds-background-neutral, #ebecf0)"};
}

.two-d-container .data-footer {
    padding: 5px
}

.two-d-container .data-footer p {
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,'Fira Sans','Droid Sans','Helvetica Neue',sans-serif;
    color: ${"var(--ds-text-subtle, #172b4d)"};
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 20px
}

table.aui thead tr th {
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,'Fira Sans','Droid Sans','Helvetica Neue',sans-serif;
    color: #5e6c84;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    padding-top: 4px;
    padding-bottom: 4px
}

table.aui thead .aui-iconfont-up {
    visibility: hidden
}

table.aui thead .aui-iconfont-up:after {
    top: 11px;
    margin-left: -16px;
    content: "";
    display: inline-block;
    width: 4px;
    height: 4px;
    border: none;
    border-right: 2px solid;
    border-top: 2px solid;
    transform: rotate(-45deg);
    visibility: visible;
    float: right;
    position: relative;
    top: 8px
}

table.aui thead .aui-iconfont-down {
    visibility: hidden
}

table.aui thead .aui-iconfont-down:after {
    top: 11px;
    margin-left: -16px;
    content: "";
    display: inline-block;
    width: 4px;
    height: 4px;
    border: none;
    border-right: 2px solid;
    border-top: 2px solid;
    transform: rotate(135deg);
    visibility: visible;
    float: right;
    position: relative;
    top: 6px
}

table.aui tbody:before {
    height: 0
}

table.aui tbody a {
    text-decoration: none;
    color: ${"var(--ds-link, #5e6c84)"};
}

table.aui tbody a:focus,table.aui tbody a:hover {
    color: ${"var(--ds-link, #0065ff)"};
    text-decoration: underline
}

table.aui tbody a:active {
    color: ${"var(--ds-link, #0049b0)"};
    text-decoration: underline
}

table.aui tbody>tr:hover {
    background: #ebecf0
}

table.aui tbody>tr>td:last-child {
    border-left-width: 0
}

table.aui tbody>tr>th {
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,'Fira Sans','Droid Sans','Helvetica Neue',sans-serif;
    color: #172b4d;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 20px
}

table.aui tbody>tr:first-child>th {
    border-top-width: 0
}

.stats-gadget {
    padding: 0
}

.stats-gadget tbody a {
    color: #0052cc;
    text-decoration: none;
    color: #5e6c84
}

.stats-gadget tbody a:focus,.stats-gadget tbody a:hover {
    color: #0065ff;
    text-decoration: underline
}

.stats-gadget tbody a:active {
    color: #0049b0;
    text-decoration: underline
}

.stats-gadget tbody tr>td .aui-progress-indicator-value {
    background: #0065ff
}

.stats-gadget tbody tr.stats-gadget-final-row>* {
    font-weight: 400;
    background: #ebecf0
}

.stats-gadget tbody td {
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,'Fira Sans','Droid Sans','Helvetica Neue',sans-serif;
    color: #172b4d;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 20px
}

.stats-gadget tbody:last-child>td,.stats-gadget tbody:last-child>th {
    padding: 7px
}

.favourite-filters-item {
    padding: 0
}

.favourite-filters-item .favourite-filters-view {
    border-top: 2px solid #dfe1e6
}

.favourite-filters-item tbody>tr:first-child td {
    border-top-width: 1px
}

.favourite-filters-item .config-options,.favourite-filters-item .empty-container {
    padding: 6px 0 0 7px
}

.favourite-filters-item .config-options .operations-list .aui-button:first-child,.favourite-filters-item .config-options .operations-list li:first-child,.favourite-filters-item .config-options>div .aui-button:first-child,.favourite-filters-item .config-options>div li:first-child,.favourite-filters-item .empty-container .operations-list .aui-button:first-child,.favourite-filters-item .empty-container .operations-list li:first-child,.favourite-filters-item .empty-container>div .aui-button:first-child,.favourite-filters-item .empty-container>div li:first-child {
    display: none
}

.favourite-filters-item .config-options .operations-list .aui-button:nth-child(2),.favourite-filters-item .config-options>div .aui-button:nth-child(2),.favourite-filters-item .empty-container .operations-list .aui-button:nth-child(2),.favourite-filters-item .empty-container>div .aui-button:nth-child(2) {
    margin-left: 0;
    padding-left: 0
}

.favourite-filters-item .config-options .operations-list a,.favourite-filters-item .config-options>div a,.favourite-filters-item .empty-container .operations-list a,.favourite-filters-item .empty-container>div a {
    color: #0052cc;
    text-decoration: none;
    color: #5e6c84
}

.favourite-filters-item .config-options .operations-list a:focus,.favourite-filters-item .config-options .operations-list a:hover,.favourite-filters-item .config-options>div a:focus,.favourite-filters-item .config-options>div a:hover,.favourite-filters-item .empty-container .operations-list a:focus,.favourite-filters-item .empty-container .operations-list a:hover,.favourite-filters-item .empty-container>div a:focus,.favourite-filters-item .empty-container>div a:hover {
    color: #0065ff;
    text-decoration: underline
}

.favourite-filters-item .config-options .operations-list a:active,.favourite-filters-item .config-options>div a:active,.favourite-filters-item .empty-container .operations-list a:active,.favourite-filters-item .empty-container>div a:active {
    color: #0049b0;
    text-decoration: underline
}

.createdvsresolved-gadget {
    padding: 0
}

.createdvsresolved-gadget a {
    color: #0052cc;
    text-decoration: none;
    color: #5e6c84
}

.createdvsresolved-gadget a:focus,.createdvsresolved-gadget a:hover {
    color: #0065ff;
    text-decoration: underline
}

.createdvsresolved-gadget a:active {
    color: #0049b0;
    text-decoration: underline
}

.createdvsresolved-gadget .createdvsresolved-chart text {
    fill: #091e42
}

.createdvsresolved-gadget .createdvsresolved-chart .grid line,.createdvsresolved-gadget .createdvsresolved-chart .versions line {
    stroke: #dfe1e6
}

.createdvsresolved-gadget .createdvsresolved-chart .created path,.createdvsresolved-gadget .createdvsresolved-chart .created-points circle {
    stroke: #ff5630
}

.createdvsresolved-gadget .createdvsresolved-chart .resolved path,.createdvsresolved-gadget .createdvsresolved-chart .resolved-points circle {
    stroke: #36b37e
}

.createdvsresolved-gadget .createdvsresolved-chart-info {
    padding-left: 7px
}

.createdvsresolved-gadget .createdvsresolved-chart-details-link {
    display: block;
    margin-left: 0
}

.createdvsresolved-gadget .createdvsresolved-chart-details-grouping {
    margin-left: 0
}

.createdvsresolved-gadget .createdvsresolved-chart-details-totals .created-totals svg {
    padding-bottom: 2px
}

.createdvsresolved-gadget .createdvsresolved-chart-details-totals .created-totals svg circle {
    stroke: #ff5630
}

.createdvsresolved-gadget .createdvsresolved-chart-details-totals .resolved-totals svg {
    padding-bottom: 2px
}

.createdvsresolved-gadget .createdvsresolved-chart-details-totals .resolved-totals svg circle {
    stroke: #36b37e
}`;
    const $c5037e48649c048f$export$17921465e6f60e58 = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement("style", null, $c5037e48649c048f$var$styles);
});
parcelRegister("fxC6K", function(module, exports) {
    $parcel$export(module.exports, "DASHBOARD_PERMISSION_ERROR_CODE_LIST", ()=>$525e26e500bd2620$export$f33202be19a062b9);
    const $525e26e500bd2620$export$f33202be19a062b9 = [
        401,
        403,
        404
    ];
});
parcelRegister("kUwqK", function(module, exports) {
    $parcel$export(module.exports, "usePageStatusLogger", ()=>$994ab79b91cbaa2e$export$8b3b053e4f10e669);
    var $5uXOq = parcelRequire("5uXOq");
    var $dJl1T;
    var $bVDE5;
    var $bOnpg;
    var $7cLxq;
    const $994ab79b91cbaa2e$var$TEN_MIN = 600000;
    const $994ab79b91cbaa2e$var$sanitiseKeys = (featureFlags)=>Object.fromEntries(Object.entries(featureFlags || {}).map(([key, value])=>[
                key.replace(/\./g, '_'),
                value
            ]));
    const $994ab79b91cbaa2e$export$8b3b053e4f10e669 = (interval)=>{
        const fireAnalytics = (0, (0, parcelRequire("bOnpg")).useDashboardAnalytics)();
        const [{ isInitialRender: isInitialRender, currentPageId: currentPageId, lastTransition: lastTransition }] = (0, (0, parcelRequire("bVDE5")).useSpaStateTransition)();
        (0, $5uXOq.useEffect)(()=>{
            const logFeatureFlags = ()=>{
                const featureFlags = JSON.stringify($994ab79b91cbaa2e$var$sanitiseKeys((0, (0, parcelRequire("7cLxq")).getDashboardFeatureFlags)()));
                const attributes = {
                    isInitialRender: isInitialRender,
                    lastTransition: lastTransition,
                    currentPageId: currentPageId,
                    featureFlags: featureFlags
                };
                (0, (0, parcelRequire("dJl1T")).default).safeInfoWithoutCustomerData('spa-apps.dashboard.page-status', Object.entries(attributes).map(([k, v])=>`${k} [${String(v ?? null)}]`).join(' '));
                fireAnalytics({
                    eventType: (0, $4d5e0871c06cee03$export$12346a674644c156),
                    actionSubject: 'dashboard.page-status',
                    actionSubjectId: 'featureFlags',
                    action: 'logged',
                    attributes: attributes
                });
            };
            logFeatureFlags();
            const logInterval = setInterval(logFeatureFlags, Math.max($994ab79b91cbaa2e$var$TEN_MIN, interval) || $994ab79b91cbaa2e$var$TEN_MIN);
            return ()=>clearInterval(logInterval);
        }, [
            interval,
            fireAnalytics,
            isInitialRender,
            currentPageId,
            lastTransition
        ]);
    };
});
parcelRegister("hwuTz", function(module, exports) {
    $parcel$export(module.exports, "RouteResourceValidator", ()=>$79eff52e66ba4eb8$export$1d8835c03b47dfbd);
    var $5uXOq = parcelRequire("5uXOq");
    var $dJl1T;
    var $gh1hi;
    var $bVDE5;
    const $79eff52e66ba4eb8$export$1d8835c03b47dfbd = ()=>{
        const [{ isInitialRender: isInitialRender }] = (0, (0, parcelRequire("bVDE5")).useSpaStateTransition)();
        const { promise: promise, loading: loading, data: data, error: error } = (0, (0, parcelRequire("gh1hi")).useDashboardAMDModulesResource)();
        const [hasRendered, setHasRendered] = (0, $5uXOq.useState)(false);
        (0, $5uXOq.useEffect)(()=>{
            if (!loading) setHasRendered(true);
        }, [
            loading
        ]);
        (0, $5uXOq.useEffect)(()=>{
            if (false) return;
            const prefix = `Resource validation [${isInitialRender ? 'initial' : 'transition'}][${hasRendered ? 'rerender' : 'original'}]`;
            if (!promise) (0, (0, parcelRequire("dJl1T")).default).safeErrorWithoutCustomerData('spa-apps.dashboard.route-resource-validate', `${prefix} failed, AMD resource may be missing`);
            else if (loading) (0, (0, parcelRequire("dJl1T")).default).safeInfoWithoutCustomerData('spa-apps.dashboard.route-resource-validate', `${prefix} loading`);
            else if (data) {
                const { amdModules: amdModules, sharedValue: sharedValue } = data;
                if (sharedValue == null || sharedValue instanceof Error) (0, (0, parcelRequire("dJl1T")).default).safeErrorWithoutCustomerData('spa-apps.dashboard.route-resource-validate', `${prefix} failed, AMD resource shared value [${String(sharedValue)}]`);
                else if (!sharedValue?.isPreloaded && isInitialRender && !hasRendered) (0, (0, parcelRequire("dJl1T")).default).safeErrorWithoutCustomerData('spa-apps.dashboard.route-resource-validate', `${prefix} failed, config+wrm resource did not run in SSR`);
                else if (amdModules === null) (0, (0, parcelRequire("dJl1T")).default).safeErrorWithoutCustomerData('spa-apps.dashboard.route-resource-validate', `${prefix} failed, WRM resources failed to load`);
                else {
                    const [failedCount, totalCount] = Object.values(amdModules).reduce(([f], v, i, arr)=>[
                            f + (v == null ? 1 : 0),
                            arr.length
                        ], [
                        0,
                        0
                    ]);
                    if (failedCount > 0) {
                        const failedNames = Object.entries(amdModules).filter(([, v])=>v == null).map(([k])=>k).join(',');
                        (0, (0, parcelRequire("dJl1T")).default).safeErrorWithoutCustomerData('spa-apps.dashboard.route-resource-validate', `${prefix} failed, AMD evaluation failed [${failedCount}] of [${totalCount}], failed modules [${failedNames}]`);
                    } else (0, (0, parcelRequire("dJl1T")).default).safeInfoWithoutCustomerData('spa-apps.dashboard.route-resource-validate', `${prefix} success`);
                }
            } else if (error) (0, (0, parcelRequire("dJl1T")).default).safeErrorWithoutCustomerData('spa-apps.dashboard.route-resource-validate', `${prefix} failed with AMD resource error [${error instanceof Error ? error.message : String(error)}]`, error);
        }, [
            promise,
            loading,
            data,
            error
        ]);
        return null;
    };
});
parcelRegister("h7J1E", function(module, exports) {
    $parcel$export(module.exports, "useGadgetTitle", ()=>$9f983f20098b68ef$export$dba832fc7432cba1);
    $parcel$export(module.exports, "useConnectGadgetTitles", ()=>$9f983f20098b68ef$export$66e677fcbdac9c95);
    var $5uXOq = parcelRequire("5uXOq");
    function $9f983f20098b68ef$export$dba832fc7432cba1({ defaultTitle: initialDefaultTitle, title: title, onSave: onSave }) {
        const [defaultTitle, setDefaultTitle] = (0, $5uXOq.useState)(initialDefaultTitle);
        const [userTitle, setUserTitle] = (0, $5uXOq.useState)(title);
        const setTitle = (0, $5uXOq.useCallback)(async (newTitle)=>{
            const previousTitle = userTitle;
            setUserTitle(newTitle);
            onSave?.(newTitle).catch(()=>{
                setUserTitle(previousTitle);
            });
        }, [
            userTitle,
            onSave
        ]);
        const setDefaultTitleWithInitialTitle = (0, $5uXOq.useCallback)((defaultTitleUpdaterOrValue)=>{
            setDefaultTitle(typeof defaultTitleUpdaterOrValue === 'function' ? defaultTitleUpdaterOrValue(initialDefaultTitle) : defaultTitleUpdaterOrValue);
        }, [
            initialDefaultTitle
        ]);
        return {
            title: userTitle || defaultTitle,
            setDefaultTitle: setDefaultTitleWithInitialTitle,
            setTitle: setTitle
        };
    }
    const $9f983f20098b68ef$export$66e677fcbdac9c95 = ()=>{
        const [connectGadgets, setConnectGadgets] = (0, $5uXOq.useState)({});
        const registerConnectGadget = (0, $5uXOq.useCallback)((id, setTitle)=>{
            setConnectGadgets((gadgets)=>({
                    ...gadgets,
                    [id]: setTitle
                }));
        }, []);
        const unregisterConnectGadget = (0, $5uXOq.useCallback)((id)=>{
            setConnectGadgets((gadgets)=>{
                const { [id]: _, ...rest } = gadgets;
                return rest;
            });
        }, []);
        const setCustomTitle = (0, $5uXOq.useCallback)((id, title)=>{
            const setTitle = connectGadgets[id];
            if (!setTitle) throw new Error(`Unable to set custom title on Connect gadget. No gadget with id ${id} found. Available ids: ${Object.keys(connectGadgets).join(', ')}`);
            return setTitle(title);
        }, [
            connectGadgets
        ]);
        return {
            registerConnectGadget: registerConnectGadget,
            setCustomTitle: setCustomTitle,
            unregisterConnectGadget: unregisterConnectGadget
        };
    };
});
parcelRegister("52i38", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$eb8d5d132a6208ab$export$2e2bcd8739ae039);
    var $7VHMR;
    var $eb8d5d132a6208ab$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        pageTitle: {
            "id": "dashboard-view.page-title",
            "defaultMessage": "Dashboard"
        }
    });
});
parcelRegister("fPA7s", function(module, exports) {
    $parcel$export(module.exports, "EmptyDashboardPlaceholder", ()=>$a47d68a6c07cc5b5$export$8f129bf59f671c93);
    var $5uXOq = parcelRequire("5uXOq");
    var $1dJ6S;
    var $dC5iT;
    var $8CUq3;
    var $kNt2r;
    var $erLBt;
    var $koVbs;
    var $iht5S;
    const $a47d68a6c07cc5b5$var$TOOLBOX_ILLUSTRATION_SIZE = '256px';
    const $a47d68a6c07cc5b5$export$8f129bf59f671c93 = ({ hasEditPermissions: hasEditPermissions })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("kNt2r")).EmptyDashboardPlaceholderContainer), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("kNt2r")).EmptyDashboardPlaceholderContentContainer), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            xcss: $a47d68a6c07cc5b5$var$boxStyle
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("erLBt")).default), {
            alt: "",
            "data-testId": "empty-dashboard-img-placeholder"
        })), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1dJ6S")).default), {
            size: "large"
        }, formatMessage((0, (0, parcelRequire("iht5S")).default).header)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("kNt2r")).EmptyDashboardPlaceholderText), null, hasEditPermissions ? formatMessage((0, (0, parcelRequire("iht5S")).default).clickEditButton) : formatMessage((0, (0, parcelRequire("iht5S")).default).noPermissions))));
    };
    const $a47d68a6c07cc5b5$var$boxStyle = (0, (0, parcelRequire("8CUq3")).xcss)({
        width: $a47d68a6c07cc5b5$var$TOOLBOX_ILLUSTRATION_SIZE,
        height: $a47d68a6c07cc5b5$var$TOOLBOX_ILLUSTRATION_SIZE,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    });
});
parcelRegister("kNt2r", function(module, exports) {
    $parcel$export(module.exports, "EmptyDashboardPlaceholderContainer", ()=>$b08803d657ecb6fa$export$6186d9ab34f7c892);
    $parcel$export(module.exports, "EmptyDashboardPlaceholderContentContainer", ()=>$b08803d657ecb6fa$export$6d5d2ac6e84d3c53);
    $parcel$export(module.exports, "EmptyDashboardPlaceholderText", ()=>$b08803d657ecb6fa$export$b65f3ce3834a21ea);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $lD23V;
    var $44v1T;
    const $b08803d657ecb6fa$export$6186d9ab34f7c892 = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: {
                ...__cmpls,
                "--_1s93bfy": (0, (0, parcelRequire("lD23V")).default)((0, (0, parcelRequire("44v1T")).PAGE_LAYOUT_OFFSET_TOP))
            },
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _1bah1h6o _4cvr1h6o _4t3inb68",
                __cmplp.className
            ])
        });
    });
    const $b08803d657ecb6fa$export$6d5d2ac6e84d3c53 = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _1bsb1kvu _p12f1vuk _4cvr1h6o _2lx21bp4",
                __cmplp.className
            ])
        });
    });
    const $b08803d657ecb6fa$export$b65f3ce3834a21ea = (0, $5uXOq.forwardRef)(({ as: C = "p", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_ca0qpxbi _19pkidpf _2hwxidpf _otyridpf _18u0idpf _y3gn1h6o",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("erLBt", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$bbbf00366986f0f4$export$2e2bcd8739ae039);
    var $dCLgq;
    var $8EX0V = parcelRequire("8EX0V");
    const $bbbf00366986f0f4$var$Illustration = (0, (0, parcelRequire("dCLgq")).createIllustration)({
        srcLight: ($parcel$interopDefault($8EX0V)),
        srcDark: ($parcel$interopDefault($8EX0V))
    });
    var $bbbf00366986f0f4$export$2e2bcd8739ae039 = $bbbf00366986f0f4$var$Illustration;
    const $bbbf00366986f0f4$export$a179c88182cb9d56 = [
        0,
        0,
        241,
        189
    ];
});
parcelRegister("8EX0V", function(module, exports) {
    module.exports = new URL("toolbox.b560c410.svg", import.meta.url).toString();
});
parcelRegister("iht5S", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$7e18658aa66f7fde$export$2e2bcd8739ae039);
    var $7VHMR;
    var $7e18658aa66f7fde$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        header: {
            "id": "dashboard-view.empty-dashboard-placeholder.header",
            "defaultMessage": "This dashboard is empty"
        },
        clickEditButton: {
            "id": "dashboard-view.empty-dashboard-placeholder.click-edit-button",
            "defaultMessage": "You can add to this dashboard by clicking the \u2018Edit\u2019 button on the top right corner."
        },
        noPermissions: {
            "id": "dashboard-view.empty-dashboard-placeholder.no-permissions",
            "defaultMessage": "You don't have permissions to add to this dashboard. You may want to create your own dashboard instead, by selecting Dashboards > Create Dashboard in the top navigation bar."
        }
    });
});
parcelRegister("cOK0t", function(module, exports) {
    $parcel$export(module.exports, "GadgetReadOnly", ()=>$acfbb20e5a1435f8$export$4bbe38d7c1a57cd0);
    var $5uXOq = parcelRequire("5uXOq");
    var $dC5iT;
    var $kgoHi;
    var $8CUq3;
    var $JIPqS;
    var $5T44u;
    var $eXVsp;
    var $hGdg6;
    var $bOnpg;
    var $6Nisr;
    var $8FVKN;
    var $lwPtc;
    var $4ozF0;
    var $j3C3s;
    var $gFWXX;
    var $1yhBH;
    var $iIi7d;
    var $cmgca;
    var $4R6GH;
    const $acfbb20e5a1435f8$var$noop = ()=>undefined;
    const $acfbb20e5a1435f8$export$4bbe38d7c1a57cd0 = ({ dashboardId: dashboardId, data: data, display: display, unconfiguredFallback: unconfiguredFallback, refreshId: refreshId, onRegisterConnectGadget: onRegisterConnectGadget, onUnregisterConnectGadget: onUnregisterConnectGadget, onMaximizeOrRestore: onMaximizeOrRestore })=>{
        const { id: id, isMaximizable: isMaximizable, titleUrl: titleUrl, forge: forge } = data;
        const isFirstPartyGadget = !(0, (0, parcelRequire("cmgca")).isConnectGadget)(data) && !forge;
        const fireAnalytics = (0, (0, parcelRequire("bOnpg")).useDashboardAnalytics)();
        const onCopyLinkHandler = (0, $5uXOq.useCallback)(()=>{
            fireAnalytics({
                eventType: (0, $4d5e0871c06cee03$export$a1562d08625f5d7e),
                action: 'clicked',
                actionSubject: 'dashboard.copy-link-button',
                actionSubjectId: 'gadget'
            });
        }, [
            fireAnalytics
        ]);
        const isDeprecated = (0, (0, parcelRequire("hGdg6")).isDeprecatedGadget)(data.amdModule);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6Nisr")).GadgetReadOnlyController), {
            data: data,
            display: display,
            dashboardRefreshId: refreshId
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("8FVKN")).GadgetControllerContextConsumer), null, ({ isDisplayed: isDisplayed, isMaximized: isMaximized, isMinimized: isMinimized, onMinimizeOrRestore: onMinimizeOrRestore, shouldUseCache: shouldUseCache, isLoading: isLoading, setIsLoading: setIsLoading, lastRefreshed: lastRefreshed, isUnconfigured: isUnconfigured, title: title, setDefaultTitle: setDefaultTitle, onRenameSave: onRenameSave })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4ozF0")).GadgetLayoutWithAnalytics), {
                gadgetData: data,
                isDisplayed: isDisplayed,
                isContentHidden: isMinimized,
                header: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("kgoHi")).default), {
                    alignItems: "center"
                }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("j3C3s")).GadgetTitleWrapper), {
                    id: `gadget-title-${id}`
                }, isDeprecated && (0, (0, parcelRequire("4R6GH")).fg)('endeavour_gadget_deprecation_banner_v1') ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
                    xcss: $acfbb20e5a1435f8$var$titleStyles
                }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gFWXX")).TitleReadOnly), {
                    title: title,
                    titleUrl: titleUrl,
                    forgeEnvironment: forge?.environment,
                    forgeEnvironmentKey: forge?.environmentKey
                }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("eXVsp")).DeprecationTooltip), null)) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gFWXX")).TitleReadOnly), {
                    title: title,
                    titleUrl: titleUrl,
                    forgeEnvironment: forge?.environment,
                    forgeEnvironmentKey: forge?.environmentKey
                })), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1yhBH")).GadgetToolbar), {
                    gadgetId: id,
                    title: title,
                    onMinimizeOrRestore: onMinimizeOrRestore,
                    isMinimized: isMinimized,
                    isMaximizable: isMaximizable,
                    onMaximizeOrRestore: onMaximizeOrRestore,
                    isMaximized: isMaximized,
                    additionalToolbarItems: isMaximizable && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5T44u")).CopyLinkButton), {
                        dashboardId: dashboardId,
                        gadgetId: id,
                        onCopyClick: onCopyLinkHandler
                    })
                })),
                footer: isFirstPartyGadget && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iIi7d")).LastRefreshed), {
                    lastRefreshed: lastRefreshed,
                    updateInterval: (0, (0, parcelRequire("JIPqS")).REFRESH_LABEL_UPDATE_INTERVAL)
                })
            }, (0, ($parcel$interopDefault($5uXOq))).createElement("div", {
                "data-testid": "dashboard-view.ui.gadget-readonly.gadget-wrapper"
            }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lwPtc")).GadgetInner), {
                dashboardId: dashboardId,
                data: data,
                isUnconfigured: isUnconfigured,
                isEditModeEnabled: false,
                isLoading: isLoading,
                isInEditMode: false,
                shouldUseCache: shouldUseCache,
                setIsLoading: setIsLoading,
                setDefaultTitle: setDefaultTitle,
                onEditModeCancel: $acfbb20e5a1435f8$var$noop,
                isMaximized: isMaximized,
                unconfiguredFallback: unconfiguredFallback,
                onRegisterConnectGadget: onRegisterConnectGadget,
                onUnregisterConnectGadget: onUnregisterConnectGadget,
                setTitle: onRenameSave
            })))));
    };
    const $acfbb20e5a1435f8$var$titleStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        display: 'flex',
        flexDirection: 'row'
    });
});
parcelRegister("5T44u", function(module, exports) {
    $parcel$export(module.exports, "CopyLinkButton", ()=>$b0246b66f763c5eb$export$534d10f1c5b9c7b3);
    var $5uXOq = parcelRequire("5uXOq");
    var $fVnbM;
    var $458iJ;
    var $b6nbP;
    var $koVbs;
    var $JIPqS;
    var $g15xx;
    const $b0246b66f763c5eb$export$534d10f1c5b9c7b3 = ({ dashboardId: dashboardId, gadgetId: gadgetId, onCopyClick: onCopyClick })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const [isCopied, setIsCopied] = (0, $5uXOq.useState)(false);
        if (false) return;
        const label = formatMessage((0, (0, parcelRequire("g15xx")).default).copyTextLabel);
        const tooltip = isCopied ? formatMessage((0, (0, parcelRequire("g15xx")).default).copiedLabel) : label;
        const id = gadgetId ? `${dashboardId}-${gadgetId}` : dashboardId;
        const { protocol: protocol, host: host, pathname: pathname } = window.location;
        const url = gadgetId ? `${protocol}//${host}${pathname}?${0, (0, parcelRequire("JIPqS")).MAXIMIZED_PARAM}=${gadgetId}` : `${protocol}//${host}${pathname}`;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("b6nbP")).default), {
            id: id,
            label: label,
            shouldFitContainer: true,
            tooltipDelay: 0,
            tooltipLabel: tooltip,
            tooltipLabelPosition: "bottom",
            value: url,
            hideFieldText: true,
            onCopyClick: onCopyClick,
            onClickChange: (status)=>setIsCopied(status),
            onCopyReset: ()=>setIsCopied(false)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
            appearance: "subtle",
            "aria-label": label,
            iconBefore: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("458iJ"))))), {
                label: label
            })
        }));
    };
});
parcelRegister("458iJ", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $2f8e117d74b4de46$var$_react = $2f8e117d74b4de46$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $c7fbr;
    var $2f8e117d74b4de46$var$_link = $2f8e117d74b4de46$var$_interopRequireDefault((parcelRequire("1f9bV")));
    function $2f8e117d74b4de46$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $2f8e117d74b4de46$var$LinkIcon = (props)=>$2f8e117d74b4de46$var$_react.default.createElement((0, parcelRequire("c7fbr")).IconFacade, Object.assign({
            dangerouslySetGlyph: `<svg width="24" height="24" viewBox="0 0 24 24" role="presentation"><g fill="currentcolor" fill-rule="evenodd"><path d="m12.856 5.457-.937.92a1 1 0 0 0 0 1.437 1.047 1.047 0 0 0 1.463 0l.984-.966c.967-.95 2.542-1.135 3.602-.288a2.54 2.54 0 0 1 .203 3.81l-2.903 2.852a2.646 2.646 0 0 1-3.696 0l-1.11-1.09L9 13.57l1.108 1.089c1.822 1.788 4.802 1.788 6.622 0l2.905-2.852a4.558 4.558 0 0 0-.357-6.82c-1.893-1.517-4.695-1.226-6.422.47"/><path d="m11.144 19.543.937-.92a1 1 0 0 0 0-1.437 1.047 1.047 0 0 0-1.462 0l-.985.966c-.967.95-2.542 1.135-3.602.288a2.54 2.54 0 0 1-.203-3.81l2.903-2.852a2.646 2.646 0 0 1 3.696 0l1.11 1.09L15 11.43l-1.108-1.089c-1.822-1.788-4.802-1.788-6.622 0l-2.905 2.852a4.558 4.558 0 0 0 .357 6.82c1.893 1.517 4.695 1.226 6.422-.47"/></g></svg>`
        }, props, {
            newIcon: $2f8e117d74b4de46$var$_link.default
        }));
    $2f8e117d74b4de46$var$LinkIcon.displayName = 'LinkIcon';
    var $2f8e117d74b4de46$var$_default = module.exports.default = $2f8e117d74b4de46$var$LinkIcon;
});
parcelRegister("1f9bV", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $0e7e525cdad15340$var$_react = $0e7e525cdad15340$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $0e7e525cdad15340$var$_UNSAFE_baseNew = $0e7e525cdad15340$var$_interopRequireDefault((parcelRequire("fEaCe")));
    function $0e7e525cdad15340$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $0e7e525cdad15340$var$LinkIcon = (props)=>$0e7e525cdad15340$var$_react.default.createElement($0e7e525cdad15340$var$_UNSAFE_baseNew.default, Object.assign({
            dangerouslySetGlyph: `<path stroke="currentcolor" stroke-width="1.5" d="m5.25 10.75 5.5-5.5M5 6.5 2.75 8.75a3.182 3.182 0 0 0 4.5 4.5L9.5 11m-3-6 2.25-2.25a3.182 3.182 0 0 1 4.5 4.5L11 9.5"/>`
        }, props));
    $0e7e525cdad15340$var$LinkIcon.displayName = 'LinkIcon';
    var $0e7e525cdad15340$var$_default = module.exports.default = $0e7e525cdad15340$var$LinkIcon;
});
parcelRegister("b6nbP", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$f75a19e3ebf5c6c9$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $7EEmi;
    class $f75a19e3ebf5c6c9$export$2e2bcd8739ae039 extends (0, $5uXOq.Component) {
        static{
            this.defaultProps = {
                className: undefined,
                shouldFitContainer: false,
                hideFieldText: false,
                onCopyClick: undefined,
                onCopyReset: undefined,
                tooltipDelay: undefined,
                remountOnChange: false,
                tooltipDisabled: false
            };
        }
        componentWillUnmount() {
            clearTimeout(this.timeoutId);
        }
        render() {
            const { id: id, label: label, tooltipLabel: tooltipLabel, tooltipLabelPosition: tooltipLabelPosition, tooltipDelay: tooltipDelay, remountOnChange: remountOnChange, value: value, className: className, shouldFitContainer: shouldFitContainer, hideFieldText: hideFieldText, tooltipDisabled: tooltipDisabled } = this.props;
            const fieldValue = hideFieldText ? '' : value;
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7EEmi")).default), {
                id: id,
                label: label,
                tooltipLabel: tooltipLabel,
                tooltipLabelPosition: tooltipLabelPosition,
                tooltipDelay: tooltipDelay,
                remountOnChange: remountOnChange,
                fieldKey: value,
                value: fieldValue,
                className: className,
                shouldFitContainer: shouldFitContainer,
                hideFieldText: hideFieldText,
                ...this.state,
                onCopyClick: this.onCopyClick,
                innerInputRef: this.onRef,
                tooltipDisabled: tooltipDisabled
            }, this.props.children);
        }
        constructor(...args){
            super(...args);
            this.state = {
                copied: false,
                copyFailed: false
            };
            this.onCopyClick = (event, analyticsEvent)=>{
                if (!this.inputTextRef || !this.inputTextRef.current) return;
                this.props.onCopyClick && this.props.onCopyClick(event, analyticsEvent);
                if (this.props.hideFieldText) this.inputTextRef.current.value = this.props.value;
                this.inputTextRef.current.select();
                let success = false;
                try {
                    success = document.execCommand('copy');
                    this.setState({
                        copied: true
                    });
                    this.props.onClickChange && this.props.onClickChange(true);
                } catch (e) {
                    success = false;
                }
                if (!success) this.setState({
                    copyFailed: true
                });
                this.timeoutId = setTimeout(()=>{
                    this.setState({
                        copied: false,
                        copyFailed: false
                    });
                    this.props.onCopyReset && this.props.onCopyReset();
                }, 3000);
                if (this.props.onCopyFinish) this.props.onCopyFinish();
            };
            this.onRef = (ref)=>{
                this.inputTextRef = ref;
            };
        }
    }
});
parcelRegister("7EEmi", function(module, exports) {
    $parcel$export(module.exports, "StyledButton", ()=>$4e1842edd397110a$export$2d5d2ba3c7c8c40b);
    $parcel$export(module.exports, "default", ()=>$4e1842edd397110a$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $3Fq5M;
    var $5ILhZ;
    var $8CUq3;
    var $kY99m;
    var $3bDjY;
    var $fVAPR;
    var $az4zD;
    var $gT8ZY;
    var $9iXoh;
    class $4e1842edd397110a$export$71579edc8f362e35 extends (0, $5uXOq.Component) {
        static{
            this.defaultProps = {
                id: (0, $68788ede79b59f1f$export$4f0520e0d7c4c7b0),
                className: undefined,
                copyFailed: false,
                shouldFitContainer: false,
                hideFieldText: false,
                tooltipDelay: 100,
                remountOnChange: false
            };
        }
        constructor(props){
            super(props);
            this.textInput = (0, $5uXOq.createRef)();
            this.props.innerInputRef(this.textInput);
        }
        renderDefaultButton() {
            const { tooltipLabel: tooltipLabel, onCopyClick: onCopyClick } = this.props;
            return (0, ($parcel$interopDefault($5uXOq))).createElement($4e1842edd397110a$export$2d5d2ba3c7c8c40b, {
                onClick: onCopyClick
            }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("3Fq5M"))))), {
                color: "currentColor",
                label: tooltipLabel
            }));
        }
        render() {
            const { id: id, fieldKey: fieldKey, value: value, label: label, tooltipLabel: tooltipLabel, tooltipLabelPosition: tooltipLabelPosition, tooltipDelay: tooltipDelay, remountOnChange: remountOnChange, intl: intl, copied: copied, onCopyClick: onCopyClick, shouldFitContainer: shouldFitContainer, copyFailed: copyFailed, hideFieldText: hideFieldText, tooltipDisabled: tooltipDisabled, ...props } = this.props;
            let actualTooltipLabel = tooltipLabel;
            if (copied) actualTooltipLabel = intl.formatMessage((0, (0, parcelRequire("gT8ZY")).default).copied);
            else if (copyFailed) actualTooltipLabel = intl.formatMessage((0, (0, parcelRequire("gT8ZY")).default).copyFailed);
            const copyButton = !this.props.children ? null : (0, $5uXOq.cloneElement)((0, $5uXOq.Children).only(this.props.children), {
                onClick: onCopyClick
            });
            return ((0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9iXoh")).Root), props, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9iXoh")).FieldContainer), {
                shouldFitContainer: shouldFitContainer,
                hideFieldText: hideFieldText
            }, label && !hideFieldText && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVAPR")).FieldLabel), {
                htmlFor: (0, $68788ede79b59f1f$export$4f0520e0d7c4c7b0),
                label: label
            }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("kY99m")).default), {
                "aria-hidden": hideFieldText ? 'true' : undefined,
                key: fieldKey,
                id: id,
                name: (0, $68788ede79b59f1f$export$4f0520e0d7c4c7b0),
                label: label,
                value: value,
                isReadOnly: true,
                ref: this.textInput,
                tabIndex: hideFieldText ? -1 : undefined
            })), tooltipDisabled ? (0, ($parcel$interopDefault($5uXOq))).createElement($4e1842edd397110a$var$FlexGrow, null, copyButton || this.renderDefaultButton()) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3bDjY")).default), {
                tag: "span",
                key: remountOnChange ? actualTooltipLabel : undefined,
                delay: tooltipDelay,
                position: tooltipLabelPosition,
                content: (0, ($parcel$interopDefault($5uXOq))).createElement("span", {
                    "aria-live": "assertive"
                }, actualTooltipLabel)
            }, copyButton || this.renderDefaultButton())));
        }
    }
    var $4e1842edd397110a$export$2e2bcd8739ae039 = (0, (0, parcelRequire("az4zD")).injectIntlV2)($4e1842edd397110a$export$71579edc8f362e35);
    const $4e1842edd397110a$export$2d5d2ba3c7c8c40b = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5ILhZ")).default), {
            ...props,
            xcss: $4e1842edd397110a$var$pressableStyles
        });
    $4e1842edd397110a$export$2d5d2ba3c7c8c40b.displayName = 'StyledButton';
    const $4e1842edd397110a$var$FlexGrow = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_16jlkb7n",
                __cmplp.className
            ])
        });
    });
    const $4e1842edd397110a$var$pressableStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        height: '40px',
        width: '40px',
        borderRadius: "var(--ds-border-radius, 3px)",
        ':hover': {
            background: "var(--ds-background-neutral-hovered, #091E4224)"
        },
        ':active': {
            background: "var(--ds-background-neutral-pressed, #091E424F)"
        }
    });
});
parcelRegister("3Fq5M", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $2ab970d264bef26f$var$_react = $2ab970d264bef26f$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $2ab970d264bef26f$var$_copy = $2ab970d264bef26f$var$_interopRequireDefault((parcelRequire("8w53e")));
    var $2ab970d264bef26f$var$_copy2 = $2ab970d264bef26f$var$_interopRequireDefault((parcelRequire("eE13R")));
    function $2ab970d264bef26f$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $2ab970d264bef26f$var$CopyIcon = (props)=>$2ab970d264bef26f$var$_react.default.createElement($2ab970d264bef26f$var$_copy.default, Object.assign({
            LEGACY_fallbackIcon: $2ab970d264bef26f$var$_copy2.default
        }, props));
    $2ab970d264bef26f$var$CopyIcon.Name = 'CopyIconMigration';
    var $2ab970d264bef26f$var$_default = module.exports.default = $2ab970d264bef26f$var$CopyIcon;
});
parcelRegister("8w53e", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $63353af41c1756be$var$_react = $63353af41c1756be$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $63353af41c1756be$var$_UNSAFE_baseNew = $63353af41c1756be$var$_interopRequireDefault((parcelRequire("fEaCe")));
    function $63353af41c1756be$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $63353af41c1756be$var$CopyIcon = (props)=>$63353af41c1756be$var$_react.default.createElement($63353af41c1756be$var$_UNSAFE_baseNew.default, Object.assign({
            dangerouslySetGlyph: `<path stroke="currentcolor" stroke-width="1.5" d="M1.75 3c0-.69.56-1.25 1.25-1.25h8c.69 0 1.25.56 1.25 1.25v8c0 .69-.56 1.25-1.25 1.25H3c-.69 0-1.25-.56-1.25-1.25z"/><path stroke="currentcolor" stroke-width="1.5" d="M6 15.25h6.75a2.5 2.5 0 0 0 2.5-2.5V6"/>`
        }, props));
    $63353af41c1756be$var$CopyIcon.displayName = 'CopyIcon';
    var $63353af41c1756be$var$_default = module.exports.default = $63353af41c1756be$var$CopyIcon;
});
parcelRegister("eE13R", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $aa959fe1f5a86e24$var$_react = $aa959fe1f5a86e24$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $c7fbr;
    var $aa959fe1f5a86e24$var$_copy = $aa959fe1f5a86e24$var$_interopRequireDefault((parcelRequire("8w53e")));
    function $aa959fe1f5a86e24$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $aa959fe1f5a86e24$var$CopyIcon = (props)=>$aa959fe1f5a86e24$var$_react.default.createElement((0, parcelRequire("c7fbr")).IconFacade, Object.assign({
            dangerouslySetGlyph: `<svg width="24" height="24" viewBox="0 0 24 24" role="presentation"><g fill="currentcolor"><path d="M10 19h8V8h-8zM8 7.992C8 6.892 8.902 6 10.009 6h7.982C19.101 6 20 6.893 20 7.992v11.016c0 1.1-.902 1.992-2.009 1.992H10.01A2 2 0 0 1 8 19.008z"/><path d="M5 16V4.992C5 3.892 5.902 3 7.009 3H15v13zm2 0h8V5H7z"/></g></svg>`
        }, props, {
            newIcon: $aa959fe1f5a86e24$var$_copy.default
        }));
    $aa959fe1f5a86e24$var$CopyIcon.displayName = 'CopyIcon';
    var $aa959fe1f5a86e24$var$_default = module.exports.default = $aa959fe1f5a86e24$var$CopyIcon;
});
parcelRegister("kY99m", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$509076ff0c6afa78$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $f8Sb3;
    var $6QvkM;
    var $hbrbl;
    const $509076ff0c6afa78$var$analyticsParams = {
        componentName: 'textField',
        packageName: "platform-pkg",
        packageVersion: "0.0.0-use.local"
    };
    const $509076ff0c6afa78$var$Textfield = (0, $5uXOq.forwardRef)((props, ref)=>{
        const { appearance: appearance = 'standard', className: className, elemAfterInput: elemAfterInput, elemBeforeInput: elemBeforeInput, isCompact: isCompact = false, isDisabled: isDisabled = false, isInvalid: isInvalid = false, isMonospaced: isMonospaced = false, isReadOnly: isReadOnly = false, isRequired: isRequired = false, name: name, onBlur: onBlur, onChange: onChange, onFocus: onFocus, onMouseDown: onMouseDown, placeholder: placeholder, testId: testId, width: width, ...spreadProps } = props;
        const inputRef = (0, $5uXOq.useRef)(null);
        const handleOnFocus = (0, (0, parcelRequire("6QvkM")).usePlatformLeafEventHandler)({
            fn: (event)=>{
                onFocus && onFocus(event);
            },
            action: 'focused',
            ...$509076ff0c6afa78$var$analyticsParams
        });
        const handleOnBlur = (0, (0, parcelRequire("6QvkM")).usePlatformLeafEventHandler)({
            fn: (event)=>{
                onBlur && onBlur(event);
            },
            action: 'blurred',
            ...$509076ff0c6afa78$var$analyticsParams
        });
        const handleOnMouseDown = (0, $5uXOq.useCallback)((event)=>{
            const target = event.target;
            if (target.tagName !== 'INPUT') event.preventDefault();
            if (inputRef && inputRef.current && !isDisabled && document.activeElement !== inputRef.current) inputRef.current.focus();
            onMouseDown && onMouseDown(event);
        }, [
            onMouseDown,
            isDisabled
        ]);
        const setInputRef = (0, $5uXOq.useCallback)((inputElement)=>{
            inputRef.current = inputElement;
            if (!ref) return;
            if (typeof ref === 'object') ref.current = inputElement;
            if (typeof ref === 'function') ref(inputElement);
        }, [
            ref
        ]);
        const containerStyles = (0, $5uXOq.useMemo)(()=>(0, (0, parcelRequire("hbrbl")).containerStyles)(appearance, width), [
            appearance,
            width
        ]);
        const inputStyle = (0, (0, parcelRequire("hbrbl")).inputStyles)();
        return ((0, (0, parcelRequire("f8Sb3")).jsx)("div", {
            role: "presentation",
            "data-disabled": isDisabled ? isDisabled : undefined,
            "data-invalid": isInvalid ? isInvalid : undefined,
            "data-ds--text-field--container": true,
            "data-testid": testId && `${testId}-container`,
            onMouseDown: handleOnMouseDown,
            css: containerStyles,
            className: className
        }, elemBeforeInput, (0, (0, parcelRequire("f8Sb3")).jsx)("input", {
            ...spreadProps,
            "aria-invalid": isInvalid ? isInvalid : undefined,
            css: inputStyle,
            "data-compact": isCompact ? isCompact : undefined,
            "data-ds--text-field--input": true,
            "data-monospaced": isMonospaced ? isMonospaced : undefined,
            "data-testid": testId,
            disabled: isDisabled,
            name: name,
            onBlur: handleOnBlur,
            onChange: onChange,
            onFocus: handleOnFocus,
            placeholder: placeholder,
            readOnly: isReadOnly,
            ref: setInputRef,
            required: isRequired
        }), elemAfterInput));
    });
    var $509076ff0c6afa78$export$2e2bcd8739ae039 = $509076ff0c6afa78$var$Textfield;
});
parcelRegister("hbrbl", function(module, exports) {
    $parcel$export(module.exports, "containerStyles", ()=>$9b106cc21ace4b3b$export$5972a68b6e54f38f);
    $parcel$export(module.exports, "inputStyles", ()=>$9b106cc21ace4b3b$export$3eeb47dc8e6249d9);
    const $9b106cc21ace4b3b$var$backgroundColor = {
        standard: `var(--ds-background-input, ${(0, $829f609a65d26a98$export$e655ef011c4aa1a2)})`,
        subtle: 'transparent',
        none: 'transparent'
    };
    const $9b106cc21ace4b3b$var$backgroundColorFocus = {
        standard: `var(--ds-background-input-pressed, ${(0, $829f609a65d26a98$export$29a4f05edc054374)})`,
        subtle: `var(--ds-background-input-pressed, ${(0, $829f609a65d26a98$export$29a4f05edc054374)})`,
        none: 'transparent'
    };
    const $9b106cc21ace4b3b$var$backgroundColorHover = {
        standard: `var(--ds-background-input-hovered, ${(0, $829f609a65d26a98$export$3e51e8f1440314ba)})`,
        subtle: `var(--ds-background-input-hovered, ${(0, $829f609a65d26a98$export$3e51e8f1440314ba)})`,
        none: 'transparent'
    };
    const $9b106cc21ace4b3b$var$borderColor = {
        standard: `var(--ds-border-input, ${(0, $829f609a65d26a98$export$d6447c357493214f)})`,
        subtle: 'transparent',
        none: 'transparent'
    };
    const $9b106cc21ace4b3b$var$borderColorFocus = {
        standard: `var(--ds-border-focused, ${(0, $829f609a65d26a98$export$215b7063051591f1)})`,
        subtle: `var(--ds-border-focused, ${(0, $829f609a65d26a98$export$215b7063051591f1)})`,
        none: 'transparent'
    };
    const $9b106cc21ace4b3b$var$borderColorHover = {
        standard: `var(--ds-border-input, ${(0, $829f609a65d26a98$export$d6447c357493214f)})`,
        subtle: "var(--ds-border-input, transparent)",
        none: 'transparent'
    };
    const $9b106cc21ace4b3b$var$getContainerTextBgAndBorderColor = (appearance)=>({
            backgroundColor: $9b106cc21ace4b3b$var$backgroundColor[appearance],
            borderColor: $9b106cc21ace4b3b$var$borderColor[appearance],
            color: `var(--ds-text, ${0, $829f609a65d26a98$export$fbda843f0a27fe96})`,
            cursor: 'text',
            '&:hover:not([data-disabled])': {
                backgroundColor: $9b106cc21ace4b3b$var$backgroundColorHover[appearance],
                borderColor: $9b106cc21ace4b3b$var$borderColorHover[appearance]
            },
            '&:focus-within:not([data-disabled])': {
                backgroundColor: $9b106cc21ace4b3b$var$backgroundColorFocus[appearance],
                borderColor: $9b106cc21ace4b3b$var$borderColorFocus[appearance],
                boxShadow: `inset 0 0 0 ${"var(--ds-border-width, 1px)"} ${$9b106cc21ace4b3b$var$borderColorFocus[appearance]}`
            },
            '&[data-disabled]': {
                color: `var(--ds-text-disabled, ${0, $829f609a65d26a98$export$5f71e19f3a7ad9de})`,
                cursor: 'not-allowed',
                ...appearance === 'standard' && {
                    backgroundColor: `var(--ds-background-disabled, ${0, $829f609a65d26a98$export$e655ef011c4aa1a2})`,
                    borderColor: `var(--ds-background-disabled, ${0, $829f609a65d26a98$export$e655ef011c4aa1a2})`
                }
            },
            '&[data-invalid], &[data-invalid]:hover': {
                borderColor: `var(--ds-border-danger, ${0, $829f609a65d26a98$export$2010d0fb4295b346})`,
                boxShadow: `inset 0 0 0 ${"var(--ds-border-width, 1px)"} ${`var(--ds-border-danger, ${0, $829f609a65d26a98$export$2010d0fb4295b346})`}`
            },
            '&[data-invalid]:focus-within': {
                backgroundColor: `var(--ds-background-input-pressed, ${0, $829f609a65d26a98$export$29a4f05edc054374})`,
                borderColor: `var(--ds-border-focused, ${0, $829f609a65d26a98$export$215b7063051591f1})`,
                boxShadow: `inset 0 0 0 ${"var(--ds-border-width, 1px)"} ${`var(--ds-border-focused, ${0, $829f609a65d26a98$export$215b7063051591f1})`}`
            },
            '@media screen and (-ms-high-contrast: active)': {
                '&[data-invalid]:focus-within': {
                    borderColor: 'Highlight'
                },
                '&:focus-within': {
                    borderColor: 'Highlight'
                },
                '&[data-disabled]': {
                    borderColor: 'GrayText'
                }
            }
        });
    const $9b106cc21ace4b3b$var$widthMap = {
        xsmall: 80,
        small: 160,
        medium: 240,
        large: 320,
        xlarge: 480
    };
    const $9b106cc21ace4b3b$var$getMaxWidth = (width)=>!width ? `100%` : width in $9b106cc21ace4b3b$var$widthMap ? $9b106cc21ace4b3b$var$widthMap[width] : +width;
    const $9b106cc21ace4b3b$export$5972a68b6e54f38f = (appearance, width)=>({
            alignItems: 'center',
            ...$9b106cc21ace4b3b$var$getContainerTextBgAndBorderColor(appearance),
            borderRadius: 3,
            borderWidth: "var(--ds-border-width, 1px)",
            ...appearance !== 'none' ? {
                padding: `${"var(--ds-border-width, 1px)"} 0`
            } : {},
            borderStyle: appearance === 'none' ? 'none' : 'solid',
            boxSizing: 'border-box',
            display: 'flex',
            flex: '1 1 100%',
            font: "var(--ds-font-body, normal 400 14px/20px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)",
            justifyContent: 'space-between',
            maxWidth: $9b106cc21ace4b3b$var$getMaxWidth(width),
            overflow: 'hidden',
            transition: `background-color 0.2s ease-in-out, border-color 0.2s ease-in-out`,
            wordWrap: 'break-word',
            verticalAlign: 'top',
            pointerEvents: 'auto'
        });
    const $9b106cc21ace4b3b$export$3eeb47dc8e6249d9 = ()=>({
            backgroundColor: 'transparent',
            border: 0,
            boxSizing: 'border-box',
            color: 'inherit',
            cursor: 'inherit',
            font: "var(--ds-font-body, normal 400 14px/20px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)",
            minWidth: '0',
            outline: 'none',
            width: '100%',
            '&[data-monospaced]': {
                fontFamily: "var(--ds-font-family-code, ui-monospace, Menlo, \"Segoe UI Mono\", \"Ubuntu Mono\", monospace)"
            },
            '&[data-compact]': {
                padding: `${"var(--ds-space-050, 4px)"} ${"var(--ds-space-075, 6px)"}`
            },
            '&:not([data-compact])': {
                padding: `${"var(--ds-space-100, 8px)"} ${"var(--ds-space-075, 6px)"}`
            },
            '&[disabled]': {
                WebkitTextFillColor: `var(--ds-text-disabled, ${0, $829f609a65d26a98$export$5f71e19f3a7ad9de})`
            },
            '&::-ms-clear': {
                display: 'none'
            },
            '&:invalid': {
                boxShadow: 'none'
            },
            '&:placeholder-shown': {
                textOverflow: 'ellipsis'
            },
            '&::placeholder': {
                color: `var(--ds-text-subtlest, ${0, $829f609a65d26a98$export$f563ee71e9ee74cd})`,
                '&:disabled': {
                    color: `var(--ds-text-disabled, ${0, $829f609a65d26a98$export$5f71e19f3a7ad9de})`
                }
            },
            '@media screen and (-ms-high-contrast: active)': {
                '&[disabled]': {
                    color: 'GrayText'
                }
            }
        });
});
var $829f609a65d26a98$exports = {};
$parcel$export($829f609a65d26a98$exports, "R50", ()=>$829f609a65d26a98$export$4b84fd0e2c617bd);
$parcel$export($829f609a65d26a98$exports, "R75", ()=>$829f609a65d26a98$export$b34b069eff244586);
$parcel$export($829f609a65d26a98$exports, "R100", ()=>$829f609a65d26a98$export$be802dd0d8b57f35);
$parcel$export($829f609a65d26a98$exports, "R200", ()=>$829f609a65d26a98$export$60847d42f9da92a6);
$parcel$export($829f609a65d26a98$exports, "R300", ()=>$829f609a65d26a98$export$4a7404f376229999);
$parcel$export($829f609a65d26a98$exports, "R400", ()=>$829f609a65d26a98$export$2010d0fb4295b346);
$parcel$export($829f609a65d26a98$exports, "R500", ()=>$829f609a65d26a98$export$2837b5a1a71d5c02);
$parcel$export($829f609a65d26a98$exports, "Y50", ()=>$829f609a65d26a98$export$8d9596b529bbb9cf);
$parcel$export($829f609a65d26a98$exports, "Y75", ()=>$829f609a65d26a98$export$41060a744ab0b7ae);
$parcel$export($829f609a65d26a98$exports, "Y100", ()=>$829f609a65d26a98$export$1cc05bbbf340e559);
$parcel$export($829f609a65d26a98$exports, "Y200", ()=>$829f609a65d26a98$export$5aae31392d72caaa);
$parcel$export($829f609a65d26a98$exports, "Y300", ()=>$829f609a65d26a98$export$c6930421db9fa514);
$parcel$export($829f609a65d26a98$exports, "Y400", ()=>$829f609a65d26a98$export$59619663aa6500fb);
$parcel$export($829f609a65d26a98$exports, "Y500", ()=>$829f609a65d26a98$export$874739ea9e379ab6);
$parcel$export($829f609a65d26a98$exports, "G50", ()=>$829f609a65d26a98$export$8a6341c32b6a3ee6);
$parcel$export($829f609a65d26a98$exports, "G75", ()=>$829f609a65d26a98$export$69a3bf21b9686c2b);
$parcel$export($829f609a65d26a98$exports, "G100", ()=>$829f609a65d26a98$export$afbce1382ec95f73);
$parcel$export($829f609a65d26a98$exports, "G200", ()=>$829f609a65d26a98$export$747d86715d4175de);
$parcel$export($829f609a65d26a98$exports, "G300", ()=>$829f609a65d26a98$export$940e1f69e873f202);
$parcel$export($829f609a65d26a98$exports, "G400", ()=>$829f609a65d26a98$export$a49394138f7b8e85);
$parcel$export($829f609a65d26a98$exports, "G500", ()=>$829f609a65d26a98$export$6fa04827ac68a4fb);
$parcel$export($829f609a65d26a98$exports, "B50", ()=>$829f609a65d26a98$export$226de0355ed012f8);
$parcel$export($829f609a65d26a98$exports, "B75", ()=>$829f609a65d26a98$export$8c13c2ee36917869);
$parcel$export($829f609a65d26a98$exports, "B100", ()=>$829f609a65d26a98$export$ecfc1dff386a3e7a);
$parcel$export($829f609a65d26a98$exports, "B200", ()=>$829f609a65d26a98$export$215b7063051591f1);
$parcel$export($829f609a65d26a98$exports, "B300", ()=>$829f609a65d26a98$export$e62e8919cca44969);
$parcel$export($829f609a65d26a98$exports, "B400", ()=>$829f609a65d26a98$export$b3de0ae08da2ce64);
$parcel$export($829f609a65d26a98$exports, "B500", ()=>$829f609a65d26a98$export$a6ee3d71bc067634);
$parcel$export($829f609a65d26a98$exports, "B600", ()=>$829f609a65d26a98$export$67f15409e87642a1);
$parcel$export($829f609a65d26a98$exports, "B700", ()=>$829f609a65d26a98$export$f0539dbc42dbb067);
$parcel$export($829f609a65d26a98$exports, "P50", ()=>$829f609a65d26a98$export$363e4475840015bb);
$parcel$export($829f609a65d26a98$exports, "P75", ()=>$829f609a65d26a98$export$4b0a4417ed59760f);
$parcel$export($829f609a65d26a98$exports, "P100", ()=>$829f609a65d26a98$export$6011520a64d9a187);
$parcel$export($829f609a65d26a98$exports, "P200", ()=>$829f609a65d26a98$export$a7175d4e9206bc80);
$parcel$export($829f609a65d26a98$exports, "P300", ()=>$829f609a65d26a98$export$b48405b95304b5a1);
$parcel$export($829f609a65d26a98$exports, "P400", ()=>$829f609a65d26a98$export$c71e4279dd54a18d);
$parcel$export($829f609a65d26a98$exports, "P500", ()=>$829f609a65d26a98$export$147899de772240ec);
$parcel$export($829f609a65d26a98$exports, "T50", ()=>$829f609a65d26a98$export$2cf711fe14c66f06);
$parcel$export($829f609a65d26a98$exports, "T75", ()=>$829f609a65d26a98$export$7a8f4d33f6f9772c);
$parcel$export($829f609a65d26a98$exports, "T100", ()=>$829f609a65d26a98$export$776c9c497ef08533);
$parcel$export($829f609a65d26a98$exports, "T200", ()=>$829f609a65d26a98$export$69cb3ca10c1e330e);
$parcel$export($829f609a65d26a98$exports, "T300", ()=>$829f609a65d26a98$export$9dc1ef0f3f48059b);
$parcel$export($829f609a65d26a98$exports, "T400", ()=>$829f609a65d26a98$export$7c835c78b6fcdef8);
$parcel$export($829f609a65d26a98$exports, "T500", ()=>$829f609a65d26a98$export$6036577f95c3877e);
$parcel$export($829f609a65d26a98$exports, "N0", ()=>$829f609a65d26a98$export$29a4f05edc054374);
$parcel$export($829f609a65d26a98$exports, "N10", ()=>$829f609a65d26a98$export$e655ef011c4aa1a2);
$parcel$export($829f609a65d26a98$exports, "N20", ()=>$829f609a65d26a98$export$9fdfb749e33cdf97);
$parcel$export($829f609a65d26a98$exports, "N30", ()=>$829f609a65d26a98$export$3e51e8f1440314ba);
$parcel$export($829f609a65d26a98$exports, "N40", ()=>$829f609a65d26a98$export$1ce2d1575d444ceb);
$parcel$export($829f609a65d26a98$exports, "N50", ()=>$829f609a65d26a98$export$8754fc7e6d8b3000);
$parcel$export($829f609a65d26a98$exports, "N60", ()=>$829f609a65d26a98$export$c2e91cbeada39b8b);
$parcel$export($829f609a65d26a98$exports, "N70", ()=>$829f609a65d26a98$export$5f71e19f3a7ad9de);
$parcel$export($829f609a65d26a98$exports, "N80", ()=>$829f609a65d26a98$export$eaa8c8a70ac22806);
$parcel$export($829f609a65d26a98$exports, "N90", ()=>$829f609a65d26a98$export$7c87d337fb91a97d);
$parcel$export($829f609a65d26a98$exports, "N100", ()=>$829f609a65d26a98$export$d6447c357493214f);
$parcel$export($829f609a65d26a98$exports, "N200", ()=>$829f609a65d26a98$export$f563ee71e9ee74cd);
$parcel$export($829f609a65d26a98$exports, "N300", ()=>$829f609a65d26a98$export$e2ef2124cf4024b1);
$parcel$export($829f609a65d26a98$exports, "N400", ()=>$829f609a65d26a98$export$eece5f492e429f08);
$parcel$export($829f609a65d26a98$exports, "N500", ()=>$829f609a65d26a98$export$c39c0fe0f26207ae);
$parcel$export($829f609a65d26a98$exports, "N600", ()=>$829f609a65d26a98$export$7090862dc3038a9f);
$parcel$export($829f609a65d26a98$exports, "N700", ()=>$829f609a65d26a98$export$ad0bacf983b7ddaa);
$parcel$export($829f609a65d26a98$exports, "N800", ()=>$829f609a65d26a98$export$7d61b15e0da132af);
$parcel$export($829f609a65d26a98$exports, "N900", ()=>$829f609a65d26a98$export$fbda843f0a27fe96);
$parcel$export($829f609a65d26a98$exports, "N10A", ()=>$829f609a65d26a98$export$791e57b27ca738ef);
$parcel$export($829f609a65d26a98$exports, "N20A", ()=>$829f609a65d26a98$export$8d43898f60b96a82);
$parcel$export($829f609a65d26a98$exports, "N30A", ()=>$829f609a65d26a98$export$920c0d5c49070351);
$parcel$export($829f609a65d26a98$exports, "N40A", ()=>$829f609a65d26a98$export$46987b49aab6d1a8);
$parcel$export($829f609a65d26a98$exports, "N50A", ()=>$829f609a65d26a98$export$7d3359664e8bb0e5);
$parcel$export($829f609a65d26a98$exports, "N60A", ()=>$829f609a65d26a98$export$d2d3bedfb276cac0);
$parcel$export($829f609a65d26a98$exports, "N70A", ()=>$829f609a65d26a98$export$6e5e7499ff054f6d);
$parcel$export($829f609a65d26a98$exports, "N80A", ()=>$829f609a65d26a98$export$59264b346c877fa7);
$parcel$export($829f609a65d26a98$exports, "N90A", ()=>$829f609a65d26a98$export$d092757d7c64925c);
$parcel$export($829f609a65d26a98$exports, "N100A", ()=>$829f609a65d26a98$export$486ab785e4bac3c6);
$parcel$export($829f609a65d26a98$exports, "N200A", ()=>$829f609a65d26a98$export$fba592bbc6419fea);
$parcel$export($829f609a65d26a98$exports, "N300A", ()=>$829f609a65d26a98$export$13609db0e6812ab8);
$parcel$export($829f609a65d26a98$exports, "N400A", ()=>$829f609a65d26a98$export$fbae4efaa892d883);
$parcel$export($829f609a65d26a98$exports, "N500A", ()=>$829f609a65d26a98$export$f13946d26f440ce6);
$parcel$export($829f609a65d26a98$exports, "N600A", ()=>$829f609a65d26a98$export$19f58225ac52a306);
$parcel$export($829f609a65d26a98$exports, "N700A", ()=>$829f609a65d26a98$export$6384f09da70e7b62);
$parcel$export($829f609a65d26a98$exports, "N800A", ()=>$829f609a65d26a98$export$d0dccc730683edc2);
$parcel$export($829f609a65d26a98$exports, "DN900", ()=>$829f609a65d26a98$export$2f6319cf7d736c2a);
$parcel$export($829f609a65d26a98$exports, "DN800", ()=>$829f609a65d26a98$export$95126bc8884d6634);
$parcel$export($829f609a65d26a98$exports, "DN700", ()=>$829f609a65d26a98$export$8338d8c35d44ff1);
$parcel$export($829f609a65d26a98$exports, "DN600", ()=>$829f609a65d26a98$export$5368d76ae4fbbda7);
$parcel$export($829f609a65d26a98$exports, "DN500", ()=>$829f609a65d26a98$export$9f0a771d16ec8d36);
$parcel$export($829f609a65d26a98$exports, "DN400", ()=>$829f609a65d26a98$export$abcf8c38338b4aa5);
$parcel$export($829f609a65d26a98$exports, "DN300", ()=>$829f609a65d26a98$export$97e81faa16a182c7);
$parcel$export($829f609a65d26a98$exports, "DN200", ()=>$829f609a65d26a98$export$bfe21c59123ecf33);
$parcel$export($829f609a65d26a98$exports, "DN100", ()=>$829f609a65d26a98$export$ee82aa2ea6a1558);
$parcel$export($829f609a65d26a98$exports, "DN90", ()=>$829f609a65d26a98$export$682927c6ed76be02);
$parcel$export($829f609a65d26a98$exports, "DN80", ()=>$829f609a65d26a98$export$91fd9994c5be9757);
$parcel$export($829f609a65d26a98$exports, "DN70", ()=>$829f609a65d26a98$export$d281fbfe3cff2df6);
$parcel$export($829f609a65d26a98$exports, "DN60", ()=>$829f609a65d26a98$export$3902c91d8b98a753);
$parcel$export($829f609a65d26a98$exports, "DN50", ()=>$829f609a65d26a98$export$8e8133c1ba5845b5);
$parcel$export($829f609a65d26a98$exports, "DN40", ()=>$829f609a65d26a98$export$33ff357f6620b39);
$parcel$export($829f609a65d26a98$exports, "DN30", ()=>$829f609a65d26a98$export$633847cf062b69e0);
$parcel$export($829f609a65d26a98$exports, "DN20", ()=>$829f609a65d26a98$export$9776a6e503bdbf76);
$parcel$export($829f609a65d26a98$exports, "DN10", ()=>$829f609a65d26a98$export$6c813d5160dff5db);
$parcel$export($829f609a65d26a98$exports, "DN0", ()=>$829f609a65d26a98$export$3d4f271a500072a);
$parcel$export($829f609a65d26a98$exports, "DN800A", ()=>$829f609a65d26a98$export$fe151285c22759ec);
$parcel$export($829f609a65d26a98$exports, "DN700A", ()=>$829f609a65d26a98$export$a101c6190d0da34);
$parcel$export($829f609a65d26a98$exports, "DN600A", ()=>$829f609a65d26a98$export$4b16f52932fda51);
$parcel$export($829f609a65d26a98$exports, "DN500A", ()=>$829f609a65d26a98$export$a74bcd7b9845b0d8);
$parcel$export($829f609a65d26a98$exports, "DN400A", ()=>$829f609a65d26a98$export$9209c179316d1751);
$parcel$export($829f609a65d26a98$exports, "DN300A", ()=>$829f609a65d26a98$export$88f55133f636c8dd);
$parcel$export($829f609a65d26a98$exports, "DN200A", ()=>$829f609a65d26a98$export$51126f85d1009512);
$parcel$export($829f609a65d26a98$exports, "DN100A", ()=>$829f609a65d26a98$export$a069b547bd23af78);
$parcel$export($829f609a65d26a98$exports, "DN90A", ()=>$829f609a65d26a98$export$40cce05af3b2600a);
$parcel$export($829f609a65d26a98$exports, "DN80A", ()=>$829f609a65d26a98$export$b78942185925a0d);
$parcel$export($829f609a65d26a98$exports, "DN70A", ()=>$829f609a65d26a98$export$e058fb44b8eaafca);
$parcel$export($829f609a65d26a98$exports, "DN60A", ()=>$829f609a65d26a98$export$92b0b8de26920c18);
$parcel$export($829f609a65d26a98$exports, "DN50A", ()=>$829f609a65d26a98$export$67564f7459d3fc9e);
$parcel$export($829f609a65d26a98$exports, "DN40A", ()=>$829f609a65d26a98$export$77868e1ca884ffa3);
$parcel$export($829f609a65d26a98$exports, "DN30A", ()=>$829f609a65d26a98$export$2afd71d7d3ea2cd5);
$parcel$export($829f609a65d26a98$exports, "DN20A", ()=>$829f609a65d26a98$export$f2a0acaab9972c70);
$parcel$export($829f609a65d26a98$exports, "DN10A", ()=>$829f609a65d26a98$export$9ab70e67d6475361);
$parcel$export($829f609a65d26a98$exports, "T30", ()=>$829f609a65d26a98$export$d2adc5dedf09fced);
$parcel$export($829f609a65d26a98$exports, "L50", ()=>$829f609a65d26a98$export$f51762073d2bb381);
$parcel$export($829f609a65d26a98$exports, "L75", ()=>$829f609a65d26a98$export$95e26d54def96891);
const $829f609a65d26a98$export$4b84fd0e2c617bd = '#FFEBE6';
const $829f609a65d26a98$export$b34b069eff244586 = '#FFBDAD';
const $829f609a65d26a98$export$be802dd0d8b57f35 = '#FF8F73';
const $829f609a65d26a98$export$60847d42f9da92a6 = '#FF7452';
const $829f609a65d26a98$export$4a7404f376229999 = '#FF5630';
const $829f609a65d26a98$export$2010d0fb4295b346 = '#DE350B';
const $829f609a65d26a98$export$2837b5a1a71d5c02 = '#BF2600';
const $829f609a65d26a98$export$8d9596b529bbb9cf = '#FFFAE6';
const $829f609a65d26a98$export$41060a744ab0b7ae = '#FFF0B3';
const $829f609a65d26a98$export$1cc05bbbf340e559 = '#FFE380';
const $829f609a65d26a98$export$5aae31392d72caaa = '#FFC400';
const $829f609a65d26a98$export$c6930421db9fa514 = '#FFAB00';
const $829f609a65d26a98$export$59619663aa6500fb = '#FF991F';
const $829f609a65d26a98$export$874739ea9e379ab6 = '#FF8B00';
const $829f609a65d26a98$export$8a6341c32b6a3ee6 = '#E3FCEF';
const $829f609a65d26a98$export$69a3bf21b9686c2b = '#ABF5D1';
const $829f609a65d26a98$export$afbce1382ec95f73 = '#79F2C0';
const $829f609a65d26a98$export$747d86715d4175de = '#57D9A3';
const $829f609a65d26a98$export$940e1f69e873f202 = '#36B37E';
const $829f609a65d26a98$export$a49394138f7b8e85 = '#00875A';
const $829f609a65d26a98$export$6fa04827ac68a4fb = '#006644';
const $829f609a65d26a98$export$226de0355ed012f8 = '#DEEBFF';
const $829f609a65d26a98$export$8c13c2ee36917869 = '#B3D4FF';
const $829f609a65d26a98$export$ecfc1dff386a3e7a = '#4C9AFF';
const $829f609a65d26a98$export$215b7063051591f1 = '#2684FF';
const $829f609a65d26a98$export$e62e8919cca44969 = '#0065FF';
const $829f609a65d26a98$export$b3de0ae08da2ce64 = '#0052CC';
const $829f609a65d26a98$export$a6ee3d71bc067634 = '#0747A6';
const $829f609a65d26a98$export$67f15409e87642a1 = '#669DF1';
const $829f609a65d26a98$export$f0539dbc42dbb067 = '#8FB8F6';
const $829f609a65d26a98$export$363e4475840015bb = '#EAE6FF';
const $829f609a65d26a98$export$4b0a4417ed59760f = '#C0B6F2';
const $829f609a65d26a98$export$6011520a64d9a187 = '#998DD9';
const $829f609a65d26a98$export$a7175d4e9206bc80 = '#8777D9';
const $829f609a65d26a98$export$b48405b95304b5a1 = '#6554C0';
const $829f609a65d26a98$export$c71e4279dd54a18d = '#5243AA';
const $829f609a65d26a98$export$147899de772240ec = '#403294';
const $829f609a65d26a98$export$2cf711fe14c66f06 = '#E6FCFF';
const $829f609a65d26a98$export$7a8f4d33f6f9772c = '#B3F5FF';
const $829f609a65d26a98$export$776c9c497ef08533 = '#79E2F2';
const $829f609a65d26a98$export$69cb3ca10c1e330e = '#00C7E6';
const $829f609a65d26a98$export$9dc1ef0f3f48059b = '#00B8D9';
const $829f609a65d26a98$export$7c835c78b6fcdef8 = '#00A3BF';
const $829f609a65d26a98$export$6036577f95c3877e = '#008DA6';
const $829f609a65d26a98$export$29a4f05edc054374 = '#FFFFFF';
const $829f609a65d26a98$export$e655ef011c4aa1a2 = '#FAFBFC';
const $829f609a65d26a98$export$9fdfb749e33cdf97 = '#F4F5F7';
const $829f609a65d26a98$export$3e51e8f1440314ba = '#EBECF0';
const $829f609a65d26a98$export$1ce2d1575d444ceb = '#DFE1E6';
const $829f609a65d26a98$export$8754fc7e6d8b3000 = '#C1C7D0';
const $829f609a65d26a98$export$c2e91cbeada39b8b = '#B3BAC5';
const $829f609a65d26a98$export$5f71e19f3a7ad9de = '#A5ADBA';
const $829f609a65d26a98$export$eaa8c8a70ac22806 = '#97A0AF';
const $829f609a65d26a98$export$7c87d337fb91a97d = '#8993A4';
const $829f609a65d26a98$export$d6447c357493214f = '#7A869A';
const $829f609a65d26a98$export$f563ee71e9ee74cd = '#6B778C';
const $829f609a65d26a98$export$e2ef2124cf4024b1 = '#5E6C84';
const $829f609a65d26a98$export$eece5f492e429f08 = '#505F79';
const $829f609a65d26a98$export$c39c0fe0f26207ae = '#42526E';
const $829f609a65d26a98$export$7090862dc3038a9f = '#344563';
const $829f609a65d26a98$export$ad0bacf983b7ddaa = '#253858';
const $829f609a65d26a98$export$7d61b15e0da132af = '#172B4D';
const $829f609a65d26a98$export$fbda843f0a27fe96 = '#091E42';
const $829f609a65d26a98$export$791e57b27ca738ef = 'rgba(9, 30, 66, 0.02)';
const $829f609a65d26a98$export$8d43898f60b96a82 = 'rgba(9, 30, 66, 0.04)';
const $829f609a65d26a98$export$920c0d5c49070351 = 'rgba(9, 30, 66, 0.08)';
const $829f609a65d26a98$export$46987b49aab6d1a8 = 'rgba(9, 30, 66, 0.13)';
const $829f609a65d26a98$export$7d3359664e8bb0e5 = 'rgba(9, 30, 66, 0.25)';
const $829f609a65d26a98$export$d2d3bedfb276cac0 = 'rgba(9, 30, 66, 0.31)';
const $829f609a65d26a98$export$6e5e7499ff054f6d = 'rgba(9, 30, 66, 0.36)';
const $829f609a65d26a98$export$59264b346c877fa7 = 'rgba(9, 30, 66, 0.42)';
const $829f609a65d26a98$export$d092757d7c64925c = 'rgba(9, 30, 66, 0.48)';
const $829f609a65d26a98$export$486ab785e4bac3c6 = 'rgba(9, 30, 66, 0.54)';
const $829f609a65d26a98$export$fba592bbc6419fea = 'rgba(9, 30, 66, 0.60)';
const $829f609a65d26a98$export$13609db0e6812ab8 = 'rgba(9, 30, 66, 0.66)';
const $829f609a65d26a98$export$fbae4efaa892d883 = 'rgba(9, 30, 66, 0.71)';
const $829f609a65d26a98$export$f13946d26f440ce6 = 'rgba(9, 30, 66, 0.77)';
const $829f609a65d26a98$export$19f58225ac52a306 = 'rgba(9, 30, 66, 0.82)';
const $829f609a65d26a98$export$6384f09da70e7b62 = 'rgba(9, 30, 66, 0.89)';
const $829f609a65d26a98$export$d0dccc730683edc2 = 'rgba(9, 30, 66, 0.95)';
const $829f609a65d26a98$export$2f6319cf7d736c2a = '#E6EDFA';
const $829f609a65d26a98$export$95126bc8884d6634 = '#DCE5F5';
const $829f609a65d26a98$export$8338d8c35d44ff1 = '#CED9EB';
const $829f609a65d26a98$export$5368d76ae4fbbda7 = '#B8C7E0';
const $829f609a65d26a98$export$9f0a771d16ec8d36 = '#ABBBD6';
const $829f609a65d26a98$export$abcf8c38338b4aa5 = '#9FB0CC';
const $829f609a65d26a98$export$97e81faa16a182c7 = '#8C9CB8';
const $829f609a65d26a98$export$bfe21c59123ecf33 = '#7988A3';
const $829f609a65d26a98$export$ee82aa2ea6a1558 = '#67758F';
const $829f609a65d26a98$export$682927c6ed76be02 = '#56637A';
const $829f609a65d26a98$export$91fd9994c5be9757 = '#455166';
const $829f609a65d26a98$export$d281fbfe3cff2df6 = '#3B475C';
const $829f609a65d26a98$export$3902c91d8b98a753 = '#313D52';
const $829f609a65d26a98$export$8e8133c1ba5845b5 = '#283447';
const $829f609a65d26a98$export$33ff357f6620b39 = '#202B3D';
const $829f609a65d26a98$export$633847cf062b69e0 = '#1B2638';
const $829f609a65d26a98$export$9776a6e503bdbf76 = '#121A29';
const $829f609a65d26a98$export$6c813d5160dff5db = '#0E1624';
const $829f609a65d26a98$export$3d4f271a500072a = '#0D1424';
const $829f609a65d26a98$export$fe151285c22759ec = 'rgba(13, 20, 36, 0.06)';
const $829f609a65d26a98$export$a101c6190d0da34 = 'rgba(13, 20, 36, 0.14)';
const $829f609a65d26a98$export$4b16f52932fda51 = 'rgba(13, 20, 36, 0.18)';
const $829f609a65d26a98$export$a74bcd7b9845b0d8 = 'rgba(13, 20, 36, 0.29)';
const $829f609a65d26a98$export$9209c179316d1751 = 'rgba(13, 20, 36, 0.36)';
const $829f609a65d26a98$export$88f55133f636c8dd = 'rgba(13, 20, 36, 0.40)';
const $829f609a65d26a98$export$51126f85d1009512 = 'rgba(13, 20, 36, 0.47)';
const $829f609a65d26a98$export$a069b547bd23af78 = 'rgba(13, 20, 36, 0.53)';
const $829f609a65d26a98$export$40cce05af3b2600a = 'rgba(13, 20, 36, 0.63)';
const $829f609a65d26a98$export$b78942185925a0d = 'rgba(13, 20, 36, 0.73)';
const $829f609a65d26a98$export$e058fb44b8eaafca = 'rgba(13, 20, 36, 0.78)';
const $829f609a65d26a98$export$92b0b8de26920c18 = 'rgba(13, 20, 36, 0.81)';
const $829f609a65d26a98$export$67564f7459d3fc9e = 'rgba(13, 20, 36, 0.85)';
const $829f609a65d26a98$export$77868e1ca884ffa3 = 'rgba(13, 20, 36, 0.89)';
const $829f609a65d26a98$export$2afd71d7d3ea2cd5 = 'rgba(13, 20, 36, 0.92)';
const $829f609a65d26a98$export$f2a0acaab9972c70 = 'rgba(13, 20, 36, 0.95)';
const $829f609a65d26a98$export$9ab70e67d6475361 = 'rgba(13, 20, 36, 0.97)';
const $829f609a65d26a98$export$d2adc5dedf09fced = '#292A2E';
const $829f609a65d26a98$export$f51762073d2bb381 = '#94C748';
const $829f609a65d26a98$export$95e26d54def96891 = '#B3DF72';
parcelRegister("fVAPR", function(module, exports) {
    $parcel$export(module.exports, "FieldLabel", ()=>$97462970d1593b07$export$1acdcf5a973a8414);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $47bDC = parcelRequire("47bDC");
    var $3RZIb;
    var $gwIPs;
    const $97462970d1593b07$export$1acdcf5a973a8414 = ({ label: label, htmlFor: htmlFor, isRequired: isRequired = false })=>(0, ($parcel$interopDefault($5uXOq))).createElement($97462970d1593b07$var$Label, {
            htmlFor: htmlFor
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($97462970d1593b07$var$LabelText, null, (0, ($parcel$interopDefault($5uXOq))).createElement("span", null, label), isRequired ? (0, ($parcel$interopDefault($5uXOq))).createElement($97462970d1593b07$var$RequiredIndicator, {
            role: "presentation"
        }, "*") : null));
    const $97462970d1593b07$var$Label = (0, $5uXOq.forwardRef)(({ as: C = "label", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1ule",
                __cmplp.className
            ])
        });
    });
    const $97462970d1593b07$var$LabelText = (0, $47bDC.default).div.withConfig({
        componentId: "sc-9kgl5s-0"
    })`${(0, (0, parcelRequire("3RZIb")).h200)()} display:block;font-family:${(0, (0, parcelRequire("gwIPs")).fontFamily)};margin:0;${(0, $47bDC.css)({
        padding: `${"var(--ds-space-250, 20px)"} 0px ${"var(--ds-space-050, 4px)"} 0px`
    })}`;
    const $97462970d1593b07$var$RequiredIndicator = (0, $47bDC.default).span.withConfig({
        componentId: "sc-9kgl5s-1"
    })({
        color: `var(--ds-text-danger, ${(0, $829f609a65d26a98$exports).R400})`,
        fontFamily: (0, parcelRequire("gwIPs")).fontFamily,
        paddingLeft: "var(--ds-space-025, 2px)"
    });
    $97462970d1593b07$var$RequiredIndicator.displayName = 'RequiredIndicator';
});
const $68788ede79b59f1f$export$4f0520e0d7c4c7b0 = 'field-copy-text';
parcelRegister("gT8ZY", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$fb069c8c90804ba8$export$2e2bcd8739ae039);
    var $7VHMR;
    var $fb069c8c90804ba8$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        copied: {
            "id": "common.field-copy-text.copied",
            "defaultMessage": "Copied!"
        },
        copyFailed: {
            "id": "common.field-copy-text.copy-failed",
            "defaultMessage": "Couldn't copy to clipboard"
        }
    });
});
parcelRegister("9iXoh", function(module, exports) {
    $parcel$export(module.exports, "Root", ()=>$d3bb6eb164541ffa$export$be92b6f5f03c0fe9);
    $parcel$export(module.exports, "FieldContainer", ()=>$d3bb6eb164541ffa$export$b5c4b0e7cd7f148b);
    var $47bDC = parcelRequire("47bDC");
    const $d3bb6eb164541ffa$export$be92b6f5f03c0fe9 = (0, $47bDC.default).div.withConfig({
        componentId: "sc-1g0si8d-0"
    })({
        display: 'flex',
        alignItems: 'flex-end'
    }, (props)=>props.hideFieldText === true ? (0, $47bDC.css)({
            userSelect: 'none'
        }) : '');
    const $d3bb6eb164541ffa$export$b5c4b0e7cd7f148b = (0, $47bDC.default).div.withConfig({
        componentId: "sc-1g0si8d-1"
    })({
        marginRight: "var(--ds-space-100, 8px)"
    }, (props)=>props.shouldFitContainer ? (0, $47bDC.css)({
            flexGrow: 1
        }) : '', (props)=>props.hideFieldText === true ? (0, $47bDC.css)({
            position: 'absolute',
            left: '-9999px',
            height: 0,
            opacity: 0,
            pointerEvents: 'none',
            userSelect: 'none'
        }) : '');
});
parcelRegister("g15xx", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$ac14ce1c250287f9$export$2e2bcd8739ae039);
    var $7VHMR;
    var $ac14ce1c250287f9$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        copyTextLabel: {
            "id": "dashboard-view.copy-link-button.copy-link",
            "defaultMessage": "Copy link"
        },
        copiedLabel: {
            "id": "dashboard-view.copy-link-button.copied",
            "defaultMessage": "Copied!"
        }
    });
});
parcelRegister("eXVsp", function(module, exports) {
    $parcel$export(module.exports, "DeprecationTooltip", ()=>$a9bfc6bd944fc87c$export$3afd8bd60d8bcdb8);
    var $5uXOq = parcelRequire("5uXOq");
    var $6yUsV;
    var $dC5iT;
    var $8CUq3;
    var $koVbs;
    var $hDzhb;
    var $cM8Sm;
    const $a9bfc6bd944fc87c$export$3afd8bd60d8bcdb8 = ()=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            xcss: $a9bfc6bd944fc87c$var$tooltipStyles
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6yUsV")).default), {
            appearance: "warning"
        }, formatMessage((0, (0, parcelRequire("cM8Sm")).default).gadgetDeprecationTooltip, {
            Link: (chunks)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hDzhb")).default), {
                    href: "https://community.atlassian.com/t5/Jira-articles/Update-We-re-ending-support-for-five-dashboard-gadgets-in-May/ba-p/2844556"
                }, chunks),
            br: ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement("br", null), (0, ($parcel$interopDefault($5uXOq))).createElement("br", null))
        })));
    };
    const $a9bfc6bd944fc87c$var$tooltipStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        marginLeft: 'space.050'
    });
});
parcelRegister("6yUsV", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$e2c43b93305034f0$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $f8Sb3;
    var $fVnbM;
    var $ctg8C;
    var $f5TWP;
    var $cgL5K;
    var $60LNO;
    function $e2c43b93305034f0$var$_EMOTION_STRINGIFIED_CSS_ERROR__() {
        return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop).";
    }
    const $e2c43b93305034f0$var$rootStyles = {
        name: "ssyr0c",
        styles: "display:inline-block;max-width:100%;&:focus{outline:1px solid;}&:hover{[data-ds--inline-message--icon]{color:var(--icon-accent-color);}[data-ds--inline-message--button]{text-decoration:underline;}}"
    };
    const $e2c43b93305034f0$var$iconColor = (appearance)=>{
        switch(appearance){
            case 'connectivity':
                return `var(--ds-icon-brand, ${0, $829f609a65d26a98$export$e62e8919cca44969})`;
            case 'confirmation':
                return `var(--ds-icon-success, ${0, $829f609a65d26a98$export$747d86715d4175de})`;
            case 'info':
                return `var(--ds-icon-discovery, ${0, $829f609a65d26a98$export$a7175d4e9206bc80})`;
            case 'warning':
                return `var(--ds-icon-warning, ${0, $829f609a65d26a98$export$5aae31392d72caaa})`;
            case 'error':
                return `var(--ds-icon-danger, ${0, $829f609a65d26a98$export$4a7404f376229999})`;
        }
    };
    const $e2c43b93305034f0$var$InlineMessage = ({ placement: placement = 'bottom-start', secondaryText: secondaryText = '', title: title = '', type: type = 'connectivity', appearance: appearance, children: children, testId: testId, iconLabel: iconLabel })=>{
        const [isOpen, setIsOpen] = (0, $5uXOq.useState)(false);
        const toggleDialog = (0, $5uXOq.useCallback)(()=>{
            setIsOpen((oldState)=>!oldState);
        }, [
            setIsOpen
        ]);
        const onCloseDialog = (0, $5uXOq.useCallback)(()=>setIsOpen(false), [
            setIsOpen
        ]);
        if (!appearance) appearance = type;
        return (0, (0, parcelRequire("f8Sb3")).jsx)("div", {
            css: $e2c43b93305034f0$var$rootStyles,
            style: {
                '--icon-accent-color': $e2c43b93305034f0$var$iconColor(appearance)
            },
            "data-testid": testId
        }, (0, (0, parcelRequire("f8Sb3")).jsx)((0, (0, parcelRequire("ctg8C")).default), {
            onClose: onCloseDialog,
            content: children,
            isOpen: isOpen,
            placement: placement,
            testId: testId && `${testId}--inline-dialog`
        }, (0, (0, parcelRequire("f8Sb3")).jsx)((0, (0, parcelRequire("fVnbM")).default), {
            "data-ds--inline-message--button": true,
            appearance: "subtle-link",
            onClick: toggleDialog,
            spacing: "none",
            testId: testId && `${testId}--button`,
            "aria-expanded": isOpen
        }, (0, (0, parcelRequire("f8Sb3")).jsx)((0, (0, parcelRequire("f5TWP")).default), {
            space: "space.050",
            alignBlock: "center"
        }, (0, (0, parcelRequire("f8Sb3")).jsx)((0, (0, parcelRequire("60LNO")).default), {
            isOpen: isOpen,
            appearance: appearance,
            label: iconLabel
        }), (0, (0, parcelRequire("f8Sb3")).jsx)((0, (0, parcelRequire("f5TWP")).default), {
            space: "space.100"
        }, title && (0, (0, parcelRequire("f8Sb3")).jsx)((0, (0, parcelRequire("cgL5K")).default), {
            weight: "medium",
            testId: testId && `${testId}--title`
        }, title), secondaryText && (0, (0, parcelRequire("f8Sb3")).jsx)((0, (0, parcelRequire("cgL5K")).default), {
            weight: "medium",
            color: "color.text.subtlest",
            maxLines: 1,
            testId: testId && `${testId}--text`
        }, secondaryText))))));
    };
    var $e2c43b93305034f0$export$2e2bcd8739ae039 = $e2c43b93305034f0$var$InlineMessage;
});
parcelRegister("60LNO", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$474777c4d72d6c07$export$2e2bcd8739ae039);
    var $f8Sb3;
    var $jfSgx;
    function $474777c4d72d6c07$var$_EMOTION_STRINGIFIED_CSS_ERROR__() {
        return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop).";
    }
    const $474777c4d72d6c07$var$iconColor = (appearance)=>{
        switch(appearance){
            case 'connectivity':
                return `var(--ds-icon-brand, ${0, $829f609a65d26a98$export$b3de0ae08da2ce64})`;
            case 'confirmation':
                return `var(--ds-icon-success, ${0, $829f609a65d26a98$export$940e1f69e873f202})`;
            case 'info':
                return `var(--ds-icon-discovery, ${0, $829f609a65d26a98$export$b48405b95304b5a1})`;
            case 'warning':
                return `var(--ds-icon-warning, ${0, $829f609a65d26a98$export$c6930421db9fa514})`;
            case 'error':
                return `var(--ds-icon-danger, ${0, $829f609a65d26a98$export$2010d0fb4295b346})`;
        }
    };
    const $474777c4d72d6c07$var$iconWrapperStyles = {
        name: "kw6wgo",
        styles: "display:flex;align-items:center;flex:0 0 auto;color:var(--icon-color)"
    };
    const $474777c4d72d6c07$var$iconColorStyles = {
        name: "1iy94md",
        styles: "color:var(--icon-accent-color)"
    };
    const $474777c4d72d6c07$var$SelectedIcon = ({ appearance: appearance, isOpen: isOpen, label: label })=>{
        const { [appearance]: { icon: Icon, defaultLabel: defaultLabel } } = (0, (0, parcelRequire("jfSgx")).typesMapping);
        return (0, (0, parcelRequire("f8Sb3")).jsx)("span", {
            "data-ds--inline-message--icon": true,
            style: {
                '--icon-color': $474777c4d72d6c07$var$iconColor(appearance)
            },
            css: [
                $474777c4d72d6c07$var$iconWrapperStyles,
                isOpen && $474777c4d72d6c07$var$iconColorStyles
            ]
        }, (0, (0, parcelRequire("f8Sb3")).jsx)(Icon, {
            testId: "inline-message-icon",
            label: label || defaultLabel,
            color: "currentColor",
            LEGACY_size: "medium",
            spacing: "spacious"
        }));
    };
    var $474777c4d72d6c07$export$2e2bcd8739ae039 = $474777c4d72d6c07$var$SelectedIcon;
});
parcelRegister("jfSgx", function(module, exports) {
    $parcel$export(module.exports, "typesMapping", ()=>$e3da74fa628ddd87$export$4d111645c0b39d5d);
    var $dD7Zc;
    var $1jWct;
    var $c2i4s;
    var $bU3PY;
    const $e3da74fa628ddd87$export$4d111645c0b39d5d = {
        connectivity: {
            icon: (0, ($parcel$interopDefault((0, parcelRequire("bU3PY"))))),
            defaultLabel: 'connectivity inline message'
        },
        confirmation: {
            icon: (0, ($parcel$interopDefault((0, parcelRequire("c2i4s"))))),
            defaultLabel: 'confirmation inline message'
        },
        info: {
            icon: (0, ($parcel$interopDefault((0, parcelRequire("1jWct"))))),
            defaultLabel: 'info inline message'
        },
        warning: {
            icon: (0, ($parcel$interopDefault((0, parcelRequire("bU3PY"))))),
            defaultLabel: 'warning inline message'
        },
        error: {
            icon: (0, ($parcel$interopDefault((0, parcelRequire("dD7Zc"))))),
            defaultLabel: 'error inline message'
        }
    };
});
parcelRegister("1jWct", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $0f64b62884a9bedb$var$_react = $0f64b62884a9bedb$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $0f64b62884a9bedb$var$_information = $0f64b62884a9bedb$var$_interopRequireDefault((parcelRequire("2Yv61")));
    var $0f64b62884a9bedb$var$_info = $0f64b62884a9bedb$var$_interopRequireDefault((parcelRequire("2hXfb")));
    function $0f64b62884a9bedb$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $0f64b62884a9bedb$var$InformationIcon = (props)=>$0f64b62884a9bedb$var$_react.default.createElement($0f64b62884a9bedb$var$_information.default, Object.assign({
            LEGACY_fallbackIcon: $0f64b62884a9bedb$var$_info.default
        }, props));
    $0f64b62884a9bedb$var$InformationIcon.Name = 'InformationIconMigration';
    var $0f64b62884a9bedb$var$_default = module.exports.default = $0f64b62884a9bedb$var$InformationIcon;
});
parcelRegister("c2i4s", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $8c33b074f80a91d5$var$_react = $8c33b074f80a91d5$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $8c33b074f80a91d5$var$_success = $8c33b074f80a91d5$var$_interopRequireDefault((parcelRequire("b0JGA")));
    var $8c33b074f80a91d5$var$_checkCircle = $8c33b074f80a91d5$var$_interopRequireDefault((parcelRequire("btivG")));
    function $8c33b074f80a91d5$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $8c33b074f80a91d5$var$SuccessIcon = (props)=>$8c33b074f80a91d5$var$_react.default.createElement($8c33b074f80a91d5$var$_success.default, Object.assign({
            LEGACY_fallbackIcon: $8c33b074f80a91d5$var$_checkCircle.default
        }, props));
    $8c33b074f80a91d5$var$SuccessIcon.Name = 'SuccessIconMigration';
    var $8c33b074f80a91d5$var$_default = module.exports.default = $8c33b074f80a91d5$var$SuccessIcon;
});
parcelRegister("cM8Sm", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$5c54fb5c971a7e8c$export$2e2bcd8739ae039);
    var $7VHMR;
    var $5c54fb5c971a7e8c$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        gadgetDeprecationTooltip: {
            "id": "dashboard-internal-common.common.gadget.deprecation-tooltip.gadget-deprecation-tooltip.non-final",
            "defaultMessage": "Starting May 2025, we're no longer supporting this gadget. Delete it before then to reference its data in the available alternatives.<br></br><Link>More about the gadgets and the extended timeline</Link>"
        }
    });
});
parcelRegister("6Nisr", function(module, exports) {
    $parcel$export(module.exports, "GadgetReadOnlyController", ()=>$8a6270d5e3995ead$export$ef82df624fcfbd37);
    var $5uXOq = parcelRequire("5uXOq");
    var $fJpBG;
    var $83NgX;
    var $jATTU;
    var $8FVKN;
    var $2XTsA;
    var $9TUxn;
    var $HCTtk;
    const $8a6270d5e3995ead$export$ef82df624fcfbd37 = ({ data: { id: id, userPrefs: userPrefs, reactKey: reactKey, configurable: configurable, refreshable: isRefreshableByJira, connect: connect, forge: forge, ...data }, display: display, dashboardRefreshId: dashboardRefreshId, children: children })=>{
        const localStorageService = (0, (0, parcelRequire("83NgX")).useLocalStorageService)(id);
        const { reinitialise: onGadgetKeyChange, isEditable: isReactEditable, isLoading: isReactLoading } = (0, (0, parcelRequire("9TUxn")).useReactGadget)(id, reactKey);
        const automaticRefreshMs = (0, $5uXOq.useMemo)(()=>{
            const { refresh: refresh } = (0, (0, parcelRequire("fJpBG")).decodeUserPreferences)(userPrefs?.fields);
            return parseInt(refresh, 10) * 60000 || null;
        }, [
            userPrefs?.fields
        ]);
        const [isLoading, setIsLoading] = (0, $5uXOq.useState)(true);
        const readonly = (0, (0, parcelRequire("HCTtk")).useGadgetReadOnlyController)({
            userPrefs: userPrefs,
            configurable: isReactEditable ?? configurable,
            ...data
        });
        const ignoreGlobalDashboardRefresh = Boolean(forge?.key || connect?.addonKey) && !isRefreshableByJira;
        const { onRefresh: onRefresh, ...common } = (0, (0, parcelRequire("2XTsA")).useGadgetCommonController)({
            id: id,
            display: display,
            automaticRefreshMs: automaticRefreshMs,
            localStorageService: localStorageService,
            dashboardRefreshId: ignoreGlobalDashboardRefresh ? null : dashboardRefreshId,
            onGadgetKeyChange: onGadgetKeyChange
        });
        const ecosystemRefresh = (0, (0, parcelRequire("jATTU")).useEcosystemRefresh)({
            id: id,
            connect: connect,
            forge: forge
        });
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("8FVKN")).GadgetControllerContextProvider), {
            value: {
                ...common,
                ...readonly,
                isRenamable: false,
                isLoading: isReactLoading ?? isLoading,
                setIsLoading: reactKey ? ()=>undefined : setIsLoading,
                onRefresh: isRefreshableByJira ? onRefresh : ecosystemRefresh.onRefresh ?? onRefresh
            }
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("div", {
            ref: ecosystemRefresh.wrapperRef
        }, children));
    };
});
parcelRegister("83NgX", function(module, exports) {
    $parcel$export(module.exports, "useLocalStorageService", ()=>$d6a2211d9def985e$export$aae02958650bede0);
    var $5uXOq = parcelRequire("5uXOq");
    var $19nfo;
    const $d6a2211d9def985e$export$d4655e9fae202a89 = (id)=>(0, $5uXOq.useCallback)((...args)=>(0, (0, parcelRequire("19nfo")).getGadgetDataFromLocalStorage)(id, ...args), [
            id
        ]);
    const $d6a2211d9def985e$export$3bf5aef0d5c5f925 = (id)=>(0, $5uXOq.useCallback)((...args)=>(0, (0, parcelRequire("19nfo")).putGadgetDataFromLocalStorage)(id, ...args), [
            id
        ]);
    const $d6a2211d9def985e$export$84a14851141ef231 = (id)=>(0, $5uXOq.useCallback)((...args)=>(0, (0, parcelRequire("19nfo")).deleteGadgetDataFromLocalStorage)(id, ...args), [
            id
        ]);
    const $d6a2211d9def985e$export$aae02958650bede0 = (id)=>{
        const get = $d6a2211d9def985e$export$d4655e9fae202a89(id);
        const put = $d6a2211d9def985e$export$3bf5aef0d5c5f925(id);
        const del = $d6a2211d9def985e$export$84a14851141ef231(id);
        return {
            get: get,
            put: put,
            del: del
        };
    };
});
parcelRegister("jATTU", function(module, exports) {
    $parcel$export(module.exports, "useEcosystemRefresh", ()=>$dc36a798f3ea6a32$export$a96edb2d9dc3e9bf);
    var $5uXOq = parcelRequire("5uXOq");
    var $3v45P;
    var $JIPqS;
    const $dc36a798f3ea6a32$export$1da99641cec7fdf3 = (payload, id)=>window.connectHost?.broadcastEvent((0, (0, parcelRequire("JIPqS")).CONNECT_GADGET_REFRESH_REQUEST_EVENT), {
            id: id
        }, payload);
    const $dc36a798f3ea6a32$export$a96edb2d9dc3e9bf = ({ id: id, connect: connect, forge: forge })=>{
        const isConnect = Boolean(connect?.addonKey);
        const isForge = Boolean(forge?.key);
        const wrapperRef = (0, $5uXOq.useRef)(null);
        const ecosystemOnRefresh = (0, $5uXOq.useCallback)(()=>{
            const payload = {
                origin: 'gadget',
                gadgetId: id
            };
            if (isConnect) $dc36a798f3ea6a32$export$1da99641cec7fdf3(payload, wrapperRef.current?.querySelector('iframe')?.id);
            else if (isForge) (0, (0, parcelRequire("3v45P")).emit)((0, (0, parcelRequire("JIPqS")).FORGE_JIRA_DASHBOARD_GADGET_REFRESH_EVENT), payload);
        }, [
            id,
            isConnect,
            isForge
        ]);
        return {
            wrapperRef: wrapperRef,
            onRefresh: isConnect || isForge ? ecosystemOnRefresh : undefined
        };
    };
});
parcelRegister("8FVKN", function(module, exports) {
    $parcel$export(module.exports, "GadgetControllerContextProvider", ()=>$34f96cfa0524cd59$export$4de1bad6b03d7763);
    $parcel$export(module.exports, "GadgetControllerContextConsumer", ()=>$34f96cfa0524cd59$export$4d4eb85e0f24da5c);
    $parcel$export(module.exports, "useGadgetRefresh", ()=>$34f96cfa0524cd59$export$9e5c8ef16417c38e);
    $parcel$export(module.exports, "useGadgetRenderKey", ()=>$34f96cfa0524cd59$export$b4c09774e3d2cd2d);
    $parcel$export(module.exports, "useIsConnectDisabled", ()=>$34f96cfa0524cd59$export$2afbda65cda4cd52);
    $parcel$export(module.exports, "useGadgetSetPreferences", ()=>$34f96cfa0524cd59$export$263d54a76be27f46);
    var $jnkfq;
    const { createUseContextField: $34f96cfa0524cd59$export$56fb4dd71461f98e, Provider: $34f96cfa0524cd59$export$4de1bad6b03d7763, Consumer: $34f96cfa0524cd59$export$4d4eb85e0f24da5c } = (0, (0, parcelRequire("jnkfq")).createRendererContext)();
    const $34f96cfa0524cd59$export$9e5c8ef16417c38e = $34f96cfa0524cd59$export$56fb4dd71461f98e('onRefresh');
    const $34f96cfa0524cd59$export$b4c09774e3d2cd2d = $34f96cfa0524cd59$export$56fb4dd71461f98e('renderKey');
    const $34f96cfa0524cd59$export$2afbda65cda4cd52 = $34f96cfa0524cd59$export$56fb4dd71461f98e('isConnectDisabled');
    const $34f96cfa0524cd59$export$263d54a76be27f46 = $34f96cfa0524cd59$export$56fb4dd71461f98e('setPreferences');
});
parcelRegister("2XTsA", function(module, exports) {
    $parcel$export(module.exports, "useGadgetCommonController", ()=>$369e7d9cc957d88d$export$3db154a5767b2bd0);
    var $5uXOq = parcelRequire("5uXOq");
    var $JIPqS;
    const $369e7d9cc957d88d$export$3db154a5767b2bd0 = ({ id: id, display: display, automaticRefreshMs: automaticRefreshMs, localStorageService: { get: localStorageServiceGet, put: localStorageServicePut, del: localStorageServiceDel }, dashboardRefreshId: dashboardRefreshId, onGadgetKeyChange: onGadgetKeyChange })=>{
        const isDisplayed = display !== (0, (0, parcelRequire("JIPqS")).GADGET_DISPLAY).HIDDEN;
        const isMaximized = display === (0, (0, parcelRequire("JIPqS")).GADGET_DISPLAY).MAXIMIZED;
        const [isMinimized, setIsMinimized] = (0, $5uXOq.useState)(()=>localStorageServiceGet('minimized') === 'true');
        const onMinimizeOrRestore = (0, $5uXOq.useCallback)((doMinimize)=>{
            if (doMinimize !== isMinimized) {
                if (doMinimize) localStorageServicePut('minimized', 'true');
                else localStorageServiceDel('minimized');
                setIsMinimized(doMinimize);
            }
        }, [
            isMinimized,
            setIsMinimized,
            localStorageServicePut,
            localStorageServiceDel
        ]);
        const [shouldUseCache, setShouldUseCache] = (0, $5uXOq.useState)(true);
        const [refreshId, setRefreshId] = (0, $5uXOq.useState)(Date.now());
        const renderKey = `${id}-${refreshId}-${dashboardRefreshId}-${isMaximized ? 'canvas' : 'default'}`;
        const prevRenderKey = (0, $5uXOq.useRef)(renderKey);
        const onRefresh = (0, $5uXOq.useCallback)(()=>{
            setShouldUseCache(false);
            setRefreshId(Date.now());
        }, []);
        (0, $5uXOq.useEffect)(()=>{
            if (automaticRefreshMs == null || automaticRefreshMs <= 0) return ()=>{};
            const refreshIntervalMs = Math.min(automaticRefreshMs, Math.pow(2, 31) - 1);
            const interval = setInterval(onRefresh, refreshIntervalMs);
            return ()=>{
                clearInterval(interval);
            };
        }, [
            automaticRefreshMs,
            onRefresh
        ]);
        (0, $5uXOq.useLayoutEffect)(()=>{
            if (prevRenderKey.current !== renderKey) {
                prevRenderKey.current = renderKey;
                onGadgetKeyChange();
            }
        }, [
            renderKey,
            onGadgetKeyChange
        ]);
        const lastRefreshed = Math.max(Number(dashboardRefreshId), refreshId);
        return {
            isDisplayed: isDisplayed,
            isMaximized: isMaximized,
            isMinimized: isMinimized,
            onMinimizeOrRestore: onMinimizeOrRestore,
            renderKey: renderKey,
            shouldUseCache: shouldUseCache,
            onRefresh: onRefresh,
            lastRefreshed: lastRefreshed
        };
    };
});
parcelRegister("9TUxn", function(module, exports) {
    $parcel$export(module.exports, "useReactGadget", ()=>$f21126f6c18da11d$export$a821269bdacf74ba);
    var $5uXOq = parcelRequire("5uXOq");
    var $kspzT;
    var $gh1hi;
    var $iXzvf;
    const $f21126f6c18da11d$export$a821269bdacf74ba = (gadgetId, reactKey)=>{
        const { update: update, refresh: refresh, loading: loading } = (0, (0, parcelRequire("gh1hi")).useDashboardReactGadgetsResource)();
        const [resetId, setResetId] = (0, $5uXOq.useState)(null);
        const [namedState, setNamedState] = (0, $5uXOq.useState)('idle');
        const isReactGadget = (0, (0, parcelRequire("kspzT")).isReactKey)(reactKey);
        const reinitialise = (0, $5uXOq.useCallback)(()=>{
            if (namedState === 'idle') {
                if (loading) {
                    setResetId(gadgetId);
                    setNamedState('update');
                } else {
                    update((0, (0, parcelRequire("iXzvf")).lensReactGadget)(gadgetId, null));
                    setNamedState('refresh');
                }
            }
        }, [
            gadgetId,
            loading,
            update,
            namedState
        ]);
        (0, $5uXOq.useLayoutEffect)(()=>{
            if (!loading && resetId != null && namedState === 'update') {
                setResetId(null);
                update((0, (0, parcelRequire("iXzvf")).lensReactGadget)(resetId, null));
                setNamedState('refresh');
            }
        }, [
            loading,
            update,
            resetId,
            namedState
        ]);
        (0, $5uXOq.useEffect)(()=>{
            if (namedState === 'refresh') {
                setNamedState('loading');
                refresh();
            }
        }, [
            refresh,
            namedState
        ]);
        (0, $5uXOq.useEffect)(()=>{
            if (!loading && namedState === 'loading') setNamedState('idle');
        }, [
            loading,
            namedState
        ]);
        if (!isReactGadget) return {
            reinitialise: ()=>undefined,
            isEditable: null,
            isLoading: null
        };
        const { componentEdit: componentEdit } = (0, (0, parcelRequire("kspzT")).default)[reactKey];
        return {
            reinitialise: reinitialise,
            isEditable: Boolean(componentEdit),
            isLoading: namedState === 'loading' || loading && namedState === 'idle'
        };
    };
});
parcelRegister("iXzvf", function(module, exports) {
    $parcel$export(module.exports, "lensReactGadget", ()=>$18ff57dda50a1c7b$export$703ae6d6d75e47f1);
    const $18ff57dda50a1c7b$export$bada82ca295a26d5 = (updateGadgets)=>(resource)=>resource && {
                ...resource,
                reactGadgetsState: updateGadgets(resource.reactGadgetsState)
            };
    const $18ff57dda50a1c7b$export$703ae6d6d75e47f1 = (id, setterOrValue)=>{
        const setter = typeof setterOrValue === 'function' ? setterOrValue : ()=>setterOrValue;
        return $18ff57dda50a1c7b$export$bada82ca295a26d5((reactGadgetsState)=>{
            if (!reactGadgetsState || !(id in reactGadgetsState)) return reactGadgetsState;
            const prevStateOrError = reactGadgetsState[id];
            const newState = setter(prevStateOrError);
            const prevState = prevStateOrError === null || prevStateOrError instanceof Error ? {} : prevStateOrError;
            return {
                ...reactGadgetsState,
                [id]: newState === null ? null : {
                    ...prevState,
                    ...newState
                }
            };
        });
    };
});
parcelRegister("HCTtk", function(module, exports) {
    $parcel$export(module.exports, "useGadgetReadOnlyController", ()=>$b218980b25edb990$export$8c1c073a838c204c);
    var $8Rkzz = parcelRequire("8Rkzz");
    var $h7J1E;
    var $l7m8v;
    const $b218980b25edb990$export$8c1c073a838c204c = ({ customisedTitle: customisedTitle, title: defaultTitle, configurable: configurable, hasNonHiddenUserPrefs: hasNonHiddenUserPrefs, renderedGadgetUrl: renderedGadgetUrl, userPrefs: userPrefs })=>{
        const { isUnconfigured: isUnconfigured } = (0, (0, parcelRequire("l7m8v")).useConfigurationStatus)({
            configurable: configurable,
            hasNonHiddenUserPrefs: hasNonHiddenUserPrefs,
            renderedGadgetUrl: renderedGadgetUrl,
            userPrefs: userPrefs
        });
        const { title: title, setDefaultTitle: setDefaultTitle, setTitle: setTitle } = (0, (0, parcelRequire("h7J1E")).useGadgetTitle)({
            defaultTitle: defaultTitle,
            title: customisedTitle
        });
        return {
            isUnconfigured: isUnconfigured,
            isEditModeEnabled: false,
            isEditButtonDisabled: true,
            isInEditMode: false,
            isConnectDisabled: false,
            onEditModeTrigger: (0, ($parcel$interopDefault($8Rkzz))),
            onEditModeCancel: (0, ($parcel$interopDefault($8Rkzz))),
            title: title,
            isRenaming: false,
            setDefaultTitle: setDefaultTitle,
            onRenameTrigger: (0, ($parcel$interopDefault($8Rkzz))),
            onRenameSave: setTitle,
            onRenameCancel: (0, ($parcel$interopDefault($8Rkzz))),
            setPreferences: ()=>null
        };
    };
});
parcelRegister("l7m8v", function(module, exports) {
    $parcel$export(module.exports, "useConfigurationStatus", ()=>$93e0d1b0527a2ad8$export$416bc2c29f65a3f8);
    var $5uXOq = parcelRequire("5uXOq");
    var $5IKQ6;
    const $93e0d1b0527a2ad8$export$416bc2c29f65a3f8 = ({ configurable: configurable, hasNonHiddenUserPrefs: hasNonHiddenUserPrefs, renderedGadgetUrl: renderedGadgetUrl, userPrefs: userPrefs })=>{
        const isConfigurable = Boolean(configurable || hasNonHiddenUserPrefs || renderedGadgetUrl);
        const isUnconfigured = (0, $5uXOq.useMemo)(()=>isConfigurable && !(0, (0, parcelRequire("5IKQ6")).isGadgetConfigured)(userPrefs), [
            isConfigurable,
            userPrefs
        ]);
        return {
            isConfigurable: isConfigurable,
            isUnconfigured: isUnconfigured
        };
    };
});
parcelRegister("lwPtc", function(module, exports) {
    $parcel$export(module.exports, "GadgetInner", ()=>$1be6d64e8f66591c$export$9bb8ef6d23720658);
    var $5uXOq = parcelRequire("5uXOq");
    var $kspzT;
    var $l8ruj;
    var $8UPoO;
    var $gc2JH;
    var $cmgca;
    var $eW8ji;
    var $1NzHy;
    var $9sF5P;
    var $k3y1k;
    var $ce1BZ;
    const $1be6d64e8f66591c$export$9bb8ef6d23720658 = ({ dashboardId: dashboardId, data: data, isUnconfigured: isUnconfigured, isEditModeEnabled: isEditModeEnabled, isLoading: isLoading, isInEditMode: isInEditMode, setIsLoading: setIsLoading, setDefaultTitle: setDefaultTitle, setTitle: setTitle, shouldUseCache: shouldUseCache, isMaximized: isMaximized, onEditModeCancel: onEditModeCancel, unconfiguredFallback: unconfiguredFallback, onRegisterConnectGadget: onRegisterConnectGadget, onUnregisterConnectGadget: onUnregisterConnectGadget })=>{
        const enhancedContext = (0, (0, parcelRequire("gc2JH")).useGadgetDataContextEnhancer)(data.context);
        const onGadgetRender = (0, (0, parcelRequire("8UPoO")).useOnGadgetRender)(data.id);
        const unconfiguredModernGadgetInViewMode = !isInEditMode && isUnconfigured && !(0, (0, parcelRequire("cmgca")).isConnectGadget)(data);
        const localStorageHeight = (0, (0, parcelRequire("8UPoO")).useLocalStorageGadgetHeight)(data.id);
        if (unconfiguredFallback != null && unconfiguredModernGadgetInViewMode) return unconfiguredFallback;
        if ((0, (0, parcelRequire("cmgca")).isConnectGadget)(data)) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("l8ruj")).default), {
            name: "gadget-inner-connect"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1NzHy")).Connect), {
            dashboardId: dashboardId,
            gadgetId: data.id,
            inlineHtml: data.inlineHtml,
            isLoading: isLoading,
            isInEditMode: isInEditMode,
            setIsLoading: setIsLoading,
            onEditModeCancel: onEditModeCancel,
            onRegister: onRegisterConnectGadget,
            onUnregister: onUnregisterConnectGadget,
            setTitle: setTitle
        }));
        if (data.amdModule != null) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("l8ruj")).default), {
            name: "gadget-inner-amd"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("eW8ji")).Amd), {
            dashboardId: dashboardId,
            gadgetId: data.id,
            amdModule: data.amdModule,
            context: enhancedContext,
            defaultTitle: data.title,
            userPrefs: data.userPrefs,
            inlineHtml: data.inlineHtml,
            isEditable: isEditModeEnabled,
            isLoading: isLoading,
            isInEditMode: isInEditMode,
            setIsLoading: setIsLoading,
            setDefaultTitle: setDefaultTitle,
            shouldUseCache: shouldUseCache,
            isMaximized: isMaximized,
            onEditModeCancel: onEditModeCancel
        }));
        if (data.inlineHtml != null && data.renderedGadgetUrl == null) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("l8ruj")).default), {
            name: "gadget-inner-inline-html"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("k3y1k")).InlineHtml), {
            id: data.id,
            inlineHtml: data.inlineHtml,
            isLoading: isLoading,
            setIsLoading: setIsLoading
        }));
        if (data.forge != null) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("l8ruj")).default), {
            name: "gadget-inner-forge"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9sF5P")).Forge), {
            forgeKey: data.forge.key,
            gadgetData: data,
            isInEditMode: isInEditMode,
            onEditModeCancel: onEditModeCancel,
            setIsLoading: setIsLoading
        }));
        if ((0, (0, parcelRequire("kspzT")).isReactKey)(data.reactKey)) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("l8ruj")).default), {
            name: "gadget-inner-react"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ce1BZ")).ReactGadget), {
            id: data.id,
            reactKey: data.reactKey,
            onEditModeCancel: onEditModeCancel,
            isInEditMode: isInEditMode,
            userPrefs: data.userPrefs,
            onRenderComplete: onGadgetRender,
            setDefaultTitle: setDefaultTitle,
            customSkeletonHeightInPx: localStorageHeight
        }));
        throw new Error(`Unrecognised gadget type [${data.title}, ${data?.gadgetSpecUrl}]`);
    };
});
parcelRegister("gc2JH", function(module, exports) {
    $parcel$export(module.exports, "useGadgetDataContextEnhancer", ()=>$ef0abc66ae6c989f$export$c9b763726c221f0f);
    var $5uXOq = parcelRequire("5uXOq");
    var $90yGq;
    var $8wYJI;
    const $ef0abc66ae6c989f$export$191677f0ab656833 = ({ user: user, ...context }, enhancedUser)=>({
            ...context,
            user: {
                ...user,
                ...enhancedUser
            }
        });
    const $ef0abc66ae6c989f$export$c9b763726c221f0f = (data)=>{
        const accountId = (0, (0, parcelRequire("90yGq")).useAccountId)();
        const languageTag = (0, (0, parcelRequire("8wYJI")).useLanguageTag)();
        return (0, $5uXOq.useMemo)(()=>$ef0abc66ae6c989f$export$191677f0ab656833(data, {
                accountId: accountId,
                languageTag: languageTag
            }), [
            accountId,
            data,
            languageTag
        ]);
    };
});
parcelRegister("8wYJI", function(module, exports) {
    $parcel$export(module.exports, "useLanguageTag", ()=>$98f5b4da7049a1e2$export$dbb9c2c3f6b09f02);
    var $9OXo1;
    const $98f5b4da7049a1e2$export$271af7ea6b55125e = 'en-US';
    const $98f5b4da7049a1e2$export$dbb9c2c3f6b09f02 = ()=>{
        const tenantContext = (0, (0, parcelRequire("9OXo1")).useTenantContext)();
        return tenantContext && tenantContext.languageTag || $98f5b4da7049a1e2$export$271af7ea6b55125e;
    };
});
parcelRegister("cmgca", function(module, exports) {
    $parcel$export(module.exports, "isConnectGadget", ()=>$d6e9d4b1bec846d6$export$d4464474d72c938d);
    var $JIPqS;
    const $d6e9d4b1bec846d6$export$d4464474d72c938d = ({ amdModule: amdModule })=>amdModule === (0, (0, parcelRequire("JIPqS")).CONNECT_ITEM_AMD_MODULE);
});
parcelRegister("eW8ji", function(module, exports) {
    $parcel$export(module.exports, "Amd", ()=>$f71dbcea44b19d9c$export$f7e1ea694836dc0c);
    var $5uXOq = parcelRequire("5uXOq");
    var $hh0bW;
    var $dJl1T;
    var $JIPqS;
    var $fJpBG;
    var $jcw0u;
    var $gh1hi;
    var $bVDE5;
    var $4uQB7;
    var $c1XZn;
    var $jszBL;
    var $8FVKN;
    var $ih7Bj;
    var $7vg9b;
    var $i0rkS;
    var $5TsWX;
    var $ikoXd;
    var $WIEWs;
    const $f71dbcea44b19d9c$var$expAttributes = {
        analyticsSource: 'dashboard',
        experience: (0, (0, parcelRequire("JIPqS")).VIEW_WRM_GADGET_EXPERIENCE),
        application: null,
        edition: null,
        additionalAttributes: {}
    };
    const $f71dbcea44b19d9c$var$reportError = (gadgetId, reportGadgetMetrics, error)=>{
        reportGadgetMetrics(gadgetId, (0, (0, parcelRequire("5TsWX")).GADGET_START_EVENT), 'ErrorMessage');
        reportGadgetMetrics(gadgetId, (0, (0, parcelRequire("5TsWX")).GADGET_RENDERING_FINISHED_EVENT), 'ErrorMessage');
        (0, (0, parcelRequire("hh0bW")).default)({
            ...$f71dbcea44b19d9c$var$expAttributes,
            wasExperienceSuccesful: false
        });
        (0, (0, parcelRequire("jcw0u")).default)({
            error: error,
            meta: {
                id: (0, (0, parcelRequire("JIPqS")).VIEW_WRM_GADGET_EXPERIENCE),
                packageName: 'jiraDashboardInternalCommon'
            }
        });
    };
    const $f71dbcea44b19d9c$export$f7e1ea694836dc0c = ({ dashboardId: dashboardId, gadgetId: gadgetId, amdModule: amdModule, context: context, defaultTitle: defaultTitle, userPrefs: userPrefs, inlineHtml: inlineHtml, isEditable: isEditable, isLoading: isLoading, isInEditMode: isInEditMode, setIsLoading: setIsLoading, setDefaultTitle: setDefaultTitle, shouldUseCache: shouldUseCache, isMaximized: isMaximized, onEditModeCancel: onEditModeCancel })=>{
        const [{ currentPageId: currentPageId }] = (0, (0, parcelRequire("bVDE5")).useSpaStateTransition)();
        const { loading: loadingB, data: data } = (0, (0, parcelRequire("gh1hi")).useDashboardAMDModulesResource)();
        const { amdModules: amdModules = null } = data ?? {};
        const setPreferences = (0, (0, parcelRequire("8FVKN")).useGadgetSetPreferences)();
        const preference = (0, $5uXOq.useMemo)(()=>(0, (0, parcelRequire("fJpBG")).decodeUserPreferences)(userPrefs?.fields), [
            userPrefs?.fields
        ]);
        const module = (0, $5uXOq.useRef)(null);
        const divRef = (0, $5uXOq.useRef)(null);
        const { addEventHandler: addEventHandler, triggerEvent: triggerEvent, removeEventHandlers: removeEventHandlers } = (0, (0, parcelRequire("jszBL")).useEventPublisher)();
        const isGlobalsReady = (0, (0, parcelRequire("ih7Bj")).useIsGlobalsReady)();
        const isRenderable = isGlobalsReady && !loadingB && amdModules?.[amdModule ?? ''] != null || module.current != null;
        const isSkeleton = isLoading && !isInEditMode;
        const [, { broadcastMessage: broadcastMessageBusListener }] = (0, (0, parcelRequire("i0rkS")).useMessageBus)();
        const [gadgetError, setGadgetError] = (0, $5uXOq.useState)(null);
        (0, (0, parcelRequire("7vg9b")).useLinkInterceptor)(divRef.current, isLoading);
        const reportGadgetMetrics = (0, $5uXOq.useCallback)((gid, eventType, contentType, markName, markStage)=>{
            broadcastMessageBusListener((0, (0, parcelRequire("JIPqS")).GADGET_METRICS_TYPE).REPORT, {
                eventType: eventType,
                contentType: contentType,
                markName: markName,
                markStage: markStage,
                source: (0, (0, parcelRequire("5TsWX")).WRM_GADGET_METRICS)
            }, {
                gadgetId: gid,
                pageId: currentPageId
            });
        }, [
            broadcastMessageBusListener,
            currentPageId
        ]);
        const gadgetApi = (0, $5uXOq.useMemo)(()=>(0, (0, parcelRequire("ikoXd")).getInlineGadgetApi)({
                gadgetId: gadgetId,
                amdModule: amdModule ?? '',
                context: context,
                defaultTitle: defaultTitle,
                isEditable: isEditable,
                closeEdit: onEditModeCancel,
                setIsLoading: setIsLoading,
                ref: divRef,
                setDefaultTitle: setDefaultTitle,
                reportGadgetMetrics: reportGadgetMetrics,
                dashboardId: dashboardId,
                addEventHandler: addEventHandler,
                removeEventHandlers: removeEventHandlers,
                triggerEvent: triggerEvent,
                setPreferences: setPreferences,
                shouldUseCache: shouldUseCache,
                view: isMaximized ? 'canvas' : 'default',
                setError: setGadgetError
            }), []);
        (0, $5uXOq.useLayoutEffect)(()=>{
            if (inlineHtml == null || divRef.current == null) return;
            divRef.current.innerHTML = '';
            divRef.current.appendChild(document.createRange().createContextualFragment(inlineHtml));
        }, [
            inlineHtml
        ]);
        (0, $5uXOq.useLayoutEffect)(()=>{
            (async ()=>{
                if (!isRenderable || amdModule == null) return;
                if (module.current == null) {
                    const Module = amdModules[amdModule];
                    try {
                        module.current = new Module(gadgetApi);
                    } catch (error) {
                        $f71dbcea44b19d9c$var$reportError(gadgetId, reportGadgetMetrics, error);
                        setGadgetError((0, (0, parcelRequire("JIPqS")).GADGET_ERROR_TYPE).VIEW_ERROR);
                        (0, (0, parcelRequire("dJl1T")).default).safeErrorWithoutCustomerData('spa-apps.dashboard.gadget.amd', `Failed to instantiate AMD module [${amdModule}]`, error);
                    }
                }
                if (module.current != null) {
                    (0, (0, parcelRequire("dJl1T")).default).safeInfoWithoutCustomerData('spa-apps.dashboard.gadget.amd', `Render AMD module [${amdModule}] in ${isInEditMode ? 'Edit' : 'View'} mode`);
                    reportGadgetMetrics(gadgetId, (0, (0, parcelRequire("5TsWX")).GADGET_RENDER_CONTAINER_EVENT), isInEditMode ? 'Config' : 'View', undefined, undefined);
                    const render = isInEditMode && module.current?.renderEdit || preference.isConfigured && module.current?.render || null;
                    if (render == null) {
                        $f71dbcea44b19d9c$var$reportError(gadgetId, reportGadgetMetrics, new Error('gadget render is null'));
                        setGadgetError((0, (0, parcelRequire("JIPqS")).GADGET_ERROR_TYPE).VIEW_ERROR);
                        (0, (0, parcelRequire("dJl1T")).default).safeErrorWithoutCustomerData('spa-apps.dashboard.gadget.amd', `Render AMD module [${amdModule}] failure in ${isInEditMode ? 'Edit' : 'View'} mode, render method missing`);
                    } else {
                        try {
                            render.call(module.current, window.jQuery(divRef.current), preference);
                            setGadgetError(null);
                            (0, (0, parcelRequire("hh0bW")).default)({
                                ...$f71dbcea44b19d9c$var$expAttributes,
                                wasExperienceSuccesful: true
                            });
                        } catch (error) {
                            $f71dbcea44b19d9c$var$reportError(gadgetId, reportGadgetMetrics, error);
                            setGadgetError((0, (0, parcelRequire("JIPqS")).GADGET_ERROR_TYPE).VIEW_ERROR);
                            (0, (0, parcelRequire("dJl1T")).default).safeErrorWithoutCustomerData('spa-apps.dashboard.gadget.amd', `Render AMD module [${amdModule}] failure in ${isInEditMode ? 'Edit' : 'View'} mode, render method throws error`, error);
                        }
                        triggerEvent('afterRender');
                    }
                }
            })();
        }, [
            isInEditMode,
            preference,
            isRenderable
        ]);
        (0, $5uXOq.useEffect)(()=>()=>{
                if (module.current) module.current = null;
            }, []);
        return gadgetError != null ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4uQB7")).GadgetErrorFallback), {
            errorType: gadgetError,
            id: gadgetId
        }) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("WIEWs")).Container), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("WIEWs")).GadgetContainer), {
            id: `gadget-${gadgetId}`,
            ref: divRef,
            isHidden: isSkeleton
        }), isSkeleton && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("WIEWs")).SkeletonWrapper), {
            "data-testid": "dashboard-internal-common.ui.gadget.gadget-inner.amd.loading-skeleton"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("c1XZn")).SkeletonGadgetContent), {
            dashboardId: dashboardId,
            gadgetId: gadgetId
        })));
    };
});
parcelRegister("4uQB7", function(module, exports) {
    $parcel$export(module.exports, "GadgetErrorFallbackBase", ()=>$73c4c8c0862da954$export$f9edce43cd0e391a);
    $parcel$export(module.exports, "GadgetErrorFallback", ()=>$73c4c8c0862da954$export$6de1d4202af2589f);
    var $5uXOq = parcelRequire("5uXOq");
    var $fVnbM;
    var $gtreQ;
    var $koVbs;
    var $8FVKN;
    var $ii3gE;
    var $2n7g7;
    var $e0K9l;
    const $73c4c8c0862da954$export$f9edce43cd0e391a = ({ id: id, message: message, isRefresh: isRefresh = false })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const onRefreshClick = (0, (0, parcelRequire("8FVKN")).useGadgetRefresh)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ii3gE")).GadgetFallback), {
            id: id,
            icon: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("gtreQ"))))), {
                label: formatMessage((0, (0, parcelRequire("2n7g7")).default).errorTitle),
                primaryColor: `var(--ds-text-warning, ${(0, $829f609a65d26a98$exports).Y300})`
            }),
            action: isRefresh && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
                appearance: "default",
                spacing: "default",
                onClick: onRefreshClick
            }, formatMessage((0, (0, parcelRequire("2n7g7")).default).refreshButtonText))
        }, message);
    };
    const $73c4c8c0862da954$export$6de1d4202af2589f = ({ id: id, errorType: errorType })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement($73c4c8c0862da954$export$f9edce43cd0e391a, {
            message: formatMessage((0, (0, parcelRequire("e0K9l")).MESSAGES_BY_ERROR)[errorType]),
            isRefresh: (0, (0, parcelRequire("e0K9l")).IS_REFRESH_BY_ERROR)[errorType],
            id: id
        });
    };
});
parcelRegister("ii3gE", function(module, exports) {
    $parcel$export(module.exports, "GadgetFallback", ()=>$b7e4ec4cca3d9de1$export$5c6c3d588eda4d74);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $dC5iT;
    var $8CUq3;
    var $8UPoO;
    const $b7e4ec4cca3d9de1$export$5c6c3d588eda4d74 = ({ icon: icon, action: action, id: id, children: children })=>{
        const ref = (0, $5uXOq.useRef)(null);
        const onGadgetRender = (0, (0, parcelRequire("8UPoO")).useOnGadgetRender)(id);
        (0, $5uXOq.useEffect)(()=>{
            const measuredHeight = ref.current?.getBoundingClientRect().height;
            onGadgetRender(measuredHeight === 0 ? undefined : measuredHeight);
        }, [
            onGadgetRender
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement($b7e4ec4cca3d9de1$var$GadgetFallbackWrapper, {
            ref: ref
        }, icon, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            xcss: $b7e4ec4cca3d9de1$var$contentStyles
        }, children), action);
    };
    const $b7e4ec4cca3d9de1$var$GadgetFallbackWrapper = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1yt41ejb _bfhk11bn _2rkoiti9 _1e0c1txw _2lx21bp4 _1bah1h6o _4cvr1h6o _syaz1dyx _vchhusvi _y3gn1h6o _4t3i1kw7",
                __cmplp.className
            ])
        });
    });
    const $b7e4ec4cca3d9de1$var$contentStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        font: "var(--ds-font-heading-xxsmall, normal 600 12px/16px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)",
        margin: 'space.100'
    });
});
parcelRegister("2n7g7", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$8791e45dc80d54b0$export$2e2bcd8739ae039);
    var $7VHMR;
    var $8791e45dc80d54b0$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        errorMessage: {
            "id": "dashboard-internal-common.common.gadget.gadget-error-fallback.error-message",
            "defaultMessage": "We're having some trouble displaying this gadget. It's probably a temporary problem. Try refreshing the gadget."
        },
        errorTitle: {
            "id": "dashboard-internal-common.common.gadget.gadget-error-fallback.error-title",
            "defaultMessage": "Warning"
        },
        configErrorMessage: {
            "id": "dashboard-internal-common.common.gadget.gadget-error-fallback.config-error-message",
            "defaultMessage": "Something went wrong. We could not save your gadget configuration. Refresh this gadget to update its configuration and try again."
        },
        refreshButtonText: {
            "id": "dashboard-internal-common.common.gadget.gadget-error-fallback.refresh-button-text",
            "defaultMessage": "Refresh"
        },
        notSupportedErrorMessage: {
            "id": "dashboard-internal-common.common.gadget.gadget-error-fallback.not-supported-error-message",
            "defaultMessage": "This gadget is no longer supported by Jira."
        }
    });
});
parcelRegister("e0K9l", function(module, exports) {
    $parcel$export(module.exports, "MESSAGES_BY_ERROR", ()=>$96b21687e2fe8cc8$export$8c49a4613a61ecc1);
    $parcel$export(module.exports, "IS_REFRESH_BY_ERROR", ()=>$96b21687e2fe8cc8$export$1b18ce488a22b856);
    var $JIPqS;
    var $2n7g7;
    const $96b21687e2fe8cc8$export$8c49a4613a61ecc1 = {
        [(0, (0, parcelRequire("JIPqS")).GADGET_ERROR_TYPE).CONFIG_ERROR]: (0, (0, parcelRequire("2n7g7")).default).configErrorMessage,
        [(0, (0, parcelRequire("JIPqS")).GADGET_ERROR_TYPE).VIEW_ERROR]: (0, (0, parcelRequire("2n7g7")).default).errorMessage,
        [(0, (0, parcelRequire("JIPqS")).GADGET_ERROR_TYPE).EDIT_ERROR]: (0, (0, parcelRequire("2n7g7")).default).errorMessage,
        [(0, (0, parcelRequire("JIPqS")).GADGET_ERROR_TYPE).NOT_SUPPORTED_ERROR]: (0, (0, parcelRequire("2n7g7")).default).notSupportedErrorMessage
    };
    const $96b21687e2fe8cc8$export$1b18ce488a22b856 = {
        [(0, (0, parcelRequire("JIPqS")).GADGET_ERROR_TYPE).CONFIG_ERROR]: true,
        [(0, (0, parcelRequire("JIPqS")).GADGET_ERROR_TYPE).VIEW_ERROR]: true,
        [(0, (0, parcelRequire("JIPqS")).GADGET_ERROR_TYPE).EDIT_ERROR]: true,
        [(0, (0, parcelRequire("JIPqS")).GADGET_ERROR_TYPE).NOT_SUPPORTED_ERROR]: false
    };
});
parcelRegister("c1XZn", function(module, exports) {
    $parcel$export(module.exports, "SkeletonGadgetContent", ()=>$5d9b139cc9647a02$export$8609a145e570faa1);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $lD23V;
    var $7Wo16;
    var $fNobW;
    var $44v1T;
    var $koVbs;
    var $19nfo;
    var $kZxfg;
    const $5d9b139cc9647a02$export$8609a145e570faa1 = ({ dashboardId: dashboardId, gadgetId: gadgetId, customHeight: customHeight, isPulsing: isPulsing = true, isLimitedToScreenHeight: isLimitedToScreenHeight = false })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const gadgetLocalHeight = customHeight ?? `${(0, (0, parcelRequire("19nfo")).getLocalStorageHeight)(dashboardId, gadgetId)}px`;
        const heightIsZero = gadgetLocalHeight.match(/^0[^.0-9]*$/);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fNobW")).default), {
            name: "skeleton-gadget-content"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($5d9b139cc9647a02$var$SkeletonContent, {
            height: gadgetLocalHeight,
            isLimitedToScreenHeight: isLimitedToScreenHeight,
            isPulsing: isPulsing,
            "data-testid": "dashboard-internal-common.common.gadget.gadget-skeleton.skeleton-content",
            role: "status",
            "aria-label": "Loading"
        }, !heightIsZero && (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("7Wo16"))))), {
            LEGACY_size: "large",
            label: formatMessage((0, (0, parcelRequire("kZxfg")).default).genericPlaceholderIcon),
            color: "currentColor"
        })));
    };
    const $5d9b139cc9647a02$var$PulseAnimation = null;
    const $5d9b139cc9647a02$var$SkeletonContent = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { isLimitedToScreenHeight: isLimitedToScreenHeight, isPulsing: isPulsing, ...__cmpldp } = __cmplp;
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmpldp,
            style: {
                ...__cmpls,
                "--_1kmmpls": (0, (0, parcelRequire("lD23V")).default)(__cmplp.height ?? '100%'),
                "--_1s93bfy": (0, (0, parcelRequire("lD23V")).default)((0, (0, parcelRequire("44v1T")).PAGE_LAYOUT_OFFSET_TOP))
            },
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_4t3i101j _bfhk1s4m _2rko1y44 _1e0c1txw _4cvr1h6o _1bah1h6o _1nj11i4r _v4q31yy8 _nhwa12c5 _1qzz10vm _8em7p3kn",
                __cmplp.isLimitedToScreenHeight && "_c71lve9e",
                __cmplp.isPulsing ? "_h82i9cwz" : "_h82iidpf",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("7Wo16", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $5c80946547c07390$var$_react = $5c80946547c07390$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $5c80946547c07390$var$_chartBar = $5c80946547c07390$var$_interopRequireDefault((parcelRequire("fDskX")));
    var $5c80946547c07390$var$_presentation = $5c80946547c07390$var$_interopRequireDefault((parcelRequire("dRtil")));
    function $5c80946547c07390$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $5c80946547c07390$var$ChartBarIcon = (props)=>$5c80946547c07390$var$_react.default.createElement($5c80946547c07390$var$_chartBar.default, Object.assign({
            LEGACY_fallbackIcon: $5c80946547c07390$var$_presentation.default
        }, props));
    $5c80946547c07390$var$ChartBarIcon.Name = 'ChartBarIconMigration';
    var $5c80946547c07390$var$_default = module.exports.default = $5c80946547c07390$var$ChartBarIcon;
});
parcelRegister("fDskX", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $b6207aa8debd29f4$var$_react = $b6207aa8debd29f4$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $b6207aa8debd29f4$var$_UNSAFE_baseNew = $b6207aa8debd29f4$var$_interopRequireDefault((parcelRequire("fEaCe")));
    function $b6207aa8debd29f4$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $b6207aa8debd29f4$var$ChartBarIcon = (props)=>$b6207aa8debd29f4$var$_react.default.createElement($b6207aa8debd29f4$var$_UNSAFE_baseNew.default, Object.assign({
            dangerouslySetGlyph: `<path stroke="currentcolor" stroke-linejoin="round" stroke-width="1.5" d="M5.917 9.75H1.75V13c0 .69.56 1.25 1.25 1.25h2.917m0-4.5v4.5m0-4.5v-4h4.166m-4.166 8.5h4.166m0-8.5v-4h4.167V13c0 .69-.56 1.25-1.25 1.25h-2.917m0-8.5v8.5"/>`
        }, props));
    $b6207aa8debd29f4$var$ChartBarIcon.displayName = 'ChartBarIcon';
    var $b6207aa8debd29f4$var$_default = module.exports.default = $b6207aa8debd29f4$var$ChartBarIcon;
});
parcelRegister("dRtil", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $a176f2f205c06729$var$_react = $a176f2f205c06729$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $c7fbr;
    var $a176f2f205c06729$var$_chartBar = $a176f2f205c06729$var$_interopRequireDefault((parcelRequire("fDskX")));
    function $a176f2f205c06729$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $a176f2f205c06729$var$MediaServicesPresentationIcon = (props)=>$a176f2f205c06729$var$_react.default.createElement((0, parcelRequire("c7fbr")).IconFacade, Object.assign({
            dangerouslySetGlyph: `<svg width="24" height="24" viewBox="0 0 24 24" role="presentation"><g fill-rule="evenodd"><rect width="16" height="16" x="4" y="4" fill="currentcolor" rx="2"/><rect width="2" height="3" x="8" y="12" fill="inherit" rx="1"/><rect width="2" height="5" x="11" y="10" fill="inherit" rx="1"/><rect width="2" height="7" x="14" y="8" fill="inherit" rx="1"/></g></svg>`
        }, props, {
            newIcon: $a176f2f205c06729$var$_chartBar.default
        }));
    $a176f2f205c06729$var$MediaServicesPresentationIcon.displayName = 'MediaServicesPresentationIcon';
    var $a176f2f205c06729$var$_default = module.exports.default = $a176f2f205c06729$var$MediaServicesPresentationIcon;
});
parcelRegister("kZxfg", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$4cfdcaba3d94c905$export$2e2bcd8739ae039);
    var $7VHMR;
    var $4cfdcaba3d94c905$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        genericPlaceholderIcon: {
            "id": "dashboard-internal-common.common.gadget.gadget-skeleton.generic-placeholder-icon",
            "defaultMessage": "Placeholder icon"
        }
    });
});
parcelRegister("jszBL", function(module, exports) {
    $parcel$export(module.exports, "useEventPublisher", ()=>$4e63d8ae41297db4$export$15f587a36eadd076);
    var $5uXOq = parcelRequire("5uXOq");
    const $4e63d8ae41297db4$export$15f587a36eadd076 = ()=>{
        const eventHandlers = (0, $5uXOq.useRef)(new Map()).current;
        const singleUseEventHandlers = (0, $5uXOq.useRef)(new Map()).current;
        const addEventHandler = (0, $5uXOq.useCallback)((eventName, handler, isSingleUse = false)=>{
            const handlers = isSingleUse ? singleUseEventHandlers : eventHandlers;
            handlers.has(eventName) ? handlers.get(eventName)?.add(handler) : handlers.set(eventName, new Set([
                handler
            ]));
        }, [
            eventHandlers,
            singleUseEventHandlers
        ]);
        const removeEventHandlers = (0, $5uXOq.useCallback)((eventName, handler)=>{
            if (eventName == null) {
                eventHandlers.clear();
                singleUseEventHandlers.clear();
                return;
            }
            if (!handler) {
                eventHandlers.delete(eventName);
                singleUseEventHandlers.delete(eventName);
                return;
            }
            eventHandlers.get(eventName)?.delete(handler);
            singleUseEventHandlers.get(eventName)?.delete(handler);
        }, [
            eventHandlers,
            singleUseEventHandlers
        ]);
        const triggerEvent = (0, $5uXOq.useCallback)((eventName)=>{
            eventHandlers.get(eventName)?.forEach((handler)=>{
                handler();
            });
            singleUseEventHandlers.get(eventName)?.forEach((handler)=>{
                handler();
            });
            singleUseEventHandlers.delete(eventName);
        }, [
            eventHandlers,
            singleUseEventHandlers
        ]);
        return {
            addEventHandler: addEventHandler,
            removeEventHandlers: removeEventHandlers,
            triggerEvent: triggerEvent
        };
    };
});
parcelRegister("ih7Bj", function(module, exports) {
    $parcel$export(module.exports, "useIsGlobalsReady", ()=>$6d5b1af24dce0aaf$export$676419e1d49cf911);
    var $5uXOq = parcelRequire("5uXOq");
    var $dJl1T;
    var $jU6q7;
    var $4P2OM;
    let $6d5b1af24dce0aaf$var$pollingSingleton = null;
    const $6d5b1af24dce0aaf$export$ef596e2a3bce12f6 = ()=>{
        $6d5b1af24dce0aaf$var$pollingSingleton = null;
    };
    class $6d5b1af24dce0aaf$var$PollingTimedOutError extends Error {
        constructor(){
            super('Timed out polling for globals');
            this.skipSentry = true;
        }
    }
    const $6d5b1af24dce0aaf$export$676419e1d49cf911 = ()=>{
        try {
            $6d5b1af24dce0aaf$var$pollingSingleton = $6d5b1af24dce0aaf$var$pollingSingleton ?? new (0, (0, parcelRequire("jU6q7")).default)(100, (0, (0, parcelRequire("4P2OM")).POLLING_TIMEOUT_MS), (0, (0, parcelRequire("dJl1T")).default));
        } catch (error) {
            (0, (0, parcelRequire("dJl1T")).default).safeWarnWithoutCustomerData('spa-apps.dashboard.common', 'error creating globals polling singleton', error);
        }
        const [isGlobalsReady, setIsGlobalsReady] = (0, $5uXOq.useState)(()=>$6d5b1af24dce0aaf$var$pollingSingleton?.isReady ?? false);
        const [isTimedOut, setIsTimedOut] = (0, $5uXOq.useState)(()=>$6d5b1af24dce0aaf$var$pollingSingleton?.isTimedOut ?? false);
        (0, $5uXOq.useEffect)(()=>{
            const setGlobalsReady = ()=>{
                setIsGlobalsReady(true);
            };
            const setTimedOut = ()=>{
                setIsTimedOut(true);
            };
            $6d5b1af24dce0aaf$var$pollingSingleton?.addEventListener('onReady', setGlobalsReady);
            $6d5b1af24dce0aaf$var$pollingSingleton?.addEventListener('onTimeout', setTimedOut);
            return ()=>{
                $6d5b1af24dce0aaf$var$pollingSingleton?.removeEventListener('onReady', setGlobalsReady);
                $6d5b1af24dce0aaf$var$pollingSingleton?.removeEventListener('onTimeout', setTimedOut);
            };
        }, []);
        (0, $5uXOq.useEffect)(()=>{
            if (isTimedOut) throw new $6d5b1af24dce0aaf$var$PollingTimedOutError();
        }, [
            isTimedOut
        ]);
        return $6d5b1af24dce0aaf$var$pollingSingleton !== null ? isGlobalsReady : (0, (0, parcelRequire("4P2OM")).DEPENDENCIES).every((dep)=>window[dep]);
    };
});
parcelRegister("jU6q7", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$6560cd8e50818359$export$2e2bcd8739ae039);
    var $4P2OM;
    var $euXBp;
    const $6560cd8e50818359$var$formatGlobals = (deps)=>deps.map((dep)=>`window.${dep}`).join(', ');
    const $6560cd8e50818359$var$EventTargetUniversal = typeof EventTarget === 'undefined' ? (0, (0, parcelRequire("euXBp")).default) : EventTarget;
    class $6560cd8e50818359$export$2e2bcd8739ae039 extends $6560cd8e50818359$var$EventTargetUniversal {
        #intervalID;
        #timeoutID;
        #startTime;
        constructor(pollingMs, timeoutMs, logger){
            super();
            this.isReady = false;
            this.isTimedOut = false;
            this.missingDeps = [];
            this.#intervalID = null;
            this.#timeoutID = null;
            this.#startTime = Date.now();
            this.#constructPolling = (pollingMs, timeoutMs, logger)=>{
                this.#intervalID = setInterval(()=>{
                    const newReadyDeps = (0, (0, parcelRequire("4P2OM")).DEPENDENCIES).filter((dep)=>window[dep] && this.missingDeps.includes(dep));
                    if (newReadyDeps.length !== 0) {
                        this.missingDeps = this.missingDeps.filter((dep)=>!newReadyDeps.includes(dep));
                        logger.safeInfoWithoutCustomerData('spa-apps.dashboard.common', `globals [${$6560cd8e50818359$var$formatGlobals(newReadyDeps)}] ready after ${(Date.now() - this.#startTime) / 1000}s, missing globals [${$6560cd8e50818359$var$formatGlobals(this.missingDeps)}]`);
                    }
                    if (this.missingDeps.length === 0) {
                        this.isReady = true;
                        this.destroy();
                        this.dispatchEvent(new CustomEvent('onReady'));
                        logger.safeInfoWithoutCustomerData('spa-apps.dashboard.common', `all globals [${$6560cd8e50818359$var$formatGlobals((0, (0, parcelRequire("4P2OM")).DEPENDENCIES))}] ready after ${(Date.now() - this.#startTime) / 1000}s`);
                    }
                }, pollingMs);
                this.#timeoutID = setTimeout(()=>{
                    this.destroy();
                    if (!this.isReady) {
                        this.isTimedOut = true;
                        this.dispatchEvent(new CustomEvent('onTimeout'));
                        logger.safeErrorWithoutCustomerData('spa-apps.dashboard.common', `timed out polling globals, missing globals [${$6560cd8e50818359$var$formatGlobals(this.missingDeps)}]`);
                    }
                }, timeoutMs);
            };
            this.destroy = ()=>{
                if (this.#intervalID != null) {
                    clearInterval(this.#intervalID);
                    this.#intervalID = null;
                }
                if (this.#timeoutID != null) {
                    clearTimeout(this.#timeoutID);
                    this.#timeoutID = null;
                }
            };
            this.missingDeps = (0, (0, parcelRequire("4P2OM")).DEPENDENCIES).filter((dep)=>!window[dep]);
            if (this.missingDeps.length === 0) {
                logger.safeInfoWithoutCustomerData('spa-apps.dashboard.common', `all globals [${$6560cd8e50818359$var$formatGlobals((0, (0, parcelRequire("4P2OM")).DEPENDENCIES))}] ready on initialisation`);
                this.isReady = true;
            } else {
                logger.safeWarnWithoutCustomerData('spa-apps.dashboard.common', `missing globals [${$6560cd8e50818359$var$formatGlobals(this.missingDeps)}]`);
                this.#constructPolling(pollingMs, timeoutMs, logger);
            }
        }
        #constructPolling;
    }
});
parcelRegister("4P2OM", function(module, exports) {
    $parcel$export(module.exports, "DEPENDENCIES", ()=>$f910f3784745e897$export$db362e2d293fe52e);
    $parcel$export(module.exports, "POLLING_TIMEOUT_MS", ()=>$f910f3784745e897$export$560b5415aba6175);
    const $f910f3784745e897$export$db362e2d293fe52e = [
        'require',
        'jQuery'
    ];
    const $f910f3784745e897$export$560b5415aba6175 = 60000;
});
parcelRegister("euXBp", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$9ae767f7162d54de$export$2e2bcd8739ae039);
    class $9ae767f7162d54de$export$2e2bcd8739ae039 {
        constructor(){
            throw new Error('EventTarget is not defined');
        }
    }
});
parcelRegister("7vg9b", function(module, exports) {
    $parcel$export(module.exports, "useLinkInterceptor", ()=>$403995a50b1fa001$export$d6b75f959ce086c3);
    var $5uXOq = parcelRequire("5uXOq");
    var $4R6GH;
    var $7HGHK;
    var $pa9q9;
    var $lVJAU;
    var $cdUgf;
    var $2SLmH;
    const $403995a50b1fa001$export$ad8ccaa587ef5e2e = 3984;
    const $403995a50b1fa001$var$EXCLUDED_PATHS_OLD = [
        /^\/rest\/(?:gadget|api)\/1\.0\//,
        /^\/plugins\/servlet\/gadgets\/ifr/
    ];
    const $403995a50b1fa001$var$EXCLUDED_ISSUE_TABLE_PATHS = [
        /^\/secure\/AssignIssue!default\.jspa/,
        /^\/secure\/AssignIssue\.jspa/,
        /^\/secure\/VoteOrWatchIssue\.jspa/,
        /^\/secure\/RankTop\.jspa/,
        /^\/secure\/RankBottom\.jspa/,
        /^\/secure\/CreateSubTaskIssue!default\.jspa/,
        /^\/notimportant/
    ];
    const $403995a50b1fa001$var$EXCLUDED_PATHS_NEW = [
        /^\/rest\/(?:gadget|api)\/1\.0\//,
        /^\/plugins\/servlet\/gadgets\/ifr/,
        ...$403995a50b1fa001$var$EXCLUDED_ISSUE_TABLE_PATHS
    ];
    const $403995a50b1fa001$var$isExcludedPath = (path)=>{
        const EXCLUDED_PATHS_WITH_FG = (0, (0, parcelRequire("4R6GH")).fg)('endeavour_announce_options_on_issue_table_gadgets') ? $403995a50b1fa001$var$EXCLUDED_PATHS_NEW : $403995a50b1fa001$var$EXCLUDED_PATHS_OLD;
        return EXCLUDED_PATHS_WITH_FG.some((regex)=>regex.test(path));
    };
    const $403995a50b1fa001$export$d6b75f959ce086c3 = (element, isLoading)=>{
        const { push: push } = (0, (0, parcelRequire("lVJAU")).useRouterActions)();
        const { showFlag: showFlag } = (0, (0, parcelRequire("pa9q9")).useFlagsService)();
        (0, $5uXOq.useEffect)(()=>{
            if (isLoading) return ()=>{};
            const linkClickHandler = (event)=>{
                const anchor = event.target instanceof HTMLAnchorElement ? event.target : event.target.closest?.('a');
                if (anchor == null || window.location.hostname !== anchor.hostname) return;
                if (anchor.href && anchor.href.length > $403995a50b1fa001$export$ad8ccaa587ef5e2e) {
                    event.preventDefault();
                    const flagConfig = {
                        key: (0, (0, parcelRequire("7HGHK")).toFlagId)('urlTooLongWarning'),
                        type: 'error',
                        title: (0, (0, parcelRequire("2SLmH")).default).linkInterceptorUrlTooLongTitle,
                        description: (0, (0, parcelRequire("2SLmH")).default).linkInterceptorUrlTooLongDescription
                    };
                    showFlag(flagConfig);
                } else if (anchor.pathname && !(0, (0, parcelRequire("cdUgf")).clickedWithShortcutKeys)(event) && !event.defaultPrevented && !$403995a50b1fa001$var$isExcludedPath(anchor.pathname)) {
                    event.preventDefault();
                    push(anchor.href);
                }
            };
            element?.addEventListener('click', linkClickHandler);
            return ()=>{
                element?.removeEventListener('click', linkClickHandler);
            };
        }, [
            element,
            isLoading,
            push,
            showFlag
        ]);
    };
});
parcelRegister("cdUgf", function(module, exports) {
    $parcel$export(module.exports, "clickedWithShortcutKeys", ()=>$330ad2eb7d232df1$export$141f1be6ae7df96b);
    const $330ad2eb7d232df1$export$141f1be6ae7df96b = (e)=>{
        if (e.metaKey || e.ctrlKey || e.altKey || e.shiftKey || e.button === 1) return true;
        return false;
    };
});
parcelRegister("2SLmH", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$e234c15db6f6ac4a$export$2e2bcd8739ae039);
    var $7VHMR;
    var $e234c15db6f6ac4a$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        linkInterceptorUrlTooLongTitle: {
            "id": "dashboard-internal-common.controllers.link-interceptor-url-too-long-title",
            "defaultMessage": "Requested URL too large"
        },
        linkInterceptorUrlTooLongDescription: {
            "id": "dashboard-internal-common.controllers.link-interceptor-url-too-long-description",
            "defaultMessage": "Try shortening the JQL of your saved filter."
        }
    });
});
parcelRegister("ikoXd", function(module, exports) {
    $parcel$export(module.exports, "getInlineGadgetApi", ()=>$f1aaef72e172dd4d$export$a7c3539038fb2c0);
    var $8Rkzz = parcelRequire("8Rkzz");
    var $hh0bW;
    var $dJl1T;
    var $JIPqS;
    var $jcw0u;
    var $19nfo;
    const $f1aaef72e172dd4d$export$a7c3539038fb2c0 = ({ gadgetId: gadgetId, amdModule: amdModule, context: context, defaultTitle: defaultTitle, isEditable: isEditable, closeEdit: closeEdit, ref: ref, setIsLoading: setIsLoading, setDefaultTitle: setDefaultTitle, reportGadgetMetrics: reportGadgetMetrics = (0, ($parcel$interopDefault($8Rkzz))), dashboardId: dashboardId, setPreferences: setPreferences, addEventHandler: addEventHandler, removeEventHandlers: removeEventHandlers, triggerEvent: triggerEvent, shouldUseCache: shouldUseCache, view: view, setError: setError })=>{
        const resize = ()=>{
            if (!dashboardId || !gadgetId) return;
            const height = ref.current?.getBoundingClientRect().height;
            (0, (0, parcelRequire("19nfo")).updateLocalStorageHeight)(dashboardId, gadgetId, height);
        };
        const moduleAPI = {
            on: (id, handler)=>addEventHandler(id, handler),
            once: (id, handler)=>addEventHandler(id, handler, true),
            off: (id, handler)=>removeEventHandlers(id, handler),
            trigger: (id)=>triggerEvent(id)
        };
        const expAttributes = {
            analyticsSource: 'dashboard',
            experience: (0, (0, parcelRequire("JIPqS")).SAVE_AMD_GADGET_CONFIG_PREFERENCES),
            application: null,
            edition: null,
            additionalAttributes: {}
        };
        return {
            gadget: {
                amdModule: amdModule,
                context: context,
                moduleAPI: moduleAPI
            },
            showLoadingBar: ()=>{
                setIsLoading(true);
            },
            resize: resize,
            isEditable: ()=>isEditable,
            savePreferences: async (preferences)=>{
                const maybePromise = setPreferences(preferences, ()=>{
                    setError(null);
                    closeEdit();
                });
                if (!maybePromise) return;
                try {
                    await maybePromise;
                    (0, (0, parcelRequire("hh0bW")).default)({
                        ...expAttributes,
                        wasExperienceSuccesful: true
                    });
                } catch (e) {
                    (0, (0, parcelRequire("dJl1T")).default).safeErrorWithoutCustomerData('spa-apps.dashboard.gadget.amd.api', `${e.message} [${defaultTitle}]`);
                    setError((0, (0, parcelRequire("JIPqS")).GADGET_ERROR_TYPE).CONFIG_ERROR);
                    (0, (0, parcelRequire("hh0bW")).default)({
                        ...expAttributes,
                        wasExperienceSuccesful: false
                    });
                    (0, (0, parcelRequire("jcw0u")).default)({
                        error: e,
                        meta: {
                            id: (0, (0, parcelRequire("JIPqS")).SAVE_AMD_GADGET_CONFIG_PREFERENCES),
                            packageName: 'jiraDashboardInternalCommon'
                        }
                    });
                }
            },
            setTitle: setDefaultTitle,
            hideLoadingBar: ()=>setIsLoading(false),
            initRefresh: (0, ($parcel$interopDefault($8Rkzz))),
            getRefreshFieldValue: (fieldName)=>{
                const input = ref.current?.querySelector(`input[name=${fieldName || 'refresh'}]`);
                if (input == null || !(input instanceof HTMLInputElement) || !input.checked) return 'false';
                return input.value;
            },
            getGadgetId: ()=>gadgetId,
            closeEdit: closeEdit,
            forceLayoutRefresh: ()=>{
                (0, (0, parcelRequire("dJl1T")).default).safeWarnWithoutCustomerData('endeavour.spa.dashboard.gadget.amd.api', 'deprecated forceLayoutRefresh() is called');
                resize();
            },
            publicInstace: ()=>(0, (0, parcelRequire("dJl1T")).default).safeWarnWithoutCustomerData('endeavour.spa.dashboard.gadget.amd.api', 'Unexpected public instance called'),
            _preparePreferencesToBeSaved: (preference)=>{
                let preferenceStr = '';
                try {
                    preferenceStr = JSON.stringify(preference);
                } catch (e) {
                    preferenceStr = 'non-stringifiable type';
                }
                (0, (0, parcelRequire("dJl1T")).default).safeWarnWithoutCustomerData('endeavour.spa.dashboard.gadget.amd.api', `Unexpected prepare preferences called with ${preferenceStr} for ${defaultTitle}`);
            },
            getContext: ()=>context,
            ...moduleAPI,
            listenTo: (0, ($parcel$interopDefault($8Rkzz))),
            stopListening: (0, ($parcel$interopDefault($8Rkzz))),
            listenToOnce: (0, ($parcel$interopDefault($8Rkzz))),
            reportGadgetMetrics: reportGadgetMetrics,
            shouldUseCache: shouldUseCache,
            view: view
        };
    };
});
parcelRegister("WIEWs", function(module, exports) {
    $parcel$export(module.exports, "styleOverrideFromMonolith", ()=>$ab2b4dcf374b66f3$export$a135452a315aaac8);
    $parcel$export(module.exports, "editModeStyleOverrideFromMonolith", ()=>$ab2b4dcf374b66f3$export$5dd19c8cd0b42775);
    $parcel$export(module.exports, "Container", ()=>$ab2b4dcf374b66f3$export$42a852a2b6b56249);
    $parcel$export(module.exports, "SkeletonWrapper", ()=>$ab2b4dcf374b66f3$export$6069cbe61f690103);
    $parcel$export(module.exports, "GadgetContainer", ()=>$ab2b4dcf374b66f3$export$3f9afbcf9557b0b4);
    $parcel$export(module.exports, "ConfigureButton", ()=>$ab2b4dcf374b66f3$export$b95d78b6ea7efb0b);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $fVnbM;
    const $ab2b4dcf374b66f3$export$a135452a315aaac8 = `
.piechart-gadget .piechart-fill {
    opacity: 1
}

.piechart-gadget .legend-icon {
    float: left;
    margin-top: 2px;
    margin-right: 10px
}


.piechart-gadget a {
    color: #0052cc;
    text-decoration: none;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,'Fira Sans','Droid Sans','Helvetica Neue',sans-serif;
    color: #172b4d;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 20px
}

.piechart-gadget a:focus,.piechart-gadget a:hover {
    color: #0065ff;
    text-decoration: underline
}

.piechart-gadget a:active {
    color: #0049b0;
    text-decoration: underline
}

.piechart-gadget .legend-wrapper {
    padding: 0 7px 7px 7px;
    max-width: 450px
}

.piechart-gadget .legend-wrapper a {
    text-decoration: none;
    color: ${"var(--ds-text, #5e6c84)"};
}

.piechart-gadget .legend-wrapper a:focus,.piechart-gadget .legend-wrapper a:hover {
    color: ${"var(--ds-link, #0065ff)"};
    text-decoration: underline
}

.piechart-gadget .legend-wrapper a:active {
    color: ${"var(--ds-text, #0049b0)"};
    text-decoration: underline
}

.piechart-gadget .legend-wrapper .legend-item {
    border-top-width: 0;
    border-bottom-width: 0
}

.piechart-gadget .legend-wrapper .legend-item:first-child {
    border-top: 2px solid ${"var(--ds-border-accent-gray, #dfe1e6)"};
}

.piechart-gadget .legend-wrapper .legend-item:last-child {
    border-bottom: 2px solid ${"var(--ds-border-accent-gray, #dfe1e6)"};
}

.piechart-gadget .legend-wrapper .legend-item .legend-content {
    padding: 8px 7px 8px 7px
}

.piechart-gadget .legend-wrapper .legend-item .legend-content .legend-item-value {
    padding-right: 7px
}

.two-d-container .extra-container {
    padding: 0
}

.two-d-container .extra-container tbody tr:last-child>td,.two-d-container .extra-container tbody tr:last-child>th,.two-d-container .extra-container tbody tr:last-child>tr,.two-d-container .extra-container tbody tr>td:last-child {
    padding: 7px;
    background: ${"var(--ds-background-neutral, #ebecf0)"};
}

.two-d-container .data-footer {
    padding: 5px
}

.two-d-container .data-footer p {
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,'Fira Sans','Droid Sans','Helvetica Neue',sans-serif;
    color: ${"var(--ds-text-subtle, #172b4d)"};
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 20px
}

table.aui thead tr th {
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,'Fira Sans','Droid Sans','Helvetica Neue',sans-serif;
    color: #5e6c84;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    padding-top: 4px;
    padding-bottom: 4px
}

table.aui thead .aui-iconfont-up {
    visibility: hidden
}

table.aui thead .aui-iconfont-up:after {
    top: 11px;
    margin-left: -16px;
    content: "";
    display: inline-block;
    width: 4px;
    height: 4px;
    border: none;
    border-right: 2px solid;
    border-top: 2px solid;
    transform: rotate(-45deg);
    visibility: visible;
    float: right;
    position: relative;
    top: 8px
}

table.aui thead .aui-iconfont-down {
    visibility: hidden
}

table.aui thead .aui-iconfont-down:after {
    top: 11px;
    margin-left: -16px;
    content: "";
    display: inline-block;
    width: 4px;
    height: 4px;
    border: none;
    border-right: 2px solid;
    border-top: 2px solid;
    transform: rotate(135deg);
    visibility: visible;
    float: right;
    position: relative;
    top: 6px
}

table.aui tbody:before {
    height: 0
}

table.aui tbody a {
    text-decoration: none;
    color: ${"var(--ds-link, #5e6c84)"};
}

table.aui tbody a:focus,table.aui tbody a:hover {
    color: ${"var(--ds-link, #0065ff)"};
    text-decoration: underline
}

table.aui tbody a:active {
    color: ${"var(--ds-link, #0049b0)"};
    text-decoration: underline
}

table.aui tbody>tr:hover {
    background: #ebecf0
}

table.aui tbody>tr>td:last-child {
    border-left-width: 0
}

table.aui tbody>tr>th {
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,'Fira Sans','Droid Sans','Helvetica Neue',sans-serif;
    color: #172b4d;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 20px
}

table.aui tbody>tr:first-child>th {
    border-top-width: 0
}

.stats-gadget {
    padding: 0
}

.stats-gadget tbody a {
    color: #0052cc;
    text-decoration: none;
    color: #5e6c84
}

.stats-gadget tbody a:focus,.stats-gadget tbody a:hover {
    color: #0065ff;
    text-decoration: underline
}

.stats-gadget tbody a:active {
    color: #0049b0;
    text-decoration: underline
}

.stats-gadget tbody tr>td .aui-progress-indicator-value {
    background: #0065ff
}

.stats-gadget tbody tr.stats-gadget-final-row>* {
    font-weight: 400;
    background: #ebecf0
}

.stats-gadget tbody td {
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,'Fira Sans','Droid Sans','Helvetica Neue',sans-serif;
    color: #172b4d;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 20px
}

.stats-gadget tbody:last-child>td,.stats-gadget tbody:last-child>th {
    padding: 7px
}

.favourite-filters-item {
    padding: 0
}

.favourite-filters-item .favourite-filters-view {
    border-top: 2px solid #dfe1e6
}

.favourite-filters-item tbody>tr:first-child td {
    border-top-width: 1px
}

.favourite-filters-item .config-options,.favourite-filters-item .empty-container {
    padding: 6px 0 0 7px
}

.favourite-filters-item .config-options .operations-list .aui-button:first-child,.favourite-filters-item .config-options .operations-list li:first-child,.favourite-filters-item .config-options>div .aui-button:first-child,.favourite-filters-item .config-options>div li:first-child,.favourite-filters-item .empty-container .operations-list .aui-button:first-child,.favourite-filters-item .empty-container .operations-list li:first-child,.favourite-filters-item .empty-container>div .aui-button:first-child,.favourite-filters-item .empty-container>div li:first-child {
    display: none
}

.favourite-filters-item .config-options .operations-list .aui-button:nth-child(2),.favourite-filters-item .config-options>div .aui-button:nth-child(2),.favourite-filters-item .empty-container .operations-list .aui-button:nth-child(2),.favourite-filters-item .empty-container>div .aui-button:nth-child(2) {
    margin-left: 0;
    padding-left: 0
}

.favourite-filters-item .config-options .operations-list a,.favourite-filters-item .config-options>div a,.favourite-filters-item .empty-container .operations-list a,.favourite-filters-item .empty-container>div a {
    color: #0052cc;
    text-decoration: none;
    color: #5e6c84
}

.favourite-filters-item .config-options .operations-list a:focus,.favourite-filters-item .config-options .operations-list a:hover,.favourite-filters-item .config-options>div a:focus,.favourite-filters-item .config-options>div a:hover,.favourite-filters-item .empty-container .operations-list a:focus,.favourite-filters-item .empty-container .operations-list a:hover,.favourite-filters-item .empty-container>div a:focus,.favourite-filters-item .empty-container>div a:hover {
    color: #0065ff;
    text-decoration: underline
}

.favourite-filters-item .config-options .operations-list a:active,.favourite-filters-item .config-options>div a:active,.favourite-filters-item .empty-container .operations-list a:active,.favourite-filters-item .empty-container>div a:active {
    color: #0049b0;
    text-decoration: underline
}

.createdvsresolved-gadget {
    padding: 0
}

.createdvsresolved-gadget a {
    color: #0052cc;
    text-decoration: none;
    color: #5e6c84
}

.createdvsresolved-gadget a:focus,.createdvsresolved-gadget a:hover {
    color: #0065ff;
    text-decoration: underline
}

.createdvsresolved-gadget a:active {
    color: #0049b0;
    text-decoration: underline
}

.createdvsresolved-gadget .createdvsresolved-chart text {
    fill: #091e42
}

.createdvsresolved-gadget .createdvsresolved-chart .grid line,.createdvsresolved-gadget .createdvsresolved-chart .versions line {
    stroke: #dfe1e6
}

.createdvsresolved-gadget .createdvsresolved-chart .created path,.createdvsresolved-gadget .createdvsresolved-chart .created-points circle {
    stroke: #ff5630
}

.createdvsresolved-gadget .createdvsresolved-chart .resolved path,.createdvsresolved-gadget .createdvsresolved-chart .resolved-points circle {
    stroke: #36b37e
}

.createdvsresolved-gadget .createdvsresolved-chart-info {
    padding-left: 7px
}

.createdvsresolved-gadget .createdvsresolved-chart-details-link {
    display: block;
    margin-left: 0
}

.createdvsresolved-gadget .createdvsresolved-chart-details-grouping {
    margin-left: 0
}

.createdvsresolved-gadget .createdvsresolved-chart-details-totals .created-totals svg {
    padding-bottom: 2px
}

.createdvsresolved-gadget .createdvsresolved-chart-details-totals .created-totals svg circle {
    stroke: #ff5630
}

.createdvsresolved-gadget .createdvsresolved-chart-details-totals .resolved-totals svg {
    padding-bottom: 2px
}

.createdvsresolved-gadget .createdvsresolved-chart-details-totals .resolved-totals svg circle {
    stroke: #36b37e
}`;
    const $ab2b4dcf374b66f3$export$5dd19c8cd0b42775 = `
form.aui .buttons-container .buttons, form.aui .submit-cancel-controls {
    text-align: end;
}
.piechart-gadget .dashboard-item-preferences-config {
    margin: -20px
}
`;
    const $ab2b4dcf374b66f3$export$42a852a2b6b56249 = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_kqswh2mm _1e0c1txw _2lx21bp4",
                __cmplp.className
            ])
        });
    });
    const $ab2b4dcf374b66f3$export$6069cbe61f690103 = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1bsb1osq _4t3i1osq _11q7vuon",
                __cmplp.className
            ])
        });
    });
    const $ab2b4dcf374b66f3$export$3f9afbcf9557b0b4 = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { isHidden: isHidden, ...__cmpldp } = __cmplp;
        return $5uXOq.createElement(C, {
            ...__cmpldp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "",
                __cmplp.isHidden && "_3um015vq _kqsw7079",
                __cmplp.className
            ])
        });
    });
    const $ab2b4dcf374b66f3$export$b95d78b6ea7efb0b = (0, $5uXOq.forwardRef)(({ as: C = (0, (0, parcelRequire("fVnbM")).default), style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_18u01wug",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("1NzHy", function(module, exports) {
    $parcel$export(module.exports, "Connect", ()=>$e94ecec6e18bdb3d$export$59e6910693f047c2);
    var $5uXOq = parcelRequire("5uXOq");
    var $dJl1T;
    var $dJZWD;
    var $c1XZn;
    var $8UPoO;
    var $8h45R;
    var $8FVKN;
    var $ih7Bj;
    var $19nfo;
    var $cra1f;
    const $e94ecec6e18bdb3d$export$59e6910693f047c2 = ({ dashboardId: dashboardId, gadgetId: gadgetId, inlineHtml: inlineHtml, isLoading: isLoading, isInEditMode: isInEditMode, setIsLoading: setIsLoading, onEditModeCancel: onEditModeCancel, onRegister: onRegister, onUnregister: onUnregister, setTitle: setTitle })=>{
        const divRef = (0, $5uXOq.useRef)(null);
        const isGlobalsReady = (0, (0, parcelRequire("ih7Bj")).useIsGlobalsReady)();
        const isDisabled = (0, (0, parcelRequire("8FVKN")).useIsConnectDisabled)();
        const forcedHeightFromState = (0, (0, parcelRequire("8h45R")).useForcedHeight)(gadgetId);
        const forcedHeight = (0, $5uXOq.useMemo)(()=>forcedHeightFromState ?? (0, (0, parcelRequire("19nfo")).getLocalStorageHeight)(dashboardId, gadgetId), [
            forcedHeightFromState,
            dashboardId,
            gadgetId
        ]);
        const onGadgetRender = (0, (0, parcelRequire("8UPoO")).useOnGadgetRender)(gadgetId);
        const onRefresh = (0, (0, parcelRequire("8FVKN")).useGadgetRefresh)();
        const showSkeleton = isLoading && !isInEditMode;
        (0, $5uXOq.useEffect)(()=>{
            if (!isGlobalsReady || inlineHtml == null) return;
            (0, (0, parcelRequire("dJZWD")).setupConnectJs)().then(()=>{
                const slotHtml = document.createRange().createContextualFragment(inlineHtml);
                if (divRef.current) {
                    divRef.current.innerHTML = '';
                    divRef.current.appendChild(slotHtml);
                    setIsLoading(false);
                    onGadgetRender();
                }
            }).catch((error)=>{
                (0, (0, parcelRequire("dJl1T")).default).safeErrorWithoutCustomerData('spa-apps.dashboard.gadget.connect', 'Failed to render Connect gadget', error);
            });
        }, [
            isGlobalsReady,
            inlineHtml,
            onGadgetRender,
            setIsLoading
        ]);
        (0, $5uXOq.useEffect)(()=>{
            onRegister(gadgetId, setTitle);
            return ()=>{
                onUnregister(gadgetId);
            };
        }, [
            gadgetId,
            setTitle,
            onRegister,
            onUnregister
        ]);
        (0, $5uXOq.useEffect)(()=>{
            const target = divRef.current?.getElementsByTagName('iframe')[0];
            if (!target || !isInEditMode || isLoading) return;
            window.connectHost?.broadcastEvent('jira_dashboard_item_edit', {
                id: target.getAttribute('id')
            });
        }, [
            isInEditMode,
            isLoading
        ]);
        (0, $5uXOq.useEffect)(()=>{
            const connectEventHandler = (event)=>{
                const eventData = event.data;
                const target = divRef.current?.getElementsByTagName('iframe')[0];
                if (target == null || eventData == null || eventData.eid !== target.getAttribute('id')) return;
                if (eventData.type === 'unload') onRefresh();
                else onEditModeCancel();
            };
            window.addEventListener('message', connectEventHandler, false);
            return ()=>{
                window.removeEventListener('message', connectEventHandler);
            };
        }, [
            dashboardId,
            gadgetId,
            inlineHtml,
            isGlobalsReady,
            onRefresh,
            onEditModeCancel
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cra1f")).Container), {
            minHeight: forcedHeight
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cra1f")).GadgetContainer), {
            id: `gadget-${gadgetId}`,
            "data-gadget-id": gadgetId,
            "data-testid": "dashboard-internal-common.ui.gadget.gadget-inner.connect.connect",
            ref: divRef,
            isHidden: showSkeleton,
            isDisabled: isDisabled
        }), showSkeleton && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cra1f")).SkeletonWrapper), {
            "data-testid": "dashboard-internal-common.ui.gadget.gadget-inner.connect.loading-skeleton"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("c1XZn")).SkeletonGadgetContent), {
            dashboardId: dashboardId,
            gadgetId: gadgetId
        })));
    };
});
parcelRegister("cra1f", function(module, exports) {
    $parcel$export(module.exports, "Container", ()=>$46b4db80fb140a91$export$42a852a2b6b56249);
    $parcel$export(module.exports, "SkeletonWrapper", ()=>$46b4db80fb140a91$export$6069cbe61f690103);
    $parcel$export(module.exports, "GadgetContainer", ()=>$46b4db80fb140a91$export$3f9afbcf9557b0b4);
    var $bwpsq;
    var $lD23V;
    var $5uXOq = parcelRequire("5uXOq");
    const $46b4db80fb140a91$export$42a852a2b6b56249 = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { minHeight: minHeight, ...__cmpldp } = __cmplp;
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmpldp,
            style: {
                ...__cmpls,
                "--_1l1bpbj": (0, (0, parcelRequire("lD23V")).default)(`${__cmplp.minHeight}px`)
            },
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_kqswh2mm _1tkemu0a",
                __cmplp.className
            ])
        });
    });
    const $46b4db80fb140a91$export$6069cbe61f690103 = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_kqswstnw _1ltvidpf _154iidpf _1bsb1osq _4t3i1osq _bfhku67f _1pby1sen",
                __cmplp.className
            ])
        });
    });
    const $46b4db80fb140a91$var$GadgetContainerComponent = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { isHidden: isHidden, isDisabled: isDisabled, ...__cmpldp } = __cmplp;
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmpldp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "",
                __cmplp.isHidden ? "_1e0cglyw" : "_1e0c1kw7",
                __cmplp.isDisabled ? "_lcxvglyw" : "_lcxv1kw7",
                __cmplp.className
            ])
        });
    });
    const $46b4db80fb140a91$export$3f9afbcf9557b0b4 = (0, $5uXOq.forwardRef)((props, ref)=>(0, ($parcel$interopDefault($5uXOq))).createElement($46b4db80fb140a91$var$GadgetContainerComponent, {
            className: "connect-gadget",
            ...props,
            ref: ref
        }));
});
parcelRegister("9sF5P", function(module, exports) {
    $parcel$export(module.exports, "Forge", ()=>$ce96d098a49d18cb$export$4b9c9e62f20bbcd0);
    var $5uXOq = parcelRequire("5uXOq");
    var $JIPqS;
    var $5ow89;
    var $aABmH;
    var $9k8XP;
    var $e0AC8;
    var $lfTZh;
    var $gh1hi;
    var $bVDE5;
    var $4uQB7;
    var $c1XZn;
    var $8FVKN;
    var $i0rkS;
    var $5TsWX;
    var $dg9na;
    const $ce96d098a49d18cb$export$4b36646b2dadb9ee = ({ gadgetData: gadgetData, forgeKey: forgeKey, isInEditMode: isInEditMode, onEditModeCancel: onEditModeCancel, setIsLoading: setIsLoading })=>{
        const [{ currentPageId: currentPageId }] = (0, (0, parcelRequire("bVDE5")).useSpaStateTransition)();
        const { data: dashboardData } = (0, (0, parcelRequire("gh1hi")).useDashboardResource)();
        const { data: forgeResourceData, loading: isDashboardForgeResourceLoading, error: resourceError } = (0, (0, parcelRequire("gh1hi")).useDashboardForgeResource)();
        const setPreferences = (0, (0, parcelRequire("8FVKN")).useGadgetSetPreferences)();
        const [, { broadcastMessage: broadcastMessageBusListener }] = (0, (0, parcelRequire("i0rkS")).useMessageBus)();
        const [error, setError] = (0, $5uXOq.useState)(null);
        const [isUpdatePending, setIsUpdatePending] = (0, $5uXOq.useState)(false);
        const reportGadgetMetrics = (0, $5uXOq.useCallback)((eventType, contentType, markName, markStage)=>{
            broadcastMessageBusListener((0, (0, parcelRequire("JIPqS")).GADGET_METRICS_TYPE).REPORT, {
                eventType: eventType,
                contentType: contentType,
                markName: markName,
                markStage: markStage,
                source: (0, (0, parcelRequire("5TsWX")).FORGE_GADGET_METRICS)
            }, {
                gadgetId: gadgetData.id,
                pageId: currentPageId
            });
        }, [
            gadgetData.id,
            broadcastMessageBusListener,
            currentPageId
        ]);
        (0, $5uXOq.useLayoutEffect)(()=>{
            reportGadgetMetrics((0, (0, parcelRequire("5TsWX")).GADGET_RENDER_CONTAINER_EVENT), 'View');
        }, [
            reportGadgetMetrics
        ]);
        (0, $5uXOq.useEffect)(()=>{
            if (!isDashboardForgeResourceLoading) setIsLoading(false);
        }, [
            isDashboardForgeResourceLoading,
            setIsLoading
        ]);
        const onConfigurationSave = (0, $5uXOq.useCallback)((dataToSave)=>{
            (0, (0, parcelRequire("5ow89")).fireUiTriggerClickEvent)('dashboardGadgetEdit');
            setIsUpdatePending(true);
            return (0, (0, parcelRequire("dg9na")).savePreferences)(gadgetData, dataToSave, setPreferences, setError, ()=>{
                setIsUpdatePending(false);
            });
        }, [
            gadgetData,
            setPreferences,
            setError,
            setIsUpdatePending
        ]);
        const dashboardId = dashboardData?.id || '';
        const extensionData = (0, $5uXOq.useMemo)(()=>({
                gadgetConfiguration: (0, (0, parcelRequire("dg9na")).transformUserPrefsForForgeAppConsumption)(gadgetData.userPrefs?.fields),
                dashboard: {
                    id: dashboardId
                },
                gadget: {
                    id: gadgetData.id
                }
            }), [
            gadgetData.userPrefs?.fields,
            dashboardId,
            gadgetData.id
        ]);
        if (isDashboardForgeResourceLoading || isUpdatePending) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("c1XZn")).SkeletonGadgetContent), null);
        const forgeGadget = forgeResourceData?.gadgets.find((gadget)=>gadget.id === forgeKey);
        if (resourceError || error || !forgeGadget || !dashboardId) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4uQB7")).GadgetErrorFallback), {
            errorType: error ?? (0, (0, parcelRequire("JIPqS")).GADGET_ERROR_TYPE).VIEW_ERROR,
            id: gadgetData.id
        });
        return ((0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "async-dashboard-gadget-edit",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("c1XZn")).SkeletonGadgetContent), null)
        }, isInEditMode ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9k8XP")).AsyncDashboardGadgetEdit), {
            extension: forgeGadget,
            extensionData: extensionData,
            onReportGadgetMetrics: reportGadgetMetrics,
            loadingComponent: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("c1XZn")).SkeletonGadgetContent), null),
            onEditModeCancel: onEditModeCancel,
            onConfigurationSave: onConfigurationSave
        }) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9k8XP")).AsyncDashboardGadgetView), {
            extension: forgeGadget,
            extensionData: extensionData,
            onReportGadgetMetrics: reportGadgetMetrics,
            loadingComponent: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("c1XZn")).SkeletonGadgetContent), null)
        })));
    };
    const $ce96d098a49d18cb$export$4b9c9e62f20bbcd0 = (props)=>{
        const { gadgetData: { id: id }, isInEditMode: isInEditMode } = props;
        const envType = props.gadgetData.forge?.environment;
        const forgeKey = props.gadgetData.forge?.key;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("aABmH")).AnalyticsWrapper), {
            attributes: {
                envType: envType,
                ...forgeKey ? (0, (0, parcelRequire("e0AC8")).parseExtensionId)(forgeKey) : {}
            },
            key: `${id}__${isInEditMode}`,
            isInEditMode: isInEditMode,
            errorFallback: ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4uQB7")).GadgetErrorFallback), {
                    errorType: (0, (0, parcelRequire("JIPqS")).GADGET_ERROR_TYPE).VIEW_ERROR,
                    id: id
                })
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($ce96d098a49d18cb$export$4b36646b2dadb9ee, props));
    };
});
parcelRegister("5ow89", function(module, exports) {
    $parcel$export(module.exports, "fireTrackEditedEvent", ()=>$5a2adb89750e5a75$export$1798d320c6527c08);
    $parcel$export(module.exports, "fireOperationalEditFailedEvent", ()=>$5a2adb89750e5a75$export$762dee9e981de3e1);
    $parcel$export(module.exports, "fireUiTriggerClickEvent", ()=>$5a2adb89750e5a75$export$cfefb608fbbf02c9);
    var $k2DRb;
    var $8uOa6;
    var $9BrHy;
    var $hBuqR;
    const $5a2adb89750e5a75$var$moduleEventId = (0, (0, parcelRequire("9BrHy")).getEventId)({
        module: (0, (0, parcelRequire("k2DRb")).DASHBOARD_GADGET_MODULE),
        moduleType: (0, (0, parcelRequire("hBuqR")).MODULE_TYPE_EXTENSION),
        entryPoint: (0, (0, parcelRequire("k2DRb")).ENTRY_POINT_EDIT)
    });
    const $5a2adb89750e5a75$var$triggerEventId = (0, (0, parcelRequire("9BrHy")).getEventId)({
        module: (0, (0, parcelRequire("k2DRb")).DASHBOARD_GADGET_MODULE),
        moduleType: (0, (0, parcelRequire("hBuqR")).MODULE_TYPE_TRIGGER),
        entryPoint: (0, (0, parcelRequire("k2DRb")).ENTRY_POINT_EDIT)
    });
    const $5a2adb89750e5a75$export$1798d320c6527c08 = (source, attributes)=>(0, (0, parcelRequire("8uOa6")).fireTrackEvent)((0, (0, parcelRequire("hBuqR")).TRACK_ACTIONS).EDITED, {
            id: $5a2adb89750e5a75$var$moduleEventId,
            source: source,
            attributes: attributes
        });
    const $5a2adb89750e5a75$export$762dee9e981de3e1 = (source, attributes)=>(0, (0, parcelRequire("8uOa6")).fireOperationalEvent)((0, (0, parcelRequire("hBuqR")).OPERATIONAL_ACTIONS).EDIT_FAILED, {
            id: $5a2adb89750e5a75$var$moduleEventId,
            source: source,
            attributes: attributes
        });
    const $5a2adb89750e5a75$export$cfefb608fbbf02c9 = (source, attributes)=>(0, (0, parcelRequire("8uOa6")).fireUiEvent)((0, (0, parcelRequire("hBuqR")).UI_ACTIONS).CLICKED, {
            id: $5a2adb89750e5a75$var$triggerEventId,
            source: source,
            attributes: attributes
        });
});
parcelRegister("aABmH", function(module, exports) {
    $parcel$export(module.exports, "AnalyticsWrapper", ()=>$576e3e741fcaaea4$export$63b55d605f8a2340);
    var $5uXOq = parcelRequire("5uXOq");
    var $k2DRb;
    var $1rTGO;
    const $576e3e741fcaaea4$export$63b55d605f8a2340 = (props)=>{
        const { isInEditMode: isInEditMode, ...restProps } = props;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1rTGO")).ForgeAnalyticsWrapper), {
            ...restProps,
            module: (0, (0, parcelRequire("k2DRb")).DASHBOARD_GADGET_MODULE),
            entryPoint: isInEditMode ? (0, (0, parcelRequire("k2DRb")).ENTRY_POINT_EDIT) : undefined,
            shouldFireInitializedEvent: true
        });
    };
});
parcelRegister("9k8XP", function(module, exports) {
    $parcel$export(module.exports, "AsyncDashboardGadgetView", ()=>$7499d979082e3839$export$8f8b884c8f9b7f99);
    $parcel$export(module.exports, "AsyncDashboardGadgetEdit", ()=>$7499d979082e3839$export$11802f9302dce8a3);
    var $5uXOq = parcelRequire("5uXOq");
    var $1rTGO;
    var $k2DRb;
    var $3mOWa;
    const $7499d979082e3839$var$GADGET_START_EVENT = 'gadget-start';
    const $7499d979082e3839$var$GADGET_FINISH_EVENT = 'gadget-rendering-finished';
    const $7499d979082e3839$var$getUiKitModule = ()=>(parcelRequire("hw1Lp"));
    const $7499d979082e3839$export$d53932a75c2ba294 = (0, (0, parcelRequire("1rTGO")).withMetrics)((0, $5uXOq.lazy)(()=>$7499d979082e3839$var$getUiKitModule().then((module)=>({
                default: module.DashboardGadgetView
            }))));
    const $7499d979082e3839$export$e388a2aaf41cd524 = (0, (0, parcelRequire("1rTGO")).withMetrics)((0, $5uXOq.lazy)(()=>$7499d979082e3839$var$getUiKitModule().then((module)=>({
                default: module.DashboardGadgetEdit
            }))));
    const $7499d979082e3839$var$getIframeModule = ()=>(parcelRequire("ekrgl"));
    const $7499d979082e3839$export$6e328a6d6807e3ef = (0, (0, parcelRequire("1rTGO")).withMetrics)((0, $5uXOq.lazy)(()=>$7499d979082e3839$var$getIframeModule().then((module)=>({
                default: module.DashboardGadgetViewRenderer
            }))));
    const $7499d979082e3839$export$6618f34be2146a7d = (0, (0, parcelRequire("1rTGO")).withMetrics)((0, $5uXOq.lazy)(()=>$7499d979082e3839$var$getIframeModule().then((module)=>({
                default: module.DashboardGadgetEditRenderer
            }))));
    const $7499d979082e3839$var$getForgeExtensionModule = ()=>(parcelRequire("1rVrc"));
    const $7499d979082e3839$export$90dc543486d6f150 = (0, (0, parcelRequire("1rTGO")).withMetrics)((0, $5uXOq.lazy)(()=>$7499d979082e3839$var$getForgeExtensionModule().then((module)=>({
                default: module.DashboardGadgetViewExtension
            }))));
    const $7499d979082e3839$export$8a87cc350601aff5 = (0, (0, parcelRequire("1rTGO")).withMetrics)((0, $5uXOq.lazy)(()=>$7499d979082e3839$var$getForgeExtensionModule().then((module)=>({
                default: module.DashboardGadgetEditExtension
            }))));
    const $7499d979082e3839$var$useDashboardGadget = (onReportGadgetMetrics, extensionData, entryPoint)=>{
        const onInitialRender = (0, $5uXOq.useCallback)((payload)=>{
            if (payload !== undefined && payload.error == null) onReportGadgetMetrics($7499d979082e3839$var$GADGET_FINISH_EVENT, 'View');
            else onReportGadgetMetrics($7499d979082e3839$var$GADGET_FINISH_EVENT, 'ErrorMessage');
        }, [
            onReportGadgetMetrics
        ]);
        (0, $5uXOq.useLayoutEffect)(()=>{
            onReportGadgetMetrics($7499d979082e3839$var$GADGET_START_EVENT, 'View');
        }, [
            onReportGadgetMetrics
        ]);
        return (0, $5uXOq.useMemo)(()=>{
            const extraPropsUiCommon = {
                module: (0, (0, parcelRequire("k2DRb")).DASHBOARD_GADGET_MODULE),
                entryPoint: entryPoint,
                extensionData: {
                    ...extensionData,
                    entryPoint: entryPoint,
                    type: (0, (0, parcelRequire("k2DRb")).DASHBOARD_GADGET_MODULE)
                }
            };
            return {
                onInitialRender: onInitialRender,
                extraPropsUiCommon: extraPropsUiCommon
            };
        }, [
            entryPoint,
            extensionData,
            onInitialRender
        ]);
    };
    const $7499d979082e3839$export$8f8b884c8f9b7f99 = (props)=>{
        const { extensionData: extensionData, onReportGadgetMetrics: onReportGadgetMetrics, ...restProps } = props;
        const { onInitialRender: onInitialRender, extraPropsUiCommon: extraPropsUiCommon } = $7499d979082e3839$var$useDashboardGadget(onReportGadgetMetrics, extensionData);
        const extraPropsUiKit = {
            ...extraPropsUiCommon,
            onInitialRender: onInitialRender
        };
        const extraPropsCustomUi = {
            ...extraPropsUiCommon,
            onLoad: onInitialRender
        };
        if ((0, (0, parcelRequire("3mOWa")).isNativeUiExtension)(props.extension) || (0, (0, parcelRequire("3mOWa")).isCustomUIExtension)(props.extension)) return (0, ($parcel$interopDefault($5uXOq))).createElement($7499d979082e3839$export$90dc543486d6f150, {
            ...restProps,
            ...extraPropsCustomUi
        });
        return (0, (0, parcelRequire("3mOWa")).isCustomUIExtension)(props.extension) ? (0, ($parcel$interopDefault($5uXOq))).createElement($7499d979082e3839$export$6e328a6d6807e3ef, {
            ...restProps,
            ...extraPropsCustomUi
        }) : (0, ($parcel$interopDefault($5uXOq))).createElement($7499d979082e3839$export$d53932a75c2ba294, {
            ...restProps,
            ...extraPropsUiKit
        });
    };
    const $7499d979082e3839$export$11802f9302dce8a3 = (props)=>{
        const { extensionData: extensionData, onReportGadgetMetrics: onReportGadgetMetrics, ...restProps } = props;
        const { onInitialRender: onInitialRender, extraPropsUiCommon: extraPropsUiCommon } = $7499d979082e3839$var$useDashboardGadget(onReportGadgetMetrics, extensionData, (0, (0, parcelRequire("k2DRb")).ENTRY_POINT_EDIT));
        const extraPropsUiKitEdit = {
            ...extraPropsUiCommon,
            onInitialRender: onInitialRender
        };
        const extraPropsCustomUiEdit = {
            ...extraPropsUiCommon,
            onLoad: onInitialRender
        };
        if ((0, (0, parcelRequire("3mOWa")).isNativeUiExtension)(props.extension, (0, (0, parcelRequire("k2DRb")).ENTRY_POINT_EDIT)) || (0, (0, parcelRequire("3mOWa")).isCustomUIExtension)(props.extension, (0, (0, parcelRequire("k2DRb")).ENTRY_POINT_EDIT))) return (0, ($parcel$interopDefault($5uXOq))).createElement($7499d979082e3839$export$8a87cc350601aff5, {
            ...restProps,
            ...extraPropsCustomUiEdit
        });
        return (0, (0, parcelRequire("3mOWa")).isCustomUIExtension)(props.extension, (0, (0, parcelRequire("k2DRb")).ENTRY_POINT_EDIT)) ? (0, ($parcel$interopDefault($5uXOq))).createElement($7499d979082e3839$export$6618f34be2146a7d, {
            ...restProps,
            ...extraPropsCustomUiEdit
        }) : (0, ($parcel$interopDefault($5uXOq))).createElement($7499d979082e3839$export$e388a2aaf41cd524, {
            ...restProps,
            ...extraPropsUiKitEdit
        });
    };
});
parcelRegister("hw1Lp", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("hPzOn"),
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("bXeFZ")
    ]).then(()=>parcelRequire('iYHF5'));
});
parcelRegister("ekrgl", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("9r7dn")
    ]).then(()=>parcelRequire('86L7T'));
});
parcelRegister("1rVrc", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("kWrFZ"),
        $6fhBS("eNmuD"),
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("hPzOn"),
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("godsQ")
    ]).then(()=>parcelRequire('ayAHF'));
});
parcelRegister("dg9na", function(module, exports) {
    $parcel$export(module.exports, "savePreferences", ()=>$7fcdc9b72606be07$export$f1786e2fa4419c16);
    $parcel$export(module.exports, "transformUserPrefsForForgeAppConsumption", ()=>$7fcdc9b72606be07$export$b5d9bfce42b49862);
    var $hh0bW;
    var $dJl1T;
    var $JIPqS;
    var $jcw0u;
    var $5ow89;
    const $7fcdc9b72606be07$var$expAttributes = {
        analyticsSource: 'dashboard',
        experience: (0, (0, parcelRequire("JIPqS")).SAVE_FORGE_GADGET_CONFIG_PREFERENCES),
        application: null,
        edition: null,
        additionalAttributes: {}
    };
    const $7fcdc9b72606be07$export$f1786e2fa4419c16 = async (data, preferences, setPreferences, setError, onComplete)=>{
        const { title: defaultTitle } = data;
        const newUserPrefs = {};
        Object.entries(preferences).forEach(([key, value])=>{
            newUserPrefs[key] = typeof value === 'string' ? JSON.stringify(value) : value;
        });
        const maybePromise = setPreferences(newUserPrefs, onComplete);
        if (!maybePromise) return;
        try {
            await maybePromise;
            setError(null);
            (0, (0, parcelRequire("hh0bW")).default)({
                ...$7fcdc9b72606be07$var$expAttributes,
                wasExperienceSuccesful: true
            });
            (0, (0, parcelRequire("5ow89")).fireTrackEditedEvent)('dashboardGadgetEdit');
        } catch (e) {
            (0, (0, parcelRequire("dJl1T")).default).safeErrorWithoutCustomerData('spa-apps.dashboard.gadget.forge', `${e.message} [${defaultTitle}]`);
            setError((0, (0, parcelRequire("JIPqS")).GADGET_ERROR_TYPE).CONFIG_ERROR);
            (0, (0, parcelRequire("hh0bW")).default)({
                ...$7fcdc9b72606be07$var$expAttributes,
                wasExperienceSuccesful: false
            });
            (0, (0, parcelRequire("jcw0u")).default)({
                error: e,
                meta: {
                    id: (0, (0, parcelRequire("JIPqS")).SAVE_FORGE_GADGET_CONFIG_PREFERENCES),
                    packageName: 'jiraDashboardInternalCommon'
                }
            });
            (0, (0, parcelRequire("5ow89")).fireOperationalEditFailedEvent)('dashboardGadgetEdit', {
                error: e
            });
        }
    };
    const $7fcdc9b72606be07$var$decodeQuotes = (value)=>value.replace(/(?:&quot;)/gm, '"');
    const $7fcdc9b72606be07$export$b5d9bfce42b49862 = (userPrefsFields)=>{
        const transformedUserPrefs = {};
        if (userPrefsFields !== undefined) userPrefsFields.forEach((field)=>{
            if (field.name !== 'isConfigured') transformedUserPrefs[field.name] = JSON.parse($7fcdc9b72606be07$var$decodeQuotes(field.value));
        });
        return transformedUserPrefs;
    };
});
parcelRegister("k3y1k", function(module, exports) {
    $parcel$export(module.exports, "InlineHtml", ()=>$4f214ca561897841$export$109427fbef437c1e);
    var $5uXOq = parcelRequire("5uXOq");
    var $dC5iT;
    var $8CUq3;
    var $fNobW;
    var $344pu;
    var $8UPoO;
    var $7vg9b;
    const $4f214ca561897841$export$109427fbef437c1e = ({ id: id, inlineHtml: inlineHtml, isLoading: isLoading, setIsLoading: setIsLoading })=>{
        const inlineHtmlRef = (0, $5uXOq.useRef)(null);
        const [hasIntroLogo, setHasIntroLogo] = (0, $5uXOq.useState)(false);
        (0, (0, parcelRequire("7vg9b")).useLinkInterceptor)(inlineHtmlRef.current, isLoading);
        (0, $5uXOq.useEffect)(()=>{
            const slotHtml = document.createRange().createContextualFragment(inlineHtml);
            if (inlineHtmlRef.current) {
                inlineHtmlRef.current.innerHTML = '';
                inlineHtmlRef.current.appendChild(slotHtml);
                const introLogoElement = inlineHtmlRef.current.querySelector('.intro-logo');
                if (introLogoElement) setHasIntroLogo(true);
            }
            setIsLoading(false);
        }, [
            inlineHtml,
            setIsLoading
        ]);
        (0, (0, parcelRequire("8UPoO")).useOnGadgetRenderEffect)(id, inlineHtmlRef.current);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fNobW")).default), {
            name: "inline-html-gadget",
            hold: isLoading
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            xcss: hasIntroLogo && $4f214ca561897841$var$introStyles
        }, hasIntroLogo && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("344pu")).default), {
            alt: ""
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("div", {
            id: `gadget-${id}`,
            ref: inlineHtmlRef
        })));
    };
    const $4f214ca561897841$var$introStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        overflow: 'auto',
        height: '160px',
        display: 'flex',
        justifyContent: 'space-around',
        padding: 'space.200',
        gap: 'space.200'
    });
});
parcelRegister("344pu", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$031bf061693e3531$export$2e2bcd8739ae039);
    var $dCLgq;
    var $6EOSB = parcelRequire("6EOSB");
    const $031bf061693e3531$var$Illustration = (0, (0, parcelRequire("dCLgq")).createIllustration)({
        srcLight: ($parcel$interopDefault($6EOSB)),
        srcDark: ($parcel$interopDefault($6EOSB))
    });
    var $031bf061693e3531$export$2e2bcd8739ae039 = $031bf061693e3531$var$Illustration;
    const $031bf061693e3531$export$a179c88182cb9d56 = [
        0,
        0,
        431,
        406
    ];
});
parcelRegister("6EOSB", function(module, exports) {
    module.exports = new URL("graph-go-up-with-people.4705ad58.svg", import.meta.url).toString();
});
parcelRegister("ce1BZ", function(module, exports) {
    $parcel$export(module.exports, "ReactGadget", ()=>$e6dfd10c598d91ed$export$561f0f8350aca3ee);
    var $5uXOq = parcelRequire("5uXOq");
    var $hh0bW;
    var $JIPqS;
    var $39xOx;
    var $bVDE5;
    var $4uQB7;
    var $8FVKN;
    var $i0rkS;
    var $5TsWX;
    var $kMe81;
    const $e6dfd10c598d91ed$var$expAttributes = {
        analyticsSource: 'dashboard',
        application: null,
        edition: null,
        additionalAttributes: {}
    };
    const $e6dfd10c598d91ed$export$561f0f8350aca3ee = ({ id: id, userPrefs: userPrefs, isInEditMode: isInEditMode, reactKey: reactKey, onEditModeCancel: onEditModeCancel, onRenderComplete: onRenderComplete, setDefaultTitle: setDefaultTitle, customSkeletonHeightInPx: customSkeletonHeightInPx })=>{
        const [{ currentPageId: currentPageId }] = (0, (0, parcelRequire("bVDE5")).useSpaStateTransition)();
        const [, { broadcastMessage: broadcastMessageBusListener }] = (0, (0, parcelRequire("i0rkS")).useMessageBus)();
        const setPreferences = (0, (0, parcelRequire("8FVKN")).useGadgetSetPreferences)();
        const ref = (0, $5uXOq.useRef)(null);
        const isExperienceCompletedRef = (0, $5uXOq.useRef)(false);
        const { state: state, componentView: ComponentView, componentEdit: ComponentEdit, setState: setState } = (0, (0, parcelRequire("kMe81")).useReactGadgetState)(id, reactKey);
        const experienceKey = `${(0, (0, parcelRequire("JIPqS")).VIEW_REACT_GADGET_EXPERIENCE)}-${reactKey.split('/')[1]}`;
        const reportGadgetMetrics = (0, $5uXOq.useCallback)((eventType)=>{
            broadcastMessageBusListener((0, (0, parcelRequire("JIPqS")).GADGET_METRICS_TYPE).REPORT, {
                eventType: eventType,
                contentType: isInEditMode ? 'Config' : 'View',
                source: (0, (0, parcelRequire("5TsWX")).REACT_GADGET_METRICS)
            }, {
                gadgetId: id,
                pageId: currentPageId
            });
        }, [
            broadcastMessageBusListener,
            isInEditMode,
            id,
            currentPageId
        ]);
        const reportGadgetStart = (0, $5uXOq.useCallback)(()=>{
            reportGadgetMetrics((0, (0, parcelRequire("5TsWX")).GADGET_START_EVENT));
        }, [
            reportGadgetMetrics
        ]);
        const onGadgetFinish = (0, $5uXOq.useCallback)((shouldPersistHeight = true)=>{
            if (shouldPersistHeight) {
                const height = ref.current?.getBoundingClientRect().height;
                onRenderComplete(height);
            }
            reportGadgetMetrics((0, (0, parcelRequire("5TsWX")).GADGET_RENDERING_FINISHED_EVENT));
            if (!isExperienceCompletedRef.current) {
                (0, (0, parcelRequire("hh0bW")).default)({
                    ...$e6dfd10c598d91ed$var$expAttributes,
                    experience: experienceKey,
                    wasExperienceSuccesful: true
                });
                isExperienceCompletedRef.current = true;
            }
        }, [
            reportGadgetMetrics,
            onRenderComplete,
            experienceKey
        ]);
        const errorFallback = (0, $5uXOq.useCallback)(()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4uQB7")).GadgetErrorFallback), {
                id: id,
                errorType: (0, (0, parcelRequire("JIPqS")).GADGET_ERROR_TYPE).VIEW_ERROR
            }), [
            id
        ]);
        const sendFailExperience = (0, $5uXOq.useCallback)(()=>{
            if (!isExperienceCompletedRef.current) {
                (0, (0, parcelRequire("hh0bW")).default)({
                    ...$e6dfd10c598d91ed$var$expAttributes,
                    experience: experienceKey,
                    wasExperienceSuccesful: false
                });
                isExperienceCompletedRef.current = true;
            }
        }, [
            experienceKey
        ]);
        (0, $5uXOq.useLayoutEffect)(()=>{
            reportGadgetMetrics((0, (0, parcelRequire("5TsWX")).GADGET_RENDER_CONTAINER_EVENT));
        }, [
            reportGadgetMetrics
        ]);
        const setGadgetContentTitle = (0, $5uXOq.useCallback)((contentTitle)=>{
            setDefaultTitle((initialDefaultTitle)=>contentTitle ? `${initialDefaultTitle}: ${contentTitle}` : initialDefaultTitle);
        }, [
            setDefaultTitle
        ]);
        const Component = isInEditMode ? ComponentEdit : ComponentView;
        if (state instanceof Error) {
            sendFailExperience();
            throw state;
        }
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            fallback: errorFallback,
            packageName: "jiraDashboardInternalCommon",
            id: reactKey,
            teamName: "endeavour",
            onError: sendFailExperience,
            sendToPrivacyUnsafeSplunk: true
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("div", {
            ref: ref
        }, Component && (0, ($parcel$interopDefault($5uXOq))).createElement(Component, {
            setPreferences: setPreferences,
            state: state,
            onGadgetStart: reportGadgetStart,
            onGadgetFinish: onGadgetFinish,
            userPrefs: userPrefs,
            onEditModeCancel: onEditModeCancel,
            setState: setState,
            setGadgetContentTitle: setGadgetContentTitle,
            customSkeletonHeightInPx: customSkeletonHeightInPx
        })));
    };
});
parcelRegister("kMe81", function(module, exports) {
    $parcel$export(module.exports, "useReactGadgetState", ()=>$6bed3136adbdd754$export$8e5d1b52830d94a5);
    var $5uXOq = parcelRequire("5uXOq");
    var $kspzT;
    var $gh1hi;
    var $iXzvf;
    const $6bed3136adbdd754$export$8e5d1b52830d94a5 = (id, reactKey)=>{
        const { data: data, update: update, loading: loading, error: error } = (0, (0, parcelRequire("gh1hi")).useDashboardReactGadgetsResource)();
        const reactGadgetsState = data?.reactGadgetsState ?? null;
        const state = (()=>{
            if (reactGadgetsState != null && id in reactGadgetsState) return reactGadgetsState[id];
            if (loading) return null;
            if (reactGadgetsState == null) return error ?? new Error('Gadget resource not initialised');
            return new Error(`Gadget state not found in initialised resource for gadget with reactKey: "${reactKey}" and ID: "${id}"`);
        })();
        const { componentView: componentView, componentEdit: componentEdit } = (0, (0, parcelRequire("kspzT")).default)[reactKey];
        const setState = (0, $5uXOq.useCallback)((setterOrValue)=>{
            if (reactGadgetsState && id in reactGadgetsState) update((0, (0, parcelRequire("iXzvf")).lensReactGadget)(id, setterOrValue));
        }, [
            update,
            reactGadgetsState,
            id
        ]);
        return {
            state: state,
            componentView: componentView,
            componentEdit: componentEdit,
            setState: setState
        };
    };
});
parcelRegister("4ozF0", function(module, exports) {
    $parcel$export(module.exports, "GadgetLayoutWithAnalytics", ()=>$a21461a35586ce8b$export$fbd1c75042cfdd67);
    var $5uXOq = parcelRequire("5uXOq");
    var $dC5iT;
    var $8CUq3;
    var $JIPqS;
    var $39xOx;
    var $iXVV6;
    var $4uQB7;
    var $lE1Ya;
    var $grY2f;
    function $a21461a35586ce8b$export$fbd1c75042cfdd67({ gadgetData: gadgetData, isDisplayed: isDisplayed, header: header, isContentHidden: isContentHidden, customWrapperStyles: customWrapperStyles, children: children, footer: footer, customPlaceholder: customPlaceholder }) {
        const { id: id, color: color, title: title, errorMessage: errorMessage } = gadgetData;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iXVV6")).ContextualAnalyticsData), {
            objectType: "gadget",
            objectId: gadgetData.id
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            id: "gadget",
            packageName: "jiraDashboardInternalCommon",
            fallback: "flag"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            xcss: [
                isDisplayed ? $a21461a35586ce8b$var$wrapperStyles : $a21461a35586ce8b$var$gadgetNotDisplayedStyles,
                ...Array.isArray(customWrapperStyles) ? customWrapperStyles : [
                    customWrapperStyles
                ],
                color && $a21461a35586ce8b$var$colorsMap[color]
            ],
            id: id
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("div", {
            id: `gadget-title-${id}`
        }, header), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            "aria-labelledby": `gadget-title-${id}`,
            id: `gadget-content-${id}`,
            role: "region"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("grY2f")).GadgetBoundary), {
            data: gadgetData,
            isError: !title,
            errorFallback: errorMessage ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4uQB7")).GadgetErrorFallbackBase), {
                id: id,
                message: errorMessage
            }) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4uQB7")).GadgetErrorFallback), {
                id: id,
                errorType: !title ? (0, (0, parcelRequire("JIPqS")).GADGET_ERROR_TYPE).NOT_SUPPORTED_ERROR : (0, (0, parcelRequire("JIPqS")).GADGET_ERROR_TYPE).VIEW_ERROR
            }),
            customPlaceholder: customPlaceholder
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            xcss: [
                isContentHidden ? $a21461a35586ce8b$var$contentHiddenStyles : $a21461a35586ce8b$var$contentStyles
            ]
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lE1Ya")).GadgetAnalyticsContainer), {
            data: gadgetData
        }, children), footer))))));
    }
    const $a21461a35586ce8b$var$wrapperStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        boxShadow: 'elevation.shadow.raised',
        borderTopWidth: '4px',
        borderTopStyle: 'solid',
        borderRadius: 'border.radius.100',
        marginBottom: 'space.200',
        background: `var(--ds-surface-raised, ${(0, $829f609a65d26a98$exports).N0})`,
        paddingInline: 'space.200',
        paddingBottom: 'space.200',
        position: 'relative',
        borderColor: 'color.border.brand'
    });
    const $a21461a35586ce8b$var$gadgetNotDisplayedStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        display: 'none'
    });
    const $a21461a35586ce8b$var$colorsMap = Object.fromEntries(Object.entries((0, (0, parcelRequire("JIPqS")).COLOR_MAP)).map(([key, value])=>[
            key,
            (0, (0, parcelRequire("8CUq3")).xcss)({
                borderColor: value
            })
        ]));
    const $a21461a35586ce8b$var$contentStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        overflowWrap: 'anywhere'
    });
    const $a21461a35586ce8b$var$contentHiddenStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        display: 'none'
    });
});
parcelRegister("lE1Ya", function(module, exports) {
    $parcel$export(module.exports, "GadgetAnalyticsContainer", ()=>$8dd46ecf3ec4fed0$export$33d5cbc3e6507749);
    var $5uXOq = parcelRequire("5uXOq");
    var $bOnpg;
    var $3Wusa;
    const $8dd46ecf3ec4fed0$export$33250e472f9e12c2 = 4840;
    const $8dd46ecf3ec4fed0$export$33d5cbc3e6507749 = ({ data: data, children: children })=>{
        const fireAnalytics = (0, (0, parcelRequire("bOnpg")).useDashboardAnalytics)();
        const eventSent = (0, $5uXOq.useRef)(false);
        (0, $5uXOq.useEffect)(()=>{
            if (data !== null && eventSent.current === false) {
                fireAnalytics({
                    ...(0, (0, parcelRequire("3Wusa")).getGadgetAnalyticsProps)(data),
                    action: 'viewed'
                });
                eventSent.current = true;
            }
        }, [
            data,
            fireAnalytics
        ]);
        if (!data) return null;
        if (data.renderedGadgetUrl != null && data.renderedGadgetUrl.length > $8dd46ecf3ec4fed0$export$33250e472f9e12c2) {
            if (eventSent.current === false) {
                fireAnalytics({
                    ...(0, (0, parcelRequire("3Wusa")).getGadgetAnalyticsProps)(data),
                    action: 'viewedFailed'
                });
                eventSent.current = true;
            }
            throw new Error('Request header is too large');
        }
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, children);
    };
});
parcelRegister("3Wusa", function(module, exports) {
    $parcel$export(module.exports, "getGadgetAnalyticsProps", ()=>$ad3242df71f39677$export$42d88d3ece4aff0);
    var $kspzT;
    const $ad3242df71f39677$export$42d88d3ece4aff0 = (data)=>{
        let gadgetType = 'UNKNOWN_GADGET';
        if (data.amdModule != null) gadgetType = data.amdModule;
        else if (data.forge != null) gadgetType = data.forge.key;
        else if ((0, (0, parcelRequire("kspzT")).isReactKey)(data.reactKey)) gadgetType = data.reactKey;
        return {
            eventType: (0, $4d5e0871c06cee03$export$947dac6c72b26f81),
            actionSubjectId: 'gadget',
            actionSubject: 'gadget',
            attributes: {
                gadgetTitle: data.title,
                gadgetType: gadgetType
            }
        };
    };
});
parcelRegister("grY2f", function(module, exports) {
    $parcel$export(module.exports, "GadgetBoundary", ()=>$4828aa186ac8828a$export$9327e9e817f0323d);
    var $5uXOq = parcelRequire("5uXOq");
    var $JIPqS;
    var $39xOx;
    var $c1XZn;
    var $8UPoO;
    var $8FVKN;
    const $4828aa186ac8828a$export$9327e9e817f0323d = ({ data: data, errorFallback: errorFallback, customPlaceholder: customPlaceholder, isError: isError, children: children })=>{
        const { id: id } = data;
        const renderKey = (0, (0, parcelRequire("8FVKN")).useGadgetRenderKey)();
        const shouldRender = (0, (0, parcelRequire("8UPoO")).useShouldRenderGadget)(id);
        const renderFallback = (0, $5uXOq.useCallback)(()=>errorFallback, [
            errorFallback
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            id: "gadget-content",
            packageName: "jiraDashboardInternalCommon",
            fallback: renderFallback
        }, (()=>{
            if (isError) return errorFallback;
            if (customPlaceholder) return customPlaceholder;
            if (!shouldRender) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("c1XZn")).SkeletonGadgetContent), {
                customHeight: `${(0, (0, parcelRequire("JIPqS")).GADGET_DEFAULT_HEIGHT)}px`,
                isPulsing: true
            });
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, $5uXOq.Fragment), {
                key: renderKey
            }, children);
        })());
    };
});
parcelRegister("j3C3s", function(module, exports) {
    $parcel$export(module.exports, "GadgetTitleWrapper", ()=>$41769e611e2b6e1f$export$88d654f5e42c4cf);
    var $5uXOq = parcelRequire("5uXOq");
    var $dC5iT;
    var $8CUq3;
    function $41769e611e2b6e1f$export$88d654f5e42c4cf({ isRenaming: isRenaming, children: children, id: id }) {
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            xcss: [
                $41769e611e2b6e1f$var$titleStyles,
                isRenaming && $41769e611e2b6e1f$var$titleRenamingStyles
            ],
            id: id
        }, children);
    }
    const $41769e611e2b6e1f$var$titleStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        minHeight: "var(--ds-space-250, 20px)",
        flexGrow: 1,
        minWidth: '0',
        paddingBlock: 'space.200',
        paddingRight: 'space.100',
        wordBreak: 'break-word',
        marginRight: 'space.200'
    });
    const $41769e611e2b6e1f$var$titleRenamingStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        cursor: 'text'
    });
});
parcelRegister("gFWXX", function(module, exports) {
    $parcel$export(module.exports, "TitleReadOnly", ()=>$dc1e643a17c4a39f$export$10ece9f949c62d6e);
    var $bwpsq;
    var $5uXOq = parcelRequire("5uXOq");
    var $1dJ6S;
    var $3bDjY;
    var $h0JHu;
    var $hDzhb;
    var $6hp8I;
    const $dc1e643a17c4a39f$var$TitleInner = (0, $5uXOq.forwardRef)(({ title: title, titleUrl: titleUrl, forgeEnvironment: forgeEnvironment, forgeEnvironmentKey: forgeEnvironmentKey }, ref)=>{
        const displayTitle = forgeEnvironment ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("h0JHu")).ExtensionTitle), {
            extensionEnvType: forgeEnvironment,
            extensionEnvKey: forgeEnvironmentKey,
            extensionName: title
        }) : title;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1dJ6S")).default), {
            size: "small",
            as: "h2",
            testId: "dashboard-internal-common.ui.gadget.title-readonly.title-inner"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($dc1e643a17c4a39f$var$HeaderTextWrapper, {
            ref: ref
        }, titleUrl !== undefined ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hDzhb")).default), {
            href: titleUrl
        }, displayTitle) : displayTitle));
    });
    const $dc1e643a17c4a39f$export$10ece9f949c62d6e = ({ title: title, titleUrl: titleUrl, forgeEnvironment: forgeEnvironment, forgeEnvironmentKey: forgeEnvironmentKey })=>{
        const dimensions = (0, (0, parcelRequire("6hp8I")).useDimensions)();
        const ref = (0, $5uXOq.useRef)(null);
        const [showTooltip, setShowTooltip] = (0, $5uXOq.useState)(false);
        (0, $5uXOq.useEffect)(()=>{
            if (ref.current == null) return;
            const { offsetWidth: offsetWidth, scrollWidth: scrollWidth } = ref.current;
            const shouldShowTooltip = offsetWidth < scrollWidth;
            if (showTooltip !== shouldShowTooltip) setShowTooltip(shouldShowTooltip);
        }, [
            dimensions,
            showTooltip
        ]);
        const tooltipContent = (0, $5uXOq.useMemo)(()=>{
            if (showTooltip) return forgeEnvironment ? `${title} (${forgeEnvironment})` : title;
            return null;
        }, [
            showTooltip,
            forgeEnvironment,
            title
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement($dc1e643a17c4a39f$var$TooltipWrapper, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3bDjY")).default), {
            content: tooltipContent,
            position: "top"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($dc1e643a17c4a39f$var$TitleInner, {
            title: title,
            ref: ref,
            titleUrl: titleUrl,
            forgeEnvironment: forgeEnvironment,
            forgeEnvironmentKey: forgeEnvironmentKey
        })));
    };
    const $dc1e643a17c4a39f$var$TooltipWrapper = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1ul9idpf",
                __cmplp.className
            ])
        });
    });
    const $dc1e643a17c4a39f$var$HeaderTextWrapper = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_19pkidpf _1reo15vq _18m915vq _1bto1l2s _o5721q9c",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("h0JHu", function(module, exports) {
    $parcel$export(module.exports, "ExtensionTitle", ()=>$8acb929a7e4cf7fc$export$3aa4a75e461c581c);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $k2DRb;
    var $fl3Yy;
    const $8acb929a7e4cf7fc$var$shouldShowEnv = (extensionEnvType)=>[
            (0, (0, parcelRequire("k2DRb")).STAGING_ENVIRONMENT),
            (0, (0, parcelRequire("k2DRb")).DEVELOPMENT_ENVIRONMENT)
        ].includes(extensionEnvType);
    const $8acb929a7e4cf7fc$export$3aa4a75e461c581c = ({ extensionEnvType: extensionEnvType, extensionEnvKey: extensionEnvKey, extensionName: extensionName })=>{
        if (!$8acb929a7e4cf7fc$var$shouldShowEnv(extensionEnvType)) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, extensionName);
        return (0, ($parcel$interopDefault($5uXOq))).createElement($8acb929a7e4cf7fc$var$ExtensionTitleWrapper, null, (0, (0, parcelRequire("fl3Yy")).nameWithCustomEnvironment)(extensionName, extensionEnvType, extensionEnvKey), ' ', (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fl3Yy")).EnvironmentLozenge), {
            environmentType: extensionEnvType
        }));
    };
    const $8acb929a7e4cf7fc$var$ExtensionTitleWrapper = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_4cvr1q9y _13aiidpf",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("1yhBH", function(module, exports) {
    $parcel$export(module.exports, "GadgetToolbar", ()=>$5fcf8ae904c6c9b8$export$bef5bd636ffcc29c);
    var $5uXOq = parcelRequire("5uXOq");
    var $42CjB;
    var $gE1Ym;
    var $4h1Pq;
    var $5JpK2;
    var $jOrGg;
    var $j2iKG;
    var $kgoHi;
    var $3bDjY;
    var $koVbs;
    var $bOnpg;
    var $8FVKN;
    var $7K2Ba;
    function $5fcf8ae904c6c9b8$export$bef5bd636ffcc29c({ gadgetId: gadgetId, title: title, onMinimizeOrRestore: onMinimizeOrRestore, isMinimized: isMinimized, isMaximizable: isMaximizable, onMaximizeOrRestore: onMaximizeOrRestore, isMaximized: isMaximized, additionalToolbarItems: additionalToolbarItems }) {
        const onRefresh = (0, (0, parcelRequire("8FVKN")).useGadgetRefresh)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("kgoHi")).default), null, (0, ($parcel$interopDefault($5uXOq))).createElement($5fcf8ae904c6c9b8$export$266a769812832828, {
            onClick: ()=>onMinimizeOrRestore(!isMinimized),
            action: "minimize-restore-button",
            tooltipMessageKey: (0, (0, parcelRequire("7K2Ba")).default)[isMinimized ? 'expand' : 'minimize'],
            ariaLabelMessageKey: (0, (0, parcelRequire("7K2Ba")).default)[isMinimized ? 'expandAriaLabel' : 'minimizeAriaLabel'],
            gadgetName: title,
            gadgetId: gadgetId,
            icon: isMinimized ? (0, ($parcel$interopDefault((0, parcelRequire("5JpK2"))))) : (0, ($parcel$interopDefault((0, parcelRequire("j2iKG")))))
        }), isMaximizable && (0, ($parcel$interopDefault($5uXOq))).createElement($5fcf8ae904c6c9b8$export$266a769812832828, {
            onClick: ()=>onMaximizeOrRestore(!isMaximized),
            action: "maximize-restore-button",
            tooltipMessageKey: (0, (0, parcelRequire("7K2Ba")).default)[isMaximized ? 'singleColumn' : 'maximize'],
            ariaLabelMessageKey: (0, (0, parcelRequire("7K2Ba")).default)[isMaximized ? 'singleColumn' : 'maximizeAriaLabel'],
            gadgetId: gadgetId,
            gadgetName: title,
            icon: isMaximized ? (0, ($parcel$interopDefault((0, parcelRequire("4h1Pq"))))) : (0, ($parcel$interopDefault((0, parcelRequire("gE1Ym")))))
        }), (0, ($parcel$interopDefault($5uXOq))).createElement($5fcf8ae904c6c9b8$export$266a769812832828, {
            gadgetId: gadgetId,
            onClick: onRefresh,
            action: "refresh-button",
            tooltipMessageKey: (0, (0, parcelRequire("7K2Ba")).default).refresh,
            ariaLabelMessageKey: (0, (0, parcelRequire("7K2Ba")).default).refreshAriaLabel,
            gadgetName: title,
            icon: (0, ($parcel$interopDefault((0, parcelRequire("jOrGg")))))
        }), additionalToolbarItems);
    }
    const $5fcf8ae904c6c9b8$export$266a769812832828 = ({ gadgetId: gadgetId, onClick: onClick, action: action, tooltipMessageKey: tooltipMessageKey, ariaLabelMessageKey: ariaLabelMessageKey, gadgetName: gadgetName, icon: icon })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const fireAnalytics = (0, (0, parcelRequire("bOnpg")).useDashboardAnalytics)();
        const onClickHandler = ()=>{
            fireAnalytics({
                eventType: (0, $4d5e0871c06cee03$export$a1562d08625f5d7e),
                actionSubject: `gadget.${action}`,
                action: 'clicked',
                actionSubjectId: 'gadget'
            });
            onClick();
        };
        const tooltipContent = formatMessage(tooltipMessageKey);
        const ariaLabel = formatMessage(ariaLabelMessageKey, {
            gadgetName: gadgetName
        });
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3bDjY")).default), {
            content: tooltipContent,
            hideTooltipOnClick: true,
            position: "top"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("42CjB")).default), {
            appearance: "subtle",
            spacing: "default",
            label: ariaLabel,
            onClick: onClickHandler,
            icon: icon,
            testId: `dashboard-internal-common.ui.gadget.toolbar.${action}`,
            "aria-controls": `gadget-content-${gadgetId}`
        }));
    };
});
parcelRegister("gE1Ym", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $c1e1e97b4a9cecff$var$_react = $c1e1e97b4a9cecff$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $c1e1e97b4a9cecff$var$_fullscreenEnter = $c1e1e97b4a9cecff$var$_interopRequireDefault((parcelRequire("5C3Gx")));
    var $c1e1e97b4a9cecff$var$_vidFullScreenOn = $c1e1e97b4a9cecff$var$_interopRequireDefault((parcelRequire("1Lys5")));
    function $c1e1e97b4a9cecff$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $c1e1e97b4a9cecff$var$FullscreenEnterIcon = (props)=>$c1e1e97b4a9cecff$var$_react.default.createElement($c1e1e97b4a9cecff$var$_fullscreenEnter.default, Object.assign({
            LEGACY_fallbackIcon: $c1e1e97b4a9cecff$var$_vidFullScreenOn.default
        }, props));
    $c1e1e97b4a9cecff$var$FullscreenEnterIcon.Name = 'FullscreenEnterIconMigration';
    var $c1e1e97b4a9cecff$var$_default = module.exports.default = $c1e1e97b4a9cecff$var$FullscreenEnterIcon;
});
parcelRegister("5C3Gx", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $41635c272d6a179a$var$_react = $41635c272d6a179a$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $41635c272d6a179a$var$_UNSAFE_baseNew = $41635c272d6a179a$var$_interopRequireDefault((parcelRequire("fEaCe")));
    function $41635c272d6a179a$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $41635c272d6a179a$var$FullscreenEnterIcon = (props)=>$41635c272d6a179a$var$_react.default.createElement($41635c272d6a179a$var$_UNSAFE_baseNew.default, Object.assign({
            dangerouslySetGlyph: `<path stroke="currentcolor" stroke-linejoin="round" stroke-width="1.5" d="M1.75 6V3c0-.69.56-1.25 1.25-1.25h3M14.25 10v3c0 .69-.56 1.25-1.25 1.25h-3m0-12.5h3c.69 0 1.25.56 1.25 1.25v3M6 14.25H3c-.69 0-1.25-.56-1.25-1.25v-3"/>`
        }, props));
    $41635c272d6a179a$var$FullscreenEnterIcon.displayName = 'FullscreenEnterIcon';
    var $41635c272d6a179a$var$_default = module.exports.default = $41635c272d6a179a$var$FullscreenEnterIcon;
});
parcelRegister("1Lys5", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $1494e7af70bbd024$var$_react = $1494e7af70bbd024$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $c7fbr;
    var $1494e7af70bbd024$var$_fullscreenEnter = $1494e7af70bbd024$var$_interopRequireDefault((parcelRequire("5C3Gx")));
    function $1494e7af70bbd024$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $1494e7af70bbd024$var$VidFullScreenOnIcon = (props)=>$1494e7af70bbd024$var$_react.default.createElement((0, parcelRequire("c7fbr")).IconFacade, Object.assign({
            dangerouslySetGlyph: `<svg width="24" height="24" viewBox="0 0 24 24" role="presentation"><path fill="currentcolor" fill-rule="evenodd" d="M6 18h3a1 1 0 0 1 0 2H6a2 2 0 0 1-2-2v-3a1 1 0 0 1 2 0zm12 2h-3a1 1 0 0 1 0-2h3v-3a1 1 0 0 1 2 0v3a2 2 0 0 1-2 2M6 4h3a1 1 0 1 1 0 2H6v3a1 1 0 1 1-2 0V6a2 2 0 0 1 2-2m12 2h-3a1 1 0 0 1 0-2h3a2 2 0 0 1 2 2v3a1 1 0 0 1-2 0z"/></svg>`
        }, props, {
            newIcon: $1494e7af70bbd024$var$_fullscreenEnter.default
        }));
    $1494e7af70bbd024$var$VidFullScreenOnIcon.displayName = 'VidFullScreenOnIcon';
    var $1494e7af70bbd024$var$_default = module.exports.default = $1494e7af70bbd024$var$VidFullScreenOnIcon;
});
parcelRegister("4h1Pq", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $31ca2b071cc9d641$var$_react = $31ca2b071cc9d641$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $31ca2b071cc9d641$var$_fullscreenExit = $31ca2b071cc9d641$var$_interopRequireDefault((parcelRequire("g5bVM")));
    var $31ca2b071cc9d641$var$_fullScreen = $31ca2b071cc9d641$var$_interopRequireDefault((parcelRequire("aU3Qy")));
    function $31ca2b071cc9d641$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $31ca2b071cc9d641$var$FullscreenExitIcon = (props)=>$31ca2b071cc9d641$var$_react.default.createElement($31ca2b071cc9d641$var$_fullscreenExit.default, Object.assign({
            LEGACY_fallbackIcon: $31ca2b071cc9d641$var$_fullScreen.default
        }, props));
    $31ca2b071cc9d641$var$FullscreenExitIcon.Name = 'FullscreenExitIconMigration';
    var $31ca2b071cc9d641$var$_default = module.exports.default = $31ca2b071cc9d641$var$FullscreenExitIcon;
});
parcelRegister("g5bVM", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $bb565e37400efb63$var$_react = $bb565e37400efb63$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $bb565e37400efb63$var$_UNSAFE_baseNew = $bb565e37400efb63$var$_interopRequireDefault((parcelRequire("fEaCe")));
    function $bb565e37400efb63$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $bb565e37400efb63$var$FullscreenExitIcon = (props)=>$bb565e37400efb63$var$_react.default.createElement($bb565e37400efb63$var$_UNSAFE_baseNew.default, Object.assign({
            dangerouslySetGlyph: `<path stroke="currentcolor" stroke-linejoin="round" stroke-width="1.5" d="M5.25 1v3c0 .69-.56 1.25-1.25 1.25H1M10.75 15v-3c0-.69.56-1.25 1.25-1.25h3m0-5.5h-3c-.69 0-1.25-.56-1.25-1.25V1M1 10.75h3c.69 0 1.25.56 1.25 1.25v3"/>`
        }, props));
    $bb565e37400efb63$var$FullscreenExitIcon.displayName = 'FullscreenExitIcon';
    var $bb565e37400efb63$var$_default = module.exports.default = $bb565e37400efb63$var$FullscreenExitIcon;
});
parcelRegister("aU3Qy", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $7f021e30f8edd1a3$var$_react = $7f021e30f8edd1a3$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $c7fbr;
    var $7f021e30f8edd1a3$var$_fullscreenExit = $7f021e30f8edd1a3$var$_interopRequireDefault((parcelRequire("g5bVM")));
    function $7f021e30f8edd1a3$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $7f021e30f8edd1a3$var$MediaServicesFullScreenIcon = (props)=>$7f021e30f8edd1a3$var$_react.default.createElement((0, parcelRequire("c7fbr")).IconFacade, Object.assign({
            dangerouslySetGlyph: `<svg width="24" height="24" viewBox="0 0 24 24" role="presentation"><path fill="currentcolor" fill-rule="evenodd" d="M16 8h3a1 1 0 0 1 0 2h-3a2 2 0 0 1-2-2V5a1 1 0 0 1 2 0zm-8 2H5a1 1 0 1 1 0-2h3V5a1 1 0 1 1 2 0v3a2 2 0 0 1-2 2m8 4h3a1 1 0 0 1 0 2h-3v3a1 1 0 0 1-2 0v-3a2 2 0 0 1 2-2m-8 2H5a1 1 0 0 1 0-2h3a2 2 0 0 1 2 2v3a1 1 0 0 1-2 0z"/></svg>`
        }, props, {
            newIcon: $7f021e30f8edd1a3$var$_fullscreenExit.default
        }));
    $7f021e30f8edd1a3$var$MediaServicesFullScreenIcon.displayName = 'MediaServicesFullScreenIcon';
    var $7f021e30f8edd1a3$var$_default = module.exports.default = $7f021e30f8edd1a3$var$MediaServicesFullScreenIcon;
});
parcelRegister("5JpK2", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $42c51f70ef43f1c0$var$_react = $42c51f70ef43f1c0$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $42c51f70ef43f1c0$var$_growDiagonal = $42c51f70ef43f1c0$var$_interopRequireDefault((parcelRequire("l14Zy")));
    var $42c51f70ef43f1c0$var$_actualSize = $42c51f70ef43f1c0$var$_interopRequireDefault((parcelRequire("6LXPU")));
    function $42c51f70ef43f1c0$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $42c51f70ef43f1c0$var$GrowDiagonalIcon = (props)=>$42c51f70ef43f1c0$var$_react.default.createElement($42c51f70ef43f1c0$var$_growDiagonal.default, Object.assign({
            LEGACY_fallbackIcon: $42c51f70ef43f1c0$var$_actualSize.default
        }, props));
    $42c51f70ef43f1c0$var$GrowDiagonalIcon.Name = 'GrowDiagonalIconMigration';
    var $42c51f70ef43f1c0$var$_default = module.exports.default = $42c51f70ef43f1c0$var$GrowDiagonalIcon;
});
parcelRegister("l14Zy", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $f4cd903aabaa4c53$var$_react = $f4cd903aabaa4c53$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $f4cd903aabaa4c53$var$_UNSAFE_baseNew = $f4cd903aabaa4c53$var$_interopRequireDefault((parcelRequire("fEaCe")));
    function $f4cd903aabaa4c53$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $f4cd903aabaa4c53$var$GrowDiagonalIcon = (props)=>$f4cd903aabaa4c53$var$_react.default.createElement($f4cd903aabaa4c53$var$_UNSAFE_baseNew.default, Object.assign({
            dangerouslySetGlyph: `<path stroke="currentcolor" stroke-linejoin="round" stroke-width="1.5" d="m7 9-4.75 4.75M9 7l4.75-4.75m-12 7.25v4.75H6.5m7.75-7.75V1.75H9.5"/>`
        }, props));
    $f4cd903aabaa4c53$var$GrowDiagonalIcon.displayName = 'GrowDiagonalIcon';
    var $f4cd903aabaa4c53$var$_default = module.exports.default = $f4cd903aabaa4c53$var$GrowDiagonalIcon;
});
parcelRegister("6LXPU", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $4ee58c13163bfe09$var$_react = $4ee58c13163bfe09$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $c7fbr;
    var $4ee58c13163bfe09$var$_growDiagonal = $4ee58c13163bfe09$var$_interopRequireDefault((parcelRequire("l14Zy")));
    function $4ee58c13163bfe09$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $4ee58c13163bfe09$var$MediaServicesActualSizeIcon = (props)=>$4ee58c13163bfe09$var$_react.default.createElement((0, parcelRequire("c7fbr")).IconFacade, Object.assign({
            dangerouslySetGlyph: `<svg width="24" height="24" viewBox="0 0 24 24" role="presentation"><path fill="currentcolor" fill-rule="evenodd" d="M16.587 6.003H15A1 1 0 0 1 15 4h3.9l.047.001a.98.98 0 0 1 .736.285l.032.032c.2.2.296.47.284.736l.001.048v3.896a1 1 0 1 1-2 0V7.411l-3.309 3.308a.977.977 0 0 1-1.374-.005l-.032-.032a.976.976 0 0 1-.005-1.374zM7.413 17.997H9A1 1 0 0 1 9 20H5.1l-.047-.001a.98.98 0 0 1-.736-.285l-.032-.032A.98.98 0 0 1 4 18.946v-3.944a1 1 0 1 1 2 0v1.587l3.309-3.308a.977.977 0 0 1 1.374.005l.032.032a.976.976 0 0 1 .005 1.374z"/></svg>`
        }, props, {
            newIcon: $4ee58c13163bfe09$var$_growDiagonal.default
        }));
    $4ee58c13163bfe09$var$MediaServicesActualSizeIcon.displayName = 'MediaServicesActualSizeIcon';
    var $4ee58c13163bfe09$var$_default = module.exports.default = $4ee58c13163bfe09$var$MediaServicesActualSizeIcon;
});
parcelRegister("jOrGg", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $e6c828a42ee64c30$var$_react = $e6c828a42ee64c30$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $e6c828a42ee64c30$var$_refresh = $e6c828a42ee64c30$var$_interopRequireDefault((parcelRequire("gPelT")));
    var $e6c828a42ee64c30$var$_refresh2 = $e6c828a42ee64c30$var$_interopRequireDefault((parcelRequire("75Jbm")));
    function $e6c828a42ee64c30$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $e6c828a42ee64c30$var$RefreshIcon = (props)=>$e6c828a42ee64c30$var$_react.default.createElement($e6c828a42ee64c30$var$_refresh.default, Object.assign({
            LEGACY_fallbackIcon: $e6c828a42ee64c30$var$_refresh2.default
        }, props));
    $e6c828a42ee64c30$var$RefreshIcon.Name = 'RefreshIconMigration';
    var $e6c828a42ee64c30$var$_default = module.exports.default = $e6c828a42ee64c30$var$RefreshIcon;
});
parcelRegister("gPelT", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $c3fc8ad083e2745c$var$_react = $c3fc8ad083e2745c$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $c3fc8ad083e2745c$var$_UNSAFE_baseNew = $c3fc8ad083e2745c$var$_interopRequireDefault((parcelRequire("fEaCe")));
    function $c3fc8ad083e2745c$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $c3fc8ad083e2745c$var$RefreshIcon = (props)=>$c3fc8ad083e2745c$var$_react.default.createElement($c3fc8ad083e2745c$var$_UNSAFE_baseNew.default, Object.assign({
            dangerouslySetGlyph: `<path stroke="currentcolor" stroke-linejoin="round" stroke-width="1.5" d="M6.25 6V1.75H2m5.5 12.48c-3.218-.254-5.75-2.447-5.75-5.73 0-3.368 2.42-4.921 4-6.29m4 7.77v4.25H14M8.5 1.75c3.218.255 5.75 2.447 5.75 5.73 0 3.352-2.397 4.906-3.977 6.27"/>`
        }, props));
    $c3fc8ad083e2745c$var$RefreshIcon.displayName = 'RefreshIcon';
    var $c3fc8ad083e2745c$var$_default = module.exports.default = $c3fc8ad083e2745c$var$RefreshIcon;
});
parcelRegister("75Jbm", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $529c0c456318696f$var$_react = $529c0c456318696f$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $c7fbr;
    var $529c0c456318696f$var$_refresh = $529c0c456318696f$var$_interopRequireDefault((parcelRequire("gPelT")));
    function $529c0c456318696f$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $529c0c456318696f$var$RefreshIcon = (props)=>$529c0c456318696f$var$_react.default.createElement((0, parcelRequire("c7fbr")).IconFacade, Object.assign({
            dangerouslySetGlyph: `<svg width="24" height="24" viewBox="0 0 24 24" role="presentation"><g fill="currentcolor" fill-rule="evenodd"><path fill-rule="nonzero" d="M8 6.003v2.995a1 1 0 1 0 2 0V5.102C10 4.494 9.507 4 8.9 4H5a1 1 0 0 0 0 2.003z"/><path fill-rule="nonzero" d="M9.428 18.018C7.351 16.989 6 14.807 6 12.37c0-2.266 1.167-4.319 3.02-5.425.48-.286.646-.922.371-1.421a.98.98 0 0 0-1.364-.386C5.557 6.611 4 9.35 4 12.37c0 3.248 1.802 6.158 4.57 7.529.498.247 1.095.026 1.332-.493.237-.52.025-1.141-.474-1.388"/><path d="M14 15.002v3.896c0 .608.493 1.102 1.1 1.102H19a1 1 0 0 0 0-2.003h-3v-2.995a1 1 0 1 0-2 0"/><path d="M14.097 4.596c-.237.52-.025 1.14.474 1.387 2.077 1.029 3.428 3.21 3.428 5.647 0 2.266-1.167 4.32-3.021 5.425a1.063 1.063 0 0 0-.37 1.42c.274.5.885.673 1.364.387 2.47-1.473 4.027-4.212 4.027-7.232 0-3.248-1.802-6.158-4.57-7.528A.96.96 0 0 0 15.002 4a1 1 0 0 0-.905.596"/></g></svg>`
        }, props, {
            newIcon: $529c0c456318696f$var$_refresh.default
        }));
    $529c0c456318696f$var$RefreshIcon.displayName = 'RefreshIcon';
    var $529c0c456318696f$var$_default = module.exports.default = $529c0c456318696f$var$RefreshIcon;
});
parcelRegister("j2iKG", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $ddbcbff50c42c269$var$_react = $ddbcbff50c42c269$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $ddbcbff50c42c269$var$_shrinkDiagonal = $ddbcbff50c42c269$var$_interopRequireDefault((parcelRequire("8KRBa")));
    var $ddbcbff50c42c269$var$_fitToPage = $ddbcbff50c42c269$var$_interopRequireDefault((parcelRequire("2bcm3")));
    function $ddbcbff50c42c269$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $ddbcbff50c42c269$var$ShrinkDiagonalIcon = (props)=>$ddbcbff50c42c269$var$_react.default.createElement($ddbcbff50c42c269$var$_shrinkDiagonal.default, Object.assign({
            LEGACY_fallbackIcon: $ddbcbff50c42c269$var$_fitToPage.default
        }, props));
    $ddbcbff50c42c269$var$ShrinkDiagonalIcon.Name = 'ShrinkDiagonalIconMigration';
    var $ddbcbff50c42c269$var$_default = module.exports.default = $ddbcbff50c42c269$var$ShrinkDiagonalIcon;
});
parcelRegister("8KRBa", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $65fc322a3db160cb$var$_react = $65fc322a3db160cb$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $65fc322a3db160cb$var$_UNSAFE_baseNew = $65fc322a3db160cb$var$_interopRequireDefault((parcelRequire("fEaCe")));
    function $65fc322a3db160cb$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $65fc322a3db160cb$var$ShrinkDiagonalIcon = (props)=>$65fc322a3db160cb$var$_react.default.createElement($65fc322a3db160cb$var$_UNSAFE_baseNew.default, Object.assign({
            dangerouslySetGlyph: `<path stroke="currentcolor" stroke-linejoin="round" stroke-width="1.5" d="m1.5 14.5 5.25-5.25M14.5 1.5 9.25 6.75M6.75 14V9.25H2M9.25 2v4.75H14"/>`
        }, props));
    $65fc322a3db160cb$var$ShrinkDiagonalIcon.displayName = 'ShrinkDiagonalIcon';
    var $65fc322a3db160cb$var$_default = module.exports.default = $65fc322a3db160cb$var$ShrinkDiagonalIcon;
});
parcelRegister("2bcm3", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $1966313a87133c34$var$_react = $1966313a87133c34$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $c7fbr;
    var $1966313a87133c34$var$_shrinkDiagonal = $1966313a87133c34$var$_interopRequireDefault((parcelRequire("8KRBa")));
    function $1966313a87133c34$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $1966313a87133c34$var$MediaServicesFitToPageIcon = (props)=>$1966313a87133c34$var$_react.default.createElement((0, parcelRequire("c7fbr")).IconFacade, Object.assign({
            dangerouslySetGlyph: `<svg width="24" height="24" viewBox="0 0 24 24" role="presentation"><path fill="currentcolor" fill-rule="evenodd" d="M16.413 8.997H18A1 1 0 0 1 18 11h-3.9l-.047-.001a.98.98 0 0 1-.736-.285l-.032-.032A.98.98 0 0 1 13 9.946V6.002a1 1 0 1 1 2 0v1.587l3.309-3.308a.977.977 0 0 1 1.374.005l.032.032a.976.976 0 0 1 .005 1.374zm-8.826 6.006H6A1 1 0 0 1 6 13h3.9l.047.001a.98.98 0 0 1 .736.285l.032.032c.2.2.296.47.284.736l.001.048v3.896a1 1 0 1 1-2 0v-1.587l-3.309 3.308a.977.977 0 0 1-1.374-.005l-.032-.032a.976.976 0 0 1-.005-1.374z"/></svg>`
        }, props, {
            newIcon: $1966313a87133c34$var$_shrinkDiagonal.default
        }));
    $1966313a87133c34$var$MediaServicesFitToPageIcon.displayName = 'MediaServicesFitToPageIcon';
    var $1966313a87133c34$var$_default = module.exports.default = $1966313a87133c34$var$MediaServicesFitToPageIcon;
});
parcelRegister("7K2Ba", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$9d16755660d37c6c$export$2e2bcd8739ae039);
    var $7VHMR;
    var $9d16755660d37c6c$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        minimize: {
            "id": "dashboard-internal-common.gadget.toolbar.minimize",
            "defaultMessage": "Minimize"
        },
        minimizeAriaLabel: {
            "id": "dashboard-internal-common.gadget.toolbar.minimize-aria-label",
            "defaultMessage": "Minimize {gadgetName}"
        },
        expand: {
            "id": "dashboard-internal-common.gadget.toolbar.expand",
            "defaultMessage": "Expand"
        },
        expandAriaLabel: {
            "id": "dashboard-internal-common.gadget.toolbar.expand-aria-label",
            "defaultMessage": "Expand {gadgetName}"
        },
        maximize: {
            "id": "dashboard-internal-common.gadget.toolbar.maximize",
            "defaultMessage": "Maximize"
        },
        maximizeAriaLabel: {
            "id": "dashboard-internal-common.gadget.toolbar.maximize-aria-label",
            "defaultMessage": "Maximize {gadgetName}"
        },
        refresh: {
            "id": "dashboard-internal-common.gadget.toolbar.refresh",
            "defaultMessage": "Refresh"
        },
        refreshAriaLabel: {
            "id": "dashboard-internal-common.gadget.toolbar.refresh-aria-label",
            "defaultMessage": "Refresh {gadgetName}"
        },
        singleColumn: {
            "id": "dashboard-internal-common.gadget.toolbar.single-column",
            "defaultMessage": "Single column"
        }
    });
});
parcelRegister("iIi7d", function(module, exports) {
    $parcel$export(module.exports, "LastRefreshed", ()=>$64481a9700f86c7d$export$66aab1cf9cd737cc);
    var $5uXOq = parcelRequire("5uXOq");
    var $75Jbm;
    var $kgoHi;
    var $cgL5K;
    var $8CUq3;
    var $koVbs;
    var $1p96B;
    var $9OXo1;
    var $8hIHC;
    const $64481a9700f86c7d$export$66aab1cf9cd737cc = ({ lastRefreshed: lastRefreshed, updateInterval: updateInterval })=>{
        const [lastRefreshedTimeAgo, setLastRefreshedTimeAgo] = (0, $5uXOq.useState)('');
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const { locale: locale } = (0, (0, parcelRequire("9OXo1")).useTenantContext)();
        (0, $5uXOq.useEffect)(()=>{
            setLastRefreshedTimeAgo(formatMessage((0, (0, parcelRequire("8hIHC")).default).justNow));
            const refreshInterval = setInterval(()=>lastRefreshed != null && setLastRefreshedTimeAgo(formatMessage((0, (0, parcelRequire("8hIHC")).default).timeAgo, {
                    timeAgo: (0, (0, parcelRequire("1p96B")).formatDistanceStrictWithLocale)(lastRefreshed, new Date(), locale)
                })), updateInterval);
            return ()=>clearInterval(refreshInterval);
        }, [
            formatMessage,
            lastRefreshed,
            locale,
            updateInterval
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("kgoHi")).default), {
            direction: "row",
            alignItems: "center",
            xcss: $64481a9700f86c7d$var$lastRefreshedWrapperStyles
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("75Jbm"))))), {
            size: "small",
            label: formatMessage((0, (0, parcelRequire("8hIHC")).default).lastRefreshedIcon)
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cgL5K")).default), {
            size: "UNSAFE_small",
            color: "color.text.subtle"
        }, lastRefreshedTimeAgo));
    };
    const $64481a9700f86c7d$var$lastRefreshedWrapperStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        paddingTop: 'space.200',
        color: 'color.text.subtle',
        gap: 'space.050'
    });
});
parcelRegister("8hIHC", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$3afac6d64be9e0b6$export$2e2bcd8739ae039);
    var $7VHMR;
    var $3afac6d64be9e0b6$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        justNow: {
            "id": "dashboard-internal-common.last-refreshed.just-now",
            "defaultMessage": "Just now"
        },
        timeAgo: {
            "id": "dashboard-internal-common.last-refreshed.time-ago",
            "defaultMessage": "{timeAgo} ago"
        },
        lastRefreshedIcon: {
            "id": "dashboard-internal-common.last-refreshed.last-refreshed-icon",
            "defaultMessage": "Last refreshed"
        }
    });
});
parcelRegister("32zIt", function(module, exports) {
    $parcel$export(module.exports, "HeaderReadOnly", ()=>$69498a7fcd5bdf10$export$a00d46171b6c3f6a);
    var $5uXOq = parcelRequire("5uXOq");
    var $1ywCO;
    var $65Ltd;
    var $JIPqS;
    var $bOnpg;
    var $gYYRR;
    var $dZymr;
    var $1i27D;
    var $2PcTT;
    var $lWWbJ;
    var $9jsHG;
    var $aNPat;
    var $koVbs;
    var $6jY6G;
    var $9OXo1;
    var $dCm1o;
    var $2zwcE;
    var $kC29b;
    const $69498a7fcd5bdf10$export$a00d46171b6c3f6a = ({ data: data, isEditLinkEnabled: isEditLinkEnabled, banners: banners })=>{
        const tenantContext = (0, (0, parcelRequire("9OXo1")).useTenantContext)();
        const { isAnonymous: isAnonymous } = tenantContext;
        const { id: id, title: title, systemDashboard: systemDashboard } = data;
        const { headerItems: headerItems, toolItems: toolItems } = (0, (0, parcelRequire("2PcTT")).useConnectMenuItems)(id);
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const editRoute = (0, (0, parcelRequire("6jY6G")).useRouteByName)(systemDashboard ? (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_SYSTEM_DASHBOARD_EDIT) : (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_DASHBOARD_EDIT));
        const fireAnalytics = (0, (0, parcelRequire("bOnpg")).useDashboardAnalytics)();
        const onClickHandler = (0, $5uXOq.useCallback)(()=>{
            fireAnalytics({
                eventType: (0, $4d5e0871c06cee03$export$a1562d08625f5d7e),
                action: 'clicked',
                actionSubject: 'dashboard.edit-button',
                actionSubjectId: (0, (0, parcelRequire("JIPqS")).DASHBOARD_VIEW_SOURCENAME)
            });
        }, [
            fireAnalytics
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1i27D")).Header), {
            banners: banners
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1i27D")).HeaderTitle), null, title), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1i27D")).HeaderActions), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dZymr")).ConnectHeader), {
            items: headerItems
        }), !isAnonymous && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9jsHG")).FavoriteButton), {
            dashboardId: id,
            dashboardName: title
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("2zwcE")).HeaderCopyLinkButton), {
            dashboardId: id
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lWWbJ")).DashboardRefreshButton), null), isEditLinkEnabled && (editRoute && (0, (0, parcelRequire("dCm1o")).isVisualRefreshEnabled)() ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("aNPat")).RouteLinkButton), {
            to: editRoute,
            params: {
                dashboardId: id
            },
            onClick: onClickHandler,
            testId: "dashboard-view.ui.header-readonly.dashboard-edit-button",
            iconAfter: (0, ($parcel$interopDefault((0, parcelRequire("1ywCO")))))
        }, formatMessage((0, (0, parcelRequire("kC29b")).default).edit)) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("aNPat")).RouteLinkButtonOld), {
            to: editRoute ?? undefined,
            params: {
                dashboardId: id
            },
            prefetch: "hover",
            onClick: onClickHandler,
            testId: "dashboard-view.ui.header-readonly.dashboard-edit-button",
            iconAfter: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("1ywCO"))))), {
                label: "",
                LEGACY_size: "small",
                color: "currentColor"
            })
        }, formatMessage((0, (0, parcelRequire("kC29b")).default).edit))), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gYYRR")).ActionDropdown), {
            data: data,
            connectToolMenuItems: toolItems
        })));
    };
});
parcelRegister("1ywCO", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $122246161208ff48$var$_react = $122246161208ff48$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $122246161208ff48$var$_edit = $122246161208ff48$var$_interopRequireDefault((parcelRequire("5nRKm")));
    var $122246161208ff48$var$_editFilled = $122246161208ff48$var$_interopRequireDefault((parcelRequire("avBcG")));
    function $122246161208ff48$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $122246161208ff48$var$EditIcon = (props)=>$122246161208ff48$var$_react.default.createElement($122246161208ff48$var$_edit.default, Object.assign({
            LEGACY_fallbackIcon: $122246161208ff48$var$_editFilled.default
        }, props));
    $122246161208ff48$var$EditIcon.Name = 'EditIconMigration';
    var $122246161208ff48$var$_default = module.exports.default = $122246161208ff48$var$EditIcon;
});
parcelRegister("5nRKm", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $3eb8cab3ab465b93$var$_react = $3eb8cab3ab465b93$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $3eb8cab3ab465b93$var$_UNSAFE_baseNew = $3eb8cab3ab465b93$var$_interopRequireDefault((parcelRequire("fEaCe")));
    function $3eb8cab3ab465b93$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $3eb8cab3ab465b93$var$EditIcon = (props)=>$3eb8cab3ab465b93$var$_react.default.createElement($3eb8cab3ab465b93$var$_UNSAFE_baseNew.default, Object.assign({
            dangerouslySetGlyph: `<path stroke="currentcolor" stroke-linejoin="round" stroke-width="1.5" d="M6 1.751H3c-.69 0-1.25.56-1.25 1.25v10c0 .69.56 1.25 1.25 1.25h10c.69 0 1.25-.56 1.25-1.25V10m-.75-5 1.116-1.116a1.25 1.25 0 0 0 0-1.768l-.732-.732a1.25 1.25 0 0 0-1.768 0L11 2.5M13.5 5 9.479 9.021c-.15.15-.336.26-.54.318l-3.189.911.911-3.189a1.25 1.25 0 0 1 .318-.54L11 2.5M13.5 5 11 2.5"/>`
        }, props));
    $3eb8cab3ab465b93$var$EditIcon.displayName = 'EditIcon';
    var $3eb8cab3ab465b93$var$_default = module.exports.default = $3eb8cab3ab465b93$var$EditIcon;
});
parcelRegister("avBcG", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $7a69a877f42e31b8$var$_react = $7a69a877f42e31b8$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $c7fbr;
    var $7a69a877f42e31b8$var$_edit = $7a69a877f42e31b8$var$_interopRequireDefault((parcelRequire("5nRKm")));
    function $7a69a877f42e31b8$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $7a69a877f42e31b8$var$EditFilledIcon = (props)=>$7a69a877f42e31b8$var$_react.default.createElement((0, parcelRequire("c7fbr")).IconFacade, Object.assign({
            dangerouslySetGlyph: `<svg width="24" height="24" viewBox="0 0 24 24" role="presentation"><path fill="currentcolor" fill-rule="evenodd" d="M4.02 19.23a1 1 0 0 0 1.18 1.18l3.81-.78-4.21-4.21zM19.84 6.71l-2.12-2.12A2 2 0 0 0 16.31 4c-.51 0-1.02.2-1.41.59l-9.76 9.76 4.95 4.95 9.76-9.76a2 2 0 0 0 0-2.83"/></svg>`
        }, props, {
            newIcon: $7a69a877f42e31b8$var$_edit.default
        }));
    $7a69a877f42e31b8$var$EditFilledIcon.displayName = 'EditFilledIcon';
    var $7a69a877f42e31b8$var$_default = module.exports.default = $7a69a877f42e31b8$var$EditFilledIcon;
});
parcelRegister("gYYRR", function(module, exports) {
    $parcel$export(module.exports, "ActionDropdown", ()=>$255b9987b473f48f$export$d5503927a7d116d);
    var $5uXOq = parcelRequire("5uXOq");
    var $fVnbM;
    var $BLPHT;
    var $6B7KL;
    var $aRoVz;
    var $6qrUW;
    var $9UCCQ;
    var $JIPqS;
    var $koVbs;
    var $e9QkH;
    var $9OXo1;
    var $b8ziP;
    var $edp7U;
    var $dFiez;
    var $2h3Nr;
    var $fb7AH;
    var $fqLvm;
    var $2IJzF;
    var $8AXqQ;
    var $2EGKG;
    var $x5bVw;
    const $255b9987b473f48f$export$d5503927a7d116d = ({ data: data, connectToolMenuItems: connectToolMenuItems })=>{
        const { isAnonymous: isAnonymous, atlassianAccountId: atlassianAccountId } = (0, (0, parcelRequire("9OXo1")).useTenantContext)();
        const { dropdownItemClickHandler: dropdownItemClickHandler } = (0, (0, parcelRequire("b8ziP")).useDropdownItemOnClickHandler)();
        const dropdownTriggerButton = (0, $5uXOq.useRef)(null);
        const [, { on: modalCopyOpen }] = (0, (0, parcelRequire("e9QkH")).useSwitchModals)((0, (0, parcelRequire("JIPqS")).MODAL_ID).DASHBOARD_COPY);
        const [, { on: modalAutomaticRefreshOpen }] = (0, (0, parcelRequire("e9QkH")).useSwitchModals)((0, (0, parcelRequire("JIPqS")).MODAL_ID).AUTOMATIC_REFRESH);
        const [, { on: modalRenameAndShareOpen }] = (0, (0, parcelRequire("e9QkH")).useSwitchModals)((0, (0, parcelRequire("JIPqS")).MODAL_ID).DASHBOARD_RENAME_OR_SHARE);
        const [, { on: modalDeleteOpen }] = (0, (0, parcelRequire("e9QkH")).useSwitchModals)((0, (0, parcelRequire("JIPqS")).MODAL_ID).DASHBOARD_DELETE);
        const [, { on: modalCreateOpen }] = (0, (0, parcelRequire("e9QkH")).useSwitchModals)((0, (0, parcelRequire("JIPqS")).MODAL_ID).DASHBOARD_CREATE);
        const onModalCloseCallback = (0, $5uXOq.useCallback)(()=>{
            setTimeout(()=>{
                if (dropdownTriggerButton.current) dropdownTriggerButton.current.focus();
            }, 1);
        }, [
            dropdownTriggerButton
        ]);
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        if (!data) return null;
        const dashboardId = data.id;
        const isDashboardOwner = atlassianAccountId === data.owner;
        const viewAsWallboardUrl = `/jira/dashboards/wallboard?dashboardId=${dashboardId}`;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("2EGKG")).RenameOrShareModal), {
            id: dashboardId,
            onModalCloseCallback: onModalCloseCallback
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fqLvm")).CopyModal), {
            id: dashboardId,
            onModalCloseCallback: onModalCloseCallback
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("2h3Nr")).AutomaticRefreshModal), {
            id: dashboardId,
            onModalCloseCallback: onModalCloseCallback
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("8AXqQ")).DeleteModal), {
            id: dashboardId,
            onModalCloseCallback: onModalCloseCallback
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fb7AH")).ConfigureWallboardModal), {
            onModalCloseCallback: onModalCloseCallback
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("2IJzF")).CreateModal), {
            onModalCloseCallback: onModalCloseCallback
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("BLPHT")).default), {
            isLoading: false,
            trigger: ({ triggerRef: triggerRef, ...triggerProps })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
                    ...triggerProps,
                    spacing: "default",
                    iconBefore: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("6qrUW"))))), {
                        label: formatMessage((0, (0, parcelRequire("dFiez")).default).moreActions),
                        size: "medium"
                    }),
                    ref: (buttonRef)=>{
                        if (typeof triggerRef === 'function') triggerRef(buttonRef);
                        if (dropdownTriggerButton.current === null) dropdownTriggerButton.current = buttonRef;
                    }
                }),
            shouldFlip: true,
            placement: "bottom-end",
            appearance: "tall",
            testId: "dashboard-internal-common.ui.dashboard-content.header.action-dropdown.dropdown-menu",
            defaultOpen: (0, (0, parcelRequire("edp7U")).getIsDropdownDefaultOpen)('action-dropdown'),
            shouldRenderToParent: true
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9UCCQ")).default), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("aRoVz")).default), {
            title: data.title
        }, data.writable && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6B7KL")).default), {
            "data-testid": "dashboard-internal-common.ui.dashboard-content.header.action-dropdown.rename-and-share",
            onClick: modalRenameAndShareOpen
        }, formatMessage(data.systemDashboard ? (0, (0, parcelRequire("dFiez")).default).share : (0, (0, parcelRequire("dFiez")).default).renameOrShare)), !isAnonymous && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6B7KL")).default), {
            "data-testid": "dashboard-internal-common.ui.dashboard-content.header.action-dropdown.copy",
            onClick: modalCopyOpen
        }, formatMessage((0, (0, parcelRequire("dFiez")).default).copy)), data.writable && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6B7KL")).default), {
            "data-testid": "dashboard-internal-common.ui.dashboard-content.header.action-dropdown.automatic-refresh",
            onClick: modalAutomaticRefreshOpen
        }, formatMessage((0, (0, parcelRequire("dFiez")).default).automaticRefresh)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6B7KL")).default), {
            "data-testid": "dashboard-internal-common.ui.dashboard-content.header.action-dropdown.wallboard",
            href: viewAsWallboardUrl,
            onClick: (e)=>dropdownItemClickHandler(e, viewAsWallboardUrl, 'dashboard.view-as-wallboard-item'),
            UNSAFE_shouldDisableRouterLink: true
        }, formatMessage((0, (0, parcelRequire("dFiez")).default).wallboard)), isDashboardOwner && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6B7KL")).default), {
            "data-testid": "dashboard-internal-common.ui.dashboard-content.header.action-dropdown.delete",
            onClick: modalDeleteOpen
        }, formatMessage((0, (0, parcelRequire("dFiez")).default).moveToTrash)))), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("x5bVw")).WallboardSlideshow), null), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9UCCQ")).default), {
            hasSeparator: true
        }, !isAnonymous && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6B7KL")).default), {
            "data-testid": "dashboard-internal-common.ui.dashboard-content.header.action-dropdown.create",
            onClick: modalCreateOpen
        }, formatMessage((0, (0, parcelRequire("dFiez")).default).create)), !isAnonymous && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6B7KL")).default), {
            "data-testid": "dashboard-internal-common.ui.dashboard-content.header.action-dropdown.find",
            href: "/jira/dashboards",
            UNSAFE_shouldDisableRouterLink: true
        }, formatMessage((0, (0, parcelRequire("dFiez")).default).find))), connectToolMenuItems && connectToolMenuItems.length ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9UCCQ")).default), {
            testId: "dashboard-internal-common.ui.dashboard-content.header.action-dropdown.dashboard-connect-menu-items",
            hasSeparator: true
        }, connectToolMenuItems.map(({ id: id, title: title, url: url, styleClass: styleClass })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6B7KL")).default), {
                key: id,
                href: url,
                UNSAFE_shouldDisableRouterLink: true
            }, (0, ($parcel$interopDefault($5uXOq))).createElement("div", {
                id: id,
                className: styleClass
            }, title)))) : null));
    };
});
parcelRegister("6B7KL", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$9ff85f737833a5bc$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $5JjdN;
    var $kJpBP;
    var $1aogK;
    var $3Z96c;
    var $VSDnB;
    var $l7Q0F;
    const $9ff85f737833a5bc$var$DropdownMenuItem = (0, $5uXOq.forwardRef)(({ children: children, component: component, description: description, elemAfter: elemAfter, elemBefore: elemBefore, href: href, isDisabled: isDisabled, isSelected: isSelected, onClick: onClick, rel: rel, shouldDescriptionWrap: shouldDescriptionWrap = true, shouldTitleWrap: shouldTitleWrap = true, target: target, testId: testId, UNSAFE_shouldDisableRouterLink: UNSAFE_shouldDisableRouterLink, returnFocusRef: returnFocusRef, interactionName: interactionName, ...rest }, ref)=>{
        const itemRef = (0, (0, parcelRequire("l7Q0F")).default)();
        const handleItemClick = (0, $5uXOq.useCallback)((event)=>{
            if (returnFocusRef?.current) returnFocusRef.current.focus();
            if (onClick) onClick(event);
        }, [
            onClick,
            returnFocusRef
        ]);
        if (component) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1aogK")).default), {
            component: component,
            description: description,
            iconAfter: elemAfter,
            iconBefore: elemBefore,
            isDisabled: isDisabled,
            isSelected: isSelected,
            onClick: handleItemClick,
            ref: (0, (0, parcelRequire("5JjdN")).default)([
                ref,
                itemRef
            ]),
            shouldDescriptionWrap: shouldDescriptionWrap,
            shouldTitleWrap: shouldTitleWrap,
            testId: testId,
            href: href,
            target: target,
            rel: rel,
            ...(0, (0, parcelRequire("VSDnB")).fg)('platform_button_item-add-ufo-metrics') && {
                interactionName: interactionName
            },
            ...rest
        }, children);
        else if (href) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3Z96c")).default), {
            description: description,
            href: href,
            iconAfter: elemAfter,
            iconBefore: elemBefore,
            isDisabled: isDisabled,
            isSelected: isSelected,
            onClick: handleItemClick,
            ref: (0, (0, parcelRequire("5JjdN")).default)([
                ref,
                itemRef
            ]),
            rel: rel,
            role: "menuitem",
            shouldDescriptionWrap: shouldDescriptionWrap,
            shouldTitleWrap: shouldTitleWrap,
            target: target,
            testId: testId,
            UNSAFE_shouldDisableRouterLink: UNSAFE_shouldDisableRouterLink,
            ...(0, (0, parcelRequire("VSDnB")).fg)('platform_button_item-add-ufo-metrics') && {
                interactionName: interactionName
            },
            ...rest
        }, children);
        else return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("kJpBP")).default), {
            description: description,
            iconAfter: elemAfter,
            iconBefore: elemBefore,
            isDisabled: isDisabled,
            isSelected: isSelected,
            onClick: handleItemClick,
            ref: (0, (0, parcelRequire("5JjdN")).default)([
                ref,
                itemRef
            ]),
            role: "menuitem",
            shouldDescriptionWrap: shouldDescriptionWrap,
            shouldTitleWrap: shouldTitleWrap,
            testId: testId,
            target: target,
            rel: rel,
            ...(0, (0, parcelRequire("VSDnB")).fg)('platform_button_item-add-ufo-metrics') && {
                interactionName: interactionName
            },
            ...rest
        }, children);
    });
    var $9ff85f737833a5bc$export$2e2bcd8739ae039 = $9ff85f737833a5bc$var$DropdownMenuItem;
});
parcelRegister("aRoVz", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$6c1f89f82839ba50$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $9KnKW;
    var $9UCCQ;
    var $k20lf;
    const $6c1f89f82839ba50$var$DropdownMenuItemGroup = (0, $5uXOq.forwardRef)(({ children: children, id: id, isList: isList, isScrollable: isScrollable, title: title, testId: testId, hasSeparator: hasSeparator, overrides: overrides, ...rest }, ref)=>{
        const uid = (0, (0, parcelRequire("9KnKW")).useId)();
        const titleId = `dropdown-menu-item-group-title-${uid}`;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9UCCQ")).default), {
            id: id,
            ref: ref,
            isList: isList,
            isScrollable: isScrollable,
            hasSeparator: hasSeparator,
            testId: testId,
            titleId: title ? titleId : undefined,
            overrides: overrides,
            ...rest
        }, title && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("k20lf")).default), {
            id: titleId,
            title: title
        }), children);
    });
    var $6c1f89f82839ba50$export$2e2bcd8739ae039 = $6c1f89f82839ba50$var$DropdownMenuItemGroup;
});
parcelRegister("6qrUW", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $4adad3971ebc70ba$var$_react = $4adad3971ebc70ba$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $c7fbr;
    var $4adad3971ebc70ba$var$_showMoreHorizontal = $4adad3971ebc70ba$var$_interopRequireDefault((parcelRequire("8U6zd")));
    function $4adad3971ebc70ba$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $4adad3971ebc70ba$var$EditorMoreIcon = (props)=>$4adad3971ebc70ba$var$_react.default.createElement((0, parcelRequire("c7fbr")).IconFacade, Object.assign({
            dangerouslySetGlyph: `<svg width="24" height="24" viewBox="0 0 24 24" role="presentation"><path fill="currentcolor" fill-rule="evenodd" d="M12 14a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m-4.5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m9 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3"/></svg>`
        }, props, {
            newIcon: $4adad3971ebc70ba$var$_showMoreHorizontal.default
        }));
    $4adad3971ebc70ba$var$EditorMoreIcon.displayName = 'EditorMoreIcon';
    var $4adad3971ebc70ba$var$_default = module.exports.default = $4adad3971ebc70ba$var$EditorMoreIcon;
});
parcelRegister("e9QkH", function(module, exports) {
    $parcel$export(module.exports, "useSwitchModals", ()=>$bbe85969245206f0$export$5ed4b99cbad4ebd6);
    var $2qauD;
    const { useSwitch: $bbe85969245206f0$var$useSwitch } = (0, (0, parcelRequire("2qauD")).createSwitchController)('jira-switch-modals');
    const $bbe85969245206f0$export$5ed4b99cbad4ebd6 = $bbe85969245206f0$var$useSwitch;
});
parcelRegister("2qauD", function(module, exports) {
    $parcel$export(module.exports, "createSwitchController", ()=>$a539fc33301c42ee$export$1856548326159ec8);
    var $5uXOq = parcelRequire("5uXOq");
    var $gg7kZ;
    var $3sQ5x;
    var $iXqE5;
    const $a539fc33301c42ee$export$1856548326159ec8 = (name, activeByDefault)=>{
        const actions = {
            on: (active)=>({ setState: setState })=>setState({
                        active: active
                    }),
            off: (active)=>({ setState: setState, getState: getState })=>{
                    if (getState().active === active || active === undefined) setState({
                        active: undefined
                    });
                },
            toggle: (active)=>({ getState: getState, dispatch: dispatch })=>{
                    if (getState().active === active) dispatch(actions.off(active));
                    else dispatch(actions.on(active));
                }
        };
        const initialState = {
            active: activeByDefault
        };
        const store = (0, (0, parcelRequire("iXqE5")).createStore)({
            name: name,
            initialState: initialState,
            actions: actions
        });
        const SwitchContainer = (0, (0, parcelRequire("gg7kZ")).createContainer)(store);
        const selector = (state, key)=>state.active === key;
        const useSwitchInternal = (0, (0, parcelRequire("3sQ5x")).createHook)(store, {
            selector: selector
        });
        const useSwitch = (active)=>{
            const [isActive, { on: onSwitch, off: offSwitch, toggle: toggleSwitch }] = useSwitchInternal(active);
            const on = (0, $5uXOq.useCallback)(()=>onSwitch(active), [
                onSwitch,
                active
            ]);
            const off = (0, $5uXOq.useCallback)(()=>offSwitch(active), [
                active,
                offSwitch
            ]);
            const toggle = (0, $5uXOq.useCallback)(()=>toggleSwitch(active), [
                toggleSwitch,
                active
            ]);
            return [
                isActive,
                {
                    on: on,
                    off: off,
                    toggle: toggle
                }
            ];
        };
        return {
            SwitchContainer: SwitchContainer,
            useSwitch: useSwitch
        };
    };
});
parcelRegister("b8ziP", function(module, exports) {
    $parcel$export(module.exports, "useDropdownItemOnClickHandler", ()=>$7f06fd4fc0d36f11$export$b9ed61ea9e46f071);
    var $lVJAU;
    var $bOnpg;
    var $cdUgf;
    const $7f06fd4fc0d36f11$export$b9ed61ea9e46f071 = ()=>{
        const { push: push } = (0, (0, parcelRequire("lVJAU")).useRouterActions)();
        const fireAnalytics = (0, (0, parcelRequire("bOnpg")).useDashboardAnalytics)();
        const dropdownItemClickHandler = (e, url, actionSubject)=>{
            if (actionSubject) fireAnalytics({
                eventType: (0, $4d5e0871c06cee03$export$a1562d08625f5d7e),
                actionSubject: actionSubject,
                action: 'clicked',
                actionSubjectId: 'dashboard'
            });
            if (!(0, (0, parcelRequire("cdUgf")).clickedWithShortcutKeys)(e)) {
                e.preventDefault();
                push(url);
            }
        };
        return {
            dropdownItemClickHandler: dropdownItemClickHandler
        };
    };
});
parcelRegister("edp7U", function(module, exports) {
    $parcel$export(module.exports, "getIsDropdownDefaultOpen", ()=>$dad419d0b39d4c21$export$638abbdcabd86e67);
    const $dad419d0b39d4c21$export$638abbdcabd86e67 = (_)=>false;
});
parcelRegister("dFiez", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$172a2c76e18069ed$export$2e2bcd8739ae039);
    var $7VHMR;
    var $172a2c76e18069ed$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        moreActions: {
            "id": "dashboard-internal-common.dashboard-content.header.action-dropdown.more-actions",
            "defaultMessage": "More dashboard actions"
        },
        copy: {
            "id": "dashboard-internal-common.dashboard-content.header.action-dropdown.copy",
            "defaultMessage": "Copy"
        },
        automaticRefresh: {
            "id": "dashboard-internal-common.dashboard-content.header.action-dropdown.automatic-refresh",
            "defaultMessage": "Configure automatic refresh"
        },
        renameOrShare: {
            "id": "dashboard-internal-common.dashboard-content.header.action-dropdown.rename-or-share",
            "defaultMessage": "Rename or share"
        },
        share: {
            "id": "dashboard-internal-common.dashboard-content.header.action-dropdown.share",
            "defaultMessage": "Share dashboard"
        },
        moveToTrash: {
            "id": "dashboard-internal-common.dashboard-content.header.action-dropdown.move-to-trash",
            "defaultMessage": "Move to trash"
        },
        find: {
            "id": "dashboard-internal-common.dashboard-content.header.action-dropdown.find",
            "defaultMessage": "View all dashboards"
        },
        create: {
            "id": "dashboard-internal-common.dashboard-content.header.action-dropdown.create",
            "defaultMessage": "Create a dashboard"
        },
        wallboard: {
            "id": "dashboard-internal-common.dashboard-content.header.action-dropdown.wallboard",
            "defaultMessage": "View as wallboard"
        }
    });
});
parcelRegister("2h3Nr", function(module, exports) {
    $parcel$export(module.exports, "AutomaticRefreshModal", ()=>$de8465027ccce40b$export$47f35766b426b3bc);
    var $5uXOq = parcelRequire("5uXOq");
    var $j55bS;
    var $hh0bW;
    var $JIPqS;
    var $39xOx;
    var $lfTZh;
    var $e9QkH;
    var $kxZPA;
    var $iLC4C;
    var $89bLT;
    const $de8465027ccce40b$var$LazyDashboardModal = (0, (0, parcelRequire("kxZPA")).lazy)(()=>(parcelRequire("TfAoI")), {
        moduleId: "./src/packages/global-pages/dashboard/internal-common/src/ui/dashboard-content/header/action-dropdown/modals/automatic-refresh-modal/main.tsx"
    });
    const $de8465027ccce40b$var$expAttributes = {
        analyticsSource: 'dashboard',
        experience: (0, (0, parcelRequire("JIPqS")).DASHBOARD_AUTOMATIC_REFRESH_EXPERIENCE),
        application: null,
        edition: null,
        additionalAttributes: {
            isCancel: false
        }
    };
    const $de8465027ccce40b$var$sendSuccessExperience = ()=>(0, (0, parcelRequire("hh0bW")).default)({
            ...$de8465027ccce40b$var$expAttributes,
            wasExperienceSuccesful: true
        });
    const $de8465027ccce40b$var$sendFailedExperience = ()=>(0, (0, parcelRequire("hh0bW")).default)({
            ...$de8465027ccce40b$var$expAttributes,
            wasExperienceSuccesful: false
        });
    const $de8465027ccce40b$export$47f35766b426b3bc = ({ id: id, onModalCloseCallback: onModalCloseCallback })=>{
        const [isOpen, { off: close }] = (0, (0, parcelRequire("e9QkH")).useSwitchModals)((0, (0, parcelRequire("JIPqS")).MODAL_ID).AUTOMATIC_REFRESH);
        const onFailed = (err)=>{
            const error = err || new Error('automatic refresh modal configuration failed.');
            (0, (0, parcelRequire("iLC4C")).handleFetchErrorAsExperience)({
                error: error,
                sendSuccessExperience: $de8465027ccce40b$var$sendSuccessExperience,
                sendFailedExperience: $de8465027ccce40b$var$sendFailedExperience,
                logErrorEvent: (0, (0, parcelRequire("89bLT")).logErrorEventFunc)(error, (0, (0, parcelRequire("JIPqS")).DASHBOARD_AUTOMATIC_REFRESH))
            });
            close();
            onModalCloseCallback?.();
        };
        const onCancel = ()=>{
            (0, (0, parcelRequire("hh0bW")).default)({
                ...$de8465027ccce40b$var$expAttributes,
                wasExperienceSuccesful: true,
                additionalAttributes: {
                    isCancel: true
                }
            });
            close();
            onModalCloseCallback?.();
        };
        const onClose = ()=>{
            close();
            onModalCloseCallback?.();
        };
        return isOpen ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            id: (0, (0, parcelRequire("JIPqS")).DASHBOARD_AUTOMATIC_REFRESH_EXPERIENCE),
            packageName: "dashboard",
            fallback: "flag",
            attributes: {
                task: (0, (0, parcelRequire("JIPqS")).DASHBOARD_AUTOMATIC_REFRESH_EXPERIENCE)
            },
            withExperienceTracker: true
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "dashboard-modal",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("j55bS")).default), {
                isTinted: true
            })
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($de8465027ccce40b$var$LazyDashboardModal, {
            id: id,
            onClose: onClose,
            onSuccess: $de8465027ccce40b$var$sendSuccessExperience,
            onCancel: onCancel,
            onFailed: onFailed
        }))) : null;
    };
});
parcelRegister("iLC4C", function(module, exports) {
    $parcel$export(module.exports, "handleFetchErrorAsExperience", ()=>$10506a1bb5cb99af$export$8b2399c5090eddc3);
    var $jJZqs;
    var $fZneA;
    const $10506a1bb5cb99af$export$65e55894e6e38a89 = 'client network error';
    const $10506a1bb5cb99af$export$796b9e0e5da90309 = 'unauthenticated (401)';
    const $10506a1bb5cb99af$export$8b2399c5090eddc3 = ({ error: error, sendSuccessExperience: sendSuccessExperience, sendFailedExperience: sendFailedExperience, logErrorEvent: logErrorEvent })=>{
        if ((0, (0, parcelRequire("fZneA")).isClientFetchError)(error)) {
            sendSuccessExperience();
            logErrorEvent($10506a1bb5cb99af$export$65e55894e6e38a89);
        } else if (error instanceof (0, (0, parcelRequire("jJZqs")).default) && error.constructor === (0, (0, parcelRequire("jJZqs")).default)) {
            const errorCode = Number(error.message.substring(error.message.length - 3));
            if (errorCode === 304) sendSuccessExperience();
            else if (errorCode === 401) logErrorEvent($10506a1bb5cb99af$export$796b9e0e5da90309);
            else {
                sendFailedExperience();
                logErrorEvent(`${errorCode} status code response`);
            }
        } else {
            sendFailedExperience();
            logErrorEvent();
        }
    };
});
parcelRegister("89bLT", function(module, exports) {
    $parcel$export(module.exports, "logErrorEventFunc", ()=>$98efc897ec5a605f$export$e315c4152a211ba8);
    var $jcw0u;
    const $98efc897ec5a605f$export$e315c4152a211ba8 = (error, eventId)=>(reason)=>{
            if (error != null) (0, (0, parcelRequire("jcw0u")).default)({
                error: error,
                meta: {
                    id: eventId,
                    packageName: 'jiraDashboardInternalCommon'
                },
                attributes: {
                    reason: reason
                }
            });
        };
});
parcelRegister("TfAoI", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("hPzOn"),
        $6fhBS("6Fl1t")
    ]).then(()=>parcelRequire('eIgK0'));
});
parcelRegister("fb7AH", function(module, exports) {
    $parcel$export(module.exports, "ConfigureWallboardModal", ()=>$c591fac53db599f8$export$cf7e06d150dcce6d);
    var $5uXOq = parcelRequire("5uXOq");
    var $j55bS;
    var $JIPqS;
    var $39xOx;
    var $lfTZh;
    var $e9QkH;
    var $kxZPA;
    const $c591fac53db599f8$var$LazyDashboardModal = (0, (0, parcelRequire("kxZPA")).lazy)(()=>(parcelRequire("b5lUu")), {
        moduleId: "./src/packages/global-pages/dashboard/internal-common/src/ui/dashboard-content/header/action-dropdown/modals/configure-wallboard-modal/modal/index.tsx"
    });
    const $c591fac53db599f8$export$cf7e06d150dcce6d = ({ onModalCloseCallback: onModalCloseCallback })=>{
        const [isOpen, { off: close }] = (0, (0, parcelRequire("e9QkH")).useSwitchModals)((0, (0, parcelRequire("JIPqS")).MODAL_ID).CONFIGURE_WALLBOARD);
        const onModalClose = ()=>{
            close();
            onModalCloseCallback?.();
        };
        return isOpen ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            id: "configure-wallboard-modal",
            packageName: "dashboard",
            fallback: "flag",
            teamName: "endeavour"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "dashboard-modal",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("j55bS")).default), {
                isTinted: true
            })
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($c591fac53db599f8$var$LazyDashboardModal, {
            onClose: onModalClose
        }))) : null;
    };
});
parcelRegister("b5lUu", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("1ftQg")
    ]).then(()=>parcelRequire('iFntg'));
});
parcelRegister("fqLvm", function(module, exports) {
    $parcel$export(module.exports, "CopyModal", ()=>$6aa3f9a2fe12b1cd$export$4a7ddad8989462fb);
    var $5uXOq = parcelRequire("5uXOq");
    var $j55bS;
    var $hh0bW;
    var $JIPqS;
    var $39xOx;
    var $34Vv8;
    var $lfTZh;
    var $e9QkH;
    var $kxZPA;
    var $lVJAU;
    var $iLC4C;
    var $89bLT;
    const $6aa3f9a2fe12b1cd$var$LazyDashboardModal = (0, (0, parcelRequire("34Vv8")).default)((0, (0, parcelRequire("kxZPA")).lazy)(()=>(parcelRequire("gzo7u")).then(({ CopyDashboardModal: CopyDashboardModal })=>CopyDashboardModal), {
        moduleId: "./src/packages/global-pages/directories/dashboards-directory-v2/copy-dashboard-modal/src/index.tsx"
    }));
    const $6aa3f9a2fe12b1cd$var$expAttributes = {
        analyticsSource: 'dashboard',
        experience: (0, (0, parcelRequire("JIPqS")).COPY_DASHBOARD_EXPERIENCE),
        application: null,
        edition: null,
        additionalAttributes: {
            isCancel: false
        }
    };
    const $6aa3f9a2fe12b1cd$var$sendSuccessExperience = ()=>(0, (0, parcelRequire("hh0bW")).default)({
            ...$6aa3f9a2fe12b1cd$var$expAttributes,
            wasExperienceSuccesful: true
        });
    const $6aa3f9a2fe12b1cd$var$sendFailedExperience = ()=>(0, (0, parcelRequire("hh0bW")).default)({
            ...$6aa3f9a2fe12b1cd$var$expAttributes,
            wasExperienceSuccesful: false
        });
    const $6aa3f9a2fe12b1cd$export$bdd968b8df213d5c = ({ id: id, onModalCloseCallback: onModalCloseCallback })=>{
        const [isOpen, { off: close }] = (0, (0, parcelRequire("e9QkH")).useSwitchModals)((0, (0, parcelRequire("JIPqS")).MODAL_ID).DASHBOARD_COPY);
        const { push: push } = (0, (0, parcelRequire("lVJAU")).useRouterActions)();
        const onSuccess = (_, response)=>{
            $6aa3f9a2fe12b1cd$var$sendSuccessExperience();
            close();
            if (response.view != null) push(response.view);
            else onModalCloseCallback?.();
        };
        const onInitiateFailed = (error)=>{
            (0, (0, parcelRequire("iLC4C")).handleFetchErrorAsExperience)({
                error: error || new Error('copy dashboard modal initialisation failed'),
                sendSuccessExperience: $6aa3f9a2fe12b1cd$var$sendSuccessExperience,
                sendFailedExperience: $6aa3f9a2fe12b1cd$var$sendFailedExperience,
                logErrorEvent: (0, (0, parcelRequire("89bLT")).logErrorEventFunc)(error, (0, (0, parcelRequire("JIPqS")).COPY_DASHBOARD))
            });
            close();
            onModalCloseCallback?.();
        };
        const onFailed = (error)=>{
            (0, (0, parcelRequire("iLC4C")).handleFetchErrorAsExperience)({
                error: error,
                sendSuccessExperience: $6aa3f9a2fe12b1cd$var$sendSuccessExperience,
                sendFailedExperience: $6aa3f9a2fe12b1cd$var$sendFailedExperience,
                logErrorEvent: (0, (0, parcelRequire("89bLT")).logErrorEventFunc)(error, (0, (0, parcelRequire("JIPqS")).COPY_DASHBOARD))
            });
            close();
            onModalCloseCallback?.();
        };
        const onCancel = ()=>{
            (0, (0, parcelRequire("hh0bW")).default)({
                ...$6aa3f9a2fe12b1cd$var$expAttributes,
                wasExperienceSuccesful: true,
                additionalAttributes: {
                    isCancel: true
                }
            });
            close();
            onModalCloseCallback?.();
        };
        return isOpen ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "dashboard-modal",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("j55bS")).default), null)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($6aa3f9a2fe12b1cd$var$LazyDashboardModal, {
            onInitiateFailed: onInitiateFailed,
            onSuccess: onSuccess,
            onFailed: onFailed,
            onCancel: onCancel,
            id: id
        })) : null;
    };
    const $6aa3f9a2fe12b1cd$export$4a7ddad8989462fb = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            id: (0, (0, parcelRequire("JIPqS")).COPY_DASHBOARD_EXPERIENCE),
            packageName: "dashboard",
            fallback: "flag",
            attributes: {
                task: (0, (0, parcelRequire("JIPqS")).COPY_DASHBOARD_EXPERIENCE)
            },
            withExperienceTracker: true
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($6aa3f9a2fe12b1cd$export$bdd968b8df213d5c, props));
});
parcelRegister("gzo7u", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("hPzOn"),
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("5k9P8")
    ]).then(()=>parcelRequire('a7HF6'));
});
parcelRegister("2IJzF", function(module, exports) {
    $parcel$export(module.exports, "CreateModal", ()=>$cdded0b8b2569776$export$204e9f8d79be983e);
    var $5uXOq = parcelRequire("5uXOq");
    var $j55bS;
    var $jgE05;
    var $JIPqS;
    var $39xOx;
    var $lfTZh;
    var $gh1hi;
    var $e9QkH;
    var $lVJAU;
    const $cdded0b8b2569776$export$e85d11db57ff5836 = ({ onModalCloseCallback: onModalCloseCallback })=>{
        const [isOpen, { off: close }] = (0, (0, parcelRequire("e9QkH")).useSwitchModals)((0, (0, parcelRequire("JIPqS")).MODAL_ID).DASHBOARD_CREATE);
        const { clear: clear } = (0, (0, parcelRequire("gh1hi")).useDashboardResource)();
        const { push: push } = (0, (0, parcelRequire("lVJAU")).useRouterActions)();
        const onSuccess = (_, operationResult)=>{
            clear();
            push(`/jira/dashboards/${operationResult.id}/edit`);
            close();
        };
        const onCancel = ()=>{
            close();
            onModalCloseCallback?.();
        };
        return isOpen ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "dashboards-modal",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("j55bS")).default), null)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("jgE05")).default), {
            onSuccess: onSuccess,
            onCancel: onCancel
        })) : null;
    };
    const $cdded0b8b2569776$export$204e9f8d79be983e = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            id: (0, (0, parcelRequire("JIPqS")).CREATE_DASHBOARD_EXPERIENCE),
            packageName: "dashboard",
            fallback: "flag",
            attributes: {
                task: (0, (0, parcelRequire("JIPqS")).CREATE_DASHBOARD_EXPERIENCE)
            },
            withExperienceTracker: true
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($cdded0b8b2569776$export$e85d11db57ff5836, props));
});
parcelRegister("jgE05", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$927aeb284ed7a228$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $lfTZh;
    var $kxZPA;
    const $927aeb284ed7a228$var$AsyncCreateDashboardModal = (0, (0, parcelRequire("kxZPA")).lazyForPaint)(()=>(parcelRequire("iDIjr")), {
        moduleId: "./src/packages/global-pages/directories/dashboards-directory-v2/create-dashboard-modal/src/index.tsx"
    });
    const $927aeb284ed7a228$var$AsyncCreateDashboardModalContainer = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "create-dashboard-modal",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($927aeb284ed7a228$var$AsyncCreateDashboardModal, props));
    var $927aeb284ed7a228$export$2e2bcd8739ae039 = $927aeb284ed7a228$var$AsyncCreateDashboardModalContainer;
});
parcelRegister("iDIjr", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("hPzOn"),
        $6fhBS("8bdSJ")
    ]).then(()=>parcelRequire('eTZZm'));
});
parcelRegister("8AXqQ", function(module, exports) {
    $parcel$export(module.exports, "DeleteModal", ()=>$f85fc38ca5cf0b6c$export$73392e09138fecd6);
    var $5uXOq = parcelRequire("5uXOq");
    var $8Rkzz = parcelRequire("8Rkzz");
    var $j55bS;
    var $hh0bW;
    var $JIPqS;
    var $39xOx;
    var $lfTZh;
    var $gh1hi;
    var $e9QkH;
    var $kxZPA;
    var $lVJAU;
    var $iLC4C;
    var $89bLT;
    const $f85fc38ca5cf0b6c$var$LazyDeleteModal = (0, (0, parcelRequire("kxZPA")).lazy)(()=>(parcelRequire("4tl1E")), {
        moduleId: "./src/packages/global-pages/directories/dashboards-directory-v2/delete-dashboard-modal/src/index.tsx"
    });
    const $f85fc38ca5cf0b6c$var$expAttributes = {
        analyticsSource: 'dashboard',
        experience: (0, (0, parcelRequire("JIPqS")).DELETE_DASHBOARD_EXPERIENCE),
        application: null,
        edition: null,
        additionalAttributes: {
            isCancel: false
        }
    };
    const $f85fc38ca5cf0b6c$var$sendSuccessExperience = ()=>(0, (0, parcelRequire("hh0bW")).default)({
            ...$f85fc38ca5cf0b6c$var$expAttributes,
            wasExperienceSuccesful: true
        });
    const $f85fc38ca5cf0b6c$var$sendFailedExperience = ()=>(0, (0, parcelRequire("hh0bW")).default)({
            ...$f85fc38ca5cf0b6c$var$expAttributes,
            wasExperienceSuccesful: false
        });
    const $f85fc38ca5cf0b6c$export$daf5ee5ed602db71 = ({ id: id, onModalCloseCallback: onModalCloseCallback })=>{
        const [isOpen, { off: close }] = (0, (0, parcelRequire("e9QkH")).useSwitchModals)((0, (0, parcelRequire("JIPqS")).MODAL_ID).DASHBOARD_DELETE);
        const { clear: clear } = (0, (0, parcelRequire("gh1hi")).useDashboardResource)();
        const { push: push } = (0, (0, parcelRequire("lVJAU")).useRouterActions)();
        const onSuccess = ()=>{
            (0, (0, parcelRequire("hh0bW")).default)({
                ...$f85fc38ca5cf0b6c$var$expAttributes,
                wasExperienceSuccesful: true
            });
            clear();
            close();
            push('/jira/dashboards');
        };
        const onInitiateFailed = (error)=>{
            (0, (0, parcelRequire("iLC4C")).handleFetchErrorAsExperience)({
                error: error || new Error('delete dashboard modal initialisation failed'),
                sendSuccessExperience: $f85fc38ca5cf0b6c$var$sendSuccessExperience,
                sendFailedExperience: $f85fc38ca5cf0b6c$var$sendFailedExperience,
                logErrorEvent: (0, (0, parcelRequire("89bLT")).logErrorEventFunc)(error, (0, (0, parcelRequire("JIPqS")).DELETE_DASHBOARD))
            });
            close();
            onModalCloseCallback?.();
        };
        const onFailed = (error)=>{
            (0, (0, parcelRequire("iLC4C")).handleFetchErrorAsExperience)({
                error: error,
                sendSuccessExperience: $f85fc38ca5cf0b6c$var$sendSuccessExperience,
                sendFailedExperience: $f85fc38ca5cf0b6c$var$sendFailedExperience,
                logErrorEvent: (0, (0, parcelRequire("89bLT")).logErrorEventFunc)(error, (0, (0, parcelRequire("JIPqS")).DELETE_DASHBOARD))
            });
            close();
            onModalCloseCallback?.();
        };
        const onCancel = ()=>{
            (0, (0, parcelRequire("hh0bW")).default)({
                ...$f85fc38ca5cf0b6c$var$expAttributes,
                wasExperienceSuccesful: true,
                additionalAttributes: {
                    isCancel: true
                }
            });
            close();
            onModalCloseCallback?.();
        };
        return isOpen ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "dashboards-modal",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("j55bS")).default), null)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($f85fc38ca5cf0b6c$var$LazyDeleteModal, {
            onInitiateSuccess: (0, ($parcel$interopDefault($8Rkzz))),
            onInitiateFailed: onInitiateFailed,
            onSuccess: onSuccess,
            onCancel: onCancel,
            onFailed: onFailed,
            id: id
        })) : null;
    };
    const $f85fc38ca5cf0b6c$export$73392e09138fecd6 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            id: (0, (0, parcelRequire("JIPqS")).DELETE_DASHBOARD_EXPERIENCE),
            packageName: "dashboard",
            fallback: "flag",
            attributes: {
                task: (0, (0, parcelRequire("JIPqS")).DELETE_DASHBOARD_EXPERIENCE)
            },
            withExperienceTracker: true
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($f85fc38ca5cf0b6c$export$daf5ee5ed602db71, props));
});
parcelRegister("4tl1E", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("hPzOn"),
        $6fhBS("2CgxW")
    ]).then(()=>parcelRequire('6ee1k'));
});
parcelRegister("2EGKG", function(module, exports) {
    $parcel$export(module.exports, "RenameOrShareModal", ()=>$952ac229700a831b$export$a113d771e12d2a12);
    var $5uXOq = parcelRequire("5uXOq");
    var $8Rkzz = parcelRequire("8Rkzz");
    var $j55bS;
    var $hh0bW;
    var $JIPqS;
    var $39xOx;
    var $lfTZh;
    var $e9QkH;
    var $kxZPA;
    var $3TUxF;
    var $iLC4C;
    var $89bLT;
    const $952ac229700a831b$var$LazyRenameOrShareModal = (0, (0, parcelRequire("kxZPA")).lazy)(()=>(parcelRequire("cPlgF")), {
        moduleId: "./src/packages/global-pages/directories/dashboards-directory-v2/edit-dashboard-modal/src/index.tsx"
    });
    const $952ac229700a831b$var$expAttributes = {
        analyticsSource: 'dashboard',
        experience: (0, (0, parcelRequire("JIPqS")).RENAME_OR_SHARE_DASHBOARD_EXPERIENCE),
        application: null,
        edition: null,
        additionalAttributes: {
            isCancel: false
        }
    };
    const $952ac229700a831b$var$sendSuccessExperience = ()=>(0, (0, parcelRequire("hh0bW")).default)({
            ...$952ac229700a831b$var$expAttributes,
            wasExperienceSuccesful: true
        });
    const $952ac229700a831b$var$sendFailedExperience = ()=>(0, (0, parcelRequire("hh0bW")).default)({
            ...$952ac229700a831b$var$expAttributes,
            wasExperienceSuccesful: false
        });
    const $952ac229700a831b$export$4f6c2201df28de83 = ({ id: id, onModalCloseCallback: onModalCloseCallback })=>{
        const [isOpen, { off: close }] = (0, (0, parcelRequire("e9QkH")).useSwitchModals)((0, (0, parcelRequire("JIPqS")).MODAL_ID).DASHBOARD_RENAME_OR_SHARE);
        const onEditDashboard = (0, (0, parcelRequire("3TUxF")).useEditDashboard)();
        const onSuccess = (_, response)=>{
            onEditDashboard(response);
            $952ac229700a831b$var$sendSuccessExperience();
            close();
            onModalCloseCallback?.();
        };
        const onInitiateFailed = (error)=>{
            (0, (0, parcelRequire("iLC4C")).handleFetchErrorAsExperience)({
                error: error || new Error('rename or share dashboard modal initialisation failed'),
                sendSuccessExperience: $952ac229700a831b$var$sendSuccessExperience,
                sendFailedExperience: $952ac229700a831b$var$sendFailedExperience,
                logErrorEvent: (0, (0, parcelRequire("89bLT")).logErrorEventFunc)(error, (0, (0, parcelRequire("JIPqS")).RENAME_OR_SHARE_DASHBOARD))
            });
            close();
            onModalCloseCallback?.();
        };
        const onFailed = (error)=>{
            (0, (0, parcelRequire("iLC4C")).handleFetchErrorAsExperience)({
                error: error,
                sendSuccessExperience: $952ac229700a831b$var$sendSuccessExperience,
                sendFailedExperience: $952ac229700a831b$var$sendFailedExperience,
                logErrorEvent: (0, (0, parcelRequire("89bLT")).logErrorEventFunc)(error, (0, (0, parcelRequire("JIPqS")).RENAME_OR_SHARE_DASHBOARD))
            });
            close();
            onModalCloseCallback?.();
        };
        const onCancel = ()=>{
            (0, (0, parcelRequire("hh0bW")).default)({
                ...$952ac229700a831b$var$expAttributes,
                wasExperienceSuccesful: true,
                additionalAttributes: {
                    isCancel: true
                }
            });
            close();
            onModalCloseCallback?.();
        };
        return isOpen ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "rename-or-share-modal",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("j55bS")).default), null)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($952ac229700a831b$var$LazyRenameOrShareModal, {
            onInitiateFailed: onInitiateFailed,
            onInitiateSuccess: (0, ($parcel$interopDefault($8Rkzz))),
            onSuccess: onSuccess,
            onCancel: onCancel,
            onFailed: onFailed,
            id: id
        })) : null;
    };
    const $952ac229700a831b$export$a113d771e12d2a12 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            id: (0, (0, parcelRequire("JIPqS")).RENAME_OR_SHARE_DASHBOARD_EXPERIENCE),
            packageName: "dashboard",
            fallback: "flag",
            attributes: {
                task: (0, (0, parcelRequire("JIPqS")).RENAME_OR_SHARE_DASHBOARD_EXPERIENCE)
            },
            withExperienceTracker: true
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($952ac229700a831b$export$4f6c2201df28de83, props));
});
parcelRegister("3TUxF", function(module, exports) {
    $parcel$export(module.exports, "useEditDashboard", ()=>$481cf9cc3e288e09$export$a7520122589f9056);
    var $5uXOq = parcelRequire("5uXOq");
    var $gh1hi;
    const $481cf9cc3e288e09$export$a7520122589f9056 = ()=>{
        const { update: update } = (0, (0, parcelRequire("gh1hi")).useDashboardResource)();
        return (0, $5uXOq.useCallback)((dashboardData)=>{
            if (!dashboardData) return;
            update((currentData)=>currentData && {
                    ...currentData,
                    title: dashboardData.name
                });
        }, [
            update
        ]);
    };
});
parcelRegister("cPlgF", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("hPzOn"),
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("XUjPB")
    ]).then(()=>parcelRequire('fZpfn'));
});
parcelRegister("x5bVw", function(module, exports) {
    $parcel$export(module.exports, "WallboardSlideshow", ()=>$e8bb3a64b8086b53$export$db3bf5ee6d309e34);
    var $5uXOq = parcelRequire("5uXOq");
    var $6B7KL;
    var $9UCCQ;
    var $JIPqS;
    var $jcw0u;
    var $koVbs;
    var $e9QkH;
    var $9OXo1;
    var $bOnpg;
    var $b8ziP;
    var $drb8j;
    const $e8bb3a64b8086b53$var$EMPTY_WALLBOARD_URL = '/';
    const $e8bb3a64b8086b53$export$11aea547bd3c125e = async ()=>{
        try {
            const response = await fetch('/rest/wallboard/latest/slideshow/url', {
                method: 'GET'
            });
            if (!response.ok) throw new Error(`Error server response: ${response.status}`);
            return response.text();
        } catch (error) {
            (0, (0, parcelRequire("jcw0u")).default)({
                error: error,
                meta: {
                    id: 'viewWallboardSlideshow',
                    packageName: 'jiraDashboardInternalCommon'
                },
                sendToPrivacyUnsafeSplunk: true
            });
            return $e8bb3a64b8086b53$var$EMPTY_WALLBOARD_URL;
        }
    };
    const $e8bb3a64b8086b53$export$db3bf5ee6d309e34 = ()=>{
        const { isAnonymous: isAnonymous } = (0, (0, parcelRequire("9OXo1")).useTenantContext)();
        const [wallboardSlideshowUrl, setWallboardSlideshowUrl] = (0, $5uXOq.useState)(undefined);
        const [, { on: modalWallboardOpen }] = (0, (0, parcelRequire("e9QkH")).useSwitchModals)((0, (0, parcelRequire("JIPqS")).MODAL_ID).CONFIGURE_WALLBOARD);
        const fireAnalytics = (0, (0, parcelRequire("bOnpg")).useDashboardAnalytics)();
        const configureWallboardSlideShowClickHandler = (0, $5uXOq.useCallback)(()=>{
            fireAnalytics({
                eventType: (0, $4d5e0871c06cee03$export$a1562d08625f5d7e),
                actionSubjectId: 'dashboard',
                actionSubject: 'wallboard-slideshow.configure',
                action: 'clicked'
            });
            setTimeout(()=>{
                modalWallboardOpen();
            }, 1);
        }, [
            fireAnalytics,
            modalWallboardOpen
        ]);
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const { dropdownItemClickHandler: dropdownItemClickHandler } = (0, (0, parcelRequire("b8ziP")).useDropdownItemOnClickHandler)();
        (0, $5uXOq.useEffect)(()=>{
            if (!isAnonymous && wallboardSlideshowUrl === undefined) $e8bb3a64b8086b53$export$11aea547bd3c125e().then((url)=>{
                setWallboardSlideshowUrl(url || $e8bb3a64b8086b53$var$EMPTY_WALLBOARD_URL);
            });
        }, [
            isAnonymous,
            wallboardSlideshowUrl
        ]);
        const isViewWallboardSlideshowEnabled = wallboardSlideshowUrl !== $e8bb3a64b8086b53$var$EMPTY_WALLBOARD_URL && !isAnonymous;
        const wallboardSlideshowUrlWithFallback = wallboardSlideshowUrl || $e8bb3a64b8086b53$var$EMPTY_WALLBOARD_URL;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9UCCQ")).default), {
            hasSeparator: true
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6B7KL")).default), {
            "data-testid": "dashboard-internal-common.ui.dashboard-content.header.action-dropdown.wallboard-slideshow.wallboard-slideshow",
            isDisabled: !isViewWallboardSlideshowEnabled,
            href: wallboardSlideshowUrlWithFallback,
            onClick: (e)=>dropdownItemClickHandler(e, wallboardSlideshowUrlWithFallback, 'dashboard.view-wallboard-slideshow-item'),
            UNSAFE_shouldDisableRouterLink: true
        }, formatMessage((0, (0, parcelRequire("drb8j")).default).wallboardSlideshow)), !isAnonymous && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6B7KL")).default), {
            "data-testid": "dashboard-internal-common.ui.dashboard-content.header.action-dropdown.wallboard-slideshow.configure-wallboard",
            onClick: configureWallboardSlideShowClickHandler
        }, formatMessage((0, (0, parcelRequire("drb8j")).default).wallboardSetup)));
    };
});
parcelRegister("drb8j", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$3bd6bbd173094bd4$export$2e2bcd8739ae039);
    var $7VHMR;
    var $3bd6bbd173094bd4$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        wallboardSlideshow: {
            "id": "dashboard-internal-common.dashboard-content.header.action-dropdown.wallboard-slideshow.wallboard-slideshow",
            "defaultMessage": "View wallboard slide show"
        },
        wallboardSetup: {
            "id": "dashboard-internal-common.dashboard-content.header.action-dropdown.wallboard-slideshow.wallboard-setup",
            "defaultMessage": "Configure wallboard slide show"
        }
    });
});
parcelRegister("dZymr", function(module, exports) {
    $parcel$export(module.exports, "ConnectHeader", ()=>$fa1518cc236818d0$export$a7c3d2bbc29ad451);
    var $5uXOq = parcelRequire("5uXOq");
    var $fVnbM;
    var $BLPHT;
    var $6B7KL;
    var $5ILhZ;
    var $hrlU6;
    var $edp7U;
    var $dUyq4;
    const $fa1518cc236818d0$export$965e85c1e0261738 = ({ item: item })=>{
        const webItemRef = (0, $5uXOq.useRef)(null);
        const onWebItemClick = ()=>{
            (0, (0, parcelRequire("hrlU6")).withConnectHost)((connectHost)=>{
                if (webItemRef.current) connectHost.triggerWebItem(webItemRef.current, item.styleClass, item.url);
            });
        };
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
            id: item.id,
            appearance: "subtle",
            onClick: onWebItemClick,
            ref: webItemRef
        }, item.title);
    };
    const $fa1518cc236818d0$var$DropdownPressableItem = ({ item: item })=>{
        const webItemRef = (0, $5uXOq.useRef)(null);
        const onWebItemClick = ()=>{
            (0, (0, parcelRequire("hrlU6")).withConnectHost)((connectHost)=>{
                if (webItemRef.current) connectHost.triggerWebItem(webItemRef.current, item.styleClass, item.url);
            });
        };
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5ILhZ")).default), {
            id: item.id,
            onClick: onWebItemClick,
            ref: (ref)=>{
                if (ref) webItemRef.current = ref;
            },
            backgroundColor: "color.background.neutral.subtle"
        }, item.title);
    };
    const $fa1518cc236818d0$export$275428e5ee6cc209 = ({ items: items })=>{
        if (items.length === 0) return null;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("BLPHT")).default), {
            isLoading: false,
            trigger: ({ triggerRef: triggerRef, ...triggerProps })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
                    appearance: "subtle",
                    spacing: "default",
                    ...triggerProps,
                    ref: triggerRef
                }, "+", items.length),
            shouldFlip: true,
            placement: "bottom-end",
            testId: "dashboard-internal-common.ui.dashboard-content.header.connect-header.dropdown-menu",
            defaultOpen: (0, (0, parcelRequire("edp7U")).getIsDropdownDefaultOpen)('connect-header')
        }, items.map((item)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6B7KL")).default), {
                key: item.id
            }, (0, ($parcel$interopDefault($5uXOq))).createElement($fa1518cc236818d0$var$DropdownPressableItem, {
                item: item
            }))));
    };
    const $fa1518cc236818d0$export$a7c3d2bbc29ad451 = ({ items: items })=>{
        if (!items) return null;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dUyq4")).ConnectHeaderWrapper), null, items.slice(0, 4).map((item)=>(0, ($parcel$interopDefault($5uXOq))).createElement($fa1518cc236818d0$export$965e85c1e0261738, {
                item: item,
                key: item.id
            })), (0, ($parcel$interopDefault($5uXOq))).createElement($fa1518cc236818d0$export$275428e5ee6cc209, {
            items: items.slice(4)
        }));
    };
});
parcelRegister("dUyq4", function(module, exports) {
    $parcel$export(module.exports, "ConnectHeaderWrapper", ()=>$a7fb468cf68df072$export$70cdd2ea5140c2c);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    const $a7fb468cf68df072$export$70cdd2ea5140c2c = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _1bahesu3 _13kxotz2",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("1i27D", function(module, exports) {
    $parcel$export(module.exports, "HeaderTitle", ()=>$f69e2b51e1f910f6$export$edb76fdb8665f52);
    $parcel$export(module.exports, "HeaderActions", ()=>$f69e2b51e1f910f6$export$223875819fd7cf93);
    $parcel$export(module.exports, "Header", ()=>$f69e2b51e1f910f6$export$8b251419efc915eb);
    var $5uXOq = parcelRequire("5uXOq");
    var $bJcWY;
    var $aZdfU;
    var $cS37j;
    var $kSvVK;
    const $f69e2b51e1f910f6$export$edb76fdb8665f52 = ({ children: children })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("aZdfU")).default), {
            medium: 4,
            testId: "dashboard-internal-common.ui.dashboard-content.header.header-title"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cS37j")).default), null, children));
    const $f69e2b51e1f910f6$export$223875819fd7cf93 = ({ children: children })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("aZdfU")).default), {
            medium: 8
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("kSvVK")).ActionWrapper), null, children)));
    const $f69e2b51e1f910f6$export$8b251419efc915eb = ({ banners: banners, children: children })=>(0, ($parcel$interopDefault($5uXOq))).createElement("header", {
            "data-testid": "dashboard-internal-common.ui.dashboard-content.header.dashboard-header"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("bJcWY")).default), {
            layout: "fluid",
            spacing: "compact"
        }, children), banners && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("kSvVK")).BannersWrapper), null, banners));
});
parcelRegister("cS37j", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$2a15efb09f101ecb$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $57MwZ;
    const $2a15efb09f101ecb$var$PageHeader = ({ actions: actions, ...props })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("57MwZ")).default), {
            ...props,
            actions: actions ? (0, ($parcel$interopDefault($5uXOq))).createElement($2a15efb09f101ecb$var$ActionsWrapper, null, actions) : null
        });
    var $2a15efb09f101ecb$export$2e2bcd8739ae039 = $2a15efb09f101ecb$var$PageHeader;
    const $2a15efb09f101ecb$var$ActionsWrapper = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_y3gn1e5h",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("57MwZ", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$9694e728fd1ea060$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $eeGIy;
    const $9694e728fd1ea060$var$PageHeader = ({ innerRef: innerRef, breadcrumbs: breadcrumbs, actions: actions, bottomBar: bottomBar, children: children, id: id, disableTitleStyles: disableTitleStyles = false, truncateTitle: truncateTitle = false })=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("eeGIy")).OuterWrapper), null, breadcrumbs, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("eeGIy")).TitleWrapper), {
            truncateTitle: truncateTitle
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("eeGIy")).TitleContainer), {
            truncateTitle: truncateTitle
        }, disableTitleStyles ? children : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("eeGIy")).StyledTitleWrapper), {
            ref: innerRef,
            truncateTitle: truncateTitle,
            id: id
        }, children)), actions && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("eeGIy")).ActionsWrapper), null, actions)), bottomBar && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("eeGIy")).BottomBarWrapper), null, " ", bottomBar, " "));
    };
    var $9694e728fd1ea060$export$2e2bcd8739ae039 = $9694e728fd1ea060$var$PageHeader;
});
parcelRegister("eeGIy", function(module, exports) {
    $parcel$export(module.exports, "OuterWrapper", ()=>$0c196d37a4e8266c$export$edaaad985a878983);
    $parcel$export(module.exports, "StyledTitleWrapper", ()=>$0c196d37a4e8266c$export$284be1ee64a3465c);
    $parcel$export(module.exports, "TitleWrapper", ()=>$0c196d37a4e8266c$export$585a3b23e887288b);
    $parcel$export(module.exports, "TitleContainer", ()=>$0c196d37a4e8266c$export$35e09ba17cc51c69);
    $parcel$export(module.exports, "ActionsWrapper", ()=>$0c196d37a4e8266c$export$c8223871eecc8f3a);
    $parcel$export(module.exports, "BottomBarWrapper", ()=>$0c196d37a4e8266c$export$6330225854c30dc);
    var $5uXOq = parcelRequire("5uXOq");
    var $f8Sb3;
    var $dC5iT;
    var $8CUq3;
    function $0c196d37a4e8266c$var$_EMOTION_STRINGIFIED_CSS_ERROR__() {
        return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop).";
    }
    const $0c196d37a4e8266c$var$truncateStyles = {
        name: "13oenf9",
        styles: "overflow-x:hidden;overflow-y:hidden;text-overflow:ellipsis;white-space:nowrap"
    };
    const $0c196d37a4e8266c$var$outerStyles = (0, (0, parcelRequire("f8Sb3")).css)({
        margin: `${"var(--ds-space-300, 24px)"} 0 ${"var(--ds-space-200, 16px)"} 0`
    });
    const $0c196d37a4e8266c$var$titleStyles = {
        name: "1o56te0",
        styles: "color:var(--ds-text, #172B4D);font:var(--ds-font-heading-large, normal 500 24px/28px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif);letter-spacing:normal;margin-block-start:0;outline:none"
    };
    const $0c196d37a4e8266c$var$titleWrapperStyles = {
        name: "1wb76vg",
        styles: "display:flex;align-items:flex-start;flex-wrap:wrap"
    };
    const $0c196d37a4e8266c$var$titleWrapperTruncateStyles = {
        name: "14gl6v0",
        styles: "flex-wrap:nowrap"
    };
    const $0c196d37a4e8266c$var$titleContainerStyles = (0, (0, parcelRequire("f8Sb3")).css)({
        minWidth: 0,
        maxWidth: '100%',
        flex: '1 0 auto',
        flexShrink: undefined,
        marginBlockEnd: "var(--ds-space-100, 8px)"
    });
    const $0c196d37a4e8266c$var$actionStyles = {
        name: "1ianfu6",
        styles: "max-width:100%;flex:0 0 auto;margin-block-end:var(--ds-space-100, 8px);margin-inline-start:auto;padding-inline-start:var(--ds-space-400, 32px);white-space:nowrap;>{text-align:right;}"
    };
    const $0c196d37a4e8266c$var$titleContainerTruncateStyles = {
        name: "s2a4s9",
        styles: "flex-shrink:1"
    };
    const $0c196d37a4e8266c$var$bottomBarStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        marginBlockStart: 'space.200'
    });
    const $0c196d37a4e8266c$export$edaaad985a878983 = ({ children: children })=>{
        return (0, (0, parcelRequire("f8Sb3")).jsx)("div", {
            css: $0c196d37a4e8266c$var$outerStyles
        }, children);
    };
    const $0c196d37a4e8266c$export$284be1ee64a3465c = (0, ($parcel$interopDefault($5uXOq))).forwardRef(({ children: children, id: id, truncateTitle: truncateTitle }, ref)=>{
        return (0, (0, parcelRequire("f8Sb3")).jsx)("h1", {
            css: [
                $0c196d37a4e8266c$var$titleStyles,
                truncateTitle && $0c196d37a4e8266c$var$truncateStyles
            ],
            ref: ref,
            tabIndex: -1,
            id: id
        }, children);
    });
    const $0c196d37a4e8266c$export$585a3b23e887288b = ({ children: children, truncateTitle: truncateTitle })=>{
        return (0, (0, parcelRequire("f8Sb3")).jsx)("div", {
            css: [
                $0c196d37a4e8266c$var$titleWrapperStyles,
                truncateTitle && $0c196d37a4e8266c$var$titleWrapperTruncateStyles
            ]
        }, children);
    };
    const $0c196d37a4e8266c$export$35e09ba17cc51c69 = ({ children: children, truncateTitle: truncateTitle })=>{
        return (0, (0, parcelRequire("f8Sb3")).jsx)("div", {
            css: [
                $0c196d37a4e8266c$var$titleContainerStyles,
                truncateTitle && $0c196d37a4e8266c$var$titleContainerTruncateStyles
            ]
        }, children);
    };
    const $0c196d37a4e8266c$export$c8223871eecc8f3a = ({ children: children })=>{
        return (0, (0, parcelRequire("f8Sb3")).jsx)("div", {
            css: $0c196d37a4e8266c$var$actionStyles
        }, children);
    };
    const $0c196d37a4e8266c$export$6330225854c30dc = ({ children: children })=>{
        return (0, (0, parcelRequire("f8Sb3")).jsx)((0, (0, parcelRequire("dC5iT")).default), {
            xcss: $0c196d37a4e8266c$var$bottomBarStyles
        }, children);
    };
});
parcelRegister("kSvVK", function(module, exports) {
    $parcel$export(module.exports, "ActionWrapper", ()=>$3989dffa8d1c8135$export$3122aab815c89f9a);
    $parcel$export(module.exports, "BannersWrapper", ()=>$3989dffa8d1c8135$export$ab656bce1543f51a);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    const $3989dffa8d1c8135$export$3122aab815c89f9a = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _1bahesu3 _18s81d9c _zulpu2gc _xlknidpf _dgz5idpf _18sqidpf _wrjxidpf _18vqd8e8",
                __cmplp.className
            ])
        });
    });
    const $3989dffa8d1c8135$export$ab656bce1543f51a = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _2lx21bp4 _zulpu2gc _zum8idpf _10361ejb",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("2PcTT", function(module, exports) {
    $parcel$export(module.exports, "useConnectMenuItems", ()=>$fdeff8836b607085$export$58f1b171f06c90ef);
    var $5uXOq = parcelRequire("5uXOq");
    var $jcw0u;
    var $7f4tk;
    const $fdeff8836b607085$export$58f1b171f06c90ef = (dashboardId)=>{
        const [connectMenuItems, setConnectMenuItems] = (0, $5uXOq.useState)({
            headerItems: [],
            toolItems: []
        });
        (0, $5uXOq.useEffect)(()=>{
            if (dashboardId == null) return;
            (async ()=>{
                const resourceUrl = `/rest/dashboards/1.0/connect/menuItems/${dashboardId}`;
                try {
                    const response = await (0, (0, parcelRequire("7f4tk")).default)(resourceUrl);
                    setConnectMenuItems(response);
                } catch (error) {
                    (0, (0, parcelRequire("jcw0u")).default)({
                        error: error,
                        meta: {
                            id: 'fetchConnectMenuItems',
                            packageName: 'jiraDashboardInternalCommon',
                            teamName: 'endeavour'
                        }
                    });
                }
            })();
        }, [
            dashboardId
        ]);
        return connectMenuItems;
    };
});
parcelRegister("lWWbJ", function(module, exports) {
    $parcel$export(module.exports, "DashboardRefreshButton", ()=>$451889f81da240b7$export$2d7dab182c84b535);
    var $5uXOq = parcelRequire("5uXOq");
    var $2rqOp;
    var $75Jbm;
    var $JIPqS;
    var $koVbs;
    var $bOnpg;
    var $iyrll;
    const $451889f81da240b7$export$2d7dab182c84b535 = ()=>{
        const onRefresh = (0, (0, parcelRequire("iyrll")).useDashboardRefresh)();
        const [isLoadingBufferVisible, setIsLoadingBufferVisible] = (0, $5uXOq.useState)(false);
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const fireAnalytics = (0, (0, parcelRequire("bOnpg")).useDashboardAnalytics)();
        const label = formatMessage({
            id: 'spa-apps.dashboard.gadget.refresh',
            defaultMessage: 'Refresh',
            description: 'Refresh all gadgets'
        });
        const showLoadingSpinner = ()=>{
            setIsLoadingBufferVisible(true);
            setTimeout(()=>{
                setIsLoadingBufferVisible(false);
            }, (0, (0, parcelRequire("JIPqS")).DASHBOARD_REFRESH_LOAD_BUFFER_MS));
        };
        const onClickHandlerRefresh = (0, $5uXOq.useCallback)(()=>{
            onRefresh();
            showLoadingSpinner();
            fireAnalytics({
                eventType: (0, $4d5e0871c06cee03$export$a1562d08625f5d7e),
                action: 'clicked',
                actionSubject: 'dashboard.refresh-button',
                actionSubjectId: (0, (0, parcelRequire("JIPqS")).DASHBOARD_VIEW_SOURCENAME)
            });
        }, [
            fireAnalytics,
            onRefresh
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("2rqOp")).default), {
            spacing: "default",
            onClick: onClickHandlerRefresh,
            iconAfter: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("75Jbm"))))), {
                label: "",
                size: "small"
            }),
            testId: "dashboard-internal-common.ui.dashboard-refresh-button.dashboard-refresh-button",
            isLoading: isLoadingBufferVisible
        }, label);
    };
});
parcelRegister("9jsHG", function(module, exports) {
    $parcel$export(module.exports, "FavoriteButton", ()=>$74ae53576ce577d5$export$8d5dab2ad1e19ea0);
    var $5uXOq = parcelRequire("5uXOq");
    var $hcObS;
    var $hLZZY;
    var $eXQym;
    const $74ae53576ce577d5$export$8d5dab2ad1e19ea0 = ({ dashboardId: dashboardId, dashboardName: dashboardName })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hcObS")).FavoriteButtonWrapper), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hLZZY")).default), {
            baseUrl: "",
            itemId: dashboardId,
            itemType: (0, (0, parcelRequire("eXQym")).DASHBOARDS_ITEM_TYPE),
            favoriteItemName: dashboardName
        }));
});
parcelRegister("hcObS", function(module, exports) {
    $parcel$export(module.exports, "FavoriteButtonWrapper", ()=>$0a68425ce3fc2fa8$export$bb1478fee6cd5f0c);
    $parcel$export(module.exports, "default", ()=>$0a68425ce3fc2fa8$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $basmW = parcelRequire("basmW");
    var $8Rkzz = parcelRequire("8Rkzz");
    var $fVnbM;
    var $dm1qi;
    var $dAf5R;
    var $5oTeF;
    var $bH1hf;
    var $7FtaJ;
    var $1Cvrw;
    var $fNobW;
    var $3bDjY;
    var $iQv12;
    var $7XYc4;
    var $az4zD;
    var $dCm1o;
    var $6fQ6A;
    const $0a68425ce3fc2fa8$var$SKELETON_TEST_ID = 'favouriting.favorite-button-stateless.skeleton';
    const $0a68425ce3fc2fa8$var$ICON_WRAPPER_TEST_ID_PREFIX = 'favouriting.favorite-button-stateless.icon-wrapper';
    class $0a68425ce3fc2fa8$export$f8ad2acf0ec4860f extends (0, $5uXOq.Component) {
        static{
            this.defaultProps = {
                isFavorite: undefined,
                favoriteItemName: undefined,
                isShownInList: false,
                isSmall: false,
                hideTooltip: false,
                onClick: (0, ($parcel$interopDefault($8Rkzz))),
                tooltipPosition: 'bottom',
                skipAnimation: false
            };
        }
        componentDidUpdate(prevProps) {
            if (!this.props.skipAnimation && (!prevProps.pending && this.props.pending || !prevProps.isFavorite && this.props.isFavorite)) {
                this.onAnimationStart();
                this.onSettingAnimationEnd();
            }
        }
        onAnimationStart() {
            this.setState({
                isAnimating: !this.state.isAnimating
            });
        }
        onSettingAnimationEnd() {
            setTimeout(()=>{
                this.setState({
                    isAnimating: false
                });
            }, $0a68425ce3fc2fa8$var$animationTime * 2);
        }
        render() {
            const { isFavorite: isFavorite, favoriteItemName: favoriteItemName, isShownInList: isShownInList, isSmall: isSmall, hideTooltip: hideTooltip, tooltipPosition: tooltipPosition, pending: pending, intl: { formatMessage: formatMessage } } = this.props;
            const { isAnimating: isAnimating } = this.state;
            if (isFavorite === undefined) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fNobW")).default), {
                name: "favourite-button"
            }, (0, ($parcel$interopDefault($5uXOq))).createElement($0a68425ce3fc2fa8$export$db65185b5889c83, {
                "data-testid": $0a68425ce3fc2fa8$var$SKELETON_TEST_ID,
                isSmall: isSmall
            }));
            const iconWrapperTestId = isShownInList ? `${$0a68425ce3fc2fa8$var$ICON_WRAPPER_TEST_ID_PREFIX}-list` : $0a68425ce3fc2fa8$var$ICON_WRAPPER_TEST_ID_PREFIX;
            const buttonContentOld = (0, ($parcel$interopDefault($5uXOq))).createElement($0a68425ce3fc2fa8$var$FavIconWrapper, {
                isFavorite: isFavorite,
                isAnimating: isAnimating,
                "data-testid": iconWrapperTestId,
                isSmall: isSmall
            }, isFavorite ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("dm1qi"))))), {
                LEGACY_fallbackIcon: (0, ($parcel$interopDefault((0, parcelRequire("bH1hf"))))),
                LEGACY_size: isSmall ? 'small' : undefined,
                color: "currentColor",
                label: formatMessage((0, (0, parcelRequire("6fQ6A")).default).removeFromStarred)
            }) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("dAf5R"))))), {
                LEGACY_fallbackIcon: (0, ($parcel$interopDefault((0, parcelRequire("5oTeF"))))),
                LEGACY_size: isSmall ? 'small' : undefined,
                color: "currentColor",
                label: formatMessage((0, (0, parcelRequire("6fQ6A")).default).addToStarred)
            }));
            const renderIcon = ()=>{
                const label = isFavorite ? formatMessage((0, (0, parcelRequire("6fQ6A")).default).removeFromStarred) : formatMessage((0, (0, parcelRequire("6fQ6A")).default).addToStarred);
                let StarIconComponent;
                if (isSmall) StarIconComponent = isFavorite ? (0, ($parcel$interopDefault((0, parcelRequire("7FtaJ"))))) : (0, ($parcel$interopDefault((0, parcelRequire("1Cvrw")))));
                else StarIconComponent = isFavorite ? (0, ($parcel$interopDefault((0, parcelRequire("dm1qi"))))) : (0, ($parcel$interopDefault((0, parcelRequire("dAf5R")))));
                return (0, ($parcel$interopDefault($5uXOq))).createElement(StarIconComponent, {
                    color: "currentColor",
                    label: label
                });
            };
            const buttonContent = (0, ($parcel$interopDefault($5uXOq))).createElement($0a68425ce3fc2fa8$var$FavIconWrapper, {
                isFavorite: isFavorite,
                isAnimating: isAnimating,
                "data-testid": iconWrapperTestId,
                isSmall: isSmall
            }, renderIcon());
            const tooltipContent = isFavorite ? formatMessage((0, (0, parcelRequire("6fQ6A")).default).removeFromStarred) : formatMessage((0, (0, parcelRequire("6fQ6A")).default).addToStarred);
            const ariaLabel = favoriteItemName ? formatMessage((0, (0, parcelRequire("6fQ6A")).default).toggleStarredWithItemName, {
                itemName: favoriteItemName
            }) : formatMessage((0, (0, parcelRequire("6fQ6A")).default).toggleStarred);
            return (0, ($parcel$interopDefault($5uXOq))).createElement($0a68425ce3fc2fa8$export$bb1478fee6cd5f0c, {
                role: "presentation",
                onClick: this.onBtnClickWrapper,
                onKeyPress: this.onBtnClickWrapper,
                "data-is-favorite": isFavorite
            }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3bDjY")).default), {
                content: !hideTooltip && tooltipContent,
                hideTooltipOnClick: true,
                position: tooltipPosition
            }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
                appearance: isShownInList ? 'subtle-link' : 'subtle',
                spacing: "none",
                "aria-label": ariaLabel,
                "aria-pressed": isFavorite,
                onClick: !pending ? this.onClick : (0, ($parcel$interopDefault($8Rkzz)))
            }, (0, (0, parcelRequire("dCm1o")).isVisualRefreshEnabled)() ? buttonContent : buttonContentOld)));
        }
        constructor(...args){
            super(...args);
            this.state = {
                isAnimating: false
            };
            this.onBtnClickWrapper = (e)=>{
                e.stopPropagation();
                e.preventDefault();
            };
            this.onClick = (_, analyticsEvent)=>{
                this.props.onClick(analyticsEvent);
            };
        }
    }
    var $0a68425ce3fc2fa8$export$2e2bcd8739ae039 = (0, ($parcel$interopDefault($basmW)))((0, (0, parcelRequire("iQv12")).default)({
        onClick: ()=>({
                name: 'star'
            })
    }), (0, (0, parcelRequire("az4zD")).injectIntlV2))($0a68425ce3fc2fa8$export$f8ad2acf0ec4860f);
    const $0a68425ce3fc2fa8$var$animationTime = 500;
    const $0a68425ce3fc2fa8$export$bb1478fee6cd5f0c = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1o8l",
                __cmplp.className
            ])
        });
    });
    const $0a68425ce3fc2fa8$var$FavoriteIconWrapperBaseOld = (0, $5uXOq.forwardRef)(({ as: C = "span", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { isSmall: isSmall, isAnimating: isAnimating, ...__cmpldp } = __cmplp;
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmpldp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _4cvr1h6o _1bah1h6o",
                __cmplp.isSmall ? "_1bsb1tcg" : "_1bsbzwfg",
                __cmplp.isSmall ? "_4t3i1tcg" : "_4t3izwfg",
                __cmplp.isAnimating && "_11vn1ssb _1px1fmmq _48hmq7pw _1uvf1e03",
                __cmplp.className
            ])
        });
    });
    const $0a68425ce3fc2fa8$var$FavoriteIconWrapperBaseNew = (0, $5uXOq.forwardRef)(({ as: C = "span", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { isSmall: isSmall, isAnimating: isAnimating, ...__cmpldp } = __cmplp;
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmpldp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw _4cvr1h6o _1bah1h6o",
                __cmplp.isSmall ? "_1bsb1tcg" : "_1bsbzwfg",
                __cmplp.isSmall ? "_4t3i1tcg" : "_4t3izwfg",
                __cmplp.isAnimating && "_1px1fmmq _48hmq7pw _1uvf1e03",
                __cmplp.className
            ])
        });
    });
    const $0a68425ce3fc2fa8$var$FavoriteIconWrapperBase = (0, (0, parcelRequire("7XYc4")).componentWithCondition)((0, (0, parcelRequire("dCm1o")).isVisualRefreshEnabled), $0a68425ce3fc2fa8$var$FavoriteIconWrapperBaseNew, $0a68425ce3fc2fa8$var$FavoriteIconWrapperBaseOld);
    const $0a68425ce3fc2fa8$var$FavIconWrapper = (0, $5uXOq.forwardRef)(({ as: C = $0a68425ce3fc2fa8$var$FavoriteIconWrapperBase, style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "",
                __cmplp.isFavorite ? "_syaz2kyj" : "_syaz2u25",
                __cmplp.isFavorite ? "_30l31ebw" : "_30l3w9a8",
                __cmplp.className
            ])
        });
    });
    const $0a68425ce3fc2fa8$export$db65185b5889c83 = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { isSmall: isSmall, ...__cmpldp } = __cmplp;
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmpldp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1e0c1txw",
                __cmplp.isSmall ? "_1bsb1tcg" : "_1bsbzwfg",
                __cmplp.isSmall ? "_4t3i1tcg" : "_4t3izwfg",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("iQv12", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$945500a9598c3ac2$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $gvRPW;
    const $945500a9598c3ac2$var$fireWithUIAnalytics = (ConnectedProps)=>{
        const providedKeys = Object.keys(ConnectedProps).sort();
        const cachedMappedProps = {};
        const cachedConnectedProps = {};
        const getStateFromMappedProps = (mapped)=>{
            providedKeys.forEach((key, index)=>{
                const prop = mapped[index];
                if (key in cachedMappedProps && cachedMappedProps[key] === prop) return;
                cachedMappedProps[key] = prop;
                cachedConnectedProps[key] = (...attrs)=>{
                    const analyticsEvent = attrs[attrs.length - 1].clone();
                    const connectedProp = ConnectedProps[key];
                    if (typeof ConnectedProps[key] === 'string') analyticsEvent.update({
                        name: connectedProp,
                        analyticsType: (0, (0, parcelRequire("gvRPW")).UI_EVENT_TYPE)
                    }).fire();
                    else if (typeof connectedProp === 'function') {
                        const update = connectedProp(...attrs);
                        analyticsEvent.update({
                            ...update,
                            analyticsType: (0, (0, parcelRequire("gvRPW")).UI_EVENT_TYPE)
                        }).fire();
                    }
                    if (typeof prop === 'function') prop(...attrs);
                };
            });
            return cachedConnectedProps;
        };
        return (WrappedComponent)=>class WithAnalytics extends (0, $5uXOq.Component) {
                static{
                    this.displayName = `WithAnalytics(${WrappedComponent.displayName || WrappedComponent.name || 'UnnamedComponent'}`;
                }
                render() {
                    const newMappedProps = getStateFromMappedProps(providedKeys.map((key)=>this.props[key]));
                    return (0, ($parcel$interopDefault($5uXOq))).createElement(WrappedComponent, {
                        ...this.props,
                        ...newMappedProps
                    });
                }
            };
    };
    var $945500a9598c3ac2$export$2e2bcd8739ae039 = $945500a9598c3ac2$var$fireWithUIAnalytics;
});
parcelRegister("6fQ6A", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$10bc2ec5d9a72db1$export$2e2bcd8739ae039);
    var $7VHMR;
    var $10bc2ec5d9a72db1$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        addToStarred: {
            "id": "platform.ui.favouriting.favourite-button-stateless.add-to-starred",
            "defaultMessage": "Add to Starred"
        },
        removeFromStarred: {
            "id": "platform.ui.favouriting.favourite-button-stateless.remove-from-starred",
            "defaultMessage": "Remove from Starred"
        },
        toggleStarred: {
            "id": "platform.ui.favouriting.favourite-button-stateless.toggle-starred",
            "defaultMessage": "Star"
        },
        toggleStarredWithItemName: {
            "id": "platform.ui.favouriting.favourite-button-stateless.toggle-starred-with-item-name",
            "defaultMessage": "Star {itemName}"
        }
    });
});
parcelRegister("hLZZY", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$a968ac170a325ff4$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $4041n;
    var $fH2dG;
    class $a968ac170a325ff4$export$2e2bcd8739ae039 extends (0, $5uXOq.Component) {
        render() {
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4041n")).FavoriteChangeConsumer), null, (favoriteChangeContext)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fH2dG")).FavoriteButton), {
                    ...this.props,
                    favoriteChangeContext: favoriteChangeContext
                }));
        }
    }
});
parcelRegister("fH2dG", function(module, exports) {
    $parcel$export(module.exports, "FavoriteButton", ()=>$e0f2aac973023e83$export$8d5dab2ad1e19ea0);
    var $5uXOq = parcelRequire("5uXOq");
    var $8Rkzz = parcelRequire("8Rkzz");
    var $hcObS;
    var $eXQym;
    var $1WPu2;
    const $e0f2aac973023e83$var$isProduction = window.BUILD_KEY !== 'JF-TEST';
    class $e0f2aac973023e83$export$8d5dab2ad1e19ea0 extends (0, $5uXOq.Component) {
        static{
            this.defaultProps = {
                isFavoriteInitial: undefined,
                favoriteItemName: undefined,
                isSmall: false,
                hideTooltip: false,
                onClick: (0, ($parcel$interopDefault($8Rkzz)))
            };
        }
        constructor(props){
            super(props);
            this.onClick = (analyticsEvent)=>{
                const { favoriteChangeContext: favoriteChangeContext, itemId: itemId, itemType: itemType, onClick: onClick } = this.props;
                const { changeFavorite: changeFavorite } = favoriteChangeContext;
                if (changeFavorite) changeFavorite({
                    id: itemId,
                    type: itemType,
                    value: !this.getIsFavorite()
                }, analyticsEvent);
                if (typeof onClick === 'function') onClick();
            };
            this.state = {
                isFavorite: props.isFavoriteInitial
            };
        }
        componentDidMount() {
            const { baseUrl: baseUrl, itemId: itemId, itemType: itemType } = this.props;
            if (typeof baseUrl === 'undefined' || typeof itemId === 'undefined' || typeof itemType === 'undefined') return;
            if (!$e0f2aac973023e83$var$isProduction && !(0, (0, parcelRequire("eXQym")).VALID_ITEM_TYPES).includes(itemType)) throw new Error(`FavoriteButton: Invalid value for prop 'type'.\n You passed: "${itemType}"\n It must be one of [${JSON.stringify((0, (0, parcelRequire("eXQym")).VALID_ITEM_TYPES))}]\n\n`);
            if (typeof this.props.isFavoriteInitial !== 'undefined') this.props.favoriteChangeContext.items[itemType][itemId] = {
                id: itemId,
                type: itemType,
                value: this.props.isFavoriteInitial,
                pending: false
            };
            if (this.getIsFavorite() === undefined) (0, (0, parcelRequire("1WPu2")).getFavorite)(baseUrl, {
                id: itemId,
                type: itemType
            }).then((isFavorite)=>{
                this.setState({
                    isFavorite: isFavorite
                });
            });
        }
        getIsFavorite() {
            const { favoriteChangeContext: favoriteChangeContext, itemId: itemId, itemType: itemType } = this.props;
            let itemChanged;
            if (typeof favoriteChangeContext.items[itemType] !== 'undefined' && typeof favoriteChangeContext.items[itemType][itemId] !== 'undefined') itemChanged = favoriteChangeContext.items[itemType][itemId];
            return itemChanged ? itemChanged.value : this.state.isFavorite;
        }
        getIsPending() {
            const { favoriteChangeContext: favoriteChangeContext, itemId: itemId, itemType: itemType } = this.props;
            let itemChanged;
            if (typeof favoriteChangeContext.items[itemType] !== 'undefined' && typeof favoriteChangeContext.items[itemType][itemId] !== 'undefined') itemChanged = favoriteChangeContext.items[itemType][itemId];
            return itemChanged ? itemChanged.pending : false;
        }
        render() {
            return (0, ($parcel$interopDefault($5uXOq))).createElement("div", {
                "data-testid": "favourite-button.favorite-button"
            }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hcObS")).default), {
                isFavorite: this.getIsFavorite(),
                favoriteItemName: this.props.favoriteItemName,
                pending: this.getIsPending(),
                hideTooltip: this.props.hideTooltip,
                tooltipPosition: this.props.tooltipPosition,
                onClick: this.onClick,
                isShownInList: this.props.isShownInList,
                isSmall: this.props.isSmall
            }));
        }
    }
});
parcelRegister("1WPu2", function(module, exports) {
    $parcel$export(module.exports, "getFavorite", ()=>$1961b1823763022d$export$fce5eda070ce88d0);
    var $eusud;
    const $1961b1823763022d$export$959ca2ee969adb8d = (baseUrl, request)=>`${baseUrl}/rest/internal/2/favourites/${request.type}/${request.id}`;
    const $1961b1823763022d$export$fce5eda070ce88d0 = async (baseUrl, request)=>(0, (0, parcelRequire("eusud")).performGetRequest)($1961b1823763022d$export$959ca2ee969adb8d(baseUrl, request));
});
parcelRegister("aNPat", function(module, exports) {
    $parcel$export(module.exports, "RouteLinkButtonOld", ()=>$bacdc7ee50699d04$export$c74dddf61fcdf9b0);
    $parcel$export(module.exports, "RouteLinkButton", ()=>$bacdc7ee50699d04$export$64b500ddabb836a3);
    var $5uXOq = parcelRequire("5uXOq");
    var $fVnbM;
    var $1GGwS;
    var $9VXkC;
    var $hDzhb;
    const $bacdc7ee50699d04$export$c74dddf61fcdf9b0 = ({ children: children, to: to, params: params, ...rest })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
            component: (0, (0, parcelRequire("hDzhb")).default),
            ...rest,
            ...to && {
                key: to.name,
                href: (0, (0, parcelRequire("9VXkC")).generatePath)(to.path, params),
                to: to,
                params: params
            }
        }, children);
    const $bacdc7ee50699d04$export$64b500ddabb836a3 = ({ to: to, params: params, children: children, ...rest })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1GGwS")).default), {
            ...rest,
            key: to.name,
            href: (0, (0, parcelRequire("9VXkC")).generatePath)(to.path, params)
        }, children);
});
parcelRegister("1GGwS", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$9768e745d0380796$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $jhmo0;
    var $fuBHX;
    const $9768e745d0380796$var$LinkButtonBase = ({ analyticsContext: analyticsContext, appearance: appearance, 'aria-label': ariaLabel, 'aria-labelledby': ariaLabelledBy, autoFocus: autoFocus, children: children, href: href, iconAfter: iconAfter, iconBefore: iconBefore, interactionName: interactionName, isDisabled: isDisabled, isSelected: isSelected, onClick: onClick, onClickCapture: onClickCapture, onKeyDownCapture: onKeyDownCapture, onKeyUpCapture: onKeyUpCapture, onMouseDownCapture: onMouseDownCapture, onMouseUpCapture: onMouseUpCapture, onPointerDownCapture: onPointerDownCapture, onPointerUpCapture: onPointerUpCapture, onTouchEndCapture: onTouchEndCapture, onTouchStartCapture: onTouchStartCapture, shouldFitContainer: shouldFitContainer, spacing: spacing, testId: testId, ...unsafeRest }, ref)=>{
        const { className: _className, css: _css, as: _as, style: _style, ...saferRest } = unsafeRest;
        const baseProps = (0, (0, parcelRequire("fuBHX")).default)({
            analyticsContext: analyticsContext,
            appearance: appearance,
            ariaLabel: ariaLabel,
            ariaLabelledBy: ariaLabelledBy,
            autoFocus: autoFocus,
            buttonType: 'link',
            children: children,
            iconAfter: iconAfter,
            iconBefore: iconBefore,
            interactionName: interactionName,
            isDisabled: isDisabled,
            isSelected: isSelected,
            onClick: onClick,
            onClickCapture: onClickCapture,
            onKeyDownCapture: onKeyDownCapture,
            onKeyUpCapture: onKeyUpCapture,
            onMouseDownCapture: onMouseDownCapture,
            onMouseUpCapture: onMouseUpCapture,
            onPointerDownCapture: onPointerDownCapture,
            onPointerUpCapture: onPointerUpCapture,
            onTouchEndCapture: onTouchEndCapture,
            onTouchStartCapture: onTouchStartCapture,
            ref: ref,
            shouldFitContainer: shouldFitContainer,
            spacing: spacing,
            testId: testId
        });
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("jhmo0")).default), {
            ...saferRest,
            "aria-label": baseProps['aria-label'],
            "aria-labelledby": baseProps['aria-labelledby'],
            ref: baseProps.ref,
            xcss: baseProps.xcss,
            onClick: baseProps.onClick,
            onMouseDownCapture: baseProps.onMouseDownCapture,
            onMouseUpCapture: baseProps.onMouseUpCapture,
            onKeyDownCapture: baseProps.onKeyDownCapture,
            onKeyUpCapture: baseProps.onKeyUpCapture,
            onTouchStartCapture: baseProps.onTouchStartCapture,
            onTouchEndCapture: baseProps.onTouchEndCapture,
            onPointerDownCapture: baseProps.onPointerDownCapture,
            onPointerUpCapture: baseProps.onPointerUpCapture,
            onClickCapture: baseProps.onClickCapture,
            testId: testId,
            href: baseProps.isDisabled ? undefined : href,
            role: baseProps.isDisabled ? 'link' : undefined,
            "aria-disabled": baseProps.isDisabled === true ? true : undefined,
            analyticsContext: analyticsContext,
            interactionName: interactionName,
            componentName: "LinkButton"
        }, baseProps.children);
    };
    const $9768e745d0380796$var$WithRef = (0, $5uXOq.forwardRef)($9768e745d0380796$var$LinkButtonBase);
    const $9768e745d0380796$var$LinkButton = (0, $5uXOq.memo)($9768e745d0380796$var$WithRef);
    var $9768e745d0380796$export$2e2bcd8739ae039 = $9768e745d0380796$var$LinkButton;
});
parcelRegister("2zwcE", function(module, exports) {
    $parcel$export(module.exports, "HeaderCopyLinkButton", ()=>$35982a17a8499e02$export$23cf9002699304df);
    var $5uXOq = parcelRequire("5uXOq");
    var $jZ4fr;
    var $imZsD;
    var $5Fk4Z;
    var $5T44u;
    var $bOnpg;
    const $35982a17a8499e02$var$DASHBOARD_COPY_BUTTON_SPOTLIGHT = 'dashboardCopyLinkButton';
    const $35982a17a8499e02$export$23cf9002699304df = ({ dashboardId: dashboardId })=>{
        const fireAnalytics = (0, (0, parcelRequire("bOnpg")).useDashboardAnalytics)();
        (0, $5uXOq.useEffect)(()=>{
            if (false) return;
            fireAnalytics({
                eventType: (0, $4d5e0871c06cee03$export$a1562d08625f5d7e),
                action: 'viewed',
                actionSubject: 'dashboard.copy-link-button',
                actionSubjectId: 'dashboard'
            });
        }, [
            fireAnalytics
        ]);
        const onCopyLinkHandler = (0, $5uXOq.useCallback)(()=>{
            fireAnalytics({
                eventType: (0, $4d5e0871c06cee03$export$a1562d08625f5d7e),
                action: 'clicked',
                actionSubject: 'dashboard.copy-link-button',
                actionSubjectId: 'dashboard'
            });
        }, [
            fireAnalytics
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("imZsD")).default), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5Fk4Z")).default), {
            name: $35982a17a8499e02$var$DASHBOARD_COPY_BUTTON_SPOTLIGHT
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5T44u")).CopyLinkButton), {
            dashboardId: dashboardId,
            onCopyClick: onCopyLinkHandler
        })), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("jZ4fr")).EngagementSpotlight), {
            engagementId: $35982a17a8499e02$var$DASHBOARD_COPY_BUTTON_SPOTLIGHT
        }));
    };
});
parcelRegister("kC29b", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$bb02b27ed9289820$export$2e2bcd8739ae039);
    var $7VHMR;
    var $bb02b27ed9289820$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        edit: {
            "id": "dashboard-view.header-readonly.edit",
            "defaultMessage": "Edit"
        }
    });
});
parcelRegister("kiq64", function(module, exports) {
    $parcel$export(module.exports, "UnconfiguredGadgetFallback", ()=>$b259a1068774e356$export$f3ce61fc49906b8e);
    var $5uXOq = parcelRequire("5uXOq");
    var $lzUP9;
    var $luBm6;
    var $cgL5K;
    var $JIPqS;
    var $ii3gE;
    var $i0rkS;
    var $5TsWX;
    var $koVbs;
    var $bVDE5;
    var $8r3Qk;
    const $b259a1068774e356$export$f3ce61fc49906b8e = ({ id: id, hasEditPermissions: hasEditPermissions })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const [, { broadcastMessage: broadcastMessageBusListener }] = (0, (0, parcelRequire("i0rkS")).useMessageBus)();
        const [{ currentPageId: currentPageId }] = (0, (0, parcelRequire("bVDE5")).useSpaStateTransition)();
        const reportGadgetMetrics = (0, $5uXOq.useCallback)((eventType)=>{
            broadcastMessageBusListener((0, (0, parcelRequire("JIPqS")).GADGET_METRICS_TYPE).REPORT, {
                eventType: eventType,
                contentType: 'Unconfigured',
                source: (0, (0, parcelRequire("5TsWX")).FALLBACK_GADGET_SOURCE)
            }, {
                gadgetId: id,
                pageId: currentPageId
            });
        }, [
            broadcastMessageBusListener,
            id,
            currentPageId
        ]);
        (0, $5uXOq.useLayoutEffect)(()=>{
            reportGadgetMetrics((0, (0, parcelRequire("5TsWX")).GADGET_RENDER_CONTAINER_EVENT));
        }, [
            reportGadgetMetrics
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ii3gE")).GadgetFallback), {
            icon: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("lzUP9"))))), {
                label: formatMessage((0, (0, parcelRequire("8r3Qk")).default).settingsIconLabel),
                primaryColor: `var(--ds-icon, ${(0, $829f609a65d26a98$exports).N500})`
            }),
            id: id
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("luBm6")).default), {
            space: "space.150"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cgL5K")).default), {
            size: "UNSAFE_small",
            weight: "semibold",
            color: "color.text.accent.gray"
        }, formatMessage((0, (0, parcelRequire("8r3Qk")).default).mainMessage)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cgL5K")).default), {
            size: "UNSAFE_small",
            weight: "semibold",
            color: "color.text.accent.gray"
        }, formatMessage(hasEditPermissions ? (0, (0, parcelRequire("8r3Qk")).default).instructionsWithEditPermissions : (0, (0, parcelRequire("8r3Qk")).default).instructionsWithoutEditPermissions))));
    };
});
parcelRegister("8r3Qk", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$bd16ba272620eaba$export$2e2bcd8739ae039);
    var $7VHMR;
    var $bd16ba272620eaba$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        mainMessage: {
            "id": "dashboard-view.unconfigured-gadget-fallback.main-message",
            "defaultMessage": "This gadget hasn't been configured yet."
        },
        instructionsWithEditPermissions: {
            "id": "dashboard-view.unconfigured-gadget-fallback.instructions-with-edit-permissions",
            "defaultMessage": "To configure this gadget, click the \u2018Edit\u2019 button situated at the top of your dashboard."
        },
        instructionsWithoutEditPermissions: {
            "id": "dashboard-view.unconfigured-gadget-fallback.instructions-without-edit-permissions",
            "defaultMessage": "To configure this gadget, you need permission to edit this dashboard."
        },
        settingsIconLabel: {
            "id": "dashboard-view.unconfigured-gadget-fallback.settings-icon-label",
            "defaultMessage": "Settings"
        }
    });
});
