import { useCallback, useRef } from 'react';
import { emit } from '@atlassian/forge-ui/events';
import {
	CONNECT_GADGET_REFRESH_REQUEST_EVENT,
	FORGE_JIRA_DASHBOARD_GADGET_REFRESH_EVENT,
} from '@atlassian/jira-dashboard-common/src/constants.tsx';
import type { GadgetData } from '@atlassian/jira-dashboard-common/src/types.tsx';

type EcosystemRefreshParams = Pick<GadgetData, 'id' | 'connect' | 'forge'>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sendConnectRefreshEvent = (payload: any, id?: string) =>
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	window.connectHost?.broadcastEvent(CONNECT_GADGET_REFRESH_REQUEST_EVENT, { id }, payload);

export const useEcosystemRefresh = ({ id, connect, forge }: EcosystemRefreshParams) => {
	const isConnect = Boolean(connect?.addonKey);
	const isForge = Boolean(forge?.key);

	const wrapperRef = useRef<HTMLDivElement>(null);

	const ecosystemOnRefresh = useCallback(() => {
		const payload = {
			origin: 'gadget',
			gadgetId: id,
		};

		if (isConnect) {
			sendConnectRefreshEvent(payload, wrapperRef.current?.querySelector('iframe')?.id);
		} else if (isForge) {
			emit(FORGE_JIRA_DASHBOARD_GADGET_REFRESH_EVENT, payload);
		}
	}, [id, isConnect, isForge]);

	return {
		wrapperRef,
		onRefresh: isConnect || isForge ? ecosystemOnRefresh : undefined,
	};
};
