import { GADGET_DISPLAY } from '@atlassian/jira-dashboard-common/src/constants.tsx';
import type { GadgetDisplay, LayoutType } from '@atlassian/jira-dashboard-common/src/types.tsx';

export const getColumnWidth = (index: number, layout: LayoutType, display: GadgetDisplay) => {
	if (display === GADGET_DISPLAY.HIDDEN) return 0;
	if (layout === 'A' || display === GADGET_DISPLAY.MAXIMIZED) return 12;
	if (layout === 'AA') return 6;
	if (layout === 'AAA') return 4;
	if (layout === 'AB') return index === 0 ? 8 : 4;
	return index === 0 ? 4 : 8;
};
