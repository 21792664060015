import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	mainMessage: {
		id: 'dashboard-view.unconfigured-gadget-fallback.main-message',
		defaultMessage: "This gadget hasn't been configured yet.",
		description:
			'Describing to the user that we could not show a view for this gadget because it is yet to be configured.',
	},
	instructionsWithEditPermissions: {
		id: 'dashboard-view.unconfigured-gadget-fallback.instructions-with-edit-permissions',
		defaultMessage:
			'To configure this gadget, click the ‘Edit’ button situated at the top of your dashboard.',
		description: 'Instructions for the user if they have edit permissions.',
	},
	instructionsWithoutEditPermissions: {
		id: 'dashboard-view.unconfigured-gadget-fallback.instructions-without-edit-permissions',
		defaultMessage: 'To configure this gadget, you need permission to edit this dashboard.',
		description: 'Instructions for the user if they do not have edit permissions.',
	},
	settingsIconLabel: {
		id: 'dashboard-view.unconfigured-gadget-fallback.settings-icon-label',
		defaultMessage: 'Settings',
		description: 'An accessible label for a presentational icon.',
	},
});
