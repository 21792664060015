import React from 'react';
import {
	DASHBOARD_GADGET_MODULE,
	ENTRY_POINT_EDIT,
} from '@atlassian/jira-forge-ui-constants/src/constants.tsx';
import { ForgeAnalyticsWrapper } from '../../common/ui/index.tsx';
import type { AnalyticsWrapperPropsView } from '../../common/ui/types.tsx';

export type Props = {
	isInEditMode: boolean;
} & AnalyticsWrapperPropsView;

export const AnalyticsWrapper = (props: Props) => {
	const { isInEditMode, ...restProps } = props;
	return (
		<ForgeAnalyticsWrapper
			{...restProps}
			module={DASHBOARD_GADGET_MODULE}
			entryPoint={isInEditMode ? ENTRY_POINT_EDIT : undefined}
			shouldFireInitializedEvent
		/>
	);
};
