import { GADGET_ERROR_TYPE } from '@atlassian/jira-dashboard-common/src/constants.tsx';
import messages from './messages.tsx';

export const MESSAGES_BY_ERROR = {
	[GADGET_ERROR_TYPE.CONFIG_ERROR]: messages.configErrorMessage,
	[GADGET_ERROR_TYPE.VIEW_ERROR]: messages.errorMessage,
	[GADGET_ERROR_TYPE.EDIT_ERROR]: messages.errorMessage,
	[GADGET_ERROR_TYPE.NOT_SUPPORTED_ERROR]: messages.notSupportedErrorMessage,
} as const;

export const IS_REFRESH_BY_ERROR = {
	[GADGET_ERROR_TYPE.CONFIG_ERROR]: true,
	[GADGET_ERROR_TYPE.VIEW_ERROR]: true,
	[GADGET_ERROR_TYPE.EDIT_ERROR]: true,
	[GADGET_ERROR_TYPE.NOT_SUPPORTED_ERROR]: false,
} as const;
