import React, { useCallback, useEffect } from 'react';
import { EngagementSpotlight } from '@atlassiansox/engagekit-ts';
import { SpotlightManager, SpotlightTarget } from '@atlaskit/onboarding';
import { CopyLinkButton } from '@atlassian/jira-dashboard-common/src/ui/copy-link-button/index.tsx';
import { useDashboardAnalytics } from '@atlassian/jira-dashboard-internal-common/src/controllers/dashboard-analytics/main.tsx';
import { UI_EVENT_TYPE } from '@atlassian/jira-product-analytics-bridge';

const DASHBOARD_COPY_BUTTON_SPOTLIGHT = 'dashboardCopyLinkButton';

export type HeaderCopyLinkButtonProps = {
	dashboardId: string;
};

export const HeaderCopyLinkButton = ({ dashboardId }: HeaderCopyLinkButtonProps) => {
	const fireAnalytics = useDashboardAnalytics();

	useEffect(() => {
		if (__SERVER__) {
			return;
		}
		fireAnalytics({
			eventType: UI_EVENT_TYPE,
			action: 'viewed',
			actionSubject: 'dashboard.copy-link-button',
			actionSubjectId: 'dashboard',
		});
	}, [fireAnalytics]);

	const onCopyLinkHandler = useCallback(() => {
		fireAnalytics({
			eventType: UI_EVENT_TYPE,
			action: 'clicked',
			actionSubject: 'dashboard.copy-link-button',
			actionSubjectId: 'dashboard',
		});
	}, [fireAnalytics]);

	return (
		<SpotlightManager>
			<SpotlightTarget name={DASHBOARD_COPY_BUTTON_SPOTLIGHT}>
				<CopyLinkButton dashboardId={dashboardId} onCopyClick={onCopyLinkHandler} />
			</SpotlightTarget>
			<EngagementSpotlight engagementId={DASHBOARD_COPY_BUTTON_SPOTLIGHT} />
		</SpotlightManager>
	);
};
