import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { GADGET_DISPLAY } from '@atlassian/jira-dashboard-common/src/constants.tsx';
import type { GadgetData, GadgetDisplay } from '@atlassian/jira-dashboard-common/src/types.tsx';
import type { LocalStorageService } from '../../services/local-storage.tsx';

export type GadgetCommonControllerArg = {
	id: GadgetData['id'];
	display: GadgetDisplay;
	automaticRefreshMs: number | null;
	localStorageService: LocalStorageService;
	dashboardRefreshId: number | null;
	onGadgetKeyChange: () => void;
};

export const useGadgetCommonController = ({
	id,
	display,
	automaticRefreshMs,
	localStorageService: {
		get: localStorageServiceGet,
		put: localStorageServicePut,
		del: localStorageServiceDel,
	},
	dashboardRefreshId,
	onGadgetKeyChange,
}: GadgetCommonControllerArg) => {
	const isDisplayed = display !== GADGET_DISPLAY.HIDDEN;
	const isMaximized = display === GADGET_DISPLAY.MAXIMIZED;

	const [isMinimized, setIsMinimized] = useState(
		() => localStorageServiceGet('minimized') === 'true',
	);

	const onMinimizeOrRestore = useCallback<(arg1: boolean) => void>(
		(doMinimize) => {
			if (doMinimize !== isMinimized) {
				if (doMinimize) {
					localStorageServicePut('minimized', 'true');
				} else {
					localStorageServiceDel('minimized');
				}
				setIsMinimized(doMinimize);
			}
		},
		[isMinimized, setIsMinimized, localStorageServicePut, localStorageServiceDel],
	);

	const [shouldUseCache, setShouldUseCache] = useState<boolean>(true);
	const [refreshId, setRefreshId] = useState<number>(Date.now());
	const renderKey = `${id}-${refreshId}-${dashboardRefreshId}-${
		isMaximized ? 'canvas' : 'default'
	}`;
	const prevRenderKey = useRef(renderKey);

	const onRefresh = useCallback(() => {
		setShouldUseCache(false);
		setRefreshId(Date.now());
	}, []);

	useEffect(() => {
		if (automaticRefreshMs == null || automaticRefreshMs <= 0) {
			// Replace with lodash/noop
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			return () => {};
		}

		// The interval is stored in a signed 32-bit int so any number above 2**31-1 would
		// become negative which will cause the callback to infinitely loop without any delay.
		// https://stackoverflow.com/a/12633556/1104483
		const refreshIntervalMs = Math.min(automaticRefreshMs, Math.pow(2, 31) - 1);
		const interval = setInterval(onRefresh, refreshIntervalMs);
		return () => {
			clearInterval(interval);
		};
	}, [automaticRefreshMs, onRefresh]);

	useLayoutEffect(() => {
		if (prevRenderKey.current !== renderKey) {
			prevRenderKey.current = renderKey;
			onGadgetKeyChange();
		}
	}, [renderKey, onGadgetKeyChange]);

	// id is a timestamp
	const lastRefreshed = Math.max(Number(dashboardRefreshId), refreshId);

	return {
		isDisplayed,
		isMaximized,
		isMinimized,
		onMinimizeOrRestore,
		renderKey,
		shouldUseCache,
		onRefresh,
		lastRefreshed,
	};
};
