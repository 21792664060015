// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ReactNode, type ReactElement, type ComponentProps } from 'react';
import { Grid, GridColumn } from '@atlaskit/page';
import PageHeader from '@atlassian/jira-common-components-page-header/src/index.tsx';
import { ActionWrapper, BannersWrapper } from './styled.tsx';
import type { HeaderActionsProps, HeaderTitleProps } from './types.tsx';

export const HeaderTitle = ({ children }: HeaderTitleProps) => (
	<GridColumn
		medium={4}
		testId="dashboard-internal-common.ui.dashboard-content.header.header-title"
	>
		<PageHeader>{children}</PageHeader>
	</GridColumn>
);

export const HeaderActions = ({ children }: HeaderActionsProps) => (
	<>
		<GridColumn medium={8}>
			<ActionWrapper>{children}</ActionWrapper>
		</GridColumn>
	</>
);

export const Header = ({
	banners,
	children,
}: {
	banners?: ReactNode;
	children: [
		ReactElement<ComponentProps<typeof HeaderTitle>>,
		ReactElement<ComponentProps<typeof HeaderActions>>,
	];
}) => (
	<header data-testid="dashboard-internal-common.ui.dashboard-content.header.dashboard-header">
		<Grid layout="fluid" spacing="compact">
			{children}
		</Grid>
		{banners && <BannersWrapper>{banners}</BannersWrapper>}
	</header>
);
