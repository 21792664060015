import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	gadgetDeprecationTooltip: {
		defaultMessage:
			"Starting May 2025, we're no longer supporting this gadget. Delete it before then to reference its data in the available alternatives.<br></br><Link>More about the gadgets and the extended timeline</Link>",
		id: 'dashboard-internal-common.common.gadget.deprecation-tooltip.gadget-deprecation-tooltip.non-final',
		description: 'The message to be displayed to the user about gadget deprecation.',
	},
});
