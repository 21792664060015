import { useMemo, useCallback } from 'react';
import {
	GADGET_DISPLAY,
	MAXIMIZED_PARAM,
} from '@atlassian/jira-dashboard-common/src/constants.tsx';
import type { GadgetData, GadgetDisplay } from '@atlassian/jira-dashboard-common/src/types.tsx';
import { useSpaStateActions } from '@atlassian/jira-spa-state-controller/src/common/index.tsx';
import { useQueryParam, useRouter } from '@atlassian/react-resource-router';

export type Window = {
	location: {
		href: string;
		assign: (url: string) => void;
	};
};

export type GadgetReturnProps = {
	isDraggable: boolean;
	display: GadgetDisplay;
	onMaximizeOrRestore: (arg1: boolean) => void;
};

export type ColumnReturnProps = {
	display: GadgetDisplay;
};

export const useMaximizedGadget = (gadgetsData: GadgetData[] | null) => {
	const [
		{
			location: { hash },
		},
	] = useRouter();
	const [maximizedQueryParam, setMaximizedQueryParam] = useQueryParam(MAXIMIZED_PARAM);
	const [, { setAppReady }] = useSpaStateActions();

	// The regex matches any character (except a newline character)
	// zero or more times .* followed by a '/'. The (?<id>\d+) part is a
	// named capturing group which matches one or more digits and assigns
	// the group to an id value accessible through .groups.id
	// We are using a backup hash decodedId for legacy URLs in case users have
	// bookmarked a legacy URL. Legacy URLs used '#' instead of '?maximized='
	// (e.g. https://.../jira/dashboards/{dashboardId}#{some-text}/{gadgetId})
	const decodedId = hash.match(/.*\/(?<id>\d+)/)?.groups?.id ?? null;
	const idFromHashOrQuery = maximizedQueryParam != null ? maximizedQueryParam : decodedId;
	const gadget =
		(idFromHashOrQuery != null && gadgetsData?.find(({ id }) => id === idFromHashOrQuery)) || null;
	const maximizedId = gadget?.isMaximizable ? idFromHashOrQuery : null;

	const getMaximisedGadgetId = useMemo(() => maximizedId, [maximizedId]);

	const getPropsByColumnIndex = useCallback<(arg1: number) => ColumnReturnProps>(
		(column) => {
			const maximizedGadget =
				maximizedId !== null ? gadgetsData?.find(({ id }) => id === maximizedId) : null;
			const { column: maximizedColumn = null } = maximizedGadget ?? {};
			const display =
				(maximizedColumn === null && GADGET_DISPLAY.NORMAL) ||
				(maximizedColumn === column && GADGET_DISPLAY.MAXIMIZED) ||
				GADGET_DISPLAY.HIDDEN;

			return { display };
		},
		[maximizedId, gadgetsData],
	);

	// create curried callbacks once in the memoized scope
	const maximizedLookup = useMemo<{
		[key: string]: (arg1: boolean) => void;
	}>(
		() =>
			Object.fromEntries(
				gadgetsData
					?.filter(({ isMaximizable }) => isMaximizable)
					.map((data: GadgetData) => [
						data.id,
						(doMaximizeNotRestore: boolean) => {
							if (maximizedId === (doMaximizeNotRestore ? data.id : null)) {
								return;
							}
							setMaximizedQueryParam(doMaximizeNotRestore ? data.id : undefined);
							setTimeout(setAppReady);
						},
					]) ?? [],
			),
		[gadgetsData, maximizedId, setAppReady, setMaximizedQueryParam],
	);

	const getPropsByGadgetId = useCallback<(arg1: string) => GadgetReturnProps>(
		(id) => {
			// Get display by gadget ID
			const display =
				(maximizedId === null && GADGET_DISPLAY.NORMAL) ||
				(maximizedId === id && GADGET_DISPLAY.MAXIMIZED) ||
				GADGET_DISPLAY.HIDDEN;

			// Get isDraggable by gadget ID
			const isDraggable = id !== maximizedId;

			// Get onMaximizeOrRestore by gadget ID
			const onMaximizeOrRestore = maximizedLookup[id] ?? (() => undefined);

			return { display, isDraggable, onMaximizeOrRestore };
		},
		[maximizedId, maximizedLookup],
	);

	return {
		getMaximisedGadgetId,
		getPropsByGadgetId,
		getPropsByColumnIndex,
	};
};
