import React from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { DEPRECATION_BANNER_V1 } from '../../constants.tsx';
import type { GadgetData } from '../../types.tsx';
import { useDashboardHasDeprecatedGadget } from '../../utils/use-dashboard-has-deprecated-gadget.tsx';
import { useDeprecationBannerState } from '../../utils/use-deprecation-banner-state.tsx';
import { DeprecationBannerV1 } from '../deprecation-banner-v1/index.tsx';

const DEPRECATION_BANNERS = {
	endeavour_gadget_deprecation_banner_v1: {
		version: DEPRECATION_BANNER_V1,
		component: DeprecationBannerV1,
	},
	// add more banners later
};

export const DeprecationBanner = ({
	gadgets,
	gadgetId,
}: {
	gadgets: GadgetData[];
	gadgetId: string;
}) => {
	const { dashboardHasDeprecatedGadget } = useDashboardHasDeprecatedGadget(gadgets);

	const featureGatedBannerVersion: keyof typeof DEPRECATION_BANNERS =
		'endeavour_gadget_deprecation_banner_v1';
	let hasDeprecationBannerFGon = false;

	if (fg('endeavour_gadget_deprecation_banner_v1')) {
		hasDeprecationBannerFGon = true;
	}

	const BannerComponent = DEPRECATION_BANNERS[featureGatedBannerVersion].component;
	const { isDeprecationBannerDismissed, onDeprecationBannerDismiss } = useDeprecationBannerState(
		DEPRECATION_BANNERS[featureGatedBannerVersion].version,
		gadgetId,
	);

	return hasDeprecationBannerFGon &&
		!isDeprecationBannerDismissed &&
		dashboardHasDeprecatedGadget ? (
		<BannerComponent onDismiss={onDeprecationBannerDismiss} />
	) : null;
};
