/**
 * Feature flags convention: go/frontend-feature-flags
 */

import memoizeOne from 'memoize-one';
import { getAllFeatureFlags } from '@atlassian/jira-feature-flagging-using-meta';

// IMPORTANT - endeavour dashboard flags should match schema, this is primarily for other teams FFs
const DASHBOARD_ADDITIONAL_FLAGS: string[] = [];
const DASHBOARD_FEATURE_FLAG_SCHEMA = /^endeavour\.(spa|dashboard|gadget)\.[\w-]+$/;

export const getDashboardFeatureFlags = memoizeOne(() => {
	// at time of writing getFeatureFlags() will throw for badly formed feature flag JSON
	try {
		const allFFs = getAllFeatureFlags() || {};
		return Object.fromEntries(
			Object.keys(allFFs)
				.filter(
					(key) =>
						DASHBOARD_FEATURE_FLAG_SCHEMA.test(key) || DASHBOARD_ADDITIONAL_FLAGS.includes(key),
				)
				.sort((a, b) => a.localeCompare(b, undefined, { ignorePunctuation: true }))
				.map((key) => [key, allFFs[key].value]),
		);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		return null;
	}
});
