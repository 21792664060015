import React, { useMemo, useState, type ReactNode } from 'react';
import type { GadgetData, GadgetDisplay } from '@atlassian/jira-dashboard-common/src/types.tsx';
import { decodeUserPreferences } from '@atlassian/jira-dashboard-user-preference/src/services/get-user-preferences/index.tsx';
import { useLocalStorageService } from '../../services/local-storage.tsx';
import { useEcosystemRefresh } from '../../utils/use-ecosystem-refresh/index.tsx';
import { GadgetControllerContextProvider } from '../gadget/context.tsx';
import { useGadgetCommonController } from '../gadget/main.tsx';
import { useReactGadget } from '../react-gadget.tsx';
import { useGadgetReadOnlyController } from './hook.tsx';

type GadgetReadOnlyControllerProps = {
	data: GadgetData;
	display: GadgetDisplay;
	dashboardRefreshId: number;
	children: ReactNode;
};

export const GadgetReadOnlyController = ({
	data: {
		id,
		userPrefs,
		reactKey,
		configurable,
		refreshable: isRefreshableByJira,
		connect,
		forge,
		...data
	},
	display,
	dashboardRefreshId,
	children,
}: GadgetReadOnlyControllerProps) => {
	const localStorageService = useLocalStorageService(id);

	const {
		reinitialise: onGadgetKeyChange,
		isEditable: isReactEditable,
		isLoading: isReactLoading,
	} = useReactGadget(id, reactKey);

	const automaticRefreshMs = useMemo(() => {
		const { refresh } = decodeUserPreferences(userPrefs?.fields);
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return parseInt(refresh as string, 10) * 60 * 1000 || null;
	}, [userPrefs?.fields]);

	const [isLoading, setIsLoading] = useState<boolean>(true);

	const readonly = useGadgetReadOnlyController({
		userPrefs,
		configurable: isReactEditable ?? configurable,
		...data,
	});

	// Ecosystem gadgets are not supposed to re-render if they are opting out refreshing
	const ignoreGlobalDashboardRefresh =
		Boolean(forge?.key || connect?.addonKey) && !isRefreshableByJira;

	const { onRefresh, ...common } = useGadgetCommonController({
		id,
		display,
		automaticRefreshMs,
		localStorageService,
		dashboardRefreshId: ignoreGlobalDashboardRefresh ? null : dashboardRefreshId,
		onGadgetKeyChange,
	});

	const ecosystemRefresh = useEcosystemRefresh({ id, connect, forge });

	return (
		<GadgetControllerContextProvider
			value={{
				...common,
				...readonly,
				isRenamable: false,
				isLoading: isReactLoading ?? isLoading,
				setIsLoading: reactKey ? () => undefined : setIsLoading,
				onRefresh: isRefreshableByJira ? onRefresh : ecosystemRefresh.onRefresh ?? onRefresh,
			}}
		>
			<div ref={ecosystemRefresh.wrapperRef}>{children}</div>
		</GadgetControllerContextProvider>
	);
};
