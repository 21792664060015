import React, { type ReactNode } from 'react';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import { useIntl } from '@atlassian/jira-intl';
import { Link } from '@atlassian/react-resource-router';
import messages from './messages.tsx';

export const DeprecationBannerV1 = ({ onDismiss }: { onDismiss: () => void }) => {
	const { formatMessage } = useIntl();
	return (
		<SectionMessage
			appearance="warning"
			actions={[
				<SectionMessageAction key="gadgetDeprecationBannerDimissV1" onClick={onDismiss}>
					{formatMessage(messages.gadgetDeprecationMessageDismissV1)}
				</SectionMessageAction>,
			]}
			title={formatMessage(messages.gadgetDeprecationTitleV1)}
		>
			{formatMessage(messages.gadgetDeprecationMessageV1, {
				Link: (chunks: ReactNode[]) => (
					<Link href="https://community.atlassian.com/t5/Jira-articles/Update-We-re-ending-support-for-five-dashboard-gadgets-in-May/ba-p/2844556">
						{chunks}
					</Link>
				),
				br: () => (
					<>
						<br />
						<br />
					</>
				),
			})}
		</SectionMessage>
	);
};
