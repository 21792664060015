import { useCallback, useLayoutEffect, useRef } from 'react';
import { setMark } from '@atlassian/jira-common-performance/src/marks.tsx';
import {
	GADGET_METRICS_TYPE,
	PAGE_READY_METRIC_KEY,
	CONNECT_ITEM_AMD_MODULE,
} from '@atlassian/jira-dashboard-common/src/constants.tsx';
import type {
	DashboardMessage,
	DashboardMessageContext,
	GadgetMetricType,
	GadgetData,
} from '@atlassian/jira-dashboard-common/src/types.tsx';
import { useDashboardResource } from '@atlassian/jira-router-resources-dashboard/src/index.tsx';
import { useSpaStateActions } from '@atlassian/jira-spa-state-controller/src/common/index.tsx';
import { useMessageBus } from './message-bus/message-bus.tsx';

const isAmdGadget = (gadget: GadgetData) =>
	gadget.amdModule != null && gadget.amdModule !== CONNECT_ITEM_AMD_MODULE;

const isWaitableGadget = (gadget: GadgetData) => isAmdGadget(gadget);

const getWaitableGadgetSet = (gadgets: GadgetData[]) =>
	new Set(gadgets.filter(isWaitableGadget).map(({ id }) => id));

export const useSpaStatePageReady = () => {
	const { data, loading } = useDashboardResource();
	const [, { registerListener, unregisterListener }] = useMessageBus();
	const lastDashboardId = useRef<unknown>();
	const pendingGadgetSet = useRef(new Set());
	const [, { setAppReady }] = useSpaStateActions();

	const callback = useCallback(
		(type: GadgetMetricType, _: DashboardMessage, { gadgetId }: DashboardMessageContext) => {
			if (type !== GADGET_METRICS_TYPE.REPORT) {
				return;
			}
			if (!pendingGadgetSet.current.has(gadgetId)) {
				return;
			}

			pendingGadgetSet.current.delete(gadgetId);
			if (pendingGadgetSet.current.size > 0) {
				return;
			}

			unregisterListener(callback);
			setMark(PAGE_READY_METRIC_KEY);
			setAppReady();
		},
		[setAppReady, unregisterListener],
	);

	useLayoutEffect(() => {
		if (loading || data == null || data.id === lastDashboardId.current) {
			// Replace with lodash/noop
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			return () => {};
		}

		lastDashboardId.current = data.id;
		pendingGadgetSet.current = getWaitableGadgetSet(data.gadgets);

		if (pendingGadgetSet.current.size === 0) {
			setMark(PAGE_READY_METRIC_KEY);
			setAppReady();
			// Replace with lodash/noop
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			return () => {};
		}

		registerListener(callback);

		return () => {
			unregisterListener(callback);
		};
	}, [callback, data, loading, registerListener, setAppReady, unregisterListener]);
};
