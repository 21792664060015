import React, { useCallback, type ReactNode } from 'react';
import EditFilledIcon from '@atlaskit/icon/core/migration/edit--edit-filled';
import {
	ROUTE_NAMES_SYSTEM_DASHBOARD_EDIT,
	ROUTE_NAMES_DASHBOARD_EDIT,
} from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { DASHBOARD_VIEW_SOURCENAME } from '@atlassian/jira-dashboard-common/src/constants.tsx';
import type { DashboardConfigType } from '@atlassian/jira-dashboard-common/src/types.tsx';
import { useDashboardAnalytics } from '@atlassian/jira-dashboard-internal-common/src/controllers/dashboard-analytics/main.tsx';
import { ActionDropdown } from '@atlassian/jira-dashboard-internal-common/src/ui/dashboard-content/header/action-dropdown/main.tsx';
import { ConnectHeader } from '@atlassian/jira-dashboard-internal-common/src/ui/dashboard-content/header/connect-header/index.tsx';
import {
	Header,
	HeaderTitle,
	HeaderActions,
} from '@atlassian/jira-dashboard-internal-common/src/ui/dashboard-content/header/main.tsx';
import { useConnectMenuItems } from '@atlassian/jira-dashboard-internal-common/src/ui/dashboard-content/header/utils.tsx';
import { DashboardRefreshButton } from '@atlassian/jira-dashboard-internal-common/src/ui/dashboard-refresh-button/index.tsx';
import { FavoriteButton } from '@atlassian/jira-dashboard-internal-common/src/ui/favorite/index.tsx';
import {
	RouteLinkButtonOld,
	RouteLinkButton,
} from '@atlassian/jira-dashboard-internal-common/src/ui/route-link-button/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useRouteByName } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import { UI_EVENT_TYPE } from '@atlassian/jira-product-analytics-bridge';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { HeaderCopyLinkButton } from './header-copy-link-button.tsx';
import messages from './messages.tsx';

export type HeaderReadOnlyProps = {
	isEditLinkEnabled: boolean;
	data: DashboardConfigType;
	banners: ReactNode;
};

export const HeaderReadOnly = ({ data, isEditLinkEnabled, banners }: HeaderReadOnlyProps) => {
	const tenantContext = useTenantContext();
	const { isAnonymous } = tenantContext;
	const { id, title, systemDashboard } = data;
	const { headerItems, toolItems } = useConnectMenuItems(id);
	const { formatMessage } = useIntl();
	const editRoute = useRouteByName(
		systemDashboard ? ROUTE_NAMES_SYSTEM_DASHBOARD_EDIT : ROUTE_NAMES_DASHBOARD_EDIT,
	);

	const fireAnalytics = useDashboardAnalytics();

	const onClickHandler = useCallback(() => {
		fireAnalytics({
			eventType: UI_EVENT_TYPE,
			action: 'clicked',
			actionSubject: 'dashboard.edit-button',
			actionSubjectId: DASHBOARD_VIEW_SOURCENAME,
		});
	}, [fireAnalytics]);

	return (
		<Header banners={banners}>
			<HeaderTitle>{title}</HeaderTitle>
			<HeaderActions>
				<ConnectHeader items={headerItems} />
				{!isAnonymous && <FavoriteButton dashboardId={id} dashboardName={title} />}
				<HeaderCopyLinkButton dashboardId={id} />
				<DashboardRefreshButton />
				{isEditLinkEnabled &&
					(editRoute && isVisualRefreshEnabled() ? (
						<RouteLinkButton
							to={editRoute}
							params={{ dashboardId: id }}
							onClick={onClickHandler}
							testId="dashboard-view.ui.header-readonly.dashboard-edit-button"
							iconAfter={EditFilledIcon}
						>
							{formatMessage(messages.edit)}
						</RouteLinkButton>
					) : (
						<RouteLinkButtonOld
							to={editRoute ?? undefined}
							params={{ dashboardId: id }}
							prefetch="hover"
							onClick={onClickHandler}
							testId="dashboard-view.ui.header-readonly.dashboard-edit-button"
							iconAfter={<EditFilledIcon label="" LEGACY_size="small" color="currentColor" />}
						>
							{formatMessage(messages.edit)}
						</RouteLinkButtonOld>
					))}
				<ActionDropdown data={data} connectToolMenuItems={toolItems} />
			</HeaderActions>
		</Header>
	);
};
