import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ActionWrapper = styled.div({
	display: 'flex',
	justifyContent: 'flex-end',
	margin: `${token('space.300', '24px')} 0 ${token('space.200', '16px')} 0`,
	gap: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'a.connect-item': {
		padding: 0,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		div: {
			padding: `${token('space.100', '8px')} ${token('space.150', '12px')} 7px ${token(
				'space.150',
				'12px',
			)}`,
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const BannersWrapper = styled.div({
	display: 'flex',
	flexDirection: 'column',
	gap: token('space.100', '8px'),
	'&:empty': {
		marginBottom: '0px',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:not(:empty)': {
		marginBottom: token('space.300', '24px'),
	},
});
