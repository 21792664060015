import { setMark } from '@atlassian/jira-common-performance/src/marks.tsx';
import { TTI_RAF_MARK } from '../constants.tsx';
import type { BM3Metric } from '../submit-apdex-mark/types.tsx';

export const afterPaintEmit = (emitMetrics: (stopTime: number) => void, metric: BM3Metric) => {
	const stopTime = performance.now();
	setTimeout(() => {
		metric.mark(TTI_RAF_MARK);
		setMark(TTI_RAF_MARK);

		setTimeout(() => {
			emitMetrics(stopTime);
		}, 500);
	});
};
