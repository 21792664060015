import { createRendererContext } from '../../utils/context/index.tsx';

export type DashboardControllerContextType = {
	onRefresh: () => void;
	refreshId: number;
	onMount: (id: string, focusCallback: () => void) => void;
	onAddedGadget: (id: string) => void;
};

const {
	createUseContextField: createUseDashboardControllerContextField,
	Provider: DashboardControllerContextProvider,
	Consumer: DashboardControllerContextConsumer,
} = createRendererContext<DashboardControllerContextType>();
export {
	createUseDashboardControllerContextField,
	DashboardControllerContextProvider,
	DashboardControllerContextConsumer,
};

export const useDashboardRefresh = createUseDashboardControllerContextField('onRefresh');
export const useOnAddedGadget = createUseDashboardControllerContextField('onAddedGadget');
