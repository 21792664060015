import { useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';

export const useDebouncedWindowWidth = (ms = 100) => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const [width, setWidth] = useState(window.innerWidth);
	const debounced = useRef(debounce((w: number) => setWidth(w), ms));

	useEffect(() => {
		const handleResize = () => {
			const { innerWidth } = window;

			if (innerWidth !== width) {
				debounced.current(innerWidth);
			}
		};

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.addEventListener('resize', handleResize);

		return () => {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.removeEventListener('resize', handleResize);
		};
	}, [width]);

	return width;
};
