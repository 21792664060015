import noop from 'lodash/noop';
import sendExperienceAnalytics from '@atlassian/jira-common-experience-tracking-analytics/src/index.tsx';
import logger from '@atlassian/jira-common-util-logging/src/log.tsx';
import {
	SAVE_AMD_GADGET_CONFIG_PREFERENCES,
	GADGET_ERROR_TYPE,
} from '@atlassian/jira-dashboard-common/src/constants.tsx';
import type { InlineGadgetApi } from '@atlassian/jira-dashboard-common/src/types.tsx';
import type { ItemProperties } from '@atlassian/jira-dashboard-user-preference/src/types.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { updateLocalStorageHeight } from '../local-storage/index.tsx';
import type { GadgetApiArgs } from './types.tsx';

// check monolith AG.InlineGadgetAPI.js
export const getInlineGadgetApi = ({
	gadgetId,
	amdModule,
	context,
	defaultTitle,
	isEditable,
	closeEdit,
	ref,
	setIsLoading,
	setDefaultTitle,
	reportGadgetMetrics = noop,
	dashboardId,
	setPreferences,
	addEventHandler,
	removeEventHandlers,
	triggerEvent,
	shouldUseCache,
	view,
	setError,
}: GadgetApiArgs): InlineGadgetApi => {
	const resize = () => {
		if (!dashboardId || !gadgetId) {
			return;
		}
		// Store current height of gadget in local storage
		const height = ref.current?.getBoundingClientRect().height;
		updateLocalStorageHeight(dashboardId, gadgetId, height);
	};

	const moduleAPI = {
		on: (id: string, handler: () => void) => addEventHandler(id, handler),
		once: (id: string, handler: () => void) => addEventHandler(id, handler, true),
		off: (id: string | undefined, handler: (() => void) | undefined) =>
			removeEventHandlers(id, handler),
		trigger: (id: string) => triggerEvent(id),
	};

	const expAttributes = {
		analyticsSource: 'dashboard',
		experience: SAVE_AMD_GADGET_CONFIG_PREFERENCES,
		application: null,
		edition: null,
		additionalAttributes: {},
	};

	return {
		gadget: {
			amdModule,
			context,
			moduleAPI,
		},
		showLoadingBar: () => {
			setIsLoading(true);
		},
		resize,
		isEditable: () => isEditable,
		savePreferences: async (preferences: ItemProperties) => {
			// IMPORTANT
			//  per JDAG-413 we need to batch react state updates with route resource update
			const maybePromise = setPreferences(preferences, () => {
				setError(null);
				closeEdit();
			});
			if (!maybePromise) {
				return;
			}
			try {
				await maybePromise;
				sendExperienceAnalytics({
					...expAttributes,
					wasExperienceSuccesful: true,
				});
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (e: any) {
				logger.safeErrorWithoutCustomerData(
					'spa-apps.dashboard.gadget.amd.api',
					`${e.message} [${defaultTitle}]`,
				);
				setError(GADGET_ERROR_TYPE.CONFIG_ERROR);
				sendExperienceAnalytics({
					...expAttributes,
					wasExperienceSuccesful: false,
				});
				fireErrorAnalytics({
					error: e,
					meta: {
						id: SAVE_AMD_GADGET_CONFIG_PREFERENCES,
						packageName: 'jiraDashboardInternalCommon',
					},
				});
			}
		},
		setTitle: setDefaultTitle,
		hideLoadingBar: () => setIsLoading(false),
		initRefresh: noop,
		getRefreshFieldValue: (fieldName: string) => {
			const input = ref.current?.querySelector(`input[name=${fieldName || 'refresh'}]`);

			if (input == null || !(input instanceof HTMLInputElement) || !input.checked) {
				return 'false';
			}

			return input.value;
		},
		getGadgetId: () => gadgetId,
		closeEdit,
		// deprecated
		forceLayoutRefresh: () => {
			logger.safeWarnWithoutCustomerData(
				'endeavour.spa.dashboard.gadget.amd.api',
				'deprecated forceLayoutRefresh() is called',
			);
			resize();
		},
		publicInstace: () =>
			logger.safeWarnWithoutCustomerData(
				'endeavour.spa.dashboard.gadget.amd.api',
				'Unexpected public instance called',
			),
		_preparePreferencesToBeSaved: (preference: ItemProperties) => {
			let preferenceStr = '';
			try {
				preferenceStr = JSON.stringify(preference);
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (e: any) {
				preferenceStr = 'non-stringifiable type';
			}

			logger.safeWarnWithoutCustomerData(
				'endeavour.spa.dashboard.gadget.amd.api',
				`Unexpected prepare preferences called with ${preferenceStr} for ${defaultTitle}`,
			);
		},
		getContext: () => context,
		// deprecated, replaced by react life cycle
		...moduleAPI,
		listenTo: noop,
		stopListening: noop,
		listenToOnce: noop,
		reportGadgetMetrics,
		shouldUseCache,
		view,
	};
};
