import { metrics } from '@atlassian/browser-metrics';

export const gadgetLoad = metrics.concurrent.custom({
	key: 'dashboard.gadget',
	featureFlags: [],
});

export const dashboardLoad = metrics.pageLoad({
	key: 'dashboard',
	featureFlags: [],
});
