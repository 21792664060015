import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	copyTextLabel: {
		defaultMessage: 'Copy link',

		id: 'dashboard-view.copy-link-button.copy-link',
		description: 'Copy dashboard or dashboard gadget link button tooltip',
	},
	copiedLabel: {
		defaultMessage: 'Copied!',

		id: 'dashboard-view.copy-link-button.copied',
		description: 'Copied dashboard or dashboard gadget link button tooltip',
	},
});
